
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State, Getter } from 'vuex-class';
import { Hospital } from '@/store/hospitals/types';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DeceasedDonorOrganDonations, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { OrganCodeValue, OrganOfferSpecificationCodeValue } from '@/store/lookups/types';

interface RecoveryOutcomeRow {
  organ: string;
  outcome: string;
  organization: string;
  recipientId?: string|null;
  recipientName?: string;
  recipientNameShort: string;
  recipientAge: string;
  lastUpdate: string;
  lastUpdateTime?: string;
  isOutOfProvince: boolean;
}

@Component({
  components: {
    SubSection,
    CardSection,
  }
})
export default class showMonitorRecoveryOutcomes extends mixins(DateUtilsMixin) {
  @State(state => state.deceasedDonors.organDonations) private organDonations!: DeceasedDonorOrganDonations[]|null;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: number;
  @Getter('getHospitalById', { namespace: 'hospitals' }) getHospitalById!: (hospitalCode?: string|null) => Hospital|null;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('getOrganSpecificationCode', { namespace: 'deceasedDonors' }) getOrganSpecificationCode!: (organDonation: DeceasedDonorOrganDonations) => number|null;

  private lookupsToLoad = [
    'donor_organ_donation_outcomes',
  ];

  //Table configuration
  get recoveryOutcomesTableConfig(): TableConfig {
    return {
      data: this.recoveryOutcomeRows,
      columns: [
        { label: this.$t('organs').toString(), field: 'organ' },
        // TP B#15335 TGLN_UAT: SP 4073 - Donor Summary - "Outcome" column should be hidden
        // { label: this.$t('outcome').toString(), field: 'outcome' },
        { label: this.$t('receiving_organization').toString(), field: 'organization' },
        { label: this.$t('recipientID').toString(), field: 'recipientId' },
        { label: this.$t('recipientName').toString(), field: 'recipientName' },
        { label: this.$t('recipientAge').toString(), field: 'recipientAge' },
        { label: this.$t('lastUpdate').toString(), field: 'lastUpdate' },
      ],
      empty: this.emptyMessage,
    };
  }

  get emptyMessage(): string {
    if (!this.organDonations) {
      return this.$t('loading').toString();
    } else {
      return this.$t('no_recovery_outcomes').toString();
    }
  }

  // Map data loaded from API to array of UI rows
  get recoveryOutcomeRows(): RecoveryOutcomeRow[] {
    const collection: DeceasedDonorOrganDonations[] = this.organDonations || [];

    // check for Kidney organ in donated_organs if there's a Kidney Both Organ Specification in organ_offer
    const hasKidneyBoth = collection.some((organDonation: DeceasedDonorOrganDonations) => {
      return this.getOrganSpecificationCode(organDonation) === OrganOfferSpecificationCodeValue.BothKidneys;
    });

    // Filter out other kidney donated_organs if other kidney organ_offer has Both Kidney Specification.
    const filtered: DeceasedDonorOrganDonations[] = collection.filter((organDonation: DeceasedDonorOrganDonations) => {
      if ( hasKidneyBoth && organDonation.organ_code === OrganCodeValue.Kidney) {
       return this.getOrganSpecificationCode(organDonation) == OrganOfferSpecificationCodeValue.BothKidneys;
      } else {
        return true;
      }
    });

    const rows: RecoveryOutcomeRow[] = filtered.map((item: DeceasedDonorOrganDonations) => {
      const organSpecificationCode = this.getOrganSpecificationCode(item);
      const organ = this.getOrganSpecificationName(item.organ_code, organSpecificationCode);
      const firstName = item?.organ_offer?.recipient_first_name;
      const lastName = item?.organ_offer?.recipient_last_name;
      const recipientName = !!firstName || !!lastName ? (`${firstName} ${lastName}`).trim() : undefined;
      const recipientNameShort = !!lastName ? (lastName.length > 3 ? lastName.substring(0, 3) : lastName) : '-';
      const recipientAgeOffered = item?.organ_offer?.recipient_age;
      const recipientAge = recipientAgeOffered != null ? recipientAgeOffered : item?.transplanted_opo_recipient?.age;
      const outcome = item.outcome_code ? this.lookupValueNumeric(item.outcome_code, 'donor_organ_donation_outcomes') : undefined;
      const organRecovery = item?.organ_recovery && item?.organ_recovery.length > 0 ? item?.organ_recovery[0] : {};
      const destinationIdentifier = organRecovery?.destination_program_identifier;
      const destinationProgramId = organRecovery?.destination_program?.$oid;
      const hospital = this.getHospitalById(destinationProgramId);
      return {
        organ: this.$t(organ).toString(),
        outcome: outcome ? outcome : '-',
        organization: destinationIdentifier || hospital?.hospital_name_info?.abbreviation || '-',
        recipientId: item?.organ_offer?.recipient_client_id ? item?.organ_offer?.recipient_client_id.toString() : null,
        recipientName,
        recipientNameShort,
        recipientAge: recipientAge != null ? recipientAge.toString() : '-',
        lastUpdate: this.parseDisplayDateUiFromDateTime(item.updated_at) || '-',
        lastUpdateTime: this.parseTimeUiFromDateTime(item.updated_at),
        isOutOfProvince: !!item?.organ_offer?.oop_recipient,
      };
    });
    return rows;
  }

  // Load donor's array of Organ Donations
  private loaded(): void {
    this.$emit('loaded', 'monitorRecoveryOutcomes');
    this.$store.dispatch('deceasedDonors/loadOrganDonations', this.donorId);
  }

  // Reload when confirmations have been updated
  private reload(): void {
    this.loaded();
  }
}
