
import { Getter, State } from 'vuex-class';
import { VirologyType } from '@/store/labs/types';
import PageTop from '@/components/shared/PageTop.vue';
import HlaTyping from '@/components/hla/HlaTyping.vue';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveProvider, SaveResult, SaveableSection } from '@/types';
import DonorSummary from '@/components/deceasedDonors/DonorSummary.vue';
import DonorDocuments from '@/components/deceasedDonors/DonorDocuments.vue';
import DonorDataHistory from '@/components/deceasedDonors/DonorDataHistory.vue';
import DonationInformation from '@/components/deceasedDonors/DonationInformation.vue';
import ReferralInformation from '@/components/deceasedDonors/ReferralInformation.vue';
import ClinicalInformation from '@/components/deceasedDonors/ClinicalInformation.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import SideNavDeceasedDonor from '@/components/deceasedDonors/side-nav/SideNavDeceasedDonor.vue';

@Component({
  components: {
    PageTop,
    HlaTyping,
    SaveToolbar,
    DonorSummary,
    DonorDocuments,
    VirologyResults,
    DonorDataHistory,
    DonationInformation,
    ReferralInformation,
    ClinicalInformation,
    SideNavDeceasedDonor,
  },
})
export default class NewDeceasedDonor extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.donors.selectedDonor) private donor!: DeceasedDonor;

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  private dispatchEventsComplete = false;

  /**
   * Returns enum value for VirologyType.DeceasedDonor
   *
   * @returns {number} enum value for Donor
   */
  get virologyType(): number {
    return VirologyType.DeceasedDonor;
  }

  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', 'Donors / New'),
      this.$store.dispatch('deceasedDonors/new'),
      this.$store.dispatch('validations/loadNew', { view: 'donors', action: 'new' }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
      this.$store.dispatch('utilities/scrollBehavior');
    });
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveDonor as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const referralInformation = this.$refs.referralInformation as ReferralInformation;
    const donationInformation = this.$refs.donationInformation as DonationInformation;
    const clinicalInformation = this.$refs.clinicalInformation as ClinicalInformation;
    const virology = this.$refs.virology as VirologyResults;
    // Get donor patch from each form section
    const referralInfoPatch: DeceasedDonor = referralInformation.extractPatch();
    const donationInfoPatch: DeceasedDonor = donationInformation.extractPatch();
    const clinicalInfoPatch: DeceasedDonor = clinicalInformation.extractPatch();
    // Combine donor patches
    const donorPatch: DeceasedDonor = {
      ...referralInfoPatch,
      ...donationInfoPatch,
      ...clinicalInfoPatch
    };

    // Merge the indicators from referral information and donation information
    donorPatch.indicators = Object.assign(referralInfoPatch.indicators || {}, donationInfoPatch.indicators);

    // Extract and add virology if exists
    if (!virology.isEmpty) {
      donorPatch.virology_labs = [virology.extractPatch()];
    }
    // Special case: assign 'death' directly, because it has fields in both Donation Info and Clinical Info
    const deathPatch = clinicalInfoPatch.death || {};
    Object.assign(deathPatch, donationInfoPatch.death);
    Object.assign(donorPatch, { death: deathPatch });
    // Clear previous errors
    (this.$refs.validations as any).reset();
    // Attempt to save the donor
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: null, donor: donorPatch }).then((success: SaveResult) => {
      // Get the Client ID assigned to the new donor
      const clientId = success.responseData.donor.client_id;
      this.$router.push({name: this.getDonorsUrl });
    }).catch((error: SaveResult) => {
      // Handle errors on the form
      this.handleErrors(error);
      // Refer to the save toolbar that handles this page
      const saveToolbar = this.$refs.saveDonor as SaveToolbar;
      // Show appropriate saving notification
      if (saveToolbar) {
        saveToolbar.stopSaving(error);
      }
    });
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup: IdLookup = {
      ...(this.$refs.referralInformation as ReferralInformation).idLookup,
      ...(this.$refs.donationInformation as DonationInformation).idLookup,
      ...(this.$refs.clinicalInformation as ClinicalInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup()
    };
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }
}
