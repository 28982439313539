
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";

@Component
export default class RecipientLink extends Vue {
  // Properties
  @Prop({ required: true }) client_id!: string;
  @Prop({ required: true }) oop_recipient!: boolean;

  get getRouteName(): string {
    return this.oop_recipient ? 'edit-recipient-oop' : 'edit-recipient';
  }
}
