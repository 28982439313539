
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import { urlParams } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from "@/store/utilities/types";

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection
  }
})
export default class WidgetRecentLivingDonors extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashRecentLivingDonors) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getRecentLivingDonors', { namespace: 'dashboard' }) getRecentLivingDonors!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;

  public currentPage = 1;
  public perPage = 10;
  public errorMessage = null;

  mounted(): void {
    Promise.all([
      this.loadData()
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });
    this.$store.dispatch('hospitals/load');
    this.$store.dispatch(
      'dashboard/loadRecentLivingDonors', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashRecentLivingDonors',
      value: this.buildRecentLivingDonorsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idRecentLivingDonors").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildRecentLivingDonorsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.recentLivingDonors);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ recentLivingDonors: {} }, currentSettings);
    newSettings.recentLivingDonors = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get tableData() {
    const recentLivingDonors: any = this.getRecentLivingDonors.entries || [];
    const results: any[] = [];
    recentLivingDonors.map((record: any) => {
      const journeys = record.living_donor_info.journeys;
      const transplantProgramId = journeys ? journeys[0].transplant_program?.transplant_hospital_id?.$oid : null;
      const transplantProgram = transplantProgramId ? this.getHospitalAbbreviation(transplantProgramId) : '--';
      const result = {
        client_id: record.client_id,
        living_donor_id: record.living_donor_id,
        first_name: record.first_name,
        last_name: record.last_name,
        transplant_program: transplantProgram,
        registration_date: record.registration_date ? this.parseDisplayDateUiFromDateTime(record.registration_date) : '--'
      };
      results.push(result);
    });
    return results;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: [
        { label: this.$t('donor_id').toString(), field: 'living_donor_id', sortable: true, type: 'number', thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('first_name').toString(), field: 'first_name', sortable: true },
        { label: this.$t('last_name').toString(), field: 'last_name', sortable: true },
        { label: this.$t('transplant_program').toString(), field: 'transplant_program', sortable: true },
        { label: this.$t('registration_date').toString(), field: 'registration_date', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DISPLAY_DATE, dateOutputFormat: Format(this.getDateFormat).DISPLAY_DATE, },
      ],
      empty: this.$t('no_living_donors').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'registration_date', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 10,
        mode: 'records',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
