
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Recipient } from "@/store/recipients/types";

@Component
export default class SelectedRecipientHlaLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({default: false}) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }
  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'recipient-hla', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
