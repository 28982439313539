import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { AdministrationState, CtrLogItem } from '@/store/administration/types';

export const getters: GetterTree<AdministrationState, RootState> = {
  getCtrCount(state: AdministrationState, getters): number {
    return state?.ctrIntegrationLog?.count || 0;
  },
  getCtrLog(state: AdministrationState, getters): CtrLogItem[] {
    return state?.ctrIntegrationLog?.entries || [];
  }
};
