
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import PageTop from '@/components/shared/PageTop.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';

@Component({
  components: {
    PageTop,
    LoadingSideNav, 
    LoadingDonorPage
  },
})
export default class BaseDeceasedDonor extends Vue {
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
}
