
import { mixins } from "vue-class-component";
import { Getter, State }  from 'vuex-class';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { KidneyDetails } from '@/store/organSpecificDetails/types';
import { KidneySpecificPageState } from '@/components/organs/kidney/KidneySpecificDetails.vue';

export interface OverridePaediatricAgeForm {
  overridePaediatricAge?: boolean;
  overrideStartDate?: string;
  overrideEndDate?: string;
  overrideComments?: string;
}

@Component({
  components: {
    DateInput,
    SubSection,
    TextAreaInput,
    CheckboxInput,
  }
})
export default class OverridePaediatricAge extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.kidneyDetails) editState!: KidneySpecificPageState;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canOverridePaediatricAge', { namespace: 'organSpecificDetails' }) canOverridePaediatricAge!: boolean;

  @Prop({ default: false }) newJourney!: boolean;

  // Whether or not to disable fields based on the checkbox
  get isSubsequentOverrideFieldsLocked(): boolean {
    if (this.newJourney || !this.journey || !this.editState || !this.editState.overridePaediatricAge) return true;

    const isOverrideCheckboxTicked = this.editState.overridePaediatricAge.overridePaediatricAge || false;

    return !isOverrideCheckboxTicked;
  }

  // Whether or not the current user can save changes to the form
  get canSaveOverridePaediatricAge(): boolean {
    return !this.newJourney && this.canOverridePaediatricAge && !this.journey.completed;
  }

  // Load form state based on journey data already loaded from the API
  private loaded(): void {
    this.initializeForm();
    this.$emit('loaded');
  }

  // Initialize form edit state
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'kidneyDetails',
      componentKey: 'overridePaediatricAge',
      value: this.buildOverridePaediatricAgeFormState(),
    });
  }

  // Generate values for this form's UI edit state, based on a source document loaded from API
  public buildOverridePaediatricAgeFormState(): OverridePaediatricAgeForm {
    let result: OverridePaediatricAgeForm = {};
    const journey: RecipientJourney = this.journey || {};
    const kidneySpecificDetails: KidneyDetails|null = journey?.organ_specific_details || null;

    if (!kidneySpecificDetails) return result;
    Object.assign(result, {
      overridePaediatricAge: kidneySpecificDetails.override_pediatric_age,
      overrideStartDate: kidneySpecificDetails.override_start_date ? this.parseDateUi(kidneySpecificDetails.override_start_date) : undefined,
      overrideEndDate: kidneySpecificDetails.override_end_date ? this.parseDateUi(kidneySpecificDetails.override_end_date) : undefined,
      overrideComments: kidneySpecificDetails.override_comments,
    });
    return result;
  }

  // Clear subsequent override fields when checkbox unticked
  private onOverridePaediatricAgeChanged(value?: boolean|null): void {
    if (!this.editState || !this.editState.overridePaediatricAge || value) return;
    
    // Update multiple properties in state simultaneously to reduce number of observed mutations
    const currentState = this.editState.overridePaediatricAge || {};
    const newState = Object.assign(currentState, {
      overrideStartDate: undefined,
      overrideEndDate: undefined,
      overrideComments: "",
    });
    this.$store.commit('pageState/set', {
      pageKey: 'kidneyDetails',
      componentKey: 'overridePaediatricAge',
      value: newState,
    });
  }

  // Saves current form state for the Override-related fields in Kidney Specific Details
  private saveOverridePaediatricAge(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveOverridePaediatricAge as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'overridePaediatricAge');
    // Generate payload based on current edit state
    const opts = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      payload: { organ_specific_details: this.extractOverridePaediatricAgePatch() },
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveOverridePaediatricAge', opts).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      saveProvider.registerSaveResult(success);
      this.reload();
      this.$emit('saved', 'overridePaediatricAge');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Reload recipient and journey
  private reload(): void {
    this.$store.dispatch('recipients/get', this.recipientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
        this.initializeForm();
      });
    });
  }

  /**
   * Returns a patch object containing changes for a Kidney Specific Details sub-document
   *
   * @returns {KidneyDetails} changes to save
   */
  private extractOverridePaediatricAgePatch(): KidneyDetails {
    if (!this.editState || !this.editState.overridePaediatricAge) return {};

    const form = this.editState.overridePaediatricAge || {};

    const result: KidneyDetails = {
      override_pediatric_age: form.overridePaediatricAge || false,
      override_start_date: form.overrideStartDate || null,
      override_end_date: form.overrideEndDate || null,
      override_comments: form.overrideComments || null,
    };

    return result;
  }

  // Validation mapping
  public idLookup(): IdLookup {
    return {
      'override_paediatric_age.override_pediatric_age' : 'ksd-override-paediatric-age-checkbox',
      'override_paediatric_age.override_start_date'    : 'ksd-override-paediatric-age-start-date',
      'override_paediatric_age.override_end_date'      : 'ksd-override-paediatric-age-end-date',
      'override_paediatric_age.override_comments'      : 'ksd-override-paediatric-age-comments',
    };
  }
}
