import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/features/actions';
import { getters } from '@/store/features/getters';
import { mutations } from '@/store/features/mutations';
import { FeatureState } from '@/store/features/types';

const state: FeatureState = {};

const namespaced = true;

export const features: Module<FeatureState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced,
};
