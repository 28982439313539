
import { Getter, State } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import SideNavCtr from '@/components/administration/side-nav/SideNavCtr.vue';
import CtrEventLog from '@/components/administration/CtrEventLog.vue';

@Component({
  components: {
    PageTop,
    CtrEventLog,
    SideNavCtr
  }
})
export default class CtrIntegration extends Vue {
  // Getters
  @Getter('donorCanAccessCtrLog', { namespace: 'users' }) private donorCanAccessCtrLog!: boolean;
}
