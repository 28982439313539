import Vue from 'vue';
import VueI18n from 'vue-i18n';
import customValidationMessages from '@/views/_locales/errorMessages.json';
import customValidationValues from '@/views/_locales/errorValues.json';
import common from '@/components/_locales/common.json';
import i18n from '@/i18n';

Vue.use(VueI18n);

// combine default & custom messages
const messagesEN = {
  code: 'en',
  messages: { 
    ...customValidationMessages.en
  }
};

const messagesFR = {
  code: 'fr',
  messages: { 
    ...customValidationMessages.fr
  }
};

const messagesTglnEN = {
  code: 'en_tgln',
  messages: {
    ...customValidationMessages.en_tgln
  }
};

const messagesTglnFR = {
  code: 'en_tgln',
  messages: {
    ...customValidationMessages.fr_tgln
  }
};

export default new VueI18n({
  // setup default locales that are pre-loaded
  locale: 'en',
  fallbackLocale: {
    'en_tgln': ['en'],
    'fr_tgln': ['fr'],
    'en_tq':   ['en'],
    'fr_tq':   ['fr'],    
    'fr':      ['en'],
    'default': ['en'],
  },
  // setup with validation messages
  messages: {
    en: {
      ...common.en, 
      ...customValidationValues.en,
      validation: messagesEN
    },
    fr: {
      ...common.fr, 
      ...customValidationValues.en,
      validation: messagesFR
    },
    en_tgln: {
      ...common.en,
      ...customValidationValues.en,
      validation: messagesTglnEN
    },
    fr_tgln: {
      ...common.en,
      ...customValidationValues.en,
      validation: messagesTglnFR
    }
  },
  silentTranslationWarn: false // show 'no translation' warning messages
});
