

import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import {
  Country,
  CountryValue,
  LhinProgram,
  Organ,
  OrganDiseaseCode,
  OrganizationType,
  ProfessionalType,
  Province,
  AdministrativeRegion,
} from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CountryInput from '@/components/shared/CountryInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { RecipientJourney, RecipientFailedOrgan, RecipientTransplantProgram, ReferralDecisionState, RecipientReferralAttributes } from '@/store/recipientJourney/types';
import { ReferralSectionPageState } from '@/components/organs/shared/_ReferralSection.vue';
import { SystemModules } from '@/store/features/types';
import { sortOptionsByValue } from '@/utils';

export interface ReferralDetailsPageState {
  receivedDate?: string|null;
  mrn?: string|null;
  referralNumber?: number|null;
  administrativeRegion?: string;
  primaryDiagnosisCode?: number|null;
  primaryDiagnosisDate?: string|null;
  secondaryDiagnosisCode?: number|null;
  secondaryDiagnosisDate?: string|null;
  referringOrganizationName?: string;
  referringOrganizationType?: number;
  referringProfessionalType?: number;
  firstName?: string;
  lastName?: string;
  officeAddress?: string;
  city?: string;
  province?: string;
  state?: string;
  otherCountry?: string|null;
  country?: string;
  postalCode?: string;
  referralDecisionDate?: string|null;
  recipientCoordinator?: number|null;
}

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    SelectInput,
    CountryInput,
  }
})
export default class ReferralDetailsSection extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.country) countryLookup!: Country[];
  @State(state => state.lookups.us_state) usStateLookup!: Province[];
  @State(state => state.lookups.province) provinceLookup!: Province[];
  @State(state => state.lookups.lhin_program) lhinLookup!: LhinProgram[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.referralSection) editState!: ReferralSectionPageState;
  @State(state => state.lookups.organization_type) organizationTypeLookup!: OrganizationType[];
  @State(state => state.lookups.professional_type) professionalTypeLookup!: ProfessionalType[];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('referralDetails', { namespace: 'journeyState' }) referralDetails!: RecipientReferralAttributes[];
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  //Vca doesn't require primary and secondary related fields
  public ORGAN_CODES_TO_EXCLUDE = [7.5];

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Is Physician Details module enabled?
  get isPhysicianDetailsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.PHYSICIAN_DETAILS);
  }

  /**
   * Return true if referral details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  // Is the Administrative Region system module enabled?
  get isAdministrativeRegionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.CONTACT_INFO_ADMINISTRATIVE_REGION);
  }

  // Is the Referral Details Extended Details system module enabled?
  get isReferralDetailsExtendedDetails(): boolean {
    return this.moduleEnabled(SystemModules.REFERRAL_DETAILS_EXTENDED_DETAILS);
  }

  /**
   * Generates Referral Details form state based on the selected journey
   *
   * @param journey current selected journey
   * @returns {ReferralDetailsForm} Referral Details form state
   */
  public buildReferralDetailsForm(journey?: RecipientJourney): ReferralDetailsPageState {
    // Define all defaults, so that if no source document is found we return defaults as is
    const defaultState: ReferralDetailsPageState = {
      province: this.defaultLookup('province'),
      state: this.defaultLookup('us_state'),
      country: this.defaultLookup('country'),
    };
    // Return defaults if no source document
    if (!journey) {
      return defaultState;
    }
    // Fetch nested documents in a type-safe way
    const failedOrgan: RecipientFailedOrgan = journey?.failed_organ || {};
    const referralDetails: RecipientReferralAttributes = journey?.stage_attributes?.referral || {};
    const transplantProgram: RecipientTransplantProgram = journey?.transplant_program || {};
    const referrer = referralDetails.referrer || {};
    const organization = referrer.organization || {};
    const professional = referrer.professional || {};
    const factors = referralDetails.factors || {};
    // Build form state based on source document and defaults if necessary
    const result: ReferralDetailsPageState = {
      // Fields from journey.failed_organ
      primaryDiagnosisCode: failedOrgan.primary_diagnosis_code,
      primaryDiagnosisDate: this.parseDateUi(failedOrgan.primary_diagnosis_date),
      secondaryDiagnosisCode: failedOrgan.secondary_diagnosis_code,
      secondaryDiagnosisDate: this.parseDateUi(failedOrgan.secondary_diagnosis_date),
      // Fields from journey.stage.attributes.referral
      referralNumber: referralDetails.referral_number || null,
      // Fields from journey.stage_attributes.referral.referrer
      receivedDate: referralDetails.received_date ? this.parseDateUi(referralDetails.received_date) : this.currentDateUi(),
      referringOrganizationName: organization.name,
      referringOrganizationType: organization.type_code,
      referringProfessionalType: professional.type_code,
      firstName: professional.first_name,
      lastName: professional.last_name,
      officeAddress: organization.street,
      city: organization.city,
      province: organization.province || defaultState.province,
      state: organization.province || defaultState.state,
      country: organization.country || defaultState.country,
      otherCountry: organization.other_country || null,
      postalCode: organization.postal_code,
      referralDecisionDate: factors.decision_date || null,
      recipientCoordinator: referralDetails.coordinator_id ? referralDetails.coordinator_id : null,
      // Fields from journey's Transplant Program model
      mrn: transplantProgram.transplant_hospital_mrn ? transplantProgram.transplant_hospital_mrn : null,
    };

    if (this.isAdministrativeRegionEnabled) {
      const countryCode = referralDetails.administrative_country_code;
      const provinceCode = referralDetails.administrative_province_code;
      const regionCode = referralDetails.administrative_region_code;

      const countryOptions = this.countryLookup || [];
      const matchingCountry = countryOptions.find((countryOption: Country) => {
        return countryOption.code === countryCode;
      });

      const provinceOptions = matchingCountry?.sub_tables?.province || [];
      const matchingProvince = provinceOptions.find((provinceOption: Province) => {
        return provinceOption.code === provinceCode;
      });

      const regionOptions = matchingProvince?.sub_tables?.administrative_regions || [];
      const matchingRegion = regionOptions.find((regionOption: AdministrativeRegion) => {
        return regionOption.code === regionCode;
      });

      result.administrativeRegion = matchingRegion != null ? `${matchingRegion.code} - ${matchingRegion.value}` : '-';
    }

    // Return form state
    return result;
  }

  public checkSecondDiagnosis(code :any): void {
    // if secondaryDiagnosisCode is the same as new firstDiagnosisCode set secondaryDiagnosisCode back to undefined and null
    if (this.editState.referralDetails && this.editState.referralDetails.primaryDiagnosisCode == this.editState.referralDetails.secondaryDiagnosisCode) {
      Vue.set(this.editState.referralDetails, 'secondaryDiagnosisCode', null);
      Vue.set(this.editState.referralDetails, 'secondaryDiagnosisDate', null);
    }
  }

  checkSecondDiagnosisForBlankValue(code: any): void {
    if (this.editState.referralDetails && code.length == 0) {
      Vue.set(this.editState.referralDetails, 'secondaryDiagnosisDate', null);
    }
  }

  // required to be true when any of the recipient's journeys has referral = accepted
  get isRequired(): boolean {
    const journeys = this.journey || [];
    const stageAttributes = this.journey?.stage_attributes || {};
    const referralAttributes = stageAttributes?.referral || {};
    const referralState = referralAttributes?.state;
    const hasJourneyWithDecisionAccepted = referralState === ReferralDecisionState.Referral_Accepted || false;

    const isRequired = (!this.newJourney && !this.journey.urgent) || (hasJourneyWithDecisionAccepted && !this.journey.urgent);
    return isRequired;
  }

  /**
   *  Show or hide elements base on country type
   */
  get showIfNotOtherCountry(): boolean {
    if(this.editState.referralDetails === undefined) return true;
    return this.editState.referralDetails.country  !== CountryValue.Other;
  }

  /**
   *  Show or hide elements based on organ
   */
  get showIfNotVca(): boolean {
    return !this.ORGAN_CODES_TO_EXCLUDE.includes(this.organCode);
  }
  
  /**
   *  Check Country is other
   */
  get checkOtherCountry(): boolean {
    if(this.editState.referralDetails === undefined) return true;
    return this.editState.referralDetails.country === CountryValue.Other ;
  }

  /**
   * Gets changes from the edit state as a patch for the journey
   *
   * If the edit state doesn't exist return an empty object
   *
   * @returns {RecipientJourney} object containing field changes
   */
  public extractPatch(): RecipientJourney {
    if (this.editState && this.editState.referralDetails) {
      return this.extractJourneyPatch(this.editState.referralDetails);
    } else {
      return {};
    }
  }

  /**
   * Returns a journey patch object containing changes from a Referral Details form
   *
   * Note: returns a journey-level patch, because form covers multiple models within Recipient Journey
   *
   * @returns {RecipientJourney}
   */
  private extractJourneyPatch(referralDetails: ReferralDetailsPageState): RecipientJourney {
    // Referral Attributes
    const referral = this.editState.referralDetails || {};
    const countryCode = referral.country;
    let province: string| null = null;
    if (countryCode == CountryValue.Canada) {
      province = referral.province || null;
    } else if (countryCode == CountryValue.USA) {
      province = referral.state || null;
    }
    // Stage Attributes / Referral
    const referralAttributesPatch: RecipientReferralAttributes = {
      referrer: {
        professional: {
          type_code: referralDetails.referringProfessionalType,
          first_name: referralDetails.firstName,
          last_name: referralDetails.lastName,
        },
        organization: {
          type_code: referralDetails.referringOrganizationType,
          street: referralDetails.officeAddress,
          city: referralDetails.city,
          province: province,
          country: referralDetails.country,
          other_country: (referralDetails.country !== CountryValue.Other) ? null : referralDetails.otherCountry,
          postal_code: referralDetails.postalCode,
          name: referralDetails.referringOrganizationName
        },
      },
    };
    // Failed Organ
    const failedOrganPatch: RecipientFailedOrgan = {
      primary_diagnosis_code: referralDetails.primaryDiagnosisCode,
      secondary_diagnosis_code: referralDetails.secondaryDiagnosisCode,
    };

    // Fields related to the Referral Details Extended Details system module (ATQ-756)
    if (this.isReferralDetailsExtendedDetails) {
      // Extended details in Stage Attributes / Referral
      referralAttributesPatch.received_date = this.sanitizeDateApi(referralDetails.receivedDate);
      // Extended details in Failed Organ
      Object.assign(failedOrganPatch, {
        primary_diagnosis_date: this.sanitizeDateApi(referralDetails.primaryDiagnosisDate),
        secondary_diagnosis_date: this.sanitizeDateApi(referralDetails.secondaryDiagnosisDate),
      });
    }

    // Transplant Program
    const transplantProgramPatch: RecipientTransplantProgram = {
      transplant_hospital_mrn: referralDetails.mrn ? referralDetails.mrn : null,
    };
    // Journey
    const journeyPatch: RecipientJourney = {
      failed_organ: failedOrganPatch,
      stage_attributes: {
        referral: referralAttributesPatch,
      },
      transplant_program: transplantProgramPatch,
    };
    return journeyPatch;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Referral Details, dispatches a save action, and registers the save result.
   *
   * @emits save
   */
  public savePatch(): void {
    const saveProvider = this.$refs.saveReferralDetails as unknown as SaveProvider;
    // Generate payload from the current editState
    const journeyPatch: RecipientJourney = this.extractPatch() || {};
    // Create journey update payload
    const journeyPayload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      journey: journeyPatch
    };
    
    // Attempt to save new journey patch
    this.$store.dispatch('journeyState/saveReferralDetails', journeyPayload).then((success: SaveResult) => {
      // Reload the recipient and journey data
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          // Register save result
          saveProvider.registerSaveResult(success);
        });
      });
      this.$emit('clear');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Resets the save toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.referralDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  /**
   * Returns an array of options for Absolute Contraindication
   *
   * Determines if we have any values in the Organ sub_table for the selected Journey
   *
   * @returns {OrganDiseaseCode[]} all options for absolute contraindication
   */
  get diseaseCodeLookup(): OrganDiseaseCode[] {
    if (!this.organLookup || !this.organCode) {
      return [];
    }
    // Retrieve information based on Journey Organ
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code === this.organCode;
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Determine which sub table to fetch options from
    let result: OrganDiseaseCode[] = [];
    const diseaseCode = organLookupEntry.sub_tables.disease_code;
    // Check if there are any disease codes
    if (Array.isArray(diseaseCode) && diseaseCode.length > 0) {
      result = diseaseCode;
    }

    return sortOptionsByValue(result);
  }

  get secondDiseaseCodeLookup(): OrganDiseaseCode[] {
    // Get the disease Codes
    let list = this.diseaseCodeLookup;
    // If primaryDiagnosisCode is chosen remove primaryDiagnosisCode from array
    if (this.editState.referralDetails && this.editState.referralDetails.primaryDiagnosisCode != null) {
      let index = this.editState.referralDetails.primaryDiagnosisCode;
      list = list.filter(function(item: any) {
        return item.code != index;
      });
    }
    return list;
  }
  
  /**
   * Return the organ code from the journey or url params
   *
   * @returns {number} organ code or 0 if none
   */
  get organCode(): number {
    if (this.newJourney) return Number(this.$route.params.organ_code);
    return this.journey.organ_code ? this.journey.organ_code : 0;
  }

  // Clear state and province on country change
  public clearProvince() {
    if (this.editState && this.editState.referralDetails) {
      Vue.set(this.editState.referralDetails, 'state', undefined);
      Vue.set(this.editState.referralDetails, 'province', undefined);

      if (this.editState.referralDetails.country === CountryValue.Other) {
        Vue.set(this.editState.referralDetails, 'otherCountry', undefined);
      }
    }
  }
   // Return value of text of postal code based on country
  get getPostalLabelText(): string {
    const countryCode = this.editState.referralDetails ? this.editState.referralDetails.country : null;
    if (countryCode == CountryValue.USA) {
      return "Zip Code";
    }
    return 'Postal Code';
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'stage_attributes.referral.received_date'                       : 'rd-recieved-date',
    'failed_organ.primary_diagnosis_code'                           : 'rd-primary-diagnosis',
    'failed_organ.primary_diagnosis_date'                           : 'rd-primary-diagnosis-date',
    'failed_organ.secondary_diagnosis_code'                         : 'rd-secondary-diagnosis',
    'failed_organ.secondary_diagnosis_date'                         : 'rd-secondary-diagnosis-date',
    'stage_attributes.referral.referrer.professional.type_code'     : 'rd-professional-type',
    'stage_attributes.referral.referrer.professional.first_name'    : 'rd-first-name',
    'stage_attributes.referral.referrer.professional.last_name'     : 'rd-last-name',
    'stage_attributes.referral.referrer.organization.name'          : 'rd-referring-name',
    'stage_attributes.referral.referrer.organization.type_code'     : 'rd-referring-type',
    'stage_attributes.referral.referrer.organization.street'        : 'rd-office-address',
    'stage_attributes.referral.referrer.organization.city'          : 'rd-city',
    'stage_attributes.referral.referrer.organization.province'      : 'province-state',
    'stage_attributes.referral.referrer.organization.country'       : 'rd-country',
    'stage_attributes.referral.referrer.organization.other_country' : 'rd-other-country',
    'stage_attributes.referral.referrer.organization.postal_code'   : 'rd-postal-code',

    // Validation keys when attempting to add a recipient to the waitlist
    'addToWaitlist.journey.failed_organ.primary_diagnosis_code'     : 'rd-primary-diagnosis',
  };
}
