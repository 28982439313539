
import '@/vee-validate-rules.ts';
import { Getter, State } from 'vuex-class';
import { ClassObject } from '@/types';
import { mergeClasses } from '@/utils';
import { HlaAntibodyTag, HlaTypingTag } from '@/store/labs/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { VXM_VERBOSE_DISPLAY } from "@/store/administration/types";

@Component({
  components: {
    VueTagsInput,
    ValidationAsterisk
  }
})
export default class HlaInput extends Vue {
  // Getters
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('parseHlaAntibodyTag', { namespace: 'labs' }) parseHlaAntibodyTag!: (rawInput: string) => HlaAntibodyTag;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop({ default: () => { return []; } }) value!: string[]; // Array of strings representing HLA Typing or HLA Antibody tags
  @Prop({ default: () => { return []; } }) alleleSpecific!: string[]; // Which antibody tags have allele-specific antibodies selected elsewhere
  @Prop({ default: () => { return []; } }) alphaBetaSpecific!: string[]; // Which antibody tags have alpha-beta antibodies selected elsewhere
  @Prop({ default: () => { return []; } }) invalidAntibodies!: string[];
    @Prop({ default: null }) vxm!: string|null; // set to top-level VXM result to support display rules, i.e. distinguished Negative vs Untested

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label
  @Prop({ default: false }) typing!: boolean // Set to true to skip parsing logic used for HLA Antibodies

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: '' }) placeholder!: string; // Placeholder for Vue Tags Input
  @Prop({ default: 'hla-input' }) inputClass!: string; // Class string for Vue Tags Input component
  @Prop() disabledEmptyText!: string; // Value of static input element when disabled and empty
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: false }) highlightSelection!: boolean; // When true antibody tags selected elsewhere are highlighted with validation classes
  @Prop({ default: () => { return []; } }) selectedAntibodies!: string[]; // Which antibody tags have been selected elsewhere and need highlighting
  @Prop({ default: false }) readonly!: boolean; // Render input as non-interactive tag badges and turn input data entry off
  @Prop() maxTags!: number; // Limits the number of tags that can be entered
  @Prop({ default: false }) inferLocus!: boolean; // When true, use most recent locus letter if parsing fails (used to paste list of antibodies)
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedHoverText!: string; // Customize text in hover title tooltip

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: '' }) ruleKey!: string;
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  // Merge constant and conditional classes
  private inputClassObject(errors: any[], additionalClasses?: string): ClassObject {
    // Define dynamic classes that are only sometimes present as a class object based on function arguments
    const conditionalClasses = {
      'is-invalid': errors[0]
    };
    // Merge the customizable class string with the dynamic classes
    if (!!additionalClasses) {
    // Include additional classes if there are any
      const inputAndAdditional = [this.inputClass, additionalClasses].join(' ').trim();
      return mergeClasses(inputAndAdditional, conditionalClasses);
    } else {
      return mergeClasses(this.inputClass, conditionalClasses);
    }
  }

  // Private attributes
  private tagInput = '';
  private tagObjects: any[] = [];
  private mostRecentlyParsedLocus: string|undefined = undefined;

  // Vue lifecycle hooks
  private mounted(): void {
    this.generateTagObjects();
    this.addKeypressForTags();
  }

  /**
   * Method add keypress for on/off tags
   *
   package: @johmun/vue-tags-input": "^2.1.0" not implements @keypress for select tag in actual version
   https://github.com/JohMun/vue-tags-input/issues/117
   inside v-slot:tag-center also does not work
   so small hack is needed ...
   todo?: now working for any key but we can add a filter for Enter|Space
   */
  private addKeypressForTags():void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;

    let vueTagsInputUl:any = document.getElementsByClassName('ti-tags');
    if( vueTagsInputUl ) {
      Array.from(vueTagsInputUl).forEach(function (vueTagsInputLi: any) {
        if(vueTagsInputLi.parentNode.parentNode.id === _vm.inputId){
           vueTagsInputLi.addEventListener('keypress', function (event: any) {
            event.target.click();
          });
        }
      });
    }
  }

  // Watchers
  @Watch('value')
  private onValueChanged(): void {
    this.generateTagObjects();
  }
  @Watch('alleleSpecific')
  private onAlleleSpecificChanged(): void {
    this.generateTagObjects();
  }
  @Watch('alphaBetaSpecific')
  private onAlphaBetaSpecificChanged(): void {
    this.generateTagObjects();
  }
  @Watch('selectedAntibodies')
  private onSelectedAntibodiesChanged(): void {
    // Regenerate tags if highlighting is enabled and selection has changed. This re-validates existing tag objects
    if (this.highlightSelection) {
      this.generateTagObjects();
    }
  }
  @Watch('invalidAntibodies')
  private onInvalidAntibodiesChanged(): void {
    // This re-validates existing tag objects
    this.generateTagObjects();
  }

  // Extract tag objects from model values and allele-specific values
  private generateTagObjects(): void {
    // Standardize HLA tag text found in model values
    if (this.typing) {
      this.tagObjects = this.value ? createTags(this.value) : [];
    } else {
      // Parse as HLA Antibodies Test SAB tag
      const antibodyTagObjects = createTags(this.value);

      // Standardize antibody tag text found in allele-specific values
      const unmarkedAlleleSpecificTagObjects = createTags(this.alleleSpecific);
      const alleleSpecificTagObjects = unmarkedAlleleSpecificTagObjects.map((unmarkedTag: any) => {
        return Object.assign(unmarkedTag, { alleleSpecific: true });
      });

      // Standardize antibody tag text found in alpha-beta values
      const unmarkedAlphaBetaSpecificTagObjects = createTags(this.alphaBetaSpecific);
      const alphaBetaSpecificTagObjects = unmarkedAlphaBetaSpecificTagObjects.map((unmarkedTag: any) => {
        return Object.assign(unmarkedTag, { alphaBetaSpecific: true });
      });

      // Combine standardized model values with allele-specific values
      this.tagObjects = antibodyTagObjects.concat(alleleSpecificTagObjects).concat(alphaBetaSpecificTagObjects);
    }
  }

  // Conditions for showing form elements
  private showEmpty(): boolean {
    return (this.disabled || this.readonly) && !!this.disabledEmptyText && (!this.value || this.value.length < 1);
  }

  // Event handlers
  private tagClicked(clickedTag: any): void {
    // Report event to parent
    this.$emit('selected', clickedTag.tag.text);
    // Regenerate tag objects if validation classes are needed, otherwise existing tags are not re-validated
    if (this.highlightSelection) {
      this.generateTagObjects();
    }
  }
  private tagsChanged(updatedTagObjects: any[]): void {
    // Update model value, which will trigger re-generation of the tag objects
    const newModelValue: string[] = updatedTagObjects.map((tag: any) => { return tag.text; });
    this.$emit('input', newModelValue);
  }
  private beforeAddingTag(newTagObject: any): void {
    const standardizedTag = this.standardizedTag(newTagObject);
    standardizedTag.addTag();
  }
  private beforeSavingTag(updatedTagObject: any): void {
    const standardizedTag = this.standardizedTag(updatedTagObject);
    standardizedTag.saveTag();
  }

  // Change a Tag Object by standardizing its text
  private standardizedTag(tagObject: any): any {
    // Check for text after trimming out whitespace, and return the unchanged tag object if nothing is found
    const tagText = tagObject.tag.text.trim();
    if (!tagText || tagText.length < 1) {
      return tagObject;
    }
    if (!this.typing) {
      // Parse as HLA Antibody from the text input
      let parsedAntibody = this.parseHlaAntibodyTag(tagText);
      // Infer gene locus if specified
      if (this.inferLocus) {
        /**
         * Parsing will initially fail when there is no locus specified in the input text. This can occur when the user
         * pastes a sequences of antibodies like "A1 2 3 4" that only have the locus specified for the first antibody. The
         * first text "A1" succeeds, and the locus "A" is stored as the most recently parsed locus. Then the initial
         * parsing attempt will fail for each subsequent antibody like "2". To accept these lists, parsing is attempted
         * a second time with the recent "A" locus implied by inserting it as a prefix like "A2".
         */
        if (parsedAntibody.mostRecentlyParsedLocus == null && this.mostRecentlyParsedLocus) {
          const impliedText = `${this.mostRecentlyParsedLocus}*${tagText}`;
          parsedAntibody = this.parseHlaAntibodyTag(impliedText); 
        }
      }
      // Check if the antibody was successfully parsed, including the implied locus for pasted lists if needed
      if (parsedAntibody) {
        if (this.inferLocus) {
          // Store the locus of the HLA Antibody tag to enable pasting sequences of antigen numbers without locus letters
          this.mostRecentlyParsedLocus = parsedAntibody.mostRecentlyParsedLocus || undefined;
        }
        // Update the tag object text based on a standardized form of the parsed antibody
        tagObject.tag.text = parsedAntibody.standardText || tagText;
      }
    } else {
      // Typing Antigens are entered without loci
    }
    // Return the tag object either including the standardized text if parsing succeeds or unchanged if parsing fails
    return tagObject;
  }

  /**
   * Provide validation objects to Vue Tags Input. These 'validations' allow the tagging component to handle
   * conditional classes for each tag. Each validation object specifies classes and a rule, with the classes being
   * applied if the rule resolves to true. Furthermore, when any of the rules resolve to true the tag cannot be 'added'
   * to the list of accepted tags.
   * 
   * When HlaInput is not being used to enter tags, and instead is only used to display values that can be clicked,
   * then the 'validity' aspect of these rules can be safely ignored. In such display use cases, the 'validation'
   * objects are essentially only functioning as conditional classes for each tag.
   * 
   * Further reading: http://vue-tags-input.com/#/examples/validation
   */
  private tagValidationObject(): any[] {
    if (!this.highlightSelection) {
      // Validation classes for antibody tag entry
      const _value = this.value;
      const _invalidAntibodies = this.invalidAntibodies;
      return [
        {
          // Highlight non-value tags e.g. Allele-specific and Alpha-beta antibodies
          classes: 'hla-antibody-highlight',
          rule({ text }: { text: string }): boolean {
            const result = !_value.includes(text);
            return result;
          }
        },
        {
          // Highlight invalid antibodies
          classes: 'hla-antibody-tag-invalid-format',
          rule({ text }: { text: string }): boolean {
            const result = _invalidAntibodies.includes(text);
            return result;
          }
        },
      ];
    } else {
      // Validation classes for selectable antibodies
      const _selectedAntibodies = this.selectedAntibodies;
      return [
        {
          // Highlight antibodies that have been selected
          classes: 'ti-selected',
          rule({ text }: { text: string }): boolean {
            return _selectedAntibodies.includes(text);
          }
        }
      ];
    }
  }

  // Use VXM display features specified by 2022-016 Change Request?
  get isVerbose(): boolean {
    return VXM_VERBOSE_DISPLAY || false;
  }

  // Is the antibody value array empty?
  // NOTE: not affected by the 'allele specific highlighting', 'alpha beta highlighting', or 'invalid' arrays
  get isEmpty(): boolean {
    const numberOfValues = (this.value || []).length;
    return numberOfValues === 0;
  }

  // Use VXM-related display rules?
  get isVxm(): boolean {
    return !!this.vxm || false;
  }

  // Should we show the Untested message?
  get isUntested(): boolean {
    if (!this.vxm) return false;

    return this.vxm === 'U';
  }
}
