
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Rules } from '@/store/validations/types';
import { WarningParams } from '@/store/tools/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';

@Component({
  components: {
    ValidationAsterisk
  }
})
export default class NumberInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() value!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  @Prop({ default: null }) warningParams!: WarningParams; // OPTIONAL show warning message when outside bounds. Example prop { min: 0.5, max: 99.0, message: 'Note: Normal range between 0.5 and 99.0' }

  // Number input attributes
  @Prop() step!: number;
  @Prop() min!: number;
  @Prop() max!: number;

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  get showWarning(): boolean { 
    return this.getWarningMessage ? true : false; 
  }

  get getWarningMessage(): string|undefined {
    if (this.warningParams) {
      const message = this.warningParams.message || undefined;
      const min = this.warningParams.min || 0;
      const max = this.warningParams.max || 0;
      const currentValue = parseFloat(this.value);
      if (currentValue < min || currentValue > max) {
        return `&nbsp;${message}`;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as NumberInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('input', event.target.value);
        }
      }
    );
  }
}
