import Component from "vue-class-component";
import Vue from "vue";
import { GenericCodeValue } from '@/store/types';
import { AssociatedJourney } from '@/store/allocations/types';

@Component
export class AllocationUtilsMixin extends Vue {
  /**
   * Provide a string representation of the recipient's required organ
   *
   * Since the recipient can be listed for combinations of organs, we might be showing a list of organ names.
   * Ideally we show the 'waitlisted_for_organs' string array derived by the Allocation Service. If this is
   * not available (e.g. it appears to be empty for CTR Recipients) we can instead use the 'organ' property
   * that relates to the organ the allocation was originally run for
   *
   * This requires a lot of manual manipulation to match what the report shows. The report registration_type for listed for
   * is different than the allocation registration type.
   * See Sean Hundt's comment on https://shore.tpondemand.com/entity/11981-tgln_uat-sp-3042-cyc1-uat2-recipient
   *
   * @returns {string} text description of the recipient's Waitlisted Organ Journeys
   */
  public parseListedFor(as_registration_type: string|null|undefined, listedFor: any) {
    const registrationType = this.parseRegistrationType(as_registration_type, listedFor);
    // If we don't have the waitlisted for organs fallback to the main organ
    const waitlistedOrgans = listedFor || [listedFor];

    // Special check for Stages being brought back as part of TP-15259 https://shore.tpondemand.com/entity/15259-tgln_uat-sp-4029-alloc-rpt-listed
    if(waitlistedOrgans.length === 1 && as_registration_type && as_registration_type.toLowerCase() === 'staged') {
      return `SINGLE - <strong>${waitlistedOrgans[0]}</strong> (${as_registration_type.toUpperCase()})`;
    }

    return `${registrationType} - <strong>${waitlistedOrgans.join(', ')}</strong>`;
  }

  public parseRegistrationType(registration_type: string|null|undefined, listedFor: any) {
    const allowedTypes = ['single', 'cluster', 'combination', 'multiple'];
    let registrationType = registration_type || '-';
    // If we don't have the waitlisted for organs fallback to the main organ
    const waitlistedOrgans = listedFor || [listedFor];

    // If we are dealing with spacial case of STAGED then we default back to single / combination
    if(!allowedTypes.includes(registrationType.toLowerCase())) {
      registrationType = waitlistedOrgans.length > 1 ? 'COMBINATION' : 'SINGLE';
    }

    return registrationType;
  }

  /**
   * Return a list of transplant program filters
   *
   * @param recipients array of recipients
   * @returns {GenericCodeValue[]} option list of transplant programs
   */
  public getHospitalPrograms(recipients: any[]): GenericCodeValue[] {
    if (recipients.length <= 0) return [];
    // Transplant Programs available filtered by valid strings
    const allPrograms: any[] = recipients.map((item: any) => {
      // Only include programs that exist
      const name = item.hospital_abbreviation || item.program;
      if (typeof name === 'string') return name;
    }).filter(Boolean);
    // De-dupe
    const uniquePrograms = [...new Set(allPrograms)];
    // Sort
    const sortedPrograms = uniquePrograms.sort();
    // Build array and return
    const selectOptions = sortedPrograms.map((item: string) => {
      return { code: item, value: item };
    });
    return selectOptions;
  }
}
