export interface UtilitiesState {
}

export interface TagObject {
  text: string;
  code?: number|string;
  expired_date?: string;
  disabled?: boolean;
  tiClasses?: string[];
  hint?: string;
}

export const Format = (dateFormat: any) => {
  const isoFormat = {
    DATE_FORMAT: 'yyyy-MM-dd',
    TIME_FORMAT: 'HH:mm',
    TIME_MASK: '##:##',
    TIME_DISPLAY: '-- : --',
    TIME_ARIA_DISPLAY: 'Entry pattern is twenty-four hour time with two digits for the hour and two digits for the day',
    TELEPHONE_MASK: '(XXX) XXX-XXXX',
    DATE_TIME_ISO: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx',
    DATE_TIME_FORMAT: 'yyyy-MM-dd HH:mm',
    FORMATTED_DATE: 'yyyy, MMM, d',
    FORMATTED_DATE_TIME: 'yyyy, MMM, d; HH:mm',
    DISPLAY_DATE: 'yyyy-MM-dd',
    DISPLAY_DATE_FORMAT: 'YYYY-MM-DD',
    DISPLAY_DATE_TIME: 'yyyy-MM-dd HH:mm',
  };

  const enFormat = {
    DATE_FORMAT: 'yyyy-MM-dd',
    TIME_FORMAT: 'HH:mm',
    TIME_MASK: '##:##',
    TIME_DISPLAY: '-- : --',
    TIME_ARIA_DISPLAY: 'Entry pattern is twenty-four hour time with two digits for the hour and two digits for the day',
    TELEPHONE_MASK: '(XXX) XXX-XXXX',
    DATE_TIME_ISO: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx',
    DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm',
    FORMATTED_DATE: 'd MMM, yyyy',
    FORMATTED_DATE_TIME: 'd MMM, yyyy; HH:mm',
    DISPLAY_DATE: 'dd-MM-yyyy',
    DISPLAY_DATE_FORMAT: 'DD-MM-YYYY',
    DISPLAY_DATE_TIME: 'dd-MM-yyyy HH:mm',
  };

  switch (dateFormat) {
    case 'iso':
      return isoFormat;
    case 'en':
      return enFormat;
    default:
      return isoFormat; // return iso Format as default
  }
};
