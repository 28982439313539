
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, NotificationsWidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from '@/store/utilities/types';

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetNotifications extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashNotifications) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getNotifications', { namespace: 'dashboard' }) getNotifications!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDateFormat', { namespace: 'features' }) private getDateFormat!: string;

  public errorMessage = null;

  get getFilteredNotifications(): any {
    const unread = this.getPreferences ? this.getPreferences.unread : true;
    const notifications = this.getNotifications || [];
    return notifications.filter((item: any) => {
      return unread ? item.unread == true : item;
    });
  }

  rowStyleForReadUnread(row: any): any {
    return row.unread ? 'tr-link row-unread' : 'tr-link';
  }

  mounted(): void {
    Promise.all([
      this.$store.dispatch('dashboard/loadNotifications')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget preferences form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashNotifications',
      value: this.buildNotificationsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idNotifications").collapse('toggle');
  }

  // Build edit state based on overall dashboard preferences
  buildNotificationsEditState(preferences: DashboardSettingState): NotificationsWidgetSettings {
    return Object.assign({}, preferences.notifications);
  }

  // Build new overall dashboard preferences with new preferences for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: NotificationsWidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ notifications: {} }, currentSettings);
    newSettings.notifications = {
      style: editState.style,
      visible: editState.visible,
      unread: editState.unread
    };
    return newSettings;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.getFilteredNotifications,
      columns: [
        { label: this.$t('date_time_of_event_trigger'), field: 'date_time', sortable: true },
        { label: this.$t('description_of_notification'), field: 'description', sortable: false },
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'date_time', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: true,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfigHistory(): TableConfig {
    return {
      data: this.getNotifications,
      columns: [
        { label: this.$t('date_time_of_event_trigger'), field: 'date_time', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT },
        { label: this.$t('description_of_notification'), field: 'description', sortable: false },
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'date_time', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
