
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SiteHeader from './components/shared/SiteHeader.vue';
import SiteFooter from './components/shared/SiteFooter.vue';
import i18n from '@/i18n';
import { EP } from '@/api-endpoints';
import { mixins } from "vue-class-component";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import WarningBanner from '@/views/WarningBanner.vue';

@Component({
  components: {
    SiteHeader,
    SiteFooter,
    WarningBanner
  }
})
export default class App extends mixins(TranslationUtilsMixin) {
  @Getter('getUser', { namespace: 'users' }) private getUser!: any;
  @Getter('checkTokenExists', { namespace: 'users' }) private checkTokenExists!: boolean;
  @Getter('getConfigBaseClass', { namespace: 'features' }) private getConfigBaseClass!: any;
  @Getter('hasAccessToken', { namespace: 'users' }) private hasAccessToken!: boolean;
  @Getter('isLoggedIn', { namespace: 'users' }) private isLoggedIn!: boolean;
  @Getter('getRegions', { namespace: 'features' }) private getRegions!: string[];
  @Getter('isFeaturesLoaded', { namespace: 'features' }) private isFeaturesLoaded!: boolean;
  @Getter('sanityCheck', { namespace: 'features' }) private sanityCheck!: boolean;

  public baseInfoLoaded = false;
  public organTypes: [] = [];

  /**
   * Returns css class whether logged-in / logged-out 
   *
   * @returns {string}
   */  
  get authenticationClass(): string {
    return this.isLoggedIn ? 'logged-in' : 'logged-out';
  }

  /**
   * true if on /login url
   *
   * @returns {boolean}
   */
  get isLoginPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/login') ? true : false;
  }

  /**
   * true if on /access_token url
   *
   * @returns {boolean}
   */
  get isAccessTokenPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/access_token') ? true : false;
  }

  public mounted(): void {
    if (this.hasAccessToken && !this.isLoginPage && !this.isAccessTokenPage) {
      this.$store.dispatch('users/loadUser').then(() => {
        Promise.all([
          this.$store.dispatch('features/load'),
          this.$store.dispatch('lookups/load', { lookup: 'organ' }),
        ]).finally(() => {
          this.setupI18n();
          this.baseInfoLoaded = true;
        });
      });
    } else {
      this.$store.dispatch('features/load').then(() => {
        this.setupI18n();
        this.baseInfoLoaded = true;
      }).catch((error) => {
        this.baseInfoLoaded = true;
      });
    }
  }
}
