import { render, staticRenderFns } from "./TransplantDetails.vue?vue&type=template&id=c731c244&"
import script from "./TransplantDetails.vue?vue&type=script&lang=ts&"
export * from "./TransplantDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/TransplantDetails.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2FTransplantDetails.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2FTransplantDetails.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports