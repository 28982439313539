
import { Getter } from 'vuex-class';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SaveState, SaveResult, DismissableNotification } from '@/types';
import { mergeClasses } from '@/utils';
import { ClassObject } from '@/types';

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class SaveToolbar extends Vue {
  // Functional properties
  @Prop({ default: false }) disabled!: boolean;

  // Optional properties to change look and feel
  @Prop({ default: 'Saving' }) savingText!: string;
  @Prop({ default: 'All changes saved' }) successText!: string;
  @Prop({ default: 'Cannot save: see error messages above' }) defaultErrorText!: string;
  @Prop({ default: 'Save' }) label!: string;
  @Prop({ default: 1000 }) minimumDisplayTime!: number;
  @Prop({ default: 'btn btn-wide btn-success sec-submit' }) buttonClass!: string;
  @Prop({ default: null }) buttonTitle!: string|null;

  // Getters
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // Merge constant and conditional classes
  private buttonClassObject(currentState: SaveState, requestedState: SaveState): ClassObject {
    // Define dynamic classes that are only sometimes present as a class object based on function arguments
    const conditionalClasses = {
      disabled: currentState === SaveState.Saving || currentState !== requestedState
    };
    // Merge the customizable class string with the dynamic classes
    return mergeClasses(this.buttonClass, conditionalClasses);
  }

  // Local declaration of enum
  public saveState = SaveState;

  // Instance variables to manage toolbar state
  private requestedState: SaveState = SaveState.Idle;
  private currentState: SaveState = SaveState.Idle;
  private lastStateChange = (new Date()).getTime();
  private errors: DismissableNotification[] = [];

  // Event handlers
  private save(): void {
    this.$emit('save');
  }

  // Functions that the component consumer should call when changing the displayed save state
  public startSaving(): void {
    this.requestedState = SaveState.Saving;
  }
  public stopSaving(saveResult: SaveResult): void {
    if (saveResult.success) {
      this.requestedState = SaveState.Success;
    } else {
      this.requestedState = SaveState.Error;
      // Setup dismissable error notifications
      const disabledModule = saveResult.validationErrors && saveResult.validationErrors.disabled_module;
      if (disabledModule) {
        // Generate a notification for a disabled module
        this.errors = [{
          text: this.$t(disabledModule) as string,
          dismissed: false
        }];
      }
      else if (saveResult.errorMessages) {
        // Generate a notification for each error message
        this.errors = saveResult.errorMessages.map((errorMessage: string) => {
          return {
            text: errorMessage,
            dismissed: false
          };
        });
      } else {
        // Generate default notification if no error messages found
        this.errors = [{ text: this.defaultErrorText, dismissed: false }];
      }
    }
  }
  public reset(): void {
    this.requestedState = SaveState.Idle;
  }

  // Handle state changes with minimum delays to ensure that notifications are readable
  @Watch('requestedState')
  private handleStateChange(newState: SaveState): void {
    // Check how much time has passed since the last state change
    const currentTime = (new Date()).getTime();
    const timeSinceLastStateChange = currentTime - this.lastStateChange;
    // Check if enough time has passed for the displayed notification to be readable
    if (timeSinceLastStateChange >= this.minimumDisplayTime) {
      // Enough time has passed, so change the state immediately and record the time that the state changed
      this.currentState = newState;
      this.lastStateChange = (new Date()).getTime();
    } else {
      // Set a timeout to delay long enough to ensure the minimum time passes, but record the state change time
      const remainingDelayTime = Math.max(0, this.minimumDisplayTime - timeSinceLastStateChange);
      this.lastStateChange = (new Date()).getTime();
      setTimeout(() => {
        // Change the state after the timeout
        this.currentState = newState;
      }, remainingDelayTime);
    }
  }

  // Dismiss success notification
  private dismissSuccess(): void {
    this.requestedState = SaveState.Idle;
  }

  // Dismiss one error notification
  private dismissError(errorIndex: number): void {
    const dismissedError = this.errors[errorIndex];
    if (dismissedError) {
      dismissedError.dismissed = true;
    }
    // Update save state if all errors have been dismissed
    const remainingErrors = this.errors.filter((error: DismissableNotification) => { return !error.dismissed; });
    if (remainingErrors.length < 1) {
      this.requestedState = SaveState.Idle;
      this.errors = [];
    }
  }

  get isDisabled(): boolean {
    const saving: boolean = this.currentState === SaveState.Saving || this.currentState !== this.requestedState;
    return this.disabled || saving;
  }
}
