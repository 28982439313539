import { ObjectId } from '@/store/types';

export interface AdministrationState {
  ctrIntegrationLog?: ctrIntegrationLogResults;
}

export interface ctrIntegrationLogResults {
  count?: number;
  entries?: CtrLogItem[];
  page_number?: number;
  page_size?: number;
}

export interface CtrLogItem {
  _id: ObjectId;
  date: string|null;
  status: string;
  entity: any;
  entity_id: number|null;
  recipient_id?: ObjectId;
  donor_id?: ObjectId;
  lab_id?: ObjectId;
  created_at: string;
  created_by: string|null;
  updated_at?: string|null;
  updated_by?: string|null;
}

// Configure VXM display feature for 2022-016 Change Request
export const VXM_VERBOSE_DISPLAY = true;

// Configure whether or not to show Donor Data History section
// NOTE: hidden for go-live 'release 0', to be shown in 'release 1' (see B#15296)
export const DONOR_DATA_HISTORY_ENABLED = false;
