
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { ObjectId } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import { SaveProvider, SaveResult, TableConfig } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { AlcoholicLiverDiseaseProgram } from '@/store/organSpecificDetails/types';
import LiverSpecificDetails, { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

export interface AldProgramRow {
  _id?: ObjectId;
  decisionDate?: string;
  programParticipant?: string;
  recipientJourneyPhase?: string;
}

export interface AldProgramPageState {
  _id?: ObjectId;
  decisionDate?: string;
  programParticipant?: boolean;
  recipientJourneyPhase?: string;
}

@Component({
  components: {
    SubSection,
    TextInput,
    DateInput,
    BooleanRadioInput,
  }
})
export default class AldProgram extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedAldProgram) selectedAldProgram!: AlcoholicLiverDiseaseProgram;
  @State(state => state.organSpecificDetails.aldPrograms) private aldPrograms!: AlcoholicLiverDiseaseProgram[];

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  /**
   * Gets the table configuration for Alcoholic Liver Disease Program.
   * 
   * @return {TableConfig} Alcoholic Liver Disease Program table configration
   */
  get aldTableConfig(): TableConfig {
    return {
      data: this.aldRows || [],
      columns: [
        { label: this.$t('ald_participation_decision_date').toString(), field: 'decisionDate' },
        { label: this.$t('ald_program_participant').toString(), field: 'programParticipant' },
        { label: this.$t('recipient_journey_phase').toString(), field: 'recipientJourneyPhase' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_ald').toString(),
      pagination: true,
    };
  }

  /**
   * Return formatted rows for the table
   * 
   * @return {AldProgramRow[]} All formatted rows from ald_program_participations
   */
  get aldRows(): AldProgramRow[] {
    if (!this.aldPrograms) return [];

    const rows = this.aldPrograms.map((aldProgram: AlcoholicLiverDiseaseProgram) => {
      const row: AldProgramRow = {
        _id: aldProgram._id ? { $oid: aldProgram._id.$oid } : undefined,
        decisionDate: aldProgram.ald_participation_date ? this.parseDisplayDateUi(aldProgram.ald_participation_date) : '-',
        programParticipant: aldProgram.ald_program_participant ? this.$t('yes').toString() : this.$t('no').toString(),
        // TODO: Journey phase is going to require some extra work
        recipientJourneyPhase: aldProgram.recipient_journey_phase || '-',
      };
      return row;
    });
    return rows;
  }

  /**
   * Return true if we can edit the selected entry
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean {
    if (this.newJourney) return false;
    if (this.journey.completed) return false;
    
    const selectedId = this.editState.aldProgram?._id;
    if (!!selectedId && !!this.aldPrograms) {
      return this.isLastEntry(selectedId, this.aldPrograms);
    }
    return true;
  }

  /**
   * Called when the component mounts
   */
  public mounted(): void {
    this.initializeForm();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'ald_participation_date' : 'ald-decision-date',
  };

  // PRIVATE

  /**
   * Initialize the pageState
   */
  private initializeForm(): void {
    this.$store.dispatch('organSpecificDetails/loadAlcoholicLiverDiseaseProgram',{ recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
      // Reset empty pageState
      this.$store.commit('pageState/set', {
        pageKey: 'liverDetails',
        componentKey: 'aldProgram',
        value: this.buildAldPageState()
      });
    });
  }

  /**
   * Builds the AldProgramPageState 
   * 
   * @param {AlcoholicLiverDiseaseProgram} aldProgram Alcoholic Liver Disease Program entry
   * @return {AldProgramPageState} The pageState 
   */
  private buildAldPageState(aldProgram?: AlcoholicLiverDiseaseProgram): AldProgramPageState {
    // Clear any errors
    this.$emit('clear');
    const lastAldProgramParticipant = this.aldPrograms ? this.aldPrograms[0] : null;
    const defaultState: AldProgramPageState = {
      decisionDate: this.currentDateUi(),
      // The default value should be the opposite of the lastEntry or false if none
      programParticipant: lastAldProgramParticipant ? !lastAldProgramParticipant?.ald_program_participant : false,
    };

    // Return a default pageState if we don't have aldProgram
    if (!aldProgram) return defaultState;

    const result: AldProgramPageState = {
      _id: aldProgram._id,
      decisionDate: aldProgram.ald_participation_date ? this.parseDateUi(aldProgram.ald_participation_date) : defaultState.decisionDate,
      programParticipant: aldProgram.ald_program_participant,
      recipientJourneyPhase: aldProgram.recipient_journey_phase,
    };
    return result;
  }

  /**
   * Resets to an empty AldProgramPageState and clear any selected item
   */
  private createAldProgram(): void {
    // Clear any selected decision
    this.$store.commit('journeyState/clearAldProgram');
    // Build empty page state
    const aldProgramPageState: AldProgramPageState = this.buildAldPageState();
    Vue.set(this.editState, 'aldProgram', aldProgramPageState);
  }

  /**
   * Build AldProgramPageState from the selected row 
   */
  private selectAldProgram(event: any): void {
    const selectedId = event.row._id.$oid || undefined;

    // Return if we don't have what we need
    if (!selectedId || !this.editState || !this.aldPrograms) return;

    // Find the item matching our selection
    const aldProgram = this.aldPrograms.find((aldProgram: AlcoholicLiverDiseaseProgram) => {
      return aldProgram?._id?.$oid === selectedId;
    });

    // Return if we didn't find anything
    if (!aldProgram) return;

    // Store the entry
    this.$store.commit('journeyState/selectAldProgram', aldProgram);
    // Build aldPageState with the entry
    const aldProgramPageState: AldProgramPageState = this.buildAldPageState(aldProgram);
    Vue.set(this.editState, 'aldProgram', aldProgramPageState);
  }

  /**
   * Generic extract 
   */
  private extractPatch(): AlcoholicLiverDiseaseProgram {
    if (!this.editState || !this.editState.aldProgram) return {};
    return this.extractAldProgramPatch(this.editState.aldProgram);
  }

  /**
   * Extract from editState our alcoholic_liver_disease_program patch 
   */
  private extractAldProgramPatch(aldProgram: AldProgramPageState): AlcoholicLiverDiseaseProgram {
    if (!aldProgram) return {};
    return {
      _id: aldProgram._id ? { $oid: aldProgram._id.$oid } : undefined,
      ald_participation_date: this.sanitizeDateApi(aldProgram.decisionDate) || null,
      ald_program_participant: aldProgram.programParticipant ? true : false,
    };
  }

  /**
   * Attempt to save the entry 
   */
  private savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.aldProgram as unknown as SaveProvider;
    // Generate payload based on current editState
    const aldProgramPayload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      aldProgram: this.extractPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveAlcoholicLiverDiseaseProgram', aldProgramPayload).then((success: SaveResult) => {
      // If successful then reload recipient to get updated journey details
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          this.$store.dispatch('organSpecificDetails/loadAlcoholicLiverDiseaseProgram',{ recipientId: this.recipientId, journeyId: this.journeyId });
          // Re-initialize
          this.initializeForm();
          // Register save result
          saveProvider.registerSaveResult(success);
        });
      });
      // Register success notification
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
}
