import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ValidationsState } from '@/store/validations/types';

export const actions: ActionTree<ValidationsState, RootState> = {
  /**
   * Load 'new' validation rules for specified resource 'view'
   * 
   * @param view the endpoint route to the resource we need validation rules for
   * @param action validation route string ('new' or 'edit')
   * @param prefix optional key to wrap around rules
   * @returns {Promise} promise resolves when rules are loaded, otherwise promise rejects with error
   */
  loadNew({ commit }, {view, action, prefix}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.new_validations, [[':view', view], [':action', action]]);
      axios.get(url).then((response: any) => {
        const responseData = response.data;
        let sanitized = responseData;
        // If 'prefix' is specified in the dispatch, nest the rules under a prefix key within the 'rules' object
        if (prefix) {
          sanitized = {
            rules: {
              [prefix]: responseData.rules,
            }
          };
        }
        // Pass value to mutation to place in store
        commit('set', sanitized);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  /**
   * Load 'edit' validation rules for specified resource 'view'
   * 
   * @param view the endpoint route to the resource we need validation rules for
   * @param action validation route string ('new' or 'edit')
   * @param clientId identifier in route for the resource we need validation rules for 
   * @param prefix optional key to wrap around rules
   * @returns {Promise} promise resolves when rules are loaded, otherwise promise rejects with error
   */
  loadEdit({ commit }, {view, action, clientId, prefix}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.edit_validations, [[':view', view], [':action', action], [':id', clientId]]);
      axios.get(url).then((response: any) => {
        const responseData = response.data;
        let sanitized = responseData;
        // If 'prefix' is specified in the dispatch, nest the rules under a prefix key within the 'rules' object
        if (prefix) {
          sanitized = {
            rules: {
              [prefix]: responseData.rules,
            }
          };
        }
        // Pass value to mutation to place in store
        commit('set', sanitized);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  loadEditDeath({ commit }, {clientId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.death_edit_validations, [[':recipient_id', clientId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('set', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  loadRecoveryInfoEditValidations({ commit }, {livingDonorId, livingDonorJourneyId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.living_donors.recoveryInfo.edit_validations, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('setAppendRecoveryInfoRules', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  loadNewHeartHemodynamicValidations({ commit }, {recipientId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.recipients.heart_hemodynamic.new_validations, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('set', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  reset({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('reset');
    });
  }
};
