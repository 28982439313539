
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { ObjectId, GenericCodeValue } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails, ProcedureLiverDownstaging } from '@/store/organSpecificDetails/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface DownstagingTherapyForm {
  downstagingTherapyDate?: string;
  downstagingTherapyType?: string;
  downstagingTherapyTypeOther?: string;
}

interface DownstagingRow {
  _id?: ObjectId;
  downstagingTherapyDate?: string;
  downstagingTherapyType?: string;
  downstagingTherapyTypeOther?: string;
}

@Component({
  components: {
    DateInput,
    SubSection,
    TextInput,
    SelectOtherInput
  }
})

export default class DownstagingTherapy extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.organSpecificDetails.liverDownstagings) private liverDownstagings!: ProcedureLiverDownstaging[];
  @State(state => state.organSpecificDetails.selectedLiverDownstaging) private selectedLiverDownstaging!: ProcedureLiverDownstaging;
  @State(state => state.lookups.downstaging_therapy_type_codes) downstagingTherapyLookup!: GenericCodeValue[];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['downstaging_therapy_type_codes'];

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    
    if (!!this.selectedLiverDownstaging && !!this.liverDownstagings) {
      if (this.selectedLiverDownstaging._id) {
        return this.isLastEntry(this.selectedLiverDownstaging._id, this.liverDownstagings);
      }
    }
    return true;
  }

  private mounted(): void {
    this.initializeDownstagingTherapy();
  }

  // Clear downstaging therapy type other when downstaging therapy type changes
  public clearDownstagingOther() {
    Vue.set(this.editState.downstagingTherapy!, "downstagingTherapyTypeOther", null);
  }

  /**
   * Gets text representation of a Downstaging Therapy Type
   * 
   * @param downstagingCode string code from lookups representing a Downstaging Therapy Type
   * @returns {string|undefined} Downstaging Therapy Type as text
   */
  private downstagingTypeValue(downstagingCode?: string): string|undefined {
    const lookupTable = this.downstagingTherapyLookup;
    if (!lookupTable) {
      return undefined;
    }
    const downstagingValue = lookupTable.find((downstaging: GenericCodeValue) => {
      return downstaging.code == downstagingCode;
    });
    if (!downstagingValue) {
      return undefined;
    }
    return downstagingValue.value;
  }


  /**
   * Gets table data for recipient liver downstaging procedures
   *
   * @returns {DownstagingRow[]} downstaging table data
   */
  get downstagingRows(): DownstagingRow[] {
    if (!this.liverDownstagings) {
      return [];
    }
    return this.liverDownstagings.map((procedure: ProcedureLiverDownstaging) => {
      return {
        _id: procedure._id,
        downstagingTherapyDate: this.parseDisplayDateUi(procedure.date) || '-',
        downstagingTherapyType: this.downstagingTypeValue(procedure.type) || '-',
        downstagingTherapyTypeOther: procedure.other || '-',
      };
    });
  }

  /**
   * Loads a form edit state based on the procedure, or a new state if there is none
   *
   * @param procedure Liver Downstaging procedure fetched from API, or undefined
   */
  public initializeDownstagingTherapy(procedure?: ProcedureLiverDownstaging): void {
    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectLiverDownstaging', procedure);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'downstagingTherapy',
      value: this.buildDownstagingTherapyForm(procedure),
    });
  }

  /**
   * Returns liver downstaging form edit state based on recipient liver downstaging procedure document
   *
   * @param procedure Liver Downstaging procedure document fetched from API
   * @returns {DownstagingTherapyForm} editable downstaging form state
   */
  public buildDownstagingTherapyForm(procedure?: ProcedureLiverDownstaging): DownstagingTherapyForm {
    if (!procedure) {
      return {
        downstagingTherapyDate: this.currentDateUi(),
        downstagingTherapyType: undefined,
        downstagingTherapyTypeOther: undefined,
      };
    }
    return {
      downstagingTherapyDate: this.parseDateUi(procedure.date),
      downstagingTherapyType: procedure.type,
      downstagingTherapyTypeOther: procedure.other,
    };
  }

  /**
   * Gets table configuration for the downstaging subsection table
   *
   * @returns {TableConfig} table configuration
   */
  get downstagingTableConfig(): TableConfig {
    return {
      data: this.downstagingRows,
      columns: [
        { label: this.$t('downstaging_therapy_date').toString(), field: 'downstagingTherapyDate', width: '33%' },
        { label: this.$t('downstaging_therapy_type').toString(), field: 'downstagingTherapyType', width: '33%' },
        { label: this.$t('downstaging_therapy_type_other').toString(), field: 'downstagingTherapyTypeOther', width: '33%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_downstaging_therapy').toString(),
      pagination: true,
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'procedure_liver_downstaging.date'  : 'lsd-downstaging-date',
    'procedure_liver_downstaging.type'  : 'lsd-downstaging-type',
    'procedure_liver_downstaging.other' : 'lsd-downstaging-type-other',
  };

  // PRIVATE

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectLiverDownstaging(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.liverDownstagings) {
      return;
    }
    // Find the selected source document
    const found = this.liverDownstagings.find((each: ProcedureLiverDownstaging) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeDownstagingTherapy(found);
  }

  /**
   * Build an empty new form edit state
   */
  private createDownstagingTherapy(): void {
    // Build form state
    this.initializeDownstagingTherapy();
  }

  /**
   * Saves current form state for downstaging
   */
  private saveDownstagingTherapy(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveDownstagingTherapy as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'downstagingTherapy');
    // Generate payload based on current edit state
    const liverDownstagingPayload = {
      id: !!this.selectedLiverDownstaging ? this.selectedLiverDownstaging._id : undefined,
      recipientId: this.recipient.client_id,
      liver_downstaging: this.extractDownstagingTherapyPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveLiverDownstaging', liverDownstagingPayload).then((success: SaveResult) => {
      // If successful reload table data, clear form, and show success notification
      this.$store.dispatch('organSpecificDetails/loadLiverDownstagings', this.recipientId);
      this.initializeDownstagingTherapy();
      saveProvider.registerSaveResult(success);
      // Clear any errors
      this.$emit('clear');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Returns a patch object containing changes for a Liver Downstaging Procedure document
   *
   * For a document that does not yet exist, the object contains all data to be saved
   *
   * @returns {ProcedureLiverDownstaging} changes to save
   */
  private extractDownstagingTherapyPatch(): ProcedureLiverDownstaging {
    if (!this.editState || !this.editState.downstagingTherapy) {
      return {};
    }
    const downstagingTherapy = this.editState.downstagingTherapy;
    return {
      date: this.sanitizeDateApi(downstagingTherapy.downstagingTherapyDate),
      type: downstagingTherapy.downstagingTherapyType,
      other: downstagingTherapy.downstagingTherapyTypeOther
    };
  }
}
