import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Coordinator, CoordinatorsState } from '@/store/coordinators/types';

export const mutations: MutationTree<CoordinatorsState> = {
  set(state, opts) {
    Vue.set(state, opts.key, opts.data);
  },
  clear(state, key) {
    Vue.set(state, key, []);
  },
};
