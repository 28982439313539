import Vue from 'vue';
import { MutationTree } from 'vuex';
import { idComparator } from '@/utils';
import { ReportState } from '@/store/reports/types';

export const mutations: MutationTree<ReportState> = {
  setCurrentReport(state, options) {
    Vue.set(state, 'currentReport', options);
  },
  setTransplantInProgressReportState(state, options) {
    Vue.set(state, 'TransplantInProgressReportState', options);
  },
  setChangeStatusReport(state, options) {
    Vue.set(state, 'ChangeStatusReportState', options);
  },
  setCrossmatchReportState(state, options) {
    Vue.set(state, 'CrossmatchReportState', options);
  },
  setDialysisReportState(state, options) {
    Vue.set(state, 'DialysisReportState', options);
  },
  setWaitingListReportState(state, options) {
    Vue.set(state, 'WaitingListReportState', options);
  },
  setAllocationReportState(state, options) {
    Vue.set(state, 'AllocationReportState', options);
  },
};
