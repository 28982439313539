
import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import {Getter} from "vuex-class";
import {EP} from "@/api-endpoints";

@Component
export default class NavSearch extends mixins(UrlUtilsMixin) {

  @Getter('checkAllowed', { namespace: 'users' } ) private checkAllowed!: (url: string, method?: string) => boolean;
  selectedSearchOption = 'recipients';
  searchTerm = '';

  get availableSearchCategories() {
    const availableCategories: any = {};
    if(this.checkAllowed(EP.recipients.create)) {
      availableCategories.recipients = this.$t('recipient_records');
    }
    if(this.checkAllowed(EP.deceasedDonors.create)) {
      availableCategories.donors = this.$t('donor_records');
    }

    return availableCategories;
  }

  get canSearchAnything(): boolean {
    return Object.keys(this.availableSearchCategories).length > 0;
  }
  public mounted() {
    this.searchTerm = this.getUrlQueryValue('term') || '';
    this.selectedSearchOption = this.getUrlQueryValue('type') || Object.keys(this.availableSearchCategories)[0];
  }

  public performSearch() {
    this.$router.push({
      name: 'global-search',
      query: {
        type: this.selectedSearchOption,
        term: this.searchTerm
      }
    });
  }
}
