
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { GenericCodeValue, ObjectId } from '@/store/types';
import { JourneyStage, RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { ACTIVE_REGION_TRANSPLANT_PROGRAM, Hospital, HospitalOrgansTransplanted } from '@/store/hospitals/types';

interface ClusterOption {
  code: string;
  value: string;
  journey: RecipientJourney;
  organCode: number[];
}

interface ClusterJourneyPageState {
  clusterWith?: string;
  copyDataFromJourneyId?: string;
}

@Component({
  components: {
    SubSection,
    SelectInput,
    SaveToolbar,
  },
})
export default class ClusterSection extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.clusterSection) editState!: ClusterJourneyPageState;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('clusteredJourneys', { namespace: 'journeyState' }) clusteredJourneys!: RecipientJourney[];
  @Getter('getJourneysByStatus', { namespace: 'recipients' }) filterJourneys!: (status: string) => RecipientJourney[];
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('relatedJourneysIncludingSelf', { namespace: 'journeyState', }) relatedJourneysIncludingSelf!: (journey: RecipientJourney) => RecipientJourney[];
  @Getter('isClusteredByJourney', { namespace: 'journeyState', }) isClusteredByJourney!: (journey: RecipientJourney) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  //Show Hep B Core Ab+ in popup if Kidney and Pancreas
  public ORGAN_CODES_TO_INCLUDE =
    [
      OrganCodeValue.Kidney,
      OrganCodeValue.PancreasWhole
    ];
  
  // TODO: 
  // 1. Disable the form if the current journey is already clustered, uncluster should be the only option
  //    - disable if journey is already completed
  // 2. Filter clusterOptions for only valid journeys (cancelled, completed, different transplant program)
  // 3. copyDataFromJourneyId is required

  /**
   * Return true if we can save a cluster
   * 
   * @returns {boolean} true if we can edit
   */
  get canSave(): boolean {
    if(this.journey.stage == JourneyStage.PostTransplant) return false;
    const state = this.journey.state == 'inactive' ? false : !this.isClustered && !this.journey?.completed;
    const patchPermission = this.checkAllowed('/recipients/:recipient_id/journeys/:journey_id/waitlist_attributes', 'PATCH');
    return state && patchPermission;
  }
  
  /**
   * Return true if this journey is already clustered
   * 
   * @returns {boolean} true if clustered
   */
  get isClustered(): boolean {
    return this.clusteredJourneys.length > 0;
  }
  
  /**
   * Get a string representation the organ_code
   * 
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }
  
  /**
   * Return list of organs available to cluster with 
   * 
   * Only active and inactive organs are available to cluster
   * 
   * @returns {ClusterOption[]}
   */
  get clusterOptions(): ClusterOption[] {
    const recipientJourneys = this.recipient.journeys || [];
    if (!recipientJourneys) return [];
    // Combine active and inactive journeys
    const filteredJourneys = recipientJourneys!.filter((journey => {
      // If the current journey is clustered then include it as an option
      if (this.isClustered && this.journey._id?.$oid == journey._id?.$oid) {
        return journey;
      }
      // Include any related journeys of the current journey
      const currentJourneyRelatedJourneyIds = (this.journey.related_journeys as (string[])).map((id: any) => { return id.$oid; });
      if (currentJourneyRelatedJourneyIds?.includes(journey._id?.$oid) ) {
        return journey;
      }

      // check if specified journey is clustered
      const isClustered = this.isClusteredByJourney(journey);
      let inactive_journeys_in_cluster;
      if(isClustered) {
        // Fetch if any journey in a cluster is inactive
        const relatedJourneysIncludingSelf = this.relatedJourneysIncludingSelf(journey);
        inactive_journeys_in_cluster = relatedJourneysIncludingSelf.find((relatedJourney: RecipientJourney) => {
          return relatedJourney.state == "inactive";
        });
      }

      // WHEN TO HIDE JOURNEYS FROM CLUSTERED LIST

      // Only display journeys that match the current journey’s Transplant Program. (Data Managed Automations)
      if (this.journey.transplant_program?.transplant_hospital_id?.$oid != journey.transplant_program?.transplant_hospital_id?.$oid) { return; }
      // Only display journeys that do not match the current journey’s Organ. (Data Managed Automations)
      if (this.journey.organ_code == journey.organ_code) { return; }
      // Only display journeys that are not cancelled. (Data Managed Automations)
      if (journey.completed) { return; }
      // Must not have a Medical Hold if all journeys are in the Waitlist phase. (Precondition)
      if (journey.stage_attributes?.waitlist?.factors?.on_hold_medical) { return; }
      // Revised preconditions such that a waitlisted journey must be active (not on hold or suspended) in order to be added to a cluster; without an active hold or suspension
      // all journey's in cluster should be active
      if (journey.state == 'inactive' || inactive_journeys_in_cluster) { return; }

      // WHEN TO SHOW JOURNEYS IN CLUSTERED LIST

      // Must have an Assessment Decision of ‘To be Listed’ if they are in the Assessment phase 
      // OR
      // BE FLAGGED AS AN URGENT JOURNEY (Precondition).
      if (journey.urgent) { return journey; }
      // Only display journeys that are in Assessment phase with an Assessment Decision of To be Listed, or Waitlist phase.
      if ( (journey.stage_attributes?.assessment?.factors?.assessment_decision_code == 1 && journey.stage ==  JourneyStage.Assessment) ||
           (journey.stage_attributes?.waitlist?.factors?.listing_date && journey.stage == JourneyStage.Waitlist)) {
        return journey;
      }
    }));

    // Start by defining a well-formed option for every recipient journey, and cache the journey data for filter logic
    const allPossibleOptions = filteredJourneys.map((journey: RecipientJourney): ClusterOption => {
      const code = journey._id?.$oid || '-';
      let value = this.organNameLookup(journey.organ_code);
      let organCode:number[] = []; 
      if(journey.organ_code) organCode.push(journey.organ_code);
      if (this.isClustered || journey.related_journeys && journey.related_journeys.length > 0) {
        (journey.related_journeys || []).forEach((journeyId: ObjectId|string) => {
          // DIAG: TECH_DEBT Migrated recipients could have their related_journeys as string[]
          // this was fixed on API but we are checking here for either string[] or ObjectId[]
          const relatedJourneyId = (typeof journeyId === 'string') ? journeyId : journeyId.$oid;
          const relatedJourney = filteredJourneys.find((journey: any) => journey._id.$oid === relatedJourneyId);
          value = `${value} / ${this.organNameLookup(relatedJourney?.organ_code)}`;
          if(relatedJourney?.organ_code) organCode.push(relatedJourney.organ_code);
        });
      }
      return { code, value, journey, organCode };
    });
    
    /**
     * First filter out any options clustered with the current journey, so that the option displayed for the current
     * cluster will correspond to the current journey. This ensures that the current journey ID is used as the option
     * code, instead of those of the organs in its cluster.
     */ 
    const unrelatedOptions = allPossibleOptions.filter((option: ClusterOption) => {
      const relatedJourneyIds: string[] = [];
      (option.journey.related_journeys || []).forEach((relatedJourney: string|ObjectId) => {
        relatedJourneyIds.push((typeof relatedJourney === 'string') ? relatedJourney : relatedJourney.$oid);
      });
      return !relatedJourneyIds.includes(this.journeyId || '');
    });

    // If the current journey is not clustered, then exclude it from these options
    const selfOptions = unrelatedOptions.filter((option: ClusterOption) => {
      const isClustered = (this.journey.related_journeys || []).length > 0;
      const isSelfOption = option.code === this.journeyId;
      return isClustered || !isSelfOption;
    });

    // Then filter out duplicate cluster options, by caching the IDs as we encounter them and filter out as needed
    const idsAlreadyIncluded: string[] = [];
    const uniqueOptions = selfOptions.filter((option: ClusterOption) => {
      const journeyId = option.code;
      const alreadyIncluded = idsAlreadyIncluded.includes(journeyId);
      if (!alreadyIncluded) {
        // Cache ID of journey AND all of its related journeys and include this option
        const relatedJourneyIds: string[] = [];
        (option.journey.related_journeys || []).forEach((relatedJourney: string|ObjectId) => {
          relatedJourneyIds.push((typeof relatedJourney === 'string') ? relatedJourney : relatedJourney.$oid);
        });
        idsAlreadyIncluded.push(journeyId, ...relatedJourneyIds);
        // Include first journey encountered for each cluster option
        return true;
      } else {
        // Exclude duplicates i.e. journeys already included based on earlier journey's relations
        return false;
      }
    });
    return uniqueOptions;
  }
  
  /**
   * Return list of viable options to copy Donor Acceptability Criteria values from 
   * 
   * This should be the current journey (and its related_journeys), 
   * plus the selected clusterWith (and its related_journeys)
   * 
   * @returns {GenericCodeValue[]}
   */
  get copyDacDataFromOptions(): GenericCodeValue[] {
    const result: GenericCodeValue[] = [];

    const validCopyFromIds: Set<string> = new Set(); // Using a set to build a unique list of journeyIds
    const relatedJourneys = this.journey.related_journeys || [];
    const clusterWith = this.editState.clusterWith;
    
    // Add the current journey
    if (this.journeyId) validCopyFromIds.add(this.journeyId);

    // Then add any related_journeys ids
    if (relatedJourneys.length > 0) {
      relatedJourneys.forEach((journeyId: string|ObjectId) => {
        // Migrated values could be string or ObjectId here so try both
        const relatedJourneyId = (typeof journeyId === 'string') ? journeyId : journeyId.$oid;
        // Add the relatedJourneyId
        validCopyFromIds.add(relatedJourneyId);
      });
    }

    // If we have a clusterWith value
    if (clusterWith) {
      // Find the clusterWith journey
      const clusterWithJourney = this.recipient.journeys?.find((journey: RecipientJourney) => {
        return journey._id?.$oid === clusterWith;
      });
      
      // Add it to the validCopyFromIds if we have an $oid
      if (clusterWithJourney?._id?.$oid) validCopyFromIds.add(clusterWithJourney._id.$oid);
      
      // Then And add any realted_journeys that it might have
      if (clusterWithJourney && clusterWithJourney.related_journeys) {
        clusterWithJourney.related_journeys.forEach((journeyId: string|ObjectId) => {
          // Migrated values could be string or ObjectId here so try both
          const relatedJourneyId = (typeof journeyId === 'string') ? journeyId : journeyId.$oid;
          // Add the relatedJourneyId
          validCopyFromIds.add(relatedJourneyId);
        });
      }
    }
    
    // Build the option list from our validCopyFromIds
    validCopyFromIds.forEach((journeyId: string) => {
      // Find the journey
      const journey = this.recipient.journeys?.find((journey: RecipientJourney) => {
        return journey._id?.$oid === journeyId;
      });

      // Ensure we have an $oid or skip to the next one
      if (!journey || !journey._id?.$oid) return;

      // Add it to the options list
      result.push({ 
        code: journey._id.$oid, 
        value: this.organNameLookup(journey.organ_code)
      });
    });
    
    return result;
  }
  
  /**
   * Gets a string used to populate the confirmation alert dialog noting which DAC value we're syncing
   * 
   * @returns {string} text for confirmation property of Sub Section
  */
  get confirmationText(): string {
    if (!this.editState?.copyDataFromJourneyId || !this.recipient?.journeys || !this.editState.clusterWith) return '';

    // Find the clusterWith journey
    const clusterWithJourney = this.recipient.journeys.find((journey: RecipientJourney) => {
      return journey._id?.$oid === this.editState.copyDataFromJourneyId;
    });

    // Find the clustering journey's
    const clusteringJourneys = this.clusterOptions.find((option: ClusterOption) => {
      return option.code === this.editState.clusterWith;
    });

    let can_copy_hepCoreAb = false;
    const copyDataFromOrgans = clusteringJourneys?.organCode;
    const copyDataToOrgan = this.journey?.organ_code;
    // hepCoreAb is copied only between kidney and pancreas whole, if any other journey exists in cluster 
    // or copying from journey's other than kidney/pancreas whole don't copy or show in popup
    const is_valid_to_copy = copyDataFromOrgans?.length == 1 ? copyDataFromOrgans.some(r=> this.ORGAN_CODES_TO_INCLUDE.includes(r)) : false;
    if(copyDataFromOrgans && copyDataToOrgan && is_valid_to_copy && this.ORGAN_CODES_TO_INCLUDE.includes(copyDataToOrgan))
      can_copy_hepCoreAb = true;
    
    const clusterWithDacValues = clusterWithJourney?.donor_acceptability || {};
    const abo = clusterWithDacValues.abo_incompatible === null ? null : clusterWithDacValues.abo_incompatible;
    const hepCoreAb = clusterWithDacValues.hep_ab_core_positive === null ? null : clusterWithDacValues.hep_ab_core_positive;
    const hcvNat = clusterWithDacValues.hcv_nat_positive === null ? null : clusterWithDacValues.hcv_nat_positive;
    const hcvAb = clusterWithDacValues.hcv_ab_positive === null ? null : clusterWithDacValues.hcv_ab_positive;

    const selectedClusterOrganName = this.organNameLookup(clusterWithJourney?.organ_code);
    // If the current value is null show it otherwise it's Accept or Decline
    let result = `${ this.$t('dac.message', {clustered_organ_name: selectedClusterOrganName}).toString() }:
    ${this.$t('dac.accept_abo_incompatible')}: ${abo === null ? this.$t('dac.null') : (abo ? this.$t('dac.true') : this.$t('dac.false'))}
    ${this.$t('dac.accept_hcv_nat')}: ${hcvNat === null ? this.$t('dac.null') : (hcvNat ? this.$t('dac.true') : this.$t('dac.false'))}
    ${this.$t('dac.accept_hcv_ab')}: ${hcvAb === null ? this.$t('dac.null') : (hcvAb ? this.$t('dac.true') : this.$t('dac.false'))}
    ${can_copy_hepCoreAb ? `${this.$t('dac.accept_hep_b')}: ${hepCoreAb === null ? this.$t('dac.null') : (hepCoreAb ? this.$t('dac.true') : this.$t('dac.false'))}` : ''}`;
    return result;
  }
  
  /**
   * Build the pageState for this section
   * 
   * @returns {ClusterJourneyPageState} form state for Add to Waitlist
   */
  public buildClusterJourneyPageState(): ClusterJourneyPageState {
    const isClustered = (this.journey.related_journeys || []).length > 0;
    return {
      clusterWith: isClustered ? this.journeyId : undefined,
      copyDataFromJourneyId: isClustered ? this.journey.donor_acceptability_from_journey_id?.$oid : undefined
    };
  }
  
  // Intialize on mount
  public mounted(): void {
    this.initializeForm();
  }

  /**
   * Populates the default form state
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'clusterSection',
      value: this.buildClusterJourneyPageState(),
    });
    // Remove any validation errors
    this.$emit('clear');
  }

  /**
   * Extract our patch
   * 
   * Prepares a cluster payload with all the journey ids needs to cluster or undefined if nothing.
   */
  public extractPatch(): any {
    const editState = this.editState || {};
    // Nothing was selected or we selected the current cluster
    if (this.journeyId === editState.clusterWith || editState.clusterWith == null || editState.clusterWith === '') {
      return undefined;
    }
    // Use a set for the clusterWith ids to ensure no duplicates
    const clusterWithIds = new Set();
    // Get the journey we're trying to cluster with
    const clusterWithJourney = this.recipient?.journeys?.find((j: RecipientJourney) => j._id?.$oid === editState.clusterWith);
    // Add the selected clusterWith id
    clusterWithIds.add(editState.clusterWith);
    // Add all related_journeys from the journey we're trying to cluster with
    clusterWithJourney?.related_journeys?.forEach((id: string|ObjectId) => { 
      clusterWithIds.add((typeof id === 'string') ? id : id.$oid);
    });
    // Add this journeys related journeys in case it's already a cluster
    this.journey.related_journeys?.forEach((id: string|ObjectId) => {
      clusterWithIds.add((typeof id === 'string') ? id : id.$oid);
    });
    // Make sure we're not sending an empty array, this will break a cluster
    if (clusterWithIds.size === 0) return undefined;
    // Returns a clean cluster payload
    return {
      related_journeys: Array.from(clusterWithIds),
      donor_acceptability_from_journey_id: editState.copyDataFromJourneyId ? { $oid: editState.copyDataFromJourneyId } : null
    };
  }
  
  /**
   * Saves the cluster
   * 
   * Prepares a cluster create payload and dispatch the createCluster action.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.clusterSection as unknown as SaveProvider;
    // Cluster patch details, undefined if nothing to save
    const clusterPatch = this.extractPatch();
    // Generate the payload
    const payload = {
      recipientId: this.recipientId, 
      journeyId: this.journeyId, 
      clusterPatch
    };
    /**
      * Attempt to save the cluster
      * 
      * Note: Clustering journeys can have consequences for the Waitlist Decisions and
      * Wait Time calculations, so here we need to reload the decisions and durations
      */
    this.$store.dispatch('journeyState/createCluster', payload).then((success: SaveResult) => {
      saveProvider.registerSaveResult(success);
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          // Reintialize the component
          this.initializeForm();
          this.$emit('reloadDonorAcceptabilityCriteria');
          // reloadOrganSpecificDetails to refect the changes occured to the general comments
          this.$emit('reloadOrganSpecificDetails');
          // Reload Waitlist Section to show updated listing date
          this.$emit('reloadWaitlistSummary');
          // If organ is liver then reload SMC Score history, exception diseases, etc.
          if (this.organCode == OrganCodeValue.Liver.toString()) {
            this.$emit('reloadLiverExceptionPoints');
          }
          // reload the hospital
          this.$store.commit('hospitals/resetLoadingTracking', ACTIVE_REGION_TRANSPLANT_PROGRAM);
          this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM);
          // Reload all waitlist decisions for the recipient's journey
          this.$store.dispatch('journeyState/loadWaitlistDecisions', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
            // Reload all the journey durations
            this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
              // Nothing to do
            });
          });
        });
      });
    }).catch((error: SaveResult) => {
      this.$emit('handleErrors', error);
      saveProvider.registerSaveResult(error);
    });
  }
  
  /**
   * Uncluster this journey
   * 
   * Attempt to remove the cluster on the selectedJourney.
   */
  public unCluster(): void {
    // Get the cluster option list item so we can use the value for the cluster name
    const clusterOption: GenericCodeValue|undefined = this.clusterOptions.find((item: GenericCodeValue) => item.code === this.journey._id?.$oid);
    const confirmed = confirm(this.$t('confirm_uncluster', { clustered_organs: clusterOption?.value  }).toString());
    if (!confirmed) return;
    
    const saveToolbar = this.$refs.unclusterButton as SaveToolbar;
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
    };

    this.$store.dispatch('journeyState/unCluster', payload).then((success: SaveResult) => {
      // Show success message
      saveToolbar.stopSaving(success);
      // If success and the clustered journeys contain General Comments in the Organ Specific Information area, show notification
      if(this.journey.comments) {
        alert(this.$t('warning_comments_on_uncluster'));
      }
      /**
       * If successful then reload recipient to get updated journey details
       * 
       * Note: Unclustering can have consequences for the Waitlist Decisions and
       * Wait Time calculations, so here we need to reload the decisions and durations
       */
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          // Reload all waitlist decisions for the recipient's journey
          this.$store.dispatch('journeyState/loadWaitlistDecisions', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
            this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
              // Re-initialize
              this.initializeForm();
              // If organ is liver then reload SMC Score history, exception diseases, etc.
              if (this.organCode == OrganCodeValue.Liver.toString()) {
                this.$emit('reloadLiverExceptionPoints');
              }
              // Reload Waitlist Section to show updated listing date
              this.$emit('reloadWaitlistSummary');
            });
          });
        });
      });
    }).catch((error: SaveResult) => {
      this.$emit('handleErrors', error);
      saveToolbar.stopSaving(error);
    });
  }

  /**
   * Checks transplant program has already transplanted an oragn cluster
   * 
   * If not displays confirmation prompt
   */
  get firstTimeClusterConfirmationText(): string {
    if (!this.editState?.copyDataFromJourneyId || !this.recipient?.journeys || !this.editState.clusterWith) return '';

    // Transplant program of the journey
    const transplant_program = this.getOntarioHospitalById(this.journey.transplant_program?.transplant_hospital_id?.$oid);
    
    const clusteringWith = this.clusterOptions.find((item:ClusterOption) => item.code === this.editState.clusterWith);
    let clusteringOrganCodes:number[]|undefined  = clusteringWith?.organCode || [];
    if(this.journey?.organ_code) clusteringOrganCodes.push(this.journey?.organ_code);
    const filteredOrganCodes = [...new Set(clusteringOrganCodes)].sort();

    const organ_transplanted = transplant_program?.organs_transplanted.find((item: HospitalOrgansTransplanted) => {
      return item.organ_code == filteredOrganCodes?.join('/');
    });
    
    if(!organ_transplanted) {
      const cluster_name:string[] = filteredOrganCodes.map((code: number): string => {
        return this.organNameLookup(code);
      });
      return `${this.$t('first_time_cluster_prompt.message', { transplant_program: transplant_program?.program_identifier, cluster_name: cluster_name.join(' / ') })}`;
    }
    return '';
  }
  
  /**
   * Resets the save toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.organDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result: { [key: string]: string } = {};

    result['related_journeys']          = 'related-journeys';
    result['donor_acceptability_from_journey_id'] = 'copy-data-from-journey';
    
    this.recipient.journeys?.forEach((journey => {
      result[`related_journeys[${journey._id?.$oid}]`]          = 'related-journeys';
      result[`donor_acceptability_from_journey_id[${journey._id?.$oid}]`] = 'copy-data-from-journey';
    }));

    return result;
  }
}
