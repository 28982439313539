
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardSettingState, Bookmark, BookmarksWidgetSetting } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveResult, SaveProvider } from '@/types';

@Component({
  components: {
    BaseWidget,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection,
    SaveToolbar
  }
})
export default class WidgetBookmarks extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashBookmarks) private editState!: BookmarksWidgetSetting;
  @State(state => state.pageState.currentPage.currentBookmark) private currentBookmark!: Bookmark;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];

  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  get bookmarksExist(): boolean {
    return this.preferences.bookmarks && this.preferences.bookmarks > 0;
  }

  get bookmarksCount(): number {
    return this.editState && this.editState.entries ? this.editState.entries.length : 0;
  }

  public selectBookmark(event: any) {
    const bookmarks: Bookmark[] = this.editState?.entries || [];

    if (event.row.name !== undefined) {
      const name: string = event.row.name;
      const selectedBookmark = bookmarks.filter((b: Bookmark)  => b.name === name )[0];
      if(selectedBookmark) {
        this.$store.commit('pageState/set', {
          pageKey: 'currentBookmark',
          value:{
            index: selectedBookmark.index,
            name: selectedBookmark.name,
            link: selectedBookmark.link
          },
        });
        this.resetSaveToolbar();
      }
    } else {
      console.warn(this.$t('missing_bookmark_name'));
      this.$router.push({name: this.getDonorsUrl});
    }
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveToolbar = this.$refs.saveBookmark as unknown as SaveToolbar;
    // Reset the save provider's save toolbar
    (this.$refs.saveBookmarksValidations as any).reset();

    if(!saveToolbar) {
      return;
    }

    saveToolbar.reset();
  }

  public createBookmark() {
    this.initializeBookmarkForm();
  }

  public updatePagination(event: any) {
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeBookmarkWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  saveBookmarks(): void {
    const saveToolbar = this.$refs.saveBookmark as SaveToolbar;

    if(this.currentBookmark && this.currentBookmark.name !== '' ){
      this.$emit('saving', 'bookmark');
      this.createOrUpdateBookmark();
    }
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState })
    .then((success: SaveResult) => {
      this.initializeBookmarkForm();
      saveToolbar.stopSaving(success);
      (this.$refs.saveBookmarksValidations as any).reset();
      this.initializeBookmarkWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    })
    .catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveToolbar.stopSaving(error);
    });
  }

  public dismiss(): void {
    $("#idBookmarks").collapse('toggle');
  }

  deleteBookmark(name: string) {
    const saveToolbar = this.$refs.saveBookmark as SaveToolbar;

    this.$emit('saving', 'bookmark');

    const bookmarks: Bookmark[] = this.editState?.entries || [];
    const filteredBookmarks = bookmarks.filter((b: Bookmark)  => b.name !== name );

    this.preferences.bookmarks.entries = filteredBookmarks;
    this.$store.dispatch('users/savePreferences', { preferences: this.preferences })
    .then((success: SaveResult) => {
      Vue.set(this.editState, 'entries', filteredBookmarks);
      this.initializeBookmarkForm();
      (this.$refs.saveBookmarksValidations as any).reset();
      saveToolbar.stopSaving(success);
      this.initializeBookmarkWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    })
    .catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveToolbar.stopSaving(error);
    });
  }

  createOrUpdateBookmark(): void{
    const bookmarks: Bookmark[] = this.editState?.entries || [];
    const existentBookmark = bookmarks.filter((b: Bookmark)  => this.currentBookmark && this.currentBookmark.index === b.index )[0];
    if(this.currentBookmark && existentBookmark) {
      existentBookmark.link = this.currentBookmark.link;
      existentBookmark.name = this.currentBookmark.name;
    }
    else{
      const newBookmark = Object.assign({ index: bookmarks.length }, this.currentBookmark);
      bookmarks.push(newBookmark);
    }
    Vue.set(this.editState, 'entries', bookmarks);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: BookmarksWidgetSetting): DashboardSettingState {
    const newSettings = Object.assign({ bookmarks: {} }, currentSettings);
    newSettings.bookmarks = {
      style: editState.style,
      visible: editState.visible,
      entries: editState.entries
    };
    return newSettings;
  }

  mounted(): void {
    this.initializeBookmarkWidget();
    this.initializeBookmarkForm();
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeBookmarkWidget(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'dashBookmarks',
      value: this.buildBookmarksEditState(this.preferences),
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeBookmarkForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'currentBookmark',
      value: {},
    });
  }

  // Build edit state based on overall dashboard settings
  buildBookmarksEditState(preferences: DashboardSettingState): BookmarksWidgetSetting {
    return Object.assign({}, preferences.bookmarks);
  }

  get tableData() {
    const bookmarks: Bookmark[] = this.editState?.entries || [];
    return bookmarks.map((bookmark: Bookmark, i: number) => {
      return {
        index: i || null,
        name: bookmark.name,
        link: `<a href='${bookmark.link}' target='_blank'>${bookmark.link}</a>`
      };
    });
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: [
        { label: this.$t('name').toString(), field: 'name' },
        { label: this.$t('link').toString(), field: 'link', html: true},
        { label: this.$t('delete').toString(), field: 'delete', width: '5%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: true,
      createText: this.$t('create_bookmark').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
