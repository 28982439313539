
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { HistoryLiverSmc } from '@/store/organSpecificDetails/types';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface OverrideLiverScoreForm {
  overrideSmcScore?: boolean;
  overrideDate?: string;
  overrideComments?: string;
  overrideType?: boolean|null;
  baselinePoints?: number|null;
  baselineDate?: string|null;
  flatScore?: number|null;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    NumberInput,
    TextAreaInput,
    CheckboxInput,
    BooleanRadioInput,
  }
})
export default class OverrideLiverScore extends Vue {
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.organSpecificDetails.historyLiverSmc) private historyLiverSmc!: HistoryLiverSmc[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('canOverrideSmcScore', { namespace: 'organSpecificDetails' }) canOverrideSmcScore!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  get canSaveOverrideLiverScore(): boolean {
    return !this.newJourney && this.canOverrideSmcScore && !this.journey.completed;
  }

  private loaded(): void {
    const journey: RecipientJourney = this.journey || {};
    const liverSpecificDetails: LiverDetails = journey.organ_specific_details || {};
    const latestSmc: HistoryLiverSmc = liverSpecificDetails.latest_smc || {};
    this.initializeForm(latestSmc);
    this.$emit('loaded', 'overrideLiverScore');
  }
  // Initialize form edit state
  private initializeForm(latestSmc?: HistoryLiverSmc): void {
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'overrideLiverScore',
      value: this.buildOverrideLiverScoreFormState(latestSmc)
    });
  }

  // Generate values for this form's UI edit state, based on a source document loaded from API
  private buildOverrideLiverScoreFormState(latestSmc?: HistoryLiverSmc): OverrideLiverScoreForm {
    let result: OverrideLiverScoreForm = {};
    if (!latestSmc) return result;

    Object.assign(result, {
      overrideSmcScore: latestSmc.override || false,
      overrideDate: latestSmc.review_date || undefined,
      overrideComments: latestSmc.override_comment || undefined,
      overrideType: latestSmc.baseline_override,
      baselineDate: latestSmc.baseline_override ? latestSmc.baseline_date : undefined,
      baselinePoints: latestSmc.baseline_score,
      flatScore: latestSmc.flat_score,
    });
    return result;
  }

  /**
   * Saves current form state for Sodium MELD
   */
  private saveOverrideLiverScore(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveOverrideLiverScore as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const opts = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id!.$oid,
      payload: { liver_smc_result: this.extractLatestSmcPatch() },
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveLiverSmcResult', opts).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      saveProvider.registerSaveResult(success);
      // Request card-section to reload sub-sections related to Liver Scores simultaneously
      // Note: this will cause the card-section to invoke this component's public 'reinitialize' method
      this.$emit('reloadLiverExceptionPoints');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Reload recipient, journey, and all related records needed for this section
  public reinitialize(): void {
    const journey: RecipientJourney = this.journey || {};
    const liverSpecificDetails: LiverDetails = journey.organ_specific_details || {};
    const latestSmc: HistoryLiverSmc = liverSpecificDetails.latest_smc || {};
    this.initializeForm(latestSmc);
  }
  // Event handler to clear out related fields
  private onOverrideTypeChanged(overrideType: boolean|null) {
    // True = Baseline, False = Flat
    const formState = this.editState.overrideLiverScore || {};

    if (overrideType) {
      // Clear Flat Score if set to Baseline
      Object.assign(formState, {
        flatScore: null,
      });
    } else {
      // Clear Baseline fields if set to Flat Score
      Object.assign(formState, {
        baselinePoints: null,
        baselineDate: null,
      });
    }
    Vue.set(this.editState, 'overrideLiverScore', formState);
  }

  /**
   * Returns a patch object containing changes for a Liver Meld Lab document
   *
   * For a document that does not yet exist, the object contains all data to be saved
   *
   * @returns {HistoryLiverSmc} changes to save
   */
  private extractLatestSmcPatch(): HistoryLiverSmc {
    if (!this.editState || !this.editState.overrideLiverScore) return {};

    const form = this.editState.overrideLiverScore || {};

    const isOverrideSmcScoreTicked = form.overrideSmcScore;
    const isBaselineOverride = !!form.overrideType;
    const isFlatScoreOverride = !isBaselineOverride;

    const result: HistoryLiverSmc = {
      // Properties common to any SMC Score Override
      override: isOverrideSmcScoreTicked,
      review_date: form.overrideDate,
      override_comment: form.overrideComments,
      baseline_override: isOverrideSmcScoreTicked ? isBaselineOverride : null,

      // Baseline Override properties
      baseline_score: isBaselineOverride ? form.baselinePoints : null,
      baseline_date: isBaselineOverride ? form.baselineDate : null,

      // Flat Score Override properties
      flat_score: isFlatScoreOverride ? form.flatScore : null,
    };

    return result;
  }

  // Whether or not to show the fields that related to specific types of overrides
  get showOverrideTypeDetails(): boolean {
    if (!this.editState || !this.editState.overrideLiverScore) return false;

    const isOverrideSmcScoreTicked = this.editState.overrideLiverScore.overrideSmcScore || false;
    return isOverrideSmcScoreTicked;
  }

  // Whether or not to show Baseline Override fields
  get showBaselineOverrideDetails(): boolean {
    if (!this.editState || !this.editState.overrideLiverScore) return false;

    const isBaselineSelected = this.editState.overrideLiverScore.overrideType || false;
    return isBaselineSelected;
  }

  // Whether or not to show Flat Score fields
  get showFlatScoreDetails(): boolean {
    if (!this.editState || !this.editState.overrideLiverScore) return false;

    const isFlatScoreSelected = this.editState.overrideLiverScore.overrideType === false;
    return isFlatScoreSelected;
  }

  // Event handler for the Override SMC Score checkbox
  private onOverrideSmcScoreChanged(ticked: boolean): void {
    if (!this.editState || !this.editState.overrideLiverScore) return;

    // Reset override type related details fields when unticked
    if (!ticked) {
      const formState = this.editState.overrideLiverScore;
      Object.assign(formState, {
        overrideType: null,
        baselinePoints: null,
        baselineDate: null,
        flatScore: null,
      });
      Vue.set(this.editState, 'overrideLiverScore', formState);
    }
  }

  // Validation mapping
  public idLookup(): IdLookup {
    return {
      'liver_smc_result.override'          : 'lsd-exception-points-override-smc-score',
      'liver_smc_result.review_date'       : 'lsd-exception-points-override-date',
      'liver_smc_result.override_comment'  : 'lsd-exception-points-override-comments',
      'liver_smc_result.baseline_override' : 'lsd-exception-points-override-type',
      'liver_smc_result.baseline_date'     : 'lsd-exception-points-baseline-date',
      'liver_smc_result.baseline_score'    : 'lsd-exception-points-baseline-points',
      'liver_smc_result.flat_score'        : 'lsd-exception-points-flat-score',
    };
  }
}
