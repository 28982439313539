
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { Organ } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { RecipientJourney, RecipientAssessmentAttributes, RecipientAssessmentFactors, ConsultationDecision, JourneyDurations } from '@/store/recipientJourney/types';
import { RecipientConsultationDecisionValue, RecipientReferralDecisionReason, RecipientReferralDecision, RecipientConsultationDecision, RecipientConsultationDecisionReason, FINAL_DECISION_CONFIRMATION, AllResponsiblePhysicians } from '@/store/lookups/types';
import { ConsultationSectionPageState } from '@/components/organs/shared/_ConsultationSection.vue';
import { SurgicalPerson } from '@/store/surgicalPersons/types';
import { GenericCodeValue, ObjectId } from '@/store/types';
import { sortOptionsByValue } from '@/utils';
import { CoordinatorOptions } from "@/store/coordinators/types";
import { ResponsiblePhysicianOption } from "@/store/responsiblePhysicians/types";

export interface ConsultationPageState {
  consultationDate?: string;
  consultationDecision?: number;
  reasonCancelled?: number;
  reasonDelayed?: number;
  consultationDelayEndDate?: string;
  consultationDelayStartDate?: string;
  recipientCoordinator?: string;
  primaryPhysician?: string;
  consultationComments?: string;
}

export interface ConsultationRow {
  _id: { $oid: string };
  consultationDate?: string;
  recipientCoordinatorName?: string;
  consultationDecisionValue?: string;
  reasonValue?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    SelectInput,
    TextAreaInput,
  }
})
export default class ConsultationDetailsSection extends mixins(DateUtilsMixin) implements SaveableSection {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.consultationSection) editState!: ConsultationSectionPageState;
  @State(state => state.journeyState.journeyDurations) journeyDurations!: JourneyDurations;
  @State(state => state.journeyState.selectedConsultationDecision) selectedConsultationDecision!: ConsultationDecision;
  @State(state => state.journeyState.selectedJourney.stage_attributes.assessment) assessmentAttributes!: RecipientAssessmentAttributes;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('hasFinalReferral', { namespace: 'journeyState' }) hasFinalReferral!: boolean;
  @Getter('consultationWaitDays', { namespace: 'journeyState' }) consultationWaitDays!: number|null;
  @Getter('consultationDecisions', { namespace: 'journeyState' }) consultationDecisions!: ConsultationDecision[];
  @Getter('activeSortedCoordinatorOptions', { namespace: 'coordinators' }) activeCoordinatorOptions!: (hospitalId: string, coordinatorId: string, organCode: number) => CoordinatorOptions[];
  @Getter('activeSortedResponsiblePhysicianOptions', { namespace: 'responsiblePhysicians' }) private responsiblePhysicianOptions!: (hospitalId: string, coordinatorId: string, organCode: number) => ResponsiblePhysicianOption[];
  @Getter('consultationDecisionOptions', { namespace: 'lookups' }) consultationDecisionOptions!: RecipientConsultationDecision[];
  @Getter('hasFinalConsultation', { namespace: 'journeyState' }) hasFinalConsultation!: boolean;
  @Getter('surgicalPersonsByHospitalId', { namespace: 'surgicalPersons' }) surgicalPersons!: (hospitalId: string) => GenericCodeValue[];
  @Getter('isCoordinator', { namespace: 'users' }) isCoordinator!: boolean;
  @Getter('getUserCoordinatorId', { namespace: 'users' }) userCoordinatorId!: ObjectId;

  @Prop({ default: false }) newJourney!: boolean
  @Prop({ default: false }) canSave!: boolean;

  loaded() {
    this.$store.dispatch('responsiblePhysicians/loadAllResponsiblePhysicians');
  }

  mounted() {
    this.initializeForm();
  }

  private initializeForm() {
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'consultationSection',
      value: { consultation: this.buildConsultationPageState() },
    });
  }

  /**
   * Return sorted responsible physician options if the user is allowed and the physician is not expired.
   */

  get activeResponsiblePhysicians() {
    return this.responsiblePhysicianOptions(
      this.journey?.transplant_program?.transplant_hospital_id?.$oid || '', 
      this.editState.consultation?.primaryPhysician || '',
      this.journey?.organ_code || 0
    );
  }

  /**
   * Return sorted coordinator options if the user is allowed and the coordinator is not expired.
   */

  get activeCoordinators() {
    return this.activeCoordinatorOptions(
      this.journey?.transplant_program?.transplant_hospital_id?.$oid || '', 
      this.editState.consultation?.recipientCoordinator || '',
      this.journey?.organ_code || 0
    );
  }

  /**
   * Return true if we meet all the qualifications to create/edit this section
   *
   * @return {boolean} true if we can edit
   */
  get canEdit(): boolean {
    // Can't create decisions on a new or completed journey
    if (this.newJourney || this.journey.completed) return false;
    // Must have a final referral and can't have a final consultation
    return this.hasFinalReferral && !this.hasFinalConsultation;
  }

  /**
   * Gets Surgical Persons Options
   *
   * @return {GenericCodeValue}
   */
  get surgicalPersonOptions(): GenericCodeValue[]{
    const hospitalId = this.journey?.transplant_program?.transplant_hospital_id?.$oid;
    return this.surgicalPersons(hospitalId || '');
  }

  /**
   * Gets table configuration for Consultation Details.
   *
   * @return {TableConfig} Consultation Details table configuration
   */
  get consultationDetailTableConfig(): TableConfig {
    let consultationRows: ConsultationRow[] = [];
    if (!!this.editState && !!this.editState.consultation) {
      consultationRows = this.consultationRows(this.consultationDecisions);
    }
    // Only show 'total wait' group header row when journey has consultation decisions
    const showTotalDaysRow = consultationRows.length > 0;
    return {
      data: showTotalDaysRow ? [
        {
          consultationDecisionValue:  this.$t('total_wait_for_consultation').toString(), // use second-to-last column field, to ensure label is next to days
          children: consultationRows,
        }
      ] : consultationRows,
      groupOptions: {
        enabled: showTotalDaysRow,
        headerPosition: 'bottom'
      },
      columns: [
        { label: this.$t('consultation_cancellation_delay_date').toString(), field: 'consultationDate', width: '20%' },
        { label: this.$t('recipient_coordinator').toString(), field: 'recipientCoordinatorName', width: '20%' },
        { label: this.$t('consultation_decision').toString(), field: 'consultationDecisionValue', width: '30%' },
        { label: this.$t('consultation_decision_reason').toString(), field: 'reasonValue', headerField: this.totalWaitForConsultation, tdClass: 'summary-column', width: '30%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_consultation_decision').toString(),
    };
  }

  /**
   * Gets a string representation of the total number of days waiting for the Consultation stage
   * @returns {string} number of wait days as text
   */
  private totalWaitForConsultation(rowObj: any): string {
    // consultation days may be zero
    const consultationDays = this.consultationWaitDays;
    if (consultationDays == null) {
      return '-';
    }
    if (consultationDays === 1) {
      return this.$t('1_day').toString();
    } else {
      return `${consultationDays} ${this.$t('days').toString()}`;
    }
  }

  /**
   * Get a boolean representation if a consultation is selected
   *
   * @returns {boolean} true when user has loaded a consultation
   */
  get existingDecision(): boolean {
    return !!this.selectedConsultationDecision;
  }

  /**
   * Returns an array of options for Decision Delayed Reasons
   *
   * Fetches the Reason subtable for the Decision Cancelled lookup table.
   *
   * @returns {RecipientConsultationDecisionReason[]} reasons for a cancellation
   */
  get consultationDesicionReasonCancelledLookup(): RecipientConsultationDecisionReason[] {
    if (!this.consultationDecisionOptions) {
      return [];
    }
    const cancelled = this.consultationDecisionOptions.find((decision: RecipientConsultationDecision) => {
      return decision.code === RecipientConsultationDecisionValue.Cancelled;
    });
    if (!cancelled) {
      return [];
    }
    return cancelled.sub_tables.recipient_consultation_decision_reason_codes;
  }

  /**
   * Returns an array of options for Decision Delayed Reasons
   *
   * Fetches the Reason subtable for the Decision Delayed lookup table.
   *
   * @returns {RecipientConsultationDecisionReason[]} reasons for a delay
   */
  get consultationDecisionReasonDelayedLookup(): RecipientConsultationDecisionReason[] {
    if (!this.consultationDecisionOptions) {
      return [];
    }
    const delayed = this.consultationDecisionOptions.find((decision: RecipientConsultationDecision) => {
      return decision.code === RecipientConsultationDecisionValue.Delayed;
    });
    if (!delayed) {
      return [];
    }
    return delayed.sub_tables.recipient_consultation_decision_reason_codes;
  }

  /**
   * Return text for confirmation prompt message if final decision
   *
   * Check the decsion code in the lookups and see if this is a final decsion,
   * if so return a message for the confirm prompt
   *
   * @returns {string|undefined} text for confirmation prompt or undefined
   */
  get confirmationText(): string|undefined {
    // Ensure we have all necessary data
    if (!this.editState || !this.consultationDecisionOptions) {
      return undefined;
    }
    // Get selected decision
    const decisionCode = this.editState.consultation ? this.editState.consultation.consultationDecision : undefined;
    // Is this a final decision?
    const finalDecision = this.consultationDecisionOptions.find((item: RecipientConsultationDecision) => {
      return decisionCode == item.code && item.final === true;
    });
    if (!finalDecision) {
      return undefined;
    }
    return this.$t('final_decision_confirmation').toString();
  }
  
  /**
   * Returns whether the user selected Consultation Completed or Cancelled
   *
   * @returns {boolean} true if the user choice is Consultation Completed or Cancelled
   */
  get isConsultationCompletedOrCancelled(): boolean {
    if (this.editState && this.editState.consultation) {
      const decision = RecipientConsultationDecisionValue.Completed || this.editState.consultation.consultationDecision == RecipientConsultationDecisionValue.Cancelled;
      return this.editState.consultation.consultationDecision == decision;
    }
    return false;
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveConsultation as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Referral Atributes, dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveConsultation as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'consultation');
    // Generate payload based on current edit state
    const consultationPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipient.client_id,
      consultation: consultationPatch,
    };
    this.$store.dispatch('journeyState/saveConsultation', payload).then((success: SaveResult) => {
       // Reload decision list
      this.reloadConsultationDetailsHistory();
      // Clear form state
      this.createConsultationDecision();
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Clear errors
      this.$emit('clear');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.consultation) {
      return {};
    }
    return this.extractConsultationPatch(this.editState.consultation);
  }

  /**
   * Build page state for the Consultation Details subsection
   *
   * @param consultationDecision a Recipient Journey Consultation Attributes model, including its nested factors
   * @returns {ConsultationPageState} page state for the Consultation Details form
   */
  public buildConsultationPageState(consultationDecision?: ConsultationDecision): ConsultationPageState {
    const defaultState: ConsultationPageState  = {
      consultationDate: this.currentDateUi(),
      recipientCoordinator: this.isCoordinator ? this.userCoordinatorId.$oid : undefined
    };
    if (!!consultationDecision) {
      const consultationDate = this.parseDateUiFromDateTime(consultationDecision.consultationDate);
      const recipientCoordinatorId = consultationDecision.recipientCoordinator;
      const consultationDecisionCode = consultationDecision.consultationDecisionCode;
      const consultationComments = consultationDecision.consultationComments;
      const reasonCode = consultationDecision.consultationDecisionReasonCode || undefined;
      // Reason code could be cancelled or delayed, separate the values
      let reasonCancelled: number|undefined = undefined;
      let reasonDelayed: number|undefined = undefined;
      switch (consultationDecisionCode) {
        case RecipientConsultationDecisionValue.Cancelled:
          reasonCancelled = reasonCode;
          break;
        case RecipientConsultationDecisionValue.Delayed:
          reasonDelayed = reasonCode;
          break;
      }
      // Build result
      const result = {
        consultationDate: consultationDate || defaultState.consultationDate,
        consultationDelayEndDate: this.parseDateUi(consultationDecision.consultationDelayEndDate),
        consultationDelayStartDate: this.parseDateUi(consultationDecision.consultationDelayStartDate),
        recipientCoordinator: recipientCoordinatorId,
        consultationDecision: consultationDecisionCode,
        reasonCancelled,
        reasonDelayed,
        primaryPhysician: consultationDecision.primaryPhysician,
        consultationComments,
      };
      return result;
    }
    return defaultState;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'factors.consultation_decision_date'                : 'consultation-date',
    'factors.consultation_decision_coordinator_id'      : 'journey-consultation-coordinator',
    'factors.consultation_decision_code'                : 'journey-consultation-decision',
    'factors.consultation_decision_reason_code'         : 'reason_code',
    'factors.consultation_decision_delay_end_date'      : 'journey-consultation-delay-end-date',
    'factors.consultation_decision_delay_start_date'    : 'consultation-date',
    'factors.consultation_decision_primary_physician_id': 'journey-consultation-responsible-physician'
  };

  // Private methods

  /**
   * Get new decision events which will refresh the table
   */
  private reloadConsultationDetailsHistory(): void {
     // Begin loading recipient decision events
    this.$store.dispatch('recipients/loadDecisionEvents', this.recipientId);
    // Reload the recipient and journey data
    this.$store.dispatch('recipients/get', this.recipientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId);
    });
  }

  /**
   * Gets form edit state changes as an Assessment Attributes patch
   *
   * Changes to Assessment Factors are nested within the patch under the 'factors' key
   *
   * @param consultation form edit state containing changes
   * @returns {RecipientAssessmentAttributes} patch object containing field changes
   */
  private extractConsultationPatch(consultation: ConsultationPageState): RecipientAssessmentAttributes {
    /**
     * Identify which reason code parameter to send based on selected decision. If a decision that has no associated
     * reasons is selected, then we must send a null decision reason code to remove any prior reason if there was one.
     */
    const decisionCode = consultation.consultationDecision;
    let decisionReasonCode: number|null = null;
    // Set decision date 
    let decisionDate = this.sanitizeDateApi(consultation.consultationDate);
    // These values should be cleared if the decision is anything but Delayed
    let delayStartDate = this.sanitizeDateApi(consultation.consultationDelayStartDate);
    let delayEndDate = this.sanitizeDateApi(consultation.consultationDelayEndDate);
    /**
     * Use double-equals instead of triple-equals to determine which decision reason was selected, because even numeric
     * values in the form edit state are strings. Using the triple-equals pattern only returns true if the compared
     * values have the same type, which when comparing form edit state values against lookup code numbers will never be
     * the case.
     */
    if (decisionCode == RecipientConsultationDecisionValue.Cancelled) {
      decisionReasonCode = consultation.reasonCancelled || null;
      delayStartDate = null;
      delayEndDate = null;
    } else if (decisionCode == RecipientConsultationDecisionValue.Delayed) {
      decisionReasonCode = consultation.reasonDelayed || null;
      decisionDate = delayStartDate;
    } else if (decisionCode == RecipientConsultationDecisionValue.Completed) {
      decisionReasonCode = null;
      delayStartDate = null;
      delayEndDate = null;
    }
    const factorsPatch: RecipientAssessmentFactors = {
      consultation_decision_code: decisionCode || null,
      consultation_decision_date: decisionDate,
      consultation_decision_delay_end_date: delayEndDate,
      consultation_decision_delay_start_date: delayStartDate,
      consultation_decision_reason_code: decisionReasonCode || null,
      consultation_decision_coordinator_id: consultation.recipientCoordinator || null,
      consultation_decision_primary_physician_id: consultation.primaryPhysician || null,
      consultation_decision_comments: consultation.consultationComments,
    };
    const result: RecipientAssessmentAttributes = {
      factors: factorsPatch,
    };
    return result;
  }

  /**
   * Gets table row data representing all Consultation Decisions for the selected recipient.
   *
   * @param consulatations all Consultation Decisions for the Recipient's journey
   * @returns {ConsultationAssessmentRow[]} Consultation Decision rows
   */
  private consultationRows(consultations?: ConsultationDecision[]): ConsultationRow[] {
    if (!consultations) {
      return [];
    }
    const rows = consultations.map((consultation: ConsultationDecision) => {
      const row: ConsultationRow = {
        _id: consultation._id,
        consultationDate: this.parseDisplayDateUiFromDateTime(consultation.consultationDate) || '-',
        recipientCoordinatorName: this.recipientCoordinatorName(consultation.recipientCoordinator),
        consultationDecisionValue: this.consultationDecisionValue(consultation) || '-',
        reasonValue: this.consultationReasonValue(consultation) || '-',
      };
      return row;
    });
    return rows;
  }

  /**
   * Returns whether or not to show Reason Cancellation
   *
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonCancelled(): boolean {
    if (!this.editState || !this.editState.consultation || !this.editState.consultation.consultationDecision) {
      return false;
    }
    return this.editState.consultation.consultationDecision == RecipientConsultationDecisionValue.Cancelled;
  }

  /**
   * Returns whether or not to show Reason Delayed
   *
   * @returns {boolean} true if the form area should be shown, false if not
   */
  private showReasonDelayed(): boolean {
    if (!this.editState || !this.editState.consultation || !this.editState.consultation.consultationDecision) {
      return false;
    }
    return this.editState.consultation.consultationDecision == RecipientConsultationDecisionValue.Delayed;
  }

  /**
   * Gets text representation of an Consultation Decision
   * @param consultationHistory historical data containing a Consultation Decision
   * @returns {string} Consultation Decision as text
   */
  private consultationDecisionValue(consultationDecision: ConsultationDecision): string|undefined {
    const decisionCode = consultationDecision.consultationDecisionCode;
    const lookupTable = this.consultationDecisionOptions;
    if (!lookupTable) {
      return undefined;
    }
    const decisionEntry = lookupTable.find((decision: RecipientConsultationDecision) => {
      return decision.code == decisionCode;
    });
    if (!decisionEntry) {
      return undefined;
    }
    return decisionEntry.value;
  }

  /**
   * Gets text representation of an Consultation Decision Reason
   * @param consultationHistory historical data containing a Consultation Decision Reason
   * @returns {string} Consultation Decision Reason as text
   */
  private consultationReasonValue(consultationDecision: ConsultationDecision): string|undefined {
    const decisionCode = consultationDecision.consultationDecisionCode;
    const reasonCode = consultationDecision.consultationDecisionReasonCode;
    const lookupTable = this.consultationDecisionOptions;
    if (!lookupTable) {
      return undefined;
    }
    const decisionEntry = lookupTable.find((decision: RecipientConsultationDecision) => {
      return decision.code == decisionCode;
    });
    if (!decisionEntry || !decisionEntry.sub_tables) {
      return undefined;
    }
    const subtable = decisionEntry.sub_tables.recipient_consultation_decision_reason_codes;
    if (!subtable) {
      return undefined;
    }
    const reasonEntry =  subtable.find((reason: RecipientConsultationDecisionReason) => {
      return reason.code == reasonCode;
    });
    if (!reasonEntry) {
      return undefined;
    }
    return reasonEntry.value;
  }

  /**
   * Gets display name string for a Recipient Coordinator
   *
   * @param recipientCoordinatorId the ID number for the Recipient Coordinator
   * @return {string|undefined} Recipient Coordinator name if one exists, otherwise undefined
   */
  private recipientCoordinatorName(recipientCoordinatorId?: string): string|undefined {
    // Fetch possible coordinator options
    const possibleCoordinators = this.activeCoordinatorOptions(
      this.journey?.transplant_program?.transplant_hospital_id?.$oid || '', 
      recipientCoordinatorId || '',
      this.journey?.organ_code || 0
    );
    // Select option with code equal to specified Object ID string
    const theCoordinatorOption = possibleCoordinators.find((option: { code: string; value: string }) => {
      return option.code === recipientCoordinatorId;
    });
    if (!theCoordinatorOption) {
      return undefined;
    }
    return theCoordinatorOption.value;
  }

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectConsultationDecision(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.editState || !this.consultationDecisions) {
      return;
    }
    // Find the selected source document
    const found = this.consultationDecisions.find((decision: ConsultationDecision) => {
      return decision._id && decision._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Store the selection
    this.$store.commit('journeyState/selectConsultationDecision', found);
    // Build form state based on selected document
    const consultationDecisionForm: ConsultationPageState = this.buildConsultationPageState(this.selectedConsultationDecision);
    Vue.set(this.editState, 'consultation', consultationDecisionForm);
  }

  /**
   * Resets edit state to prepare for entering a new document
   */
  private createConsultationDecision(): void {
    // Clear stored selection
    this.$store.commit('journeyState/clearConsultationDecision');
    // Build empty form state
    const consultationDecisionForm: ConsultationPageState = this.buildConsultationPageState();
    Vue.set(this.editState, 'consultation', consultationDecisionForm);
  }
}
