
import { NumericCodeValue } from '@/store/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class PaginationToolbar extends Vue {
  // Props
  @Prop({ required: true }) toolbarId!: string; // Set the ID
  @Prop({ required: true }) currentPage!: number;
  @Prop({ required: true }) perPage!: number;
  @Prop({ required: true }) perPageDropdown!: number[];
  @Prop({ required: true }) dropdownAllowAll!: boolean;
  @Prop({ required: true }) nextLabel!: string;
  @Prop({ required: true }) prevLabel!: string;
  @Prop({ required: true }) rowsPerPageLabel!: string;
  @Prop({ required: true }) rows!: any[];

  // Private attributes
  private internalCurrentPageValue = 1;
  private internalPerPageValue: number = this.allOption.code;

  // Build dropdown options based on properties. Result will have numeric codes and string values, both based on
  // numbers in properties. Adds the 'all option' at the beginning of the list if needed.
  get perPageOptions(): NumericCodeValue[] {
    const propOptions = this.perPageDropdown || [];
    const results = propOptions.map((option: number): NumericCodeValue => {
      return { code: option, value: option.toString() };
    });
    if (this.dropdownAllowAll) {
      results.unshift(this.allOption);
    }
    return results;
  }

  // The 'all option' entry
  get allOption(): NumericCodeValue {
    return { code: 0, value: this.$t('all').toString()};
  }

  // Total number of row entries
  get recordCount(): number {
    return this.rows.length;
  }

  // Number of pages needed to cover all row entries. Page size (perPage) divided by total entries, rounded up.
  get pageCount(): number {
    if (this.perPage === 0) return 0;

    return Math.ceil(this.recordCount / this.perPage);
  }

  // Item number of first row entry on current page. 1-based. Basically row index + 1
  get currentPageItemNumberStart(): number {
    const startIndex = (this.currentPage - 1) * this.perPage;
    return Math.min(startIndex + 1, this.pageCount);
  }

  // Item number of last row entry on current page. 1-based. First item plus page size, or last item
  get currentPageItemNumberEnd(): number {
    const endIndex = this.perPage > 0 ? Math.min(this.currentPageItemNumberStart + this.perPage - 1, this.recordCount) : this.recordCount;
    return endIndex;
  }

  // Text to display to user about current page and page size
  get currentPageInfo(): string {
    const numberStart = this.currentPageItemNumberStart;
    const numberEnd = this.currentPageItemNumberEnd;
    const recordCount = this.recordCount;
    return this.$t('current_page_info', { numberStart, numberEnd, recordCount }).toString();
  }

  // True when we cannot go to previous page
  get prevDisabled(): boolean {
    return this.pageCount <= 1 || this.currentPage === 1;
  }

  // True when we cannot go to previous page
  get nextDisabled(): boolean {
    return this.pageCount <= 1 || this.currentPage === this.pageCount;
  }

  // Initialize internal state based on properties
  private mounted(): void {
    Vue.set(this, 'internalCurrentPageValue', this.currentPage);
    Vue.set(this, 'internalPerPageValue', this.perPage);
  }

  // Emit updates to pagination properties when internal state changes
  private selectEvents(): any {
    const _vm = this as PaginationToolbar;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        change(event: any) {
          // Reset current page
          _vm.$emit('currentPage', 1);
          // Emit updated value
          _vm.$emit('perPage', _vm.internalPerPageValue);
        }
      }
    );
  }

  // Go to previous page and emit update to pagination
  private prevClicked(): void {
    if (this.prevDisabled) {
      return;
    }
    const newPage = Math.max(this.currentPage - 1, 1);
    this.$emit('currentPage', newPage);
  }

  // Go to next page and emit update to pagination
  private nextClicked(): void {
    if (this.nextDisabled) {
      return;
    }
    const newPage = Math.min(this.currentPage + 1, this.pageCount);
    this.$emit('currentPage', newPage);
  }
}
