
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { CrossmatchReportState } from '@/store/reports/types';
import TextInput from '@/components/shared/TextInput.vue';
import { BloodType } from '@/store/lookups/types';
import { Hospital } from '@/store/hospitals/types';
import { Region } from '@/store/lookups/types';
import { LaboratoriesState, Laboratory } from '@/store/laboratories/types';

interface CrossmatchOptions {
  code?: string,
  value?: string
}

@Component({
  components: {
    TextInput,
    SaveToolbar,
    SelectInput,
    SubSection
  }
})

export default class CrossmatchReport extends Vue {
  // State
  @State(state => state.reports.CrossmatchReportState) editState!: CrossmatchReportState;
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.laboratories.hla) laboratories!: Laboratory[];

  @Getter('organTransplantHospitals', { namespace: 'hospitals' }) hospitalOptions!: any[];

  private bloodTypesIncluded = ["A", "B", "AB", "O"];

  public lookupsToLoad = ['blood_type'];

  get filteredLaboratories(): any[]{
    if(!this.laboratories) {
      return [];
    }

    return this.laboratories.map((lab) => {
      return {
        code: lab.lab_code,
        value: lab.name
      };
    });
  }

  get allowedHospitals(): CrossmatchOptions[] {
    let hospitals: CrossmatchOptions[] = [{code: 'All', value: 'All' }];
    this.hospitalOptions.forEach((hospital: CrossmatchOptions) =>{
      hospitals.push(hospital);
    });
    return hospitals;
  }

  get allowedBloodTypes(): BloodType[]{
    return this.bloodTypeLookup.filter((bloodType) => {
      return this.bloodTypesIncluded.includes(bloodType.code);
    });
  }

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.dispatch('laboratories/load', 'hla').then(() => {
        this.$store.commit('reports/setCrossmatchReportState', {});
      });
    });
  }

  public buildCrossmatchPayload(): CrossmatchReportState {
    if (!this.editState) {
      return {};
    }

    const hospitalValues = this.hospitalOptions.map((hospital: CrossmatchOptions) => {
      return hospital.code || '';
    });

    const bloodTypeValues = this.allowedBloodTypes.map((bloodType: BloodType) => {
      return bloodType.code || '';
    });

    const regionValues = this.filteredLaboratories.map((laboratory: CrossmatchOptions) => {
      return laboratory.code || '';
    });

    const result = {
      region: this.editState.region ? this.editState.region : regionValues,
      hospital: !this.editState.hospital?.includes('All') ? this.editState.hospital : hospitalValues,
      blood_type: this.editState.bloodType ? this.editState.bloodType : bloodTypeValues,
      tray_number: this.editState.trayNumber
    };

    return result;
  }

  public generateReport(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    const payload = this.buildCrossmatchPayload();
    // Submit query
    this.$store.dispatch('reports/printCrossmatchReport', payload).then((result: SaveResult) => {

      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    });
  }
}
