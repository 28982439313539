
import { State, Getter } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientJourneyLinks from '@/components/recipients/side-nav/SelectedRecipientJourneyLinks.vue';
import SelectedRecipientProfileLinks from '@/components/recipients/side-nav/SelectedRecipientProfileLinks.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientHlaLinks,
    SelectedRecipientJourneyLinks,
    SelectedRecipientProfileLinks,
  }
})
export default class SideNavRecipientProfile extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];

  @Prop({ default: false }) newRecipient!: boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
