
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { APIRoute, APIBaseRoute, APINakedRoute, EP } from '@/api-endpoints';

export interface ButtonConfiguration {
  title: string|null;
  url: string|null;
}

@Component({
  components: {}
})
export default class Login extends Vue {
  @State(state => state.pageState.currentPage.login) private editState!: any;

  @Getter('getLoginProviderName', { namespace: 'features' }) private getLoginProviderName!: string;

  config: ButtonConfiguration = { title: null, url: null };

  getConfigurations(type: string|null): ButtonConfiguration {
    switch(type) {
      case 'ehealth':
        return {
          title: 'login_ehealth',
          url: APIBaseRoute(EP.loginProvider.eHealth)
        };
        break;
      case 'azure_activedirectory_v2':
        return {
          title: 'login_azure',
          url: APINakedRoute(EP.loginProvider.azure),
        };
        break;
      default: 
        return { title: null, url: null };
        break;
    }
  }

  mounted(): void {
    this.config = this.getConfigurations(this.getLoginProviderName || null);
  }
}

