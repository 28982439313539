
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { DeceasedDonor, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { DONOR_DATA_HISTORY_ENABLED } from "@/store/administration/types";

@Component
export default class SelectedDeceasedDonorProfileLinks extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('donorOrganPackagingForms', { namespace: 'deceasedDonors'}) private donorOrganPackagingForms!: any[];
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];

  // @Prop({default: true}) open!: boolean;
  @Prop({default: false}) active!: boolean;
  @Prop({default: false}) newDonor!: boolean;

  
  /**
   * Create a return to profile link
   *
   * @returns {string} client_id as a string
   */

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  get profileReturnLink(): string {
    return this.clientId === '' ? `/donors` : `/donors/${this.clientId}`;
  }

  // Determine whether or not to show the Donor Data History link
  get showDonorDataHistory(): boolean {
    return DONOR_DATA_HISTORY_ENABLED && !this.newDonor && !this.isTransplantCoordinator;
  }

  // Determine whether or not to show the Exceptional Distribution Disposition link
  get showExdDisposition(): boolean {
    return !!this.donor?.indicators?.exceptional_distribution;
  }

  // Determine whether or not to show the Monitor Recovery Outcomes section
  // - by having the group monitor_recovery_outcomes & using a consented organ
  get showMonitorRecoveryOutcomes(): boolean {
    const hasRecoveryOutcomes = this.groupExists('donor_recovery_outcomes');
    const hasConsentedOrgan = (this.selectedDonorConsentedOrganList || []).length > 0;
    return hasConsentedOrgan && hasRecoveryOutcomes;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-deceased-donor', params: { id: (this.clientId || '') }, hash: hash });
      return location.href;
      
    }
  }
}
