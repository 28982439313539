
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { router } from '@/router';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { AllocationOfferTypeValues } from '@/store/allocations/types';

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetActiveAllocations extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashAllocations) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getAllocations', { namespace: 'dashboard' }) getAllocations!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];

  public errorMessage = null;

  getDonorOrganUrl(params: any) {
    if (params.allocation_type) {
      const location = router.resolve({ name: 'deceased-donor-organ-option', params: { id: params.id, organ_code: params.organ_code, option: params.allocation_type } });
      return location.href;
    } else {
      const location = router.resolve({ name: 'deceased-donor-organ', params: { id: params.id, organ_code: params.organ_code } });
      return location.href;
    }
  }

  mounted(): void {
    Promise.all([
      this.$store.dispatch('dashboard/loadAllocations')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashAllocations',
      value: this.buildAllocationsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idAllocations").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildAllocationsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.allocations);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ allocations: {} }, currentSettings);
    newSettings.allocations = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.getAllocations,
      columns: [
        { label: this.$t('donor_id').toString(), field: 'deceased_donor_id', sortable: true },
        { label: this.$t('organ').toString(), field: 'organ' },
        { label: this.$t('allocation_status').toString(), field: 'state'}
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'deceased_donor_id', type: 'asc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
