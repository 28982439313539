
import { Getter, State } from "vuex-class";
import TextInput from "@/components/shared/TextInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import CardSection from "@/components/shared/CardSection.vue";
import { PancreasTypeCodeValue } from '@/store/lookups/types';
import { RecipientJourney } from "@/store/recipientJourney/types";
import SelectInput from "@/components/shared/SelectInput.vue";
import { SaveableSection, SaveProvider, SaveResult } from "@/types";
import { Recipient } from "@/store/recipients/types";
import { PancreasWholeDetails } from '@/store/organSpecificDetails/types';
import SubSection from '@/components/shared/SubSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { NumericCodeValue } from '@/store/types';

export interface PancreasWholeSpecificPageState {
  generalInfo: PancreasWholeSpecificDetailsForm;
  comments: string;
}

interface PancreasWholeSpecificDetailsForm {
  pancreaspak?: boolean;
  diabetes?: string;
  pancreasType?: string;
}

@Component({
  components: {
    TextInput,
    TextAreaInput,
    SubSection,
    CardSection,
    SelectInput,
    CheckboxInput,
  },
})
export default class PancreasWholeSpecificDetails extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.pancreasWholeDetails) editState!: PancreasWholeSpecificPageState;
  @State(state => state.lookups.pancreas_diabetes_type) diabetesTypesLookup!: NumericCodeValue[];
  @State(state => state.lookups.pancreas_type) pancreasTypeLookup!: NumericCodeValue[];

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isWaitlisted', { namespace: 'journeyState' }) isWaitlisted!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('wasRemovedFromWaitlist', { namespace: 'journeyState' }) wasRemovedFromWaitlist!: boolean;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  public lookupsToLoad = [ 'pancreas_diabetes_type', 'pancreas_type' ];

  /**
   * Return true if pancreas whole specific details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean {
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }
  
  /**
   * Return true if we should disable the PAK checkbox
   * 
   * This should be disabled for new, completed, was or is currently on the waitlist Journey's.  
   * Or if the pancreas_type value is PRT since the user cannot change it.
   *
   * @returns {boolean} true if we should disable it
   */
  get disablePAK(): boolean {
    // If the journey is new, completed, was or is currently on the waitlist
    if (this.newJourney || this.journey.completed || this.isWaitlisted || this.wasRemovedFromWaitlist) return true;
    // DIAG: Typescript thinks organ_specific_details is LiverDetails here so I have to explicitly tell the linter it's not.
    if ((this.journey.organ_specific_details as PancreasWholeDetails)?.pancreas_type === PancreasTypeCodeValue.PRT) return true;
    return false;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Pancreas Whole Details card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'pancreasWholeDetails');
  }

  /**
   * Populates the Pancreas Whole Specific Details form state with data from
   */
  public initializeForm(): void {
    // Initialize form edit state
    this.$store.commit("pageState/set", {
      pageKey: "pancreasWholeDetails",
      value: {
        comments: (this.journey || {}).comments,
        generalInfo : this.extractPancreasWholeSpecificDetails(this.journey),
      },      
    });
    // Begin fetching the recipient's 
    this.$store.dispatch("recipients/get", this.recipientId);
  }

  /**
   * Generates Pancreas Whole Specific Details form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {PancreasWholeSpecificDetailsForm} Pancreas Whole Specific Details form state
   */
  public extractPancreasWholeSpecificDetails(journey: RecipientJourney): PancreasWholeSpecificDetailsForm {
    if (!journey) return {};
    const organSpecificDetails = journey.organ_specific_details as PancreasWholeDetails;
    return {
      pancreaspak: organSpecificDetails.pancreas_type === PancreasTypeCodeValue.PAK,
      diabetes: organSpecificDetails.diabetes_type_code,
      pancreasType: this.lookupValue((organSpecificDetails.pancreas_type || undefined), 'pancreas_type'),
    };
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Pancreas Whole Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = (this.$refs.savePancreasWholeSpecificDetails as unknown) as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch(),
    };
    // Dispatch save action and register the response
    this.$store
      .dispatch("journeyState/saveJourney", payload)
      .then((success: SaveResult) => {
        // If successful, reload the current recipient
        this.$store.dispatch('recipients/get', this.recipientId);
        // If successful, update the current recipient and show success notification
        this.$store.commit("journeyState/setJourney",success.responseData.journey);
        saveProvider.registerSaveResult(success);
        this.initializeForm();
      })
      .catch((error: SaveResult) => {
        // Show error notification
        saveProvider.registerSaveResult(error);
        // Emit event to handle errors
        this.$emit("handleErrors", error);
      });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Pancreas Whole Specific Details
   *
   * If the edit state doesn't exist return {}
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.generalInfo) {
      return {};
    } else {
      return this.extractPancreasWholeSpecificDetailsPatch(this.editState.generalInfo);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Reset the save provider's save toolbar
    ((this.$refs.savePancreasWholeSpecificDetails as unknown) as SaveProvider).resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // General Pancreas Whole Details
    const result = {
      'organ_specific_details.diabetes_type_code' : 'pancreas-diabetes',
    };
    return result;
  }

  /**
   * PRIVATE
   */

  /**
   * Gets changes from the edit state as a patch for the Pancreas Whole Specific Details
   *
   * @returns {RecipientJourney} patch object containing field changes
   */
  private extractPancreasWholeSpecificDetailsPatch(pancreasSpecificDetails: PancreasWholeSpecificDetailsForm): RecipientJourney {
    return {
      comments: this.editState.comments,
      organ_specific_details: {
        // NOTE: If PAK is checked we need to send PAK, otherwise we send null.
        // API will set pancreas_type automatically except when we send "PAK".  Pancreas Type can be "PAK", "PRT", "PTA", or nil. 
        // In some cases it is set based on request parameters from the UI, in other cases it is set automatically here.
        // In order of precendence:
        // 1. "PRT" (Pancreas Re-Transplant)    = Set by API, for pancreas journey that was relisted
        // 2. "PAK" (Pancreas After Kidney)     = Set by UI
        // 3. "PTA" (Pancreas Transplant Alone) = Set by API, for non-clustered pancreas
        // 4. nil
        pancreas_type: pancreasSpecificDetails.pancreaspak ? PancreasTypeCodeValue.PAK : null,
        diabetes_type_code: pancreasSpecificDetails.diabetes,
      }
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit("handleErrors", errors);
  }
}
