import Vue from 'vue';
import { MutationTree } from 'vuex';
import { LabsState } from '@/store/labs/types';
import { idComparator } from '@/utils';

export const mutations: MutationTree<LabsState> = {
  clear(state, key) {
    Vue.set(state, key, undefined);
  },
  setVirologies(state, virologies) {
    Vue.set(state, 'virologies', virologies);
  },
  selectVirology(state, virology) {
    Vue.set(state, 'selectedVirology', virology);
  },
  clearVirologies(state) {
    state.virologies = [];
  },
  saveVirologyResult(state, newEntry) {
    if (newEntry && state.virologies) {
      // Look for existing entry to edit
      const indexOfExistingEntry = state.virologies.findIndex((oldEntry: any) => {
        return idComparator(oldEntry, newEntry); // compares using _id.$oid
      });
      const existingEntry = state.virologies[indexOfExistingEntry];
      if (!existingEntry) {
        // Push new entry
        const sanitizedEntry = Object.assign({}, newEntry);
        state.virologies.unshift(sanitizedEntry);
      } else {
        // Modify existing entry
        Vue.set(state.virologies, indexOfExistingEntry, newEntry);
      }
    } else {
      // TODO: proper error handling
      console.log('Could not save virology result.');
    }
  },
  setHlaTyping(state, hlaTyping) {
    Vue.set(state, 'hlaTyping', hlaTyping);
  },
  setHlaAntibodies(state, hlaAntibodies) {
    Vue.set(state, 'hlaAntibodies', hlaAntibodies);
  },
  selectHlaAntibodies(state, hlaAntibodies) {
    Vue.set(state, 'selectedHlaAntibodies', hlaAntibodies);
  },
  selectHlaSerumSummaryReview(state, hlaAntibodies) {
    Vue.set(state, 'selectedHlaSerumSummaryReview', hlaAntibodies);
  },
  setCrossmatchLabs(state, crossmatchLabs) {
    Vue.set(state, 'crossmatchLabs', crossmatchLabs);
  },
  selectCrossmatchLab(state, crossmatchLab) {
    Vue.set(state, 'selectedCrossmatch', crossmatchLab);
  },
  clearCrossmatchLab(state) {
    Vue.set(state, 'selectedCrossmatch', undefined);
  },
  selectCrossmatchSample(state, crossmatchSample) {
    Vue.set(state, 'selectedCrossmatchSample', crossmatchSample);
  },
  clearCrossmatchSample(state) {
    Vue.set(state, 'selectedCrossmatchSample', undefined);
  },
};
