import { render, staticRenderFns } from "./RemoveFromWaitlist.vue?vue&type=template&id=25f98d9a&"
import script from "./RemoveFromWaitlist.vue?vue&type=script&lang=ts&"
export * from "./RemoveFromWaitlist.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/shared/_locales/RemoveFromWaitlist.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2FRemoveFromWaitlist.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports