
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, namespace, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Hospital } from '@/store/hospitals/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { organCodeLookup, TranslationContext } from '@/types';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { LivingAllocation, LivingAllocationRecipient } from '@/store/livingAllocations/types';
import SubSection from '@/components/shared/SubSection.vue';

@Component({
  components: {
    SubSection,
  }
})
export default class AllocationDetails extends mixins(TranslationUtilsMixin, DateUtilsMixin) {
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Getter('specialConsiderations', { namespace: 'livingAllocations' }) private specialConsiderations!: TranslationContext[];
  @Getter('oopHospitalsByOrgan', { namespace: 'hospitals' }) private oopHospitalsByOrgan!: (organCode: string) => Hospital[];
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('isProvincialAllocation', { namespace: 'livingAllocations' }) private isProvincialAllocation!: boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('describeAllocationStage', { namespace: 'livingDonors' }) private describeAllocationStage!: (allocation: LivingAllocation) => string;
  
  /**
   * Get the organ_code param from the url.
   *
   * @returns {string} the organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code.toString() || '';
  }

  /**
   * Return the organ value which will get translated in the template
   *
   * @returns {string} organ value from the lookup
   */
  get organName(): string {
    if (!this.allocation.organ_code) return '-';
    return this.lookupValue((this.allocation.organ_code.toString() || ''), 'organ');
  }

  /**
   * Returns the allocation state from i18n
   *
   * @returns {string} the allocation state
   */
  get allocationStage() {
    return this.describeAllocationStage(this.allocation);
  }

  /**
   * Returns the allocation timestamp in the consistent UI displayed format.
   *
   * @returns {string} Formatted allocation created at datetime
   */
  get allocationTime() {
    return this.parseDisplayDateTimeUiFromDateTime(this.allocation.start_date);
  }

  /**
   * Return the option param from the url
   *
   * @returns {string} option param from the url
   */
  get organAllocationOption(): string {
    return (this.$route.params.option || '').toString().toLowerCase();
  }
}
