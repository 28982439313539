import axios from 'axios';
import { ActionTree, Dispatch, Commit } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { DashboardState } from '@/store/dashboard/types';

export const actions: ActionTree<DashboardState, RootState> = {
  // load dahboard notifications
  loadNotifications({ commit, dispatch }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.notifications);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data;
        commit('setNotifications', responseData["notifications"] ? responseData["notifications"] : []);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // load dahboard active allocations
  loadAllocations({ commit, dispatch, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.active_allocations);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data;
        const unfilteredOffers = responseData["active_allocations"] ? responseData["active_allocations"]["allocations"] : [];
        const filteredOffers = unfilteredOffers.filter((offer: any) => {
          return !rootGetters["allocations/isOfferConfirmed"](offer) &&
            !rootGetters["allocations/isOfferAccepted"](offer);
        });
        commit('setAllocations', filteredOffers);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // load dahboard primary offers
  loadPrimaryOffers({ commit, dispatch }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.primary_offers, [[':type', 'primary']]);
      const abort_controller = new AbortController();
      const signal = abort_controller.signal;
      commit("setPrimaryOffersAbortController", abort_controller);
      axios.get(url, { signal }).then((response: any) => {
        const responseData: any = response.data;
        commit('setPrimaryOffers', responseData["active_primary_offers"] ? responseData["active_primary_offers"]["allocations"] : []);
        resolve();
      }).catch((error: any) => {
        if (axios.isCancel(error)) {
          return;
        } else {
          reject(error);
        }
      });
    });
  },
  // load dahboard backup offers
  loadBackupOffers({ commit, dispatch }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.backup_offers, [[':type', 'backup']]);
      const abort_controller = new AbortController();
      const signal = abort_controller.signal;
      commit("setBackupOffersAbortController", abort_controller);
      axios.get(url, { signal }).then((response: any) => {
        const responseData: any = response.data;
        commit('setBackupOffers', responseData["active_backup_offers"] ? responseData["active_backup_offers"]["allocations"] : []);
        resolve();
      }).catch((error: any) => {
        if (axios.isCancel(error)) {
          return;
        } else {
          reject(error);
        }
      });
    });
  },
  // load dahboard active donors
  loadActiveDonors({ commit, getters, rootGetters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const donorsRoute = rootGetters["users/isTransplantCoordinator"] ? EP.deceasedDonors.active : EP.deceasedDonors.index;
      const url = APIRoute(donorsRoute, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data.donors;
        commit('setActiveDonors', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // load dahboard recent recipients
  loadRecentRecipients({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.recent_recipient_registrations, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data.recent_recipient_registrations;
        commit('setRecentRecipients', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // load dahboard lab results
  loadLabResults({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.lab_results, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      const abort_controller = new AbortController();
      const signal = abort_controller.signal;
      commit("setLabDonorResultsAbortController", abort_controller);
      axios.get(url, { signal }).then((response: any) => {
        const responseData: any = response.data.donor_lab_results;
        commit('setLabResults', responseData);
        resolve();
      }).catch((error: any) => {
        if (axios.isCancel(error)) {
          return;
        } else {
          reject(error);
        }
      });
    });
  },
  // load dahboard Recent Living Donor Registrations
  loadRecentLivingDonors({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.living_donor_registrations, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data.recent_living_donor_registrations;
        commit('setRecentLivingDonors', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // load dahboard urgent listings
  loadUrgentListings({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.urgent_listings, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params],
        [':urgent', true],
        [':recent_only', true]
      ]);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data.urgent_listings;
        commit('setUrgentListings', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // load dahboard ExD Donors
  loadExdDonors({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.exceptional_distribution_donors, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params],
        [':exceptional_distribution', true],
        [':results_reported', false]
      ]);
      const abort_controller = new AbortController();
      const signal = abort_controller.signal;
      commit("setExdDonorsAbortController", abort_controller);
      axios.get(url, { signal }).then((response: any) => {
        const responseData: any = response.data.exd_donors;
        commit('setExdDonors', responseData);
        resolve();
      }).catch((error: any) => {
        if (axios.isCancel(error)) {
          return;
        } else {
          reject(error);
        }
      });
    });
  },
  // load dahboard transplant in-progress results
  loadTransplantInProgress({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.transplant_in_progress);
      const abort_controller = new AbortController();
      const signal = abort_controller.signal;
      commit("setTransplantInProgressAbortController", abort_controller);
      axios.get(url, { signal }).then((response: any) => {
        const responseData: any = response.data.transplant_in_progress;
        commit('setTransplantInProgress', responseData);
        resolve();
      }).catch((error: any) => {
        if (axios.isCancel(error)) {
          return;
        } else {
          reject(error);
        }
      });
    });
  },
  // load dahboard widget role permissions
  loadWidgetRolePermissions({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.dashboard.widgets);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data.lookup;
        commit('setWidgetRolePermissions', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
};
