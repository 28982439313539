import Vue from 'vue';
import Component from 'vue-class-component';
import { FormErrors, IdLookup } from '@/store/validations/types';
import { SaveResult } from '@/types';

@Component
export class ValidationUtilsMixin extends Vue {
  /**
   * Derive UI form errors based on API error results
   *
   * @param errorResults string value (-. yes or no)
   * @returns {boolean} input value
   */
  public parseFormErrors(errorResults: SaveResult|SaveResult[], idLookup: IdLookup): FormErrors {
    const errors: SaveResult[] = Array.isArray(errorResults) ? errorResults : [errorResults];

    let aggregateErrors: FormErrors = {};
    errors.forEach((item) => {
      aggregateErrors = { ...aggregateErrors, ...item.validationErrors };
    });

    const formErrors: FormErrors = {};
    for (const key of Object.keys(aggregateErrors)) {
      const inputIds: string|string[] = idLookup[key];
      if (Array.isArray(inputIds)) {
        // Array of strings (one API property maps to multiple UI input fields)
        inputIds.forEach((inputId: string) => {
          formErrors[inputId] = aggregateErrors[key];
        });
      } else {
        // String (one-to-one mapping between an API property to a UI input field)
        formErrors[inputIds] = aggregateErrors[key];
      }
    }

    return formErrors;
  }
}
