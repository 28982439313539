
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedLivingDonorHlaLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorHlaLinks.vue";
import SelectedLivingDonorOrganLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorOrganLinks.vue";
import SelectedLivingDonorProfileLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorProfileLinks.vue";
import { LivingDonor, LivingDonorAllocationSummary } from '@/store/livingDonors/types';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedLivingDonorHlaLinks,
    SelectedLivingDonorOrganLinks,
    SelectedLivingDonorProfileLinks,
  }
})
export default class SideNavLivingDonor extends Vue {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Getter('selectedLivingDonorConsentedOrganList', { namespace: 'livingDonors'}) private selectedLivingDonorConsentedOrganList!: LivingDonorAllocationSummary[];
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;

  @Prop({default: false }) newLivingDonor!: boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
