
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { ObjectId } from '@/store/types';
import { State, Getter } from 'vuex-class';
import { TableConfig } from '@/types';
import { SaveProvider, SaveResult } from '@/types';
import {DialysisProcedure, Recipient} from '@/store/recipients/types';
import { InotropeTypes } from '@/store/lookups/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { prefixErrors } from '@/utils';
import {HeartMeasurement, HemodynamicInfo} from '@/store/organSpecificDetails/types';
import { HeartSpecificPageState } from '@/components/organs/heart/HeartSpecificDetails.vue';

export interface HemodynamicForm {
  _id?: { $oid: string };
  date?: string|null;
  inotrope?: boolean;
  inotropeTypeCode?: number|null;
  inotropeDose?: number;
  lvad?: boolean; // Boolean as "Y" || "N"
  pvrWoods?: number;
  systolicPaPressure?: number;
  diastolicPaPressure?: number;
  meanPaPressure?: number;
  echo?: boolean;
  pcwPressure?: number;
  cardiacOutput?: number;
  fick?: boolean;
  cardiacIndex?: number;
  transpulmonaryGradient?: number;
}

interface HemodynamicFormRow {
  _id?: { $oid: string };
  date?: string|null;
  inotrope?: boolean;
  pvrWoods?: string;
  systolicPaPressure?: number;
  diastolicPaPressure?: number;
  meanPaPressure?: number;
  echo?: boolean;
  fick?: boolean;
  cardiacIndex?: number;
  transpulmonaryGradient?: number;
}

@Component({
  components: {
    DateInput,
    SubSection,
    NumberInput,
    SelectInput,
    CheckboxInput,
  }
})
export default class HemodynamicInformation extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.lookups.inotrope_types) inotropeTypes!: InotropeTypes;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.heartDetails) editState!: HeartSpecificPageState;
  @State(state => state.organSpecificDetails.hemodynamicInfo) hemodynamicInfo!: HemodynamicInfo[];

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   *  Available for edit only for selected row index === 0 === max(date) from collection
   */
  get canEdit():boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }

    const selectedId = this.editState.hemodynamicInfo._id;
    if (!!selectedId && !!this.hemodynamicInfo) {
      return this.isLastEntry(selectedId, this.hemodynamicInfo);
    }
    return true;
  }

  /**
   * Begin fetching the recipient's Hemodynamic Information
   */
  public loadHemodynamicInformation(): void {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch('organSpecificDetails/loadHemodynamicInfo', recipientId);
  }

  /**
   * Gets configuration for the Hemodynamic Information table
   *
   * @returns {TableConfig} Hemodynamic Table configuration
   */
  public hemodynamicTableConfig(): TableConfig {
    return {
      data: this.hemodynamicInfoRows || [],
      // DIAG: PVR column was removed and there is no replacement
      columns: [
        { label: this.$t('date_of_test').toString(), field: 'date', width: '10%' },
        { label: this.$t('inotrope1').toString(), field: 'inotrope', width: '10%', formatFn: this.formatTableBoolean },
        { label: this.$t('pvr_woods1').toString(), field: 'pvrWoods', width: '10%' },
        { label: this.$t('systolic_pa').toString(), field: 'systolicPaPressure', width: '10%' },
        { label: this.$t('diastolic_pa').toString(), field: 'diastolicPaPressure', width: '10%' },
        { label: this.$t('mean_pa').toString(), field: 'meanPaPressure', width: '10%' },
        { label: this.$t('echo1').toString(), field: 'echo', width: '10%', formatFn: this.formatTableBoolean },
        { label: this.$t('fick_thermal_distribution').toString(), field: 'fick', width: '10%', formatFn: this.formatTableBoolean },
        { label: this.$t('cardiac_index1').toString(), field: 'cardiacIndex', width: '10%' },
        { label: this.$t('transpulmonary_gradient1').toString(), field: 'transpulmonaryGradient', width: '10%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createText: this.$t('create_hemodynamic_information').toString(),
      // Note: show create button if user can save this form, regardless of whether selected row is editable
      createButton: this.canSave
    };
  }

  formatTableBoolean(value: boolean) {
    return value ? this.$t("yes").toString() : this.$t("no").toString();
  }

  /**
   * Gets table row data for the Hemodynamic Information table
   *
   * @returns {HemodynamicFormRow[]} Hemodynamic table rows
   */
  get hemodynamicInfoRows(): HemodynamicFormRow[] {
    if (!this.hemodynamicInfo || this.hemodynamicInfo.length < 0) {
      return [];
    }
    const result: HemodynamicFormRow[] = [];
    this.hemodynamicInfo.forEach((hemodynamic: HemodynamicInfo) => {
      const row: HemodynamicFormRow = {
        _id: hemodynamic._id,
        date: this.parseDisplayDateUi(hemodynamic.date),
        inotrope: hemodynamic.inotrope,
        pvrWoods: hemodynamic.pulmonary_vascular_resistance_woods == null ? '-' : hemodynamic.pulmonary_vascular_resistance_woods.toString(),
        systolicPaPressure: hemodynamic.pulmonary_artery_systolic_pressure,
        diastolicPaPressure: hemodynamic.pulmonary_artery_diastolic_pressure,
        meanPaPressure: hemodynamic.pulmonary_mean_arterial_pressure,
        echo: hemodynamic.echocardiogram,
        fick: hemodynamic.fick_or_thermal_dilution,
        cardiacIndex:hemodynamic.cardiac_index,
        transpulmonaryGradient: hemodynamic.transpulmonary_gradient,
      };
      result.push(row);
    });
    return result;
  }

  /**
   * Loads the selected row into editState.hemodynamicInfo
   *
   * @listens hsd-hemodynamic#table-row-click
   * @param event selected row from the table
   */
  public selectHemodynamicInfo(event: { row: HemodynamicInfo }): void {
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (this.hemodynamicInfo && this.hemodynamicInfo.length > 0) {
      const hemodynamicInfo = this.hemodynamicInfo as HemodynamicInfo[];
      const foundHemodynamic: HemodynamicInfo|undefined = hemodynamicInfo
        .find((item: HemodynamicInfo) => {
          return item._id && item._id.$oid === selectedId;
        });
      if (foundHemodynamic) {
        this.initializeHemodynamicForm(foundHemodynamic);
      }
    }
  }

  /**
   * Loads a form edit state based on a hemodynamic, or a new state if there is none
   *
   * @param hemodynamic: Hemodynamic Info entry fetched from selected row, or undefined
   */
  public initializeHemodynamicForm(hemodynamic?: HemodynamicInfo): void {
    this.$store.commit('pageState/set', {
      pageKey: 'heartDetails',
      componentKey: 'hemodynamicInfo',
      value: this.sanitizeHemodynamicInfo(hemodynamic)
    });
    this.$emit('clear');
    this.resetSaveToolbar();
  }


  /**
   * Converts a selected HemodynamicInfo entry into HemodynamicForm
   *
   * @param HemodynamicInfo Hemodynamic Information
   * @returns {HemodynamicForm} Hemodynamic Form entry
   */
  public sanitizeHemodynamicInfo(hemodynamic?: HemodynamicInfo): HemodynamicForm {
    if (!hemodynamic) {
      return {
        echo: false,
        inotrope: false,
        lvad: false,
        fick: false
      };
    }
    let inotropeTypeCode: any = null;
    if(hemodynamic.inotrope_type_code !== null || hemodynamic.inotrope_type_code !== undefined || hemodynamic.inotrope_type_code >= 0)
    {
      inotropeTypeCode = hemodynamic.inotrope_type_code;
    } else{
      inotropeTypeCode = null;
    }
    return {
      _id: hemodynamic._id,
      date: this.parseDateUi(hemodynamic.date),
      inotrope: hemodynamic.inotrope,
      inotropeTypeCode: inotropeTypeCode,
      inotropeDose: hemodynamic.inotrope_dose,
      lvad: (hemodynamic.lvad === 'Y' ? true : false),
      pvrWoods: hemodynamic.pulmonary_vascular_resistance_woods,
      systolicPaPressure: hemodynamic.pulmonary_artery_systolic_pressure,
      diastolicPaPressure: hemodynamic.pulmonary_artery_diastolic_pressure,
      meanPaPressure: hemodynamic.pulmonary_mean_arterial_pressure,
      echo: hemodynamic.echocardiogram,
      pcwPressure: hemodynamic.pulmonary_capilliary_wedge_pressure,
      cardiacOutput: hemodynamic.cardiac_output,
      fick: hemodynamic.fick_or_thermal_dilution,
      cardiacIndex:hemodynamic.cardiac_index,
      transpulmonaryGradient: hemodynamic.transpulmonary_gradient,
    };
  }

  /**
   * Deselects any selected Hemodynamic Information, and clears the entry for
   * editState.heartDetails.hemodynamicInfo
   *
   * @listens hsd-hemodynamic#table-create-row
   */
  public createHemodynamicInfo(): void {
     this.initializeHemodynamicForm();
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveHemodynamicInfo as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'heart_hemodynamic.inotrope_type_code'                    : 'hemodynamic-inotrope-type',
    'heart_hemodynamic.inotrope_dose'                         : 'hemodynamic-inotrope-dose',
    'heart_hemodynamic.date'                                  : 'hemodynamic-date',
    'heart_hemodynamic.cardiac_index'                         : 'hemodynamic-cardiac-index',
    'heart_hemodynamic.cardiac_output'                        : 'hemodynamic-cardiac-output',
    'heart_hemodynamic.pulmonary_artery_diastolic_pressure'   : 'hemodynamic-diastolic-pa',
    'heart_hemodynamic.pulmonary_artery_systolic_pressure'    : 'hemodynamic-systolic-pa',
    'heart_hemodynamic.pulmonary_capilliary_wedge_pressure'   : 'hemodynamic-pcw-pressure',
    'heart_hemodynamic.pulmonary_mean_arterial_pressure'      : 'hemodynamic-mean-pa',
    'heart_hemodynamic.pulmonary_vascular_resistance_woods'   :  'hemodynamic-pvr-woods',
    'heart_hemodynamic.transpulmonary_gradient'               : 'hemodynamic-transpulmonary',
  };

  /**
   * PRIVATE
   */

  /**
   * Gets the entry for editSate.heartDetails.hemodynamicInfo
   *
   * Converts the entry for Hemodynamic Information from editState
   * into a payload ready for the API.
   *
   * @returns {HemodynamicInfo} object containing any field changes
   */
  private extractHemodynamicInfoPatch(): HemodynamicInfo {
    if (!this.editState || !this.editState.hemodynamicInfo) {
      return {};
    } else {
      const hemodynamicInfo = this.editState.hemodynamicInfo;
      return {
        date: this.sanitizeDateApi(hemodynamicInfo.date),
        inotrope: hemodynamicInfo.inotrope ? true : false,
        inotrope_type_code: hemodynamicInfo.inotropeTypeCode!=null ? hemodynamicInfo.inotropeTypeCode : null,
        inotrope_dose: hemodynamicInfo.inotropeDose,
        // DIAG: LVAD is a string value of 'Y' or 'N'
        lvad: (hemodynamicInfo.lvad ? 'Y' : 'N'),
        pulmonary_vascular_resistance_woods: hemodynamicInfo.pvrWoods,
        pulmonary_artery_systolic_pressure: hemodynamicInfo.systolicPaPressure,
        pulmonary_artery_diastolic_pressure: hemodynamicInfo.diastolicPaPressure,
        pulmonary_mean_arterial_pressure: hemodynamicInfo.meanPaPressure,
        echocardiogram: hemodynamicInfo.echo ? true : false,
        pulmonary_capilliary_wedge_pressure: hemodynamicInfo.pcwPressure,
        cardiac_output: hemodynamicInfo.cardiacOutput,
        fick_or_thermal_dilution: hemodynamicInfo.fick ? true : false,
        cardiac_index: hemodynamicInfo.cardiacIndex,
        transpulmonary_gradient: hemodynamicInfo.transpulmonaryGradient,
      };
    }
  }

  /**
   * Attempt to save the Hemodynamic Information.
   *
   * Prepares an update payload for Hemodynamic Information,
   * dispatches a save action, and registers the save result.
   */
  private saveHemodynamicInfo(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHemodynamicInfo as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'hemodynamicInfo');
    // Generate payload based on current edit state
    const hemodynamicPayload = {
      id: this.editState.hemodynamicInfo!._id,
      recipientId: this.recipient.client_id,
      hemodynamic: this.extractHemodynamicInfoPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveHemodynamicInfo', hemodynamicPayload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's hemodynamic info and show success notification
      this.loadHemodynamicInformation();
      this.createHemodynamicInfo();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
}
