
import { Getter, State } from 'vuex-class';
import TextInput from '@/components/shared/TextInput.vue';
import { LivingDonor } from '@/store/livingDonors/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { Organ, OrganCodeValue, OrganSpecification, OfferType, OfferReasonCategory, OfferReason, OrganOfferSpecificationCodeValueToOrganCode } from '@/store/lookups/types';
import { LivingAllocationRecipient, LivingAllocationOfferTypeValues, LivingAllocationOfferAction, LivingAllocationOfferResponseCodeValues, LivingAllocation, LivingAllocationOffer, LivingAllocationOfferRecipient } from '@/store/livingAllocations/types';

interface AllocationOfferPageState {
  recipients: LivingAllocationOfferRecipient[];
  type: string;
  organSpecification: number;
  reasonCategory: number;
  reason: number;
  comment: string;
  responsiblePhysician?: string;
  manualAllocationRationale?: number|null;
  manualAllocationRationaleOther?: string|null;
  reOfferScenario: boolean;
}

const OFFER_CODES_FOR_ONE_RECIPIENT = [
  LivingAllocationOfferTypeValues.Primary,,
  LivingAllocationOfferTypeValues.ConvertToAcceptedPrimary,
];

// NOTE: this determines whether or not 'Out of Sequence Reason' is required
const OFFER_CODES_THAT_RESOLVE_ENTRIES = [
  LivingAllocationOfferTypeValues.Primary,
  LivingAllocationOfferTypeValues.NoOffer,
];

// NOTE: this determines whether or not 'Out of Sequence Reason' is required
const RESPONSE_CODES_THAT_RESOLVE_ENTRIES = [
  LivingAllocationOfferResponseCodeValues.Decline,
  LivingAllocationOfferResponseCodeValues.Withdraw,
  LivingAllocationOfferResponseCodeValues.Cancel
];

const KIDNEY_PANCREAS_CLUSTER_ORGANS = [
  OrganCodeValue.Kidney,
  OrganCodeValue.PancreasWhole,
];

const OUT_OF_SEQUENCE_OFFER_REASON_HSP_EXPORT_THRESHOLD_CODE = 4;

@Component({
  components: {
    TextInput,
    SelectInput,
    ModalSection,
    TextAreaInput,
    SelectOtherInput,
    BooleanRadioInput,
  }
})
export default class OfferModal extends Vue {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.pageState.currentPage.livingAllocations) editState!: any;
  @State(state => state.lookups.out_of_sequence_offer_reasons) private  outOfSequenceOfferReasons!: NumericCodeValue[];
  @State(state => state.livingAllocations.isMakingOffer) private isMakingOffer!: boolean;

  @Getter('offerTypes', { namespace: 'lookups' }) private offerTypes!: OfferType[];
  @Getter('clientId', { namespace: 'livingDonors' }) private donorId!: LivingDonor;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('getRecipientsByEffectiveRank', { namespace: 'livingAllocations' }) getRecipientsByEffectiveRank!: (recipientRanks?: number[]) => LivingAllocationOfferRecipient[];
  @Getter('livingDonorsSpecificationsByOrganCode', { namespace: 'lookups' }) private livingDonorsSpecificationsByOrganCode!: (organCode: string) => OrganSpecification[];
  @Getter('ctrIposKidney', { namespace: 'features' }) private ctrIposKidney!: boolean;

  private showManualAllocationRationale = false;

  private setShowManualAllocationRationale() {
    this.showManualAllocationRationale = this.checkForManualAllocationRationale();
  }

  /**
   * Return no offer reason options (conditional on offer type)
   *
   * @returns {GenericCodeValue[]} No Offer Reason options
   */
  get noOfferReasonOptions(): OfferReason[] {
    const reasonCategory = this.editState.offer.reasonCategory;
    if (this.editState.offer.type == LivingAllocationOfferTypeValues.NoOffer) {
      const noOfferReason = this.noOfferReasonCategoryOptions.find((item: any) => {
        return item.code == reasonCategory;
      });
      if (noOfferReason && noOfferReason.sub_tables) {
        return noOfferReason.sub_tables.no_offer_reasons;
      }
    }
    return [];
  }

  /**
   * Return available offer types, if more than one recipient remove Primary
   *
   * @returns {OfferType[]} Offer Response types
   */
  get availableOfferTypes(): OfferType[] {
    const offerRecipients: LivingAllocationOfferRecipient[] = this.editState.offer.recipients || [];
    let available = this.offerTypes || [];
    // Some offer types can only be sent if exactly one recipient is selected
    // e.g. convert to accepted primary (living donor's are allowed multiple primary offers)
    if (offerRecipients.length > 1) {
      available = this.offerTypes.filter((offerType: OfferType) => {
        return !OFFER_CODES_FOR_ONE_RECIPIENT.includes(offerType.code as LivingAllocationOfferTypeValues);
      });
    }

    // Some offer types are only available when selected recipients have existing offers / responses
    // e.g. convert to accepted primary is only for accepted backup
    available = available.filter((offerType: OfferType) => {
      // Count selected recipients with appropriate offer type
      const numRecipientsWithRequiredOffer = offerRecipients.filter((recipientEntry: LivingAllocationOfferRecipient) => {
        return !offerType.requires_current_offer_type || recipientEntry.offer_type_code == offerType.requires_current_offer_type;
      }).length;
      // Count selected recipients with appropriate response type
      const numRecipientsWithRequiredResponse = offerRecipients.filter((recipientEntry: LivingAllocationOfferRecipient) => {
        return !offerType.requires_current_offer_response || recipientEntry.response_code == offerType.requires_current_offer_response;
      }).length;
      return numRecipientsWithRequiredOffer == offerRecipients.length && numRecipientsWithRequiredResponse == offerRecipients.length;
    });

    // If recipients are HSP, disable the 'no offer' option
    const hspRecipients = offerRecipients.filter((record: LivingAllocationOfferRecipient) => { return record.hsp == 'HSP'; });
    available.map((offerType: OfferType) => {
      if (offerType.code == LivingAllocationOfferTypeValues.NoOffer) { offerType.disabled = hspRecipients.length > 0; }
    });

    return available;
  }

  /**
   * Return no offer reason category options (conditional on reason choice)
   *
   * @returns {OfferReasonCategory[]} No Offer Reason Category options
   */
  get noOfferReasonCategoryOptions(): OfferReasonCategory[] {
    if (this.editState.offer.type == LivingAllocationOfferTypeValues.NoOffer) {
      const noOfferOptions = this.offerTypes.find((item: OfferType) => {
        return item.code == LivingAllocationOfferTypeValues.NoOffer;
      });
      return noOfferOptions?.sub_tables?.no_offer_reason_categories || [];
    }
    return [];
  }

  // Options for Manual Allocation Rationale i.e. Out of Sequence Reason
  get manualAllocationRationaleOptions(): NumericCodeValue[] {
    if (!this.outOfSequenceOfferReasons) return [];

    // only change one of out of sequence offer reasons if IPOS Kidney is ON
    if (this.ctrIposKidney) {
      const HspExportThresholdIndex = this.outOfSequenceOfferReasons.findIndex(data => data.code === OUT_OF_SEQUENCE_OFFER_REASON_HSP_EXPORT_THRESHOLD_CODE);
      this.outOfSequenceOfferReasons[HspExportThresholdIndex].value = this.outOfSequenceOfferReasons[HspExportThresholdIndex].value.replace("HSP", "IPOS");
    }

    return this.outOfSequenceOfferReasons;
  }

  /**
   * Return a boolean if we need to show no offer option list
   *
   * @returns {boolean} true if we need to show
   */
  get showNoOfferOptions(): boolean {
    if (this.editState.offer?.type === LivingAllocationOfferTypeValues.NoOffer) {
      return true;
    }
    return false;
  }

  /**
   * Get a boolean result if this is a primary offer
   *
   * @returns {boolean} true when the selected offer type is primary, false otherwise
   */
  get isPrimary(): boolean {
    if (!this.editState || !this.editState.offer) return false;

    return this.editState?.offer?.type === LivingAllocationOfferTypeValues.Primary;
  }

  /*
   * Which Offer Type lookups have 'may_require_out_of_sequence_reason' flag
   *
   * @returns {string[]} array of offer type codes
   */
  get outOfSequenceApplicableOfferTypeCodes(): string[] {
    if (!this.offerTypes) return [];

    const types: OfferType[] = this.offerTypes.filter((offerType: OfferType) => {
      return offerType.may_require_out_of_sequence_reason;
    });
    const codes: string[] = types.map((offerType: OfferType): string => {
      return offerType.code;
    });
    return codes;
  }

  /**
   * Was an entry on the Allocation List skipped, i.e. not resolved?
   *
   * Resolved means recipient has either:
   * - Primary Offer or No Offer offer type; or,
   * - Decline, Discontinue/Cancel, or Discontinue/Withdraw response code
   *
   * NOTE: this determines whether or not 'Out of Sequence Reason' is required
   *
   * TP-10003
   * if there are any higher ranked recipients with a Backup offer that is not Declined/Discontinued 
   * and who “match” the organ(s) of the recipient being offered, then an Out of Sequence Reason Code
   * (OOSRC) must be provided
   *
   * @param allocationRecipient a recipient entry from an Allocation List
   * @returns {boolean} true if recipient entry has not been 'resolved' yet
   */
  private wasSkipped(allocationRecipient: LivingAllocationRecipient): boolean {
    // get offer type
    const offerCode = allocationRecipient?.offer?.offer_type_code || null;

    // get offer response code
    const responseCode = allocationRecipient?.offer?.response_code || null;

    // Compare offer and response against array constants
    // NOTE: this allows the definition of 'skipped' to be adjusted in the constants
    const hasOfferTypeThatResolvesEntry = OFFER_CODES_THAT_RESOLVE_ENTRIES.includes(offerCode as LivingAllocationOfferTypeValues);
    const hasResponseThatResolvesEntry = RESPONSE_CODES_THAT_RESOLVE_ENTRIES.includes(responseCode as LivingAllocationOfferResponseCodeValues);

    // This function return true only if specified recipient entry has been skipped
    // In other words, recipient is considred'skipped' only if neither the 'offer type' nor the 'response' match the constant
    return !hasOfferTypeThatResolvesEntry && !hasResponseThatResolvesEntry;
  }

  /**
   * Returns true if Manual Allocation Rationale is applicable i.e. Out of Sequence Reason
   *
   * When making a primary offer,
   * - hide manual rationale if no recipients behind the row being offered.
   * - If recipients exist previous to the one being offered, check all higher ranked recipients for a primary, no-offer, decline, withdraw, or cancel
   * - - if no show manual rationale
   *
   * @returns {boolean}
   */
  private checkForManualAllocationRationale(): boolean {
    if (!this.editState || !this.editState.offer) return false;

    // Get organ primary organ code
    let organ_code = [this.allocation.organ_code];
    const organSpecification = this.editState.offer.organSpecification || null;

    // We also need to check organ specific code and if selected translate that to an organ_code to compare against
    // why?
    // 1 is combination kidney / pancreas, pancreas being the organ offered
    // 2 is combination kidney / pancreas, pancreas being the organ offered
    // 3 is kidney / pancreas cluster
    // For 3 we select primary and then on organ specification we select pancreas,
    // as we skipped 1 & 2 we need to show the out of sequence dropdown.
    if (organSpecification) {
      const values = OrganOfferSpecificationCodeValueToOrganCode as any;
      const organ_as = values[organSpecification];
      organ_code = Array.isArray(organ_as) ? organ_as : [organ_as];
    }

    // This only applies when making primary offers
    if (!this.outOfSequenceApplicableOfferTypeCodes.includes(this.editState?.offer?.type)) return false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || []; // Ordered list of all recipient being made an offer to
    const offerRecipientsRankArray = offerRecipients.map((r: LivingAllocationOfferRecipient) => r.effective_rank);
    const minOfferedRecipientRank = Math.min(...offerRecipientsRankArray);
    let minOfferedRecipient: LivingAllocationOfferRecipient|null = null;
    offerRecipients.forEach((recipient: LivingAllocationOfferRecipient) => {
      if(recipient.effective_rank == minOfferedRecipientRank) {
        minOfferedRecipient = recipient;
      }
    });

    // Logic for figuring out which LivingAllocationRecipient rows to look for skipping
    // First we get all recipients in that allocation
    const allocationRecipients = (this.allocation || {}).recipients || [];

    // Then we filter the list down the the ones we care about i.e. higher ranked recipients
    const allocationRecipientsWeCareAbout = allocationRecipients.filter((allocationRecipient: LivingAllocationRecipient) => {

      const clusterCodes = allocationRecipient.cluster_organ_codes || [];
      let insideClusterCodes = false;
      clusterCodes.forEach(o => {
        insideClusterCodes = insideClusterCodes || organ_code.includes(o);
      });

      const isHigherRank = allocationRecipient.effective_rank < minOfferedRecipientRank;
      const isSameOrgan = organ_code.includes(allocationRecipient.organ_code) || insideClusterCodes;

      return isHigherRank && isSameOrgan;
    });

    // Then we figure out which of these recipients have been skipped
    // NOTE: this is delegated to a helper method, and is defined based on Offer Type, Response Code, etc. 
    const skippedRecipients = allocationRecipientsWeCareAbout.filter((allocationRecipient: LivingAllocationRecipient) => {
      return this.wasSkipped(allocationRecipient);
    });

    // Show 'Out of Sequence Reason' if we have at least one skipped entry in Allocation List
    return skippedRecipients.length > 0;
  }

  /**
   * Whether or not to show a warning prompt about making a primary offer to a a recipient
   * who has a pending primary offer on another allocation.
   *
   * If the user is making a primary offer that includes a recipient with the boolean flag
   * 'other_offers_pending' set by the allocation service to true, then user must confirm
   * this warning prompt in order to proceed with making the eOffer.
   *
   * @returns {boolean} true if warning prompt must appear, false otherwise
   */
  get checkRecipientsForOtherOffersPending(): boolean {
    if (!this.editState) return false;

    // This only applies when making primary offers
    if (!this.isPrimary) return false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || [];
    const offerRecipientIds = offerRecipients.map((recipient: LivingAllocationOfferRecipient): string => {
      return recipient.id;
    });

    const allocationRecipients = (this.allocation || {})?.recipients || [];

    // Find any recipients with matching ID who have 'other_offers_pending' information from allocation
    const recipientsWithOtherPrimaryOffersPending = allocationRecipients.filter((recipient: LivingAllocationRecipient) => {
      return offerRecipientIds.includes(recipient._id) && recipient.other_offers_pending;
    });

    // Return true if there are any recipients who have other accepted or pending primary offers
    return recipientsWithOtherPrimaryOffersPending.length > 0;
  }

  /**
   * Whether or not to show a warning prompt about making a primary offer to a a recipient
   * who has already accepted a primary offer on another allocation.
   *
   * If the user is making a primary offer that includes a recipient with the boolean flag
   * 'other_offers_accepted' set by the allocation service to true, then user must confirm
   * this warning prompt in order to proceed with making the eOffer.
   *
   * @returns {boolean} true if warning prompt must appear, false otherwise
   */
  get checkRecipientsForOtherOffersAccepted(): boolean {
    if (!this.editState) return false;

    // This only applies when making primary offers
    if (!this.isPrimary) return false;

    // Get the recipient IDs who the offer is being made to
    const offerRecipients = this.editState?.offer?.recipients || [];
    const offerRecipientIds = offerRecipients.map((recipient: LivingAllocationOfferRecipient): string => {
      return recipient.id;
    });

    // Find any recipients with matching ID who have 'other_offers_accepted' information from allocation
    const allocationRecipients = (this.allocation || {})?.recipients || [];
    const recipientsWithOtherPrimaryOffersAccepted = allocationRecipients.filter((recipient: LivingAllocationRecipient) => {
      return offerRecipientIds.includes(recipient._id) && recipient.other_offers_accepted;
    });

    // Return true if there are any recipients who have other accepted primary offers
    return recipientsWithOtherPrimaryOffersAccepted.length > 0;
  }

  /**
   * Return true if organ is a Liver or Lung or Kidney and offer type isn't No Offer
   *
   * Kidney will only return if the recipient offer is for a K/P Cluster
   *
   * @returns {boolean} true if Liver, Lung or Kidney
   */
  get showOrganSpecification(): boolean {
    const isLiverOrLung = [OrganCodeValue.Lung, OrganCodeValue.Liver].includes(this.allocation?.organ_code);
    const isKidney = [OrganCodeValue.Kidney].includes(this.allocation?.organ_code);

    // Return if Kidney and recipient is a KPCluster and offer type isn't No Offer
    if (isKidney) return this.isKPCluster && !this.showNoOfferOptions;

    // Return if Liver/Lung and offer type isn't No Offer
    return isLiverOrLung && !this.showNoOfferOptions;
  }

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Returns an array of options for Organ Specification
   *
   * Fetches the organ specification subtable from the appropriate organ lookup table
   *
   * @returns {OrganSpecification[]} options for organ specification
   */
  get organSpecificationLookup(): OrganSpecification[] {
    const offerOrganSpec = this.livingDonorsSpecificationsByOrganCode(this.organCode);

    if (offerOrganSpec.length == 0) return [];

    // Filter options if this is a Kidney
    if (this.allocation.organ_code === OrganCodeValue.Kidney) {
      // Single and Double Kidney have different options
      return offerOrganSpec.filter((item: OrganSpecification) => {
        // Always include the Pancreas option
        if (item.value === 'Pancreas') return true;
        // TODO: Remove fallback option when lookup value exists (TP-10323 data ticket)
        // Fallback option - filter based on a string
        return !item.value.includes('Double');
      });
    }

    // Translate organ specification options
    const translated = offerOrganSpec.map((option: OrganSpecification): OrganSpecification => {
      return {
        ...option,
        value: this.$t(option.value).toString(),
      };
    });

    // Return appropriate options sub table
    return translated;
  }

  /**
   * Returns is the recipient offer is for a Kidney/Pancreas Cluster
   *
   * @returns {boolean} true when cluster_organ_code matches K/P Cluster
   */
  get isKPCluster(): boolean {
    const recipientOffers = this.editState.offer.recipients || [];
    if (recipientOffers.length == 0) return false;

    // Check which selected entries are for a cluster for Kidney and Pancreas
    const kidneyPancreasClusterOffers = recipientOffers.filter((recipientOffer: LivingAllocationOfferRecipient) => {
      const offerOrganCodes: number[] = recipientOffer.cluster_organ_codes || [];
      const applicableOrganCodes = offerOrganCodes.filter((organCode: number) => {
        return KIDNEY_PANCREAS_CLUSTER_ORGANS.includes(organCode);
      });
      return applicableOrganCodes.length === KIDNEY_PANCREAS_CLUSTER_ORGANS.length;
    });

    // Check if every single selected entry in Allocation List is for cluster containing at least Kidney and Pancreas
    return kidneyPancreasClusterOffers.length === recipientOffers.length;
  }

  /**
   * Populates state with AllocationOfferRecipient[] and opens the offer modal
   */
  public initializeAllocationOffer(recipientRanks: number[]): void {
    // Verify the Recipients exist in the Allocation and return AllocationOfferRecipient[]
    const recipients = this.getRecipientsByEffectiveRank(recipientRanks);
    // Build state from valid ids
    this.buildAllocationOfferState(recipients);
    // Open modal only if we have Recipients
    if (recipients.length > 0) this.openModal();
  }

  /**
   * Builds the Allocation Offer state if we have Recipients
   *
   * @param recipientOffers array of AllocationOfferRecipient
   */
  private buildAllocationOfferState(recipientOffers?: LivingAllocationOfferRecipient[]): void {
    // Clear the state first
    this.clearAllocationOfferState();
    // Reset any existing errors
    (this.$refs.offerValidations as any).reset();
    // If we have Recipients add them to the state
    if (recipientOffers) {
      // Look at all recipients and see if any have a re-offer-scenario flag
      const isReOfferScenario: boolean = recipientOffers.flatMap((recipient: LivingAllocationOfferRecipient) => {
        return recipient.re_offer_scenario;
      }).includes(true);
      // Add our recipients
      Vue.set(this.editState.offer, 'recipients', recipientOffers);
      // Add our re-offer-scenario flag
      Vue.set(this.editState.offer, 'reOfferScenario', isReOfferScenario);
    }
  }

  /**
   * Gets a patch object representing form edit state for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing offer details
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.offer) {
      return {};
    }
    return this.extractAllocationOfferPatch(this.editState.offer);
  }

  // PRVATE

  // Clear pageState when a modal event occurs
  private modalEvent(options: any) {
    // clear allocation state
    this.clearAllocationOfferState();
  }

  // Open modal
  private openModal(): void {
    const targetModal = this.$refs.offerModal as ModalSection;
    targetModal.toggleStaticModal();

  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.offerModal as ModalSection;
    targetModal.hideModal();
  }

  // Clear pageState
  private clearAllocationOfferState(): void {
    Vue.set(this.editState, 'offer', {
      recipients: [],
      type: undefined,
      organSpecification: undefined,
      reasonCategory: undefined,
      reason: undefined,
      comment: undefined,
      manualAllocationRationale: undefined,
      manualAllocationRationaleOther: undefined,
    });
    Vue.set(this.editState.offer, 'offerErrorMessage', '');
    const targetModal = this.$refs.offerModal as ModalSection;
    this.$emit('closeModal');
  }

  // Clear reason when changing reason category
  private clearOfferReason(): void {
    Vue.set(this.editState.offer, 'reason', undefined);
  }

  // Clear other rationale text when manual allocation rationale dropdown changed
  private onManualAllocationRationaleChanged(): void {
    Vue.set(this.editState.offer, 'manualAllocationRationaleOther', undefined);
  }

  /**
   * Return an AllocationOfferAction patch
   *
   * Prepare patch for API with Allocation Offer details
   *
   * @param offer offer pageState containing offer details
   * @returns {AllocationOfferAction} patch object containing offer details
   */
  private extractAllocationOfferPatch(offer: AllocationOfferPageState): LivingAllocationOfferAction {
    let organSpecification: number|undefined = undefined;
    let reasonCategory: number|undefined = undefined;
    let reason: number|undefined = undefined;
    // add organ spec if liver or lung and not a no offer
    if (this.showOrganSpecification && !this.showNoOfferOptions) {
      organSpecification = offer.organSpecification;
    }
    // only add reason and reason category if this is a no offer
    switch (offer.type) {
      case LivingAllocationOfferTypeValues.NoOffer:
        reasonCategory = offer.reasonCategory;
        reason = offer.reason;
        break;
    }
    // build patch
    const offerPatch: LivingAllocationOfferAction = {
      recipients: offer.recipients,
      type: offer.type,
      reason_category: reasonCategory,
      reason_code: reason,
      organ_specification_code: organSpecification,
      comments: offer.comment,
    };
    // only add manual allocation rationale if it is needed
    if (this.showManualAllocationRationale) {
      offerPatch.out_of_sequence_offer_reason_code = offer.manualAllocationRationale;
      offerPatch.out_of_sequence_offer_reason_other = offer.manualAllocationRationaleOther;
    }
    return offerPatch;
  }

  /**
   * Attempt to make an offer they can't refuse
   *
   * Prepares create offer payload for selected Allocation, dispatches create, and handle errors.
   */
  private makeOffer(): void {
    // check for primary offers to recipients who have pending primary offers from other allocations
    // NOTE: do not show this 'pending' prompt if the 'accepted' prompt will also be shown (TPGLI-6563)
    if (this.checkRecipientsForOtherOffersPending && !this.checkRecipientsForOtherOffersAccepted) {
      // if so, we need to prompt the user to confirm this before proceeding to make the offers
      const confirmationText = this.$t('confirm_other_offers_pending_warning_message');
      const confirmed = confirm(confirmationText.toString());
      if (!confirmed) return;
    }
    
    // check for primary offers to recipients who have already accepted primary offers from other allocations
    if (this.checkRecipientsForOtherOffersAccepted) {
      // if so, we need to prompt the user to confirm this before proceeding to make the offers
      const confirmationText = this.$t('confirm_other_offers_accepted_warning_message');
      const confirmed = confirm(confirmationText.toString());
      if (!confirmed) return;
    }

    // Generate payload from editState
    const payload = {
      clientId: this.donorId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      offerDetails: this.extractPatch()
    };
    // Dispatch save action and show response
    this.$store.dispatch('livingAllocations/makeOffer', payload).then((success: any) => {
      this.clearAllocationOfferState();
      (this.$refs.offerValidations as any).reset();
      this.closeModal();
    }).catch((error: any) => {
      // All errors
      const errors = error || {};
      // Single string error back
      if (typeof errors === 'string') {
        Vue.set(this.editState.offer, 'offerErrorMessage', errors);
        return;
      }
      // Error when trying to offer to 2 different organ_codes
      if ('must_offer_single_organ_code' in error) {
        Vue.set(this.editState.offer, 'offerErrorMessage', this.$t('must_offer_single_organ_code'));
        return;
      }
      Vue.set(this.editState.offer, 'offerErrorMessage', errors);
    });
  }
}
