import { render, staticRenderFns } from "./SideNavJourney.vue?vue&type=template&id=6a9591a3&"
import script from "./SideNavJourney.vue?vue&type=script&lang=ts&"
export * from "./SideNavJourney.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2Fside-nav%2FSideNavJourney.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/organs/shared/_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2Fside-nav%2FSideNavJourney.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/_locales/Organs.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2Fside-nav%2FSideNavJourney.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "@/components/organs/shared/_locales/SideNavJourney.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2Fside-nav%2FSideNavJourney.vue"
if (typeof block3 === 'function') block3(component)
import block4 from "@/components/recipients/_locales/SelectedRecipientJourneyLinks.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fshared%2Fside-nav%2FSideNavJourney.vue"
if (typeof block4 === 'function') block4(component)

export default component.exports