
import { mixins } from "vue-class-component";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { Getter, State } from 'vuex-class';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AllocationDetails from '@/components/allocations/_AllocationDetails.vue';
import DonorChecklist from '@/components/allocations/_DonorChecklist.vue';
import AllocationOfferResponse from '@/components/allocations/_AllocationOfferResponse.vue';
import AllocationControls, { AllocationControlState } from '@/components/allocations/_AllocationControls.vue';
import { Allocation, Allocations, AllocationRecipient, OfferOutcomeContext } from '@/store/allocations/types';
import AllocationRecommendationListing from '@/components/allocations/_AllocationRecommendationListing.vue';
import { EP } from '@/api-endpoints';
import AllocationAuditMessages from '@/components/allocations/_AllocationAuditMessages.vue';

@Component({
  components: {
    SubSection,
    CardSection,
    AllocationDetails,
    AllocationControls,
    AllocationOfferResponse,
    AllocationRecommendationListing,
    DonorChecklist,
    AllocationAuditMessages,
  }
})
export default class DeceasedDonorOffers extends mixins(AllocationErrorsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.allocations.selected) private allocation!: Allocation;
  @State(state => state.allocations.isLoadingAllocations) private isLoadingAllocations!: boolean;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.pageState.currentPage.allocations) editState!: AllocationControlState;
  @State(state => state.allocations.allAllocations) private allAllocations!: any;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('activeAllocations', { namespace: 'allocations' }) private activeAllocations!: Allocations[];
  @Getter('findAllocations', { namespace: 'allocations' }) private findAllocations!: (activeAllocations: Allocations[], organCode: string, option?: string, doubleKidney?: boolean) => any;
  @Getter('findAllocationForPage', { namespace: 'allocations' }) private findAllocationForPage!: (activeAllocations: Allocations[], organCode: string, option?: string, doubleKidney?: boolean) => any;
  @Getter('allPrimaryBackupOffers', { namespace: 'allocations' }) private allPrimaryBackupOffers!: AllocationRecipient[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('viewAllocationAuditMessages', { namespace: 'features' }) private viewAllocationAuditMessages!: boolean;

  @Prop({ default: false }) canSave!: boolean;

  public lookupsToLoad = [
    'gender',
    'country',
    'offer_types',
    'offer_responses',
    'cause_of_death_donor',
    'attachment_category'
  ];
  public isInitializing = false;
  public selectedAllocationId: any;

  // Emit a loaded event to the parent
  public loaded(): void {
    this.$emit('loaded', 'allocations');
  }

  /**
   * checks for read permission to checklists
   * Note: hla_tech are not allowed to see the checklists section, only view the list of recommendations
   *
   * @returns {boolean} if is allowed to see checklists
   */
  get canReadChecklists(): boolean {
    return this.checkAllowed(EP.deceasedDonors.allocations.checklist.show, "GET");
  }

  /**
   * checks whether allocation in state matches to selected allocation, then only we will show in ui
   *
   * @returns {boolean} if is allowed to see allocation
   */
  get showAllocation():boolean {
    return this.allocation?._id == this.selectedAllocationId;
  }

  /**
   * checks for write permissions to checklists
   *
   * @returns {boolean} if is allowed to see checklists
   */
  get canWriteChecklists(): boolean {
    return this.checkAllowed(EP.deceasedDonors.allocations.checklist.create, "POST");
  }

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return (this.$route.params.organ_code || '').toString() || '';
  }

  // Get the organ allocation option param from the url
  get organAllocationOption(): string {
    return this.$route.params.option;
  }

  // Do we need to show the optional 'View Audit Messages' feature?
  get showAuditMessages(): boolean {
    return this.viewAllocationAuditMessages;
  }

  // Should the audit messages button be anabled?
  get enableAuditMessages(): boolean {
    return !this.isLoadingAllocations && this.allocation && !this.isLoadingAllocation && !this.isInitializing && this.showAllocation;
  }

  // Open audit messages modal to view any warnings and errors
  private openAuditMessagesModal(): void {
    const allocationAuditMessages = this.$refs.allocationAuditMessages as AllocationAuditMessages;
    if (!allocationAuditMessages) return;

    allocationAuditMessages.showModal();
  }

  // Initialize page on mount
  public mounted(): void {
    this.$store.dispatch('validations/loadEdit', { view: 'donors', action: 'edit', clientId: this.clientId });
    this.initializeAllocation();
  }

  public reloadAllocations(): void {
    this.initializeAllocation();
  }

  // Bubble up a request to the view that the Donor data needs reloaded
  private reload(): void {
    this.$emit('reload');
  }

  /**
   * We re-initialize the allocation if the isLoadingAllocations changes and we specifically look for when the change
   * makes it false. This makes sure that we only reload after all the allocations are properly loaded instead of
   * looking for specific type of allocation as that means we see flashes.
   */
  @Watch('isLoadingAllocations')
  // TODO: TECH_DEBT: remove watch isLoadingAllocations
  public initializeAllocation() {
    const allocations = this.findAllocations(this.allAllocations, this.organCode, this.organAllocationOption, this.donor.indicators?.double_kidney);
    const allocationForPage = this.findAllocationForPage(allocations, this.organCode, this.organAllocationOption, this.donor.indicators?.double_kidney);
    // TODO: TECH_DEBT: Refactor (!this.selectedAllocationId && this.selectedAllocationId == allocationForPage._id )
    // Issue : navigating between local and provincial, shows first loaded response on last clicked allocation page
    // ie. kidney(local) is shown on kidney(provincial) or the other way
    // When it is isInitializing and selectedAllocationId is null, we shouldn't return
    // we have to get next selected Allocation..
    if(this.isInitializing && (!this.selectedAllocationId && this.selectedAllocationId == allocationForPage._id )) return; // We don't need to double load

    this.isInitializing = true;

    // Ref to our AllocationControls
    const allocationControls = this.$refs.allocationControls as AllocationControls;
    // Clear our allocation control state when loading
    if (allocationControls) allocationControls.buildAllocationPageState();
    // If we're currently loading an allocation
    if(this.isLoadingAllocations) {
      return;
    }
    this.getAllocation();
  }

  // PRIVATE

  // Get active Allocation based on organ_code
  private getAllocation() {
    // In order to show concluded allocation details, we have to get allAllocations
    this.$store.commit('allocations/clearAllocation');
    // TODO: TECH_DEBT: Currently donor details is called everytime a checklist is called, so instead of that we need to get
    // TODO: rh_indicator,height,weight from allocation service, so that we can avoid calling donordetails
    // TODO: Remove donorDetails once we have above fields from allocation service
    this.$store.commit('allocations/clearDonorDetails');

    const allocations = this.findAllocations(this.allAllocations, this.organCode, this.organAllocationOption, this.donor.indicators?.double_kidney);
    const allocationForPage = this.findAllocationForPage(allocations, this.organCode, this.organAllocationOption, this.donor.indicators?.double_kidney);
    // Let's clear the selected allocation as we are about to reset everything
    // if we have an allocation id get the allocation details from api
    if (allocationForPage) {
      const allocationPageId = allocationForPage._id;
      this.selectedAllocationId = allocationPageId;
      this.$store.dispatch('allocations/getAllocation', { clientId: this.clientId, organCode: this.organCode, allocationId: allocationPageId }).then(() => {
        this.isInitializing = false;
      });
    } else {
      this.isInitializing = false;
    }
  }

  // Offer outcome notification events bubble up to the view
  private displayOutcomeNotification(context: OfferOutcomeContext) {
    this.$emit('display-outcome-notification', context);
  }
}
