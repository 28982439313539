import { MutationTree } from 'vuex';
import { UsersState } from '@/store/users/types';
import Vue from 'vue';

// return fresh set of preferences
function getDefaultPreferences(): any {
  const defaultPreferences: any = {
    notifications: {
      style: 'full',
      visible: true,
      unread: true
    },
    allocations: {
      style: 'full',
      visible: true,
    },
    primary: {
      style: 'full',
      visible: true,
    },
    bookmarks: {
      style: 'full',
      visible: true,
      entries: []
    },
    backup: {
      style: 'full',
      visible: true,
    },
    activeDonors: {
      style: 'full',
      visible: true,
    },
    labResults: {
      style: 'full',
      visible: true,
    },
    recentRecipients: {
      style: 'full',
      visible: true,
    },
    recentLivingDonors: {
      style: 'full',
      visible: true,
    },
    urgentListings: {
      style: 'full',
      visible: true,
    },
    exdDonors: {
      style: 'full',
      visible: true,
    },
    transplantInProgress: {
      style: 'full',
      visible: true,
    }
  };
  return defaultPreferences;
}

export const mutations: MutationTree<UsersState> = {
  setUser(state, userinfo) {
    // Store user info
    const newUserInfo = userinfo;
    if (newUserInfo.preferences.data) {
      // if preferences exists, parse and return as object
      newUserInfo.preferences = JSON.parse(userinfo.preferences.data);
    } else {
      // if preferences doesn't exist, send back fresh set of default preferences
      newUserInfo.preferences = getDefaultPreferences();
    }
    Vue.set(state, 'user', newUserInfo);
  },
  setPreferences(state, preferences) {
    // Store user info
    const userinfo = state.user!;
    userinfo.preferences = JSON.parse(preferences.data) || undefined;
    Vue.set(state, 'user', userinfo);
  },
  setRoles(state, roles) {
    // Store role lookup info
    Vue.set(state, 'roles', roles);
  },
  setRoute(state, route: string) {
    // Store route
    Vue.set(state, 'route', route);
  },
};
