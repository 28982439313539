
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import HlaInput from '@/components/shared/HlaInput.vue';
import { Laboratory } from '@/store/laboratories/types';
import TextInput from '@/components/shared/TextInput.vue';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { idComparator } from '@/utils';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { HlaAntibodiesFilterRow } from '@/components/hla/HlaAntibodyFilterTable.vue';
import { Recipient, RecipientProfileDetails, RecipientDiagnosticsHla, AntibodiesCumulative } from '@/store/recipients/types';
import { LabHLAAntibody, HlaAntibodyData, HlaAntibodyTestingMethod, HLAAntibodiesForm, HlaAntibodyDataForm } from '@/store/labs/types';

interface HlaAntibodyModalState {
  selectedId: string|null;
  form: HLAAntibodiesForm|null;
}

// Types of modal switching
enum ModalSwitch {
  Previous,
  Next
}

@Component({
  components: {
    SelectInput,
    TextInput,
    TextAreaInput,
    NumberInput,
    HlaInput,
    ModalSection,
    HlaInputGroup,
  }
})
export default class HlaAntibodyModal extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) filteredAntibodyLabs!: HlaAntibodiesFilterRow[];

  // State
  @State(state => state.labs.hlaAntibodies) private hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];
  @State(state => state.pageState.currentPage.hlaAntibodyModal) editState!: { [key: string]: HlaAntibodyModalState|null };
  @State(state => state.lookups.laboratory_hla_antibody_test_kits) private hlaAntibodyTestKitLookup!: HlaAntibodyTestKit[];

  // Getters
  @Getter('hla_testing_method', { namespace: 'lookups' }) private hlaTestingMethodLookup: any;
  @Getter('hlaTestingKitClass1Options', { namespace: 'lookups' }) hlaTestingKitClass1Options!: GenericCodeValue[];
  @Getter('hlaTestingKitClass2Options', { namespace: 'lookups' }) hlaTestingKitClass2Options!: GenericCodeValue[];
  @Getter('lookupHlaAntibodyTestingKit', { namespace: 'lookups' }) lookupHlaAntibodyTestingKit!: (code: string|null) => HlaAntibodyTestKit|null;
  @Getter('buildHLAAntibodiesForm', { namespace: 'labs' }) buildHLAAntibodiesForm!: (hlaAntibodiesData: LabHLAAntibody) => HLAAntibodiesForm;

  get hlaAntibodiesModalTitle(): string {
    const form = (this.editState || {})[this.modalStateComponentKey]?.form;
    if (!form) return this.$t('serum_results').toString();

    return `${this.$t('serum_results').toString()} #${form.sample_code} - ${form.sample_draw_date}`;
  }

  get modalSectionRef(): string {
    return `${this.id}HlaAntibodyModal`;
  }

  get modalStateComponentKey(): string {
    return `${this.id}HlaAntibodyModalComponent`;
  }

  // Display Class 1 legacy data?
  get showClass1PossibleAlleleSpecific(): boolean {
    if (!this.formStateInModal) return false;

    const values = this.formStateInModal?.antibodies?.class1_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display Class 2 legacy data?
  get showClass2PossibleAlleleSpecific(): boolean {
    if (!this.formStateInModal) return false;

    const values = this.formStateInModal?.antibodies?.class2_possible_allele_specific || [];
    return values.length > 0;
  }

  // Clear State and open modal
  public initialize(selectedHlaAntibodyLabId: string) {
    this.initializePageState(selectedHlaAntibodyLabId);
    this.toggleModal();
  }

  // Open a static modal
  private toggleModal(): void {
    const targetModal = this.$refs[this.modalSectionRef] as ModalSection;
    targetModal.toggleModal();
  }

  // Build modal page state
  private initializePageState(selectedHlaAntibodyLabId: string): void {
    // Initialize modal page-level state (there can be multiple on one page)
    if (!this.editState) {
      this.$store.commit('pageState/set', {
        pageKey: 'hlaAntibodyModal',
        value: {},
      });
    }

    // Initialize component-level state within overall page state
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodyModal',
      componentKey: this.modalStateComponentKey,
      value: this.buildHlaAntibodyModalState(selectedHlaAntibodyLabId)
    });
  }

  // Map to UI form interface
  private buildHlaAntibodyModalState(selectedHlaAntibodyLabId: string): HlaAntibodyModalState|null {
    if (!this.hlaAntibodies) return null;

    // Retrieve the HLA Antibodies Test object from the Vue-X Store
    const document: LabHLAAntibody|undefined = this.hlaAntibodies.find((each: LabHLAAntibody) => {
      return each._id && each._id.$oid === selectedHlaAntibodyLabId;
    });
    if (!document) return null;

    return {
      selectedId: selectedHlaAntibodyLabId,
      form: this.buildHLAAntibodiesForm(document),
    };
  }

  private previousHlaAntibodiesTestModal(): void {
    const modal = this.$refs[this.modalSectionRef] as ModalSection;
    modal.switchModal(ModalSwitch.Previous);
  }

  private nextHlaAntibodiesTestModal(): void {
    const modal = this.$refs[this.modalSectionRef] as ModalSection;
    modal.switchModal(ModalSwitch.Next);
  }

  private onHlaAntibodiesTestModalSwitched(options?: any): void {
    // Use filtered rows from table component so that modal switch order logically corresponds to row order
    const filterRowsFromTable: HlaAntibodiesFilterRow[] = this.filteredAntibodyLabs || [];
    // Determine index of selected test with respect to the filtered rows
    const indexOfCurrentTest = filterRowsFromTable.findIndex((test: any) => {
      return idComparator(test, { _id: { $oid: this.editState[this.modalStateComponentKey]?.selectedId } });
    });
    if (indexOfCurrentTest > -1) {
      if (options == ModalSwitch.Previous) {
        // Identify previous row with respect to filtering, sorting, etc.
        let previousFilterRow: HlaAntibodiesFilterRow;
        if (indexOfCurrentTest < 1) {
          previousFilterRow = filterRowsFromTable[filterRowsFromTable.length - 1];
        } else {
          previousFilterRow = filterRowsFromTable[indexOfCurrentTest - 1];
        }
        if (previousFilterRow) {
          // Fetch direct reference to source API document regardless of filtering
          const previousTest = this.hlaAntibodies.find((test: any) => {
            return idComparator(test, previousFilterRow);
          });
          // Select previous test
          const id = previousTest?._id?.$oid;
          if (id) {
            this.initialize(id);
          }
        }
      } else if (options == ModalSwitch.Next) {
        // Identify next row with respect to filtering, sorting, etc.
        let nextFilterRow: HlaAntibodiesFilterRow;
        if (indexOfCurrentTest > filterRowsFromTable.length - 2) {
          nextFilterRow = filterRowsFromTable[0];
        } else {
          nextFilterRow = filterRowsFromTable[indexOfCurrentTest + 1];
        }
        if (nextFilterRow) {
          // Fetch direct reference to source API document regardless of filtering
          const nextTest = this.hlaAntibodies.find((test: any) => {
            return idComparator(test, nextFilterRow);
          });
          // Select next test
          const id = nextTest?._id?.$oid;
          if (id) {
            this.initialize(id);
          }
        }
      }
    }
  }

  // Check form state shown in the popup modal form
  get formStateInModal(): HLAAntibodiesForm|null {
    if (!this.editState || !this.editState[this.modalStateComponentKey] || !this.editState[this.modalStateComponentKey]) return null;

    return this.editState[this.modalStateComponentKey]?.form || null;
  }

  // Check which Class I SAB Testing Kit has been selected if any
  get selectedTestKitClass1(): HlaAntibodyTestKit|null {
    if (!this.formStateInModal) return null;

    const testingKit = this.formStateInModal?.testing_kit_class1 || null;
    return this.lookupHlaAntibodyTestingKit(testingKit);
  }

  // Check which Class II SAB Testing Kit has been selected if any
  get selectedTestKitClass2(): HlaAntibodyTestKit|null {
    if (!this.formStateInModal) return null;

    const testingKit = this.formStateInModal?.testing_kit_class2 || null;
    return this.lookupHlaAntibodyTestingKit(testingKit);
  }

  // Show non-Storage fields if overall testing method is not Storage (i.e. it is ID/PRA or SAB)
  get showNonStorage(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_default || null;
    return testingMethod != null && testingMethod !== HlaAntibodyTestingMethod.Storage;
  }

  // Show editable PRA Class I field only if Class I Testing Method is set to ID/PRA
  get showPraClass1(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class1 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Idpra;
  }

  // Show editable PRA Class II field only if Class II Testing Method is set to ID/PRA
  get showPraClass2(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class2 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Idpra;
  }

  // Show Class I SAB tags and calculated cPRA Class I field only if Class I Testing Method is set to SAB
  get showSabClass1(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class1 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Sab;
  }

  // Show Class II SAB tags and calculated cPRA Class II field only if Class II Testing Method is set to SAB
  get showSabClass2(): boolean {
    if (!this.formStateInModal) return false;

    const testingMethod = this.formStateInModal?.testing_method_class2 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Sab;
  }

  /**
   * Show calculated Combined cPRA field only if both Class I Testing Method and Class II Testing Method are
   * PRA or SAB. i.e. If either of the Testing Methods are storage or blank, there is no Combined cPRA.
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showCombinedCpra(): boolean {
    return (this.showSabClass1 || this.showPraClass1) && (this.showSabClass2 || this.showPraClass2);
  }
}
