import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ReportState, recipientDataAuditReports } from '@/store/reports/types';

export const actions: ActionTree<ReportState, RootState> = {
  new({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setCurrentReport', undefined);
      resolve();
    });
  },
  newTransplantInProgressReport({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setTransplantInProgressReportState', { sort: undefined });
      resolve();
    });
  },
  newWaitingListReport({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setWaitingListReportState', {
        organCode: null,
        hospital: null,
        lowerAge: null,
        upperAge: null,
        bloodType: null,
        acceptLivingDonor: null,
        acceptDeceasedDonor: null,
        extendedCriteriaDonor: null,
        dacAcceptanceHCVAB: null,
        dacAcceptanceHCNAT: null,
        dacAcceptanceHepABCore: null,
        suspendedPatients: null,
        gender: null,
        sortOrder: null
      });
      resolve();
    });
  },
  printTransplantInProgressReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const sort: string = query.sort;
      const ep: string = APIRoute(EP.reports.tip_report, [[':sort', sort]]);
      axios.post(ep, query).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printTransplantActivityReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const payload = { transplant_activity_report: query };
      const ep: string = APIRoute(EP.reports.transplant_activity_report);

      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else if (response.data && response.data.errors && response.data.errors.transplant_activity_report && response.data.errors.transplant_activity_report._original_response.body) {
          // the SSRS report sometimes gives a 500 error and then the error is in HTML in response.data.errors.transplant_activity_report._original_response.body;
          const response_error = response.data.errors.transplant_activity_report._original_response.body;
          // the actual error is put in a <li> and after the error it says 'To run or subscribe to this report, you must provide a parameter value.'
          // we use these values to split the actual error message out and push it
          const error = response_error.split('<li>')[1].split('To run')[0];
          reject({ success: false , errorMessages: [error], validationErrors: response.data.errors });
        } else if (response.data && response.data.errors) {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printCrossmatchReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const payload = { crossmatch_report: query };
      const ep: string = APIRoute(EP.reports.crossmatch_report);
      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printDialysisReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const payload = { dialysis_report: query };
      const ep: string = APIRoute(EP.reports.dialysis_report);
      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printChangeStatusReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const payload = { change_status_report: query };
      const ep: string = APIRoute(EP.reports.change_status_report);
      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printWaitingListReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const { report_code } = query;
      delete query.report_code;
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const ep: string = APIRoute(EP.reports.waitlist_report, [[':waitlist_report', report_code]]);
      const payload = { waitlist_report: query };
      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          const result: any = response.data.errors?.waitlist_report ? response.data.errors.waitlist_report : response.data.errors ;
          reject({ success: false , errorMessages: [result], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printOrganYieldUtilizationReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const ep: string = APIRoute(EP.reports.organ_yield_report);
      const payload = { organ_yield_report: query };
      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else if (response.data && response.data.errors) {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printRecipientDataAuditReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Recipient Data Audit endpoint is POST so we can send filter parameters as a payload
      const ep: string = APIRoute(query.type === recipientDataAuditReports.Index ? EP.reports.recipient_data_audit_index : EP.reports.recipient_data_audit_detail);
      const payload = { recipient_data_audit: query };
      axios.post(ep, payload).then((response: any) => {
        // Check if query was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else if (response.data && response.data.errors && response.data.errors.recipient_data_audit) {
          // the SSRS report sometimes gives a 500 error and then the error is in HTML in response.data.errors.recipient_data_audit._original_response.body;
          const response_error = response.data.errors.recipient_data_audit._original_response ? response.data.errors.recipient_data_audit._original_response.body : response.data.errors.recipient_data_audit;
          // the actual error is put in a <li> and after the error it says 'To run or subscribe to this report, you must provide a parameter value.'
          // we use these values to split the actual error message out and push it
          const error = response_error;
          reject({ success: false , errorMessages: [error], validationErrors: response.data.errors });
        } else if (response.data && response.data.errors) {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  printAllocationReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const ep: string = APIRoute(EP.reports.allocation_report);
      axios.post(ep, query).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  }
};
