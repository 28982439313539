
import { Getter, State } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Vue } from 'vue-property-decorator';
import { SaveProvider, SaveResult } from '@/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import ManageAccounts from '@/components/administration/ManageAccounts.vue';
import SideNavUsers from '@/components/administration/side-nav/SideNavUsers.vue';
import { UserDetails } from '@/store/userAccounts/types';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    ManageAccounts,
    SideNavUsers
  }
})
export default class Accounts extends Vue {

}
