
import { Getter, State } from 'vuex-class';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LivingDonor } from '@/store/livingDonors/types';
import { LivingAllocationResponse, LivingAllocationRecipient, LivingAllocationResponseAction, LivingAllocationOfferResponseCodeValues, LivingAllocationOfferTypeValues, LivingAllocation } from '@/store/livingAllocations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import { GenericCodeValue } from '@/store/types';
import { hospitals } from '@/store/hospitals';
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { mixins } from 'vue-class-component';

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class OfferResponseDecline extends mixins(AllocationErrorsMixin) {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.pageState.currentPage.offerResponseDecline) private editState!: any;
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isRespondingOffer) private isRespondingOffer!: boolean;
  @State(state => state.livingAllocations.isDecliningMultiple) private isDecliningMultiple!: boolean;

  @Getter('clientId', { namespace: 'livingDonors' }) private donorId!: string;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('responseOptions', { namespace: 'livingAllocations' }) private responseOptions!: (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[]) => GenericCodeValue[];
  @Getter('reasonCategoryOptions', { namespace: 'livingAllocations' }) private reasonCategoryOptions!: (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[], organCode: string) => GenericCodeValue[];
  @Getter('reasonOptions', { namespace: 'livingAllocations' }) private reasonOptions!: (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[], organCode: string) => GenericCodeValue[];
  @Getter('responsiblePhysiciansByHospitalAndOrgan', { namespace: 'responsiblePhysicians' }) private responsiblePhysicianOptions!: (byHospitalId: string, byOrganCode: string) => GenericCodeValue[];
  @Getter('disableResponseOptions', { namespace: 'livingAllocations' }) private disableResponseOptions!: (offer: LivingAllocationResponse) => boolean;
  @Getter('disableResponseCategoryOptions', { namespace: 'livingAllocations' }) private disableResponseCategoryOptions!: (offer: LivingAllocationResponse) => boolean;

  offerResponseErrorMessage = '';

  // Are we using this 'decline multiple' feature for offers to out-of-province?
  get outOfProvince(): boolean {
    if (!this.editState) return false;

    const selectedRecipientEntries: LivingAllocationResponse[] = this.editState.editedRows || [];
    const oopEntries = selectedRecipientEntries.filter((entry: LivingAllocationResponse) => {
      return !!entry.outOfProvince;
    });

    return oopEntries.length > 0;
  }

  public initializeModal(editedRows: any[], offerResponses: GenericCodeValue[], organCode: string): void {
    // build state from valid ids
    this.buildState(editedRows, offerResponses, organCode);
    (this.$refs.offerResponseDecline as ModalSection).toggleStaticModal();
  }

  /**
   * Builds the Offer Response state from recipientIds
   *
   * @param editedRows array of edited rows
   */
  private buildState(editedRows: any[], offerResponses: GenericCodeValue[], organCode: string): void {
    const hospitalId = editedRows.length > 0 ? editedRows[0].hospitalId : null;

    this.$store.commit('pageState/set', {
      pageKey: 'offerResponseDecline',
      value: {
        editedRows: editedRows,
        organCode: organCode,
        offerResponses: offerResponses,
        setting: {
          offerType: LivingAllocationOfferResponseCodeValues.Decline,
          responseCode: 'D',
          responseCategoryCode: null,
          responseReasonCode: null,
          responsiblePhysician: null,
          hospitalId: hospitalId
        }
      }
    });
  }

  // Cancel modal
  private closeModal(): void {
    (this.$refs.offerResponseDecline as ModalSection).hideModal();
    if(this.offerResponseErrorMessage) this.$emit('reloadTable');
  }

  // Confirm and save responses
  private confirmChanges(): void {
    this.performPatch();
  }

  // Update the respone for a given row
  public updateRow(event: string, key: string): void {
    const offerType = this.editState.setting.offerType;
    if (offerType == LivingAllocationOfferResponseCodeValues.Accept) {
      Vue.set(this.editState.setting, 'responseCategoryCode', undefined);
      Vue.set(this.editState.setting, 'responseReasonCode', undefined);
      Vue.set(this.editState.setting, 'responsiblePhysician', undefined);
    }
    Vue.set(this.editState.setting, key, event);
  }

  // Return filtered offer_responses: Withdraw and Cancel
  public clearValues(keys: string[]): void {
    keys.forEach((k: string) => {
      Vue.set(this.editState.setting, k, null);
    });
  }

  // Extract patch for API
  private extractAllocationResponsePatch(responses: LivingAllocationResponse[]): LivingAllocationResponseAction[] {
    const result: LivingAllocationResponseAction[] = [];
    // build payload for each response
    responses.forEach((response: LivingAllocationResponse) => {
      // build response payload
      const filteredResponse = {
        recipient_id: response._id,
        type: this.editState.setting.responseCode,
        reason_code: this.editState.setting.responseReasonCode,
        reason_category: this.editState.setting.responseCategoryCode,
        responsible_physician_id: this.editState.setting.responsiblePhysician,
        offer_organ_code: response.offerOrganCode
      };
      result.push(filteredResponse);
    });
    return result;
  }

  private performPatch(): void {
    // build patch from edited rows
    const patch = this.extractAllocationResponsePatch(this.editState.editedRows);
    const payload = {
      clientId: this.donorId,
      organCode: this.editState.organCode,
      allocationId: this.allocation._id,
      responseDetails: patch
    };

    // clear error message
    this.offerResponseErrorMessage = "";

    this.$store.commit('livingAllocations/startDecliningMultiple');
    this.$store.dispatch('livingAllocations/respondOffer', payload).then((success: any) => {
      this.$store.commit('livingAllocations/stopDecliningMultiple');
      this.$store.dispatch('livingAllocations/getAllocations', { clientId: this.donorId, state: 'active' });
      this.closeModal();
    }).catch((error: any) => {
      this.$store.commit('livingAllocations/stopDecliningMultiple');
      const error_message = this.getErrorMessage(error);
      this.offerResponseErrorMessage = error_message;
    });
  }
}
