import Vue from 'vue';
import { MutationTree } from 'vuex';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response, ListFinalDecisions, FinalDecision } from '@/store/decisions/types';

export const mutations: MutationTree<DecisionsState> = {
  setDecision(state, decision) {
    // Clone data and sanitize the clone
    const sanitizedData: Decision = Object.assign({}, decision);

    // Store sanitized clone
    Vue.set(state, 'selectedDecision', sanitizedData);
  },
  setListDecisions(state, decisions) {
    Vue.set(state, 'listDecisions', decisions);
  },

  setAttachment(state, attachment) {
    // Clone data and sanitize the clone
    const sanitizedData: Attachment = Object.assign({}, attachment);

    // Store sanitized clone
    Vue.set(state, 'selectedAttachment', sanitizedData);
  },
  setListAttachments(state, attachments) {
    Vue.set(state, 'listAttachments', attachments);
  },

  setResponse(state, response) {
    // Clone data and sanitize the clone
    const sanitizedData: Response = Object.assign({}, response);

    // Store sanitized clone
    Vue.set(state, 'selectedResponse', sanitizedData);
  },
  setListResponses(state, responses) {
    Vue.set(state, 'listResponses', responses);
  },

  setFinalDecision(state, finalDecision) {
    // Clone data and sanitize the clone
    const sanitizedData: FinalDecision = Object.assign({}, finalDecision);

    // Store sanitized clone
    Vue.set(state, 'selectedFinalDecision', sanitizedData);
  },
  setListFinalDecisions(state, finalDecisions) {
    Vue.set(state, 'listFinalDecisions', finalDecisions);
  },
  // clear children lists
  flushChildren(state) {
    Vue.set(state, 'listAttachments', undefined);
    Vue.set(state, 'listResponses', undefined);
    Vue.set(state, 'listFinalDecisions', undefined);
  }
};
