
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State, Getter }  from 'vuex-class';
import { HistoryVxm } from "@/store/history/types";
import HlaInput from '@/components/shared/HlaInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { LabHLAAntibody, HlaAntibodyTestingMethod } from '@/store/labs/types';
import HlaAntibodyFilterTable from '@/components/hla/HlaAntibodyFilterTable.vue';
import HlaVirtualCrossmatchResult from '@/components/hla/HlaVirtualCrossmatchResult.vue';

export interface VxmHistoryRow {
  _id: { $oid: string };
  date: string;
  time: string|null;
  recipientId: string;
  donorId: string;
  result: string;
}

export interface VxmHistoryFormState {
  history: {
    date: string|null;
    time: string|null;
    recipientId: string|null;
    donorId: string|null;
    result: string|null;
    vxm: {
      currentClass1Result: string;
      currentClass1UnacceptableAlleleGroup: string[];
      currentClass1UnacceptableAlleleSpecific: string[];
      currentClass1UnacceptableEpitopes: string[];
      currentClass1IndeterminateAlleleGroup: string[];
      currentClass1IndeterminateAlleleSpecific: string[];
      currentClass1IndeterminateEpitopes: string[];
      currentClass1PossibleAlleleSpecific: string[];
      currentClass1Untested: string[];
      currentClass1Messages: string[];
      cumulativeClass1Result: string;
      cumulativeClass1UnacceptableAlleleGroup: string[];
      cumulativeClass1UnacceptableAlleleSpecific: string[];
      cumulativeClass1UnacceptableEpitopes: string[];
      cumulativeClass1IndeterminateAlleleGroup: string[];
      cumulativeClass1IndeterminateAlleleSpecific: string[];
      cumulativeClass1IndeterminateEpitopes: string[];
      cumulativeClass1PossibleAlleleSpecific: string[];
      cumulativeClass1Untested: string[];
      cumulativeClass1Messages: string[];
      currentClass2Result: string;
      currentClass2UnacceptableAlleleGroup: string[];
      currentClass2UnacceptableAlleleSpecific: string[];
      currentClass2UnacceptableAlphaBeta: string[];
      currentClass2UnacceptableEpitopes: string[];
      currentClass2IndeterminateAlleleGroup: string[];
      currentClass2IndeterminateAlleleSpecific: string[];
      currentClass2IndeterminateAlphaBeta: string[];
      currentClass2IndeterminateEpitopes: string[];
      currentClass2PossibleAlleleSpecific: string[];
      currentClass2Untested: string[];
      currentClass2Messages: string[];
      cumulativeClass2Result: string;
      cumulativeClass2UnacceptableAlleleGroup: string[];
      cumulativeClass2UnacceptableAlleleSpecific: string[];
      cumulativeClass2UnacceptableAlphaBeta: string[];
      cumulativeClass2UnacceptableEpitopes: string[];
      cumulativeClass2IndeterminateAlleleGroup: string[];
      cumulativeClass2IndeterminateAlleleSpecific: string[];
      cumulativeClass2IndeterminateAlphaBeta: string[];
      cumulativeClass2IndeterminateEpitopes: string[];
      cumulativeClass2PossibleAlleleSpecific: string[];
      cumulativeClass2Untested: string[];
      cumulativeClass2Messages: string[];
    }|null,
  },
  entries: {
    selectedAntibodies: string[];
    testingMethod: number|null;
  },
}


@Component({
  components: {
    HlaInput,
    TextInput,
    SubSection,
    SelectInput,
    HlaInputGroup,
    HlaAntibodyFilterTable,
    HlaVirtualCrossmatchResult,
  }
})
export default class HlaViewVirtualCrossmatchHistory extends mixins(DateUtilsMixin) {
  @State(state => state.history.vxm) private historyVxm!: HistoryVxm[]|null;
  @State(state => state.history.selectedVxm) private selectedVxm!: HistoryVxm|null;
  @State(state => state.labs.hlaAntibodies) private hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.pageState.currentPage.vxmHistory) editState!: VxmHistoryFormState|null;

  @Getter('hla_testing_method', { namespace: 'lookups' }) private hlaTestingMethodLookup: any;

  // Table configuration
  get vxmHistoryTableConfig(): TableConfig {
    return {
      data: this.vxmHistoryRows,
      columns: [
        { label: this.$t('date').toString(), field: 'date', width: '25%' },
        { label: this.$t('recipient_id').toString(), field: 'recipientId', width: '25%' },
        { label: this.$t('donor_id').toString(), field: 'donorId', width: '25%' },
        { label: this.$t('result').toString(), field: 'result', width: '25%' },
      ],
      empty: this.emptyMessage,
      pagination: true
    };
  }

  get emptyMessage(): string {
    if (!this.historyVxm) {
      return this.$t('loading').toString();
    } else {
      return this.$t('perform_specified_vxm').toString();
    }
  }

  // Map collection of API documents to array of UI rows
  get vxmHistoryRows(): VxmHistoryRow[] {
    // Sort API collection based on raw date/time field
    const collection = this.historyVxm || [];
    // Map collection to UI row interface
    const rows: VxmHistoryRow[] = collection.map((document: HistoryVxm): VxmHistoryRow => {
      return {
        _id: document._id,
        date: this.parseDisplayDateUiFromDateTime(document.date) || '-',
        time: this.parseTimeUiFromDateTime(document.date) || null,
        recipientId: `${document.recipient_client_id}`,
        donorId: `${document.donor_deceased_donor_id || document.donor_living_donor_id}`,
        result: document.vxm_result.result || 'Error',
      };
    });
    return rows;
  }

  // Select VXM history in vue-x based on row click
  private selectVxmHistoryRow(event: any): void {
    const selectedId = event?.row?._id?.$oid;
    if (!selectedId) return;

    const found = (this.historyVxm || []).find((item: HistoryVxm) => {
      return item?._id?.$oid === selectedId;
    });
    if (!found) return;

    this.$store.commit('history/selectVxm', found);
    this.initializeForm();
  }

  // Initialize the form after vue mounts the component to the DOM
  private mounted(): void {
    this.initializeForm();
  }

  // Re-initialize when the selected VXM changes
  @Watch('selectedVxm')
  onSelectedVxmChanged(): void {
    this.initializeForm();
  }

  // Commit UI form interface data to edit state based on selected API data
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'vxmHistory',
      value: this.buildVxmHistoryState(),
    });
  }

  // Map one API document to UI form interface
  private buildVxmHistoryState(): VxmHistoryFormState|null {
    const form: VxmHistoryFormState = {
      history: {
        date: null,
        time: null,
        recipientId: null,
        donorId: null,
        result: null,
        vxm: null,
      },
      entries: {
        selectedAntibodies: [],
        testingMethod: HlaAntibodyTestingMethod.Sab,
      },
    };

    const document: HistoryVxm|null = this.selectedVxm;
    if (!document) return form;

    form.history = {
      date: this.parseDisplayDateUiFromDateTime(document.date) || null,
      time: this.parseTimeUiFromDateTime(document.date) || null,
      recipientId: `${document.recipient_client_id}`,
      donorId: `${document.donor_deceased_donor_id || document.donor_living_donor_id}`,
      result: document.vxm_result.result,
      vxm: {
        currentClass1Result: document.vxm_result.current.class1.result,
        currentClass1UnacceptableAlleleGroup: document.vxm_result.current.class1.unacceptable_allele_group,
        currentClass1UnacceptableAlleleSpecific: document.vxm_result.current.class1.unacceptable_allele_specific,
        currentClass1UnacceptableEpitopes: document.vxm_result.current.class1.unacceptable_epitopes,
        currentClass1IndeterminateAlleleGroup: document.vxm_result.current.class1.indeterminate_allele_group,
        currentClass1IndeterminateAlleleSpecific: document.vxm_result.current.class1.indeterminate_allele_specific,
        currentClass1IndeterminateEpitopes: document.vxm_result.current.class1.indeterminate_epitopes,
        currentClass1PossibleAlleleSpecific: document.vxm_result.current.class1.possible_allele_specific,
        currentClass1Untested: document.vxm_result.current.class1.untested,
        currentClass1Messages: document.vxm_result.current.class1.messages,
        cumulativeClass1Result: document.vxm_result.cumulative.class1.result,
        cumulativeClass1UnacceptableAlleleGroup: document.vxm_result.cumulative.class1.unacceptable_allele_group,
        cumulativeClass1UnacceptableAlleleSpecific: document.vxm_result.cumulative.class1.unacceptable_allele_specific,
        cumulativeClass1UnacceptableEpitopes: document.vxm_result.cumulative.class1.unacceptable_epitopes,
        cumulativeClass1IndeterminateAlleleGroup: document.vxm_result.cumulative.class1.indeterminate_allele_group,
        cumulativeClass1IndeterminateAlleleSpecific: document.vxm_result.cumulative.class1.indeterminate_allele_specific,
        cumulativeClass1IndeterminateEpitopes: document.vxm_result.cumulative.class1.indeterminate_epitopes,
        cumulativeClass1PossibleAlleleSpecific: document.vxm_result.cumulative.class1.possible_allele_specific,
        cumulativeClass1Untested: document.vxm_result.cumulative.class1.untested,
        cumulativeClass1Messages: document.vxm_result.cumulative.class1.messages,
        currentClass2Result: document.vxm_result.current.class2.result,
        currentClass2UnacceptableAlleleGroup: document.vxm_result.current.class2.unacceptable_allele_group,
        currentClass2UnacceptableAlleleSpecific: document.vxm_result.current.class2.unacceptable_allele_specific,
        currentClass2UnacceptableAlphaBeta: document.vxm_result.current.class2.unacceptable_alpha_beta,
        currentClass2UnacceptableEpitopes: document.vxm_result.current.class2.unacceptable_epitopes,
        currentClass2IndeterminateAlleleGroup: document.vxm_result.current.class2.indeterminate_allele_group,
        currentClass2IndeterminateAlleleSpecific: document.vxm_result.current.class2.indeterminate_allele_specific,
        currentClass2IndeterminateAlphaBeta: document.vxm_result.current.class2.indeterminate_alpha_beta,
        currentClass2IndeterminateEpitopes: document.vxm_result.current.class2.indeterminate_epitopes,
        currentClass2PossibleAlleleSpecific: document.vxm_result.current.class2.possible_allele_specific,
        currentClass2Untested: document.vxm_result.current.class2.untested,
        currentClass2Messages: document.vxm_result.current.class2.messages,
        cumulativeClass2Result: document.vxm_result.cumulative.class2.result,
        cumulativeClass2UnacceptableAlleleGroup: document.vxm_result.cumulative.class2.unacceptable_allele_group,
        cumulativeClass2UnacceptableAlleleSpecific: document.vxm_result.cumulative.class2.unacceptable_allele_specific,
        cumulativeClass2UnacceptableAlphaBeta: document.vxm_result.cumulative.class2.unacceptable_alpha_beta,
        cumulativeClass2UnacceptableEpitopes: document.vxm_result.cumulative.class2.unacceptable_epitopes,
        cumulativeClass2IndeterminateAlleleGroup: document.vxm_result.cumulative.class2.indeterminate_allele_group,
        cumulativeClass2IndeterminateAlleleSpecific: document.vxm_result.cumulative.class2.indeterminate_allele_specific,
        cumulativeClass2IndeterminateAlphaBeta: document.vxm_result.cumulative.class2.indeterminate_alpha_beta,
        cumulativeClass2IndeterminateEpitopes: document.vxm_result.cumulative.class2.indeterminate_epitopes,
        cumulativeClass2PossibleAlleleSpecific: document.vxm_result.cumulative.class2.possible_allele_specific,
        cumulativeClass2Untested: document.vxm_result.cumulative.class2.untested,
        cumulativeClass2Messages: document.vxm_result.cumulative.class2.messages,
      },
    };
    return form;
  }

  /**
   * When an antibody value in the VXM results is clicked, we need to toggle its
   * selection on or off. In other words, if it is not in selectedAntibodies we
   * need to add it to selectedAntibodies. If it is already in selectedAntibodies,
   * we need to remove it.
   */
  private toggleSelectedAntibody(selectedAntibody: any): void {
    if (!this.editState || !this.editState.entries) return;

    // Check whether or not the antibody has already been selected
    if (!this.editState.entries.selectedAntibodies.includes(selectedAntibody)) {
      // Add antibody to selection
      this.editState.entries.selectedAntibodies.push(selectedAntibody);
    } else {
      // Remove antibody from selection
      const indexOfGene = this.editState.entries.selectedAntibodies.indexOf(selectedAntibody);
      this.editState.entries.selectedAntibodies.splice(indexOfGene, 1);
    }
  }

  // Reset antibody value selection in form interface to an empty array
  private removeAllSelectedAntibodies(): void {
    if (!this.editState || !this.editState.entries) return;

    Vue.set(this.editState.entries, 'selectedAntibodies', []);
  }

  // Remove antibody from selectedAntibodies if its 'x' dismiss button is clicked
  private removeSelectedAntibody(selectedAntibody: any): void {
    if (!this.editState || !this.editState.entries) return;

    const indexOfGene = this.editState.entries.selectedAntibodies.indexOf(selectedAntibody);
    this.editState.entries.selectedAntibodies.splice(indexOfGene, 1);
  }

  // Whether or not any antibodies are selected i.e. is selectedAntibodies empty
  get zeroAntibodiesSelected(): boolean {
    if (!this.editState || !this.editState.entries) return true;

    return this.editState.entries.selectedAntibodies.length === 0;
  }

  // VXM Filter table configuration
  get vxmFilterTableConfig(): TableConfig {
    return {
      data: this.hlaAntibodies,
      columns: [
        { label: this.$t('sample_code').toString(), field: 'sampleCode', width: '11.67%' },
        { label: this.$t('sample_draw_date').toString(), field: 'sampleDrawDate', width: '11.67%' },
        { label: this.$t('laboratory').toString(), field: 'laboratory', width: '11.67%' },
        { label: this.$t('cpra_pra_class_i').toString(), field: 'cpra1', width: '11.67%' },
        { label: this.$t('cpra_pra_class_ii').toString(), field: 'cpra2', width: '11.67%' },
        { label: this.$t('combined_cpra').toString(), field: 'combinedCpra', width: '11.67%' },
        { label: this.$t('unacceptable').toString(), field: 'selected_unacceptable', width: '10%' },
        { label: this.$t('indeterminate').toString(), field: 'selected_indeterminate', width: '10%' },
      ],
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }
}
