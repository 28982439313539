
import { Component, Vue } from 'vue-property-decorator';
import LoadingSection from '@/components/shared/LoadingSection.vue';
import LoadingDonorSummaryCard from '@/components/shared/LoadingDonorSummaryCard.vue';

@Component({
  components: {
    LoadingSection,
    LoadingDonorSummaryCard,
  }
})
export default class LoadingDonorPage extends Vue {}
