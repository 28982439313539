import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { DecisionsState } from '@/store/decisions/types';
import { actions } from '@/store/decisions/actions';
import { mutations } from '@/store/decisions/mutations';
import { getters } from '@/store/decisions/getters';

const state: DecisionsState = {
  selectedDecision: undefined,
  listDecisions: [],
  selectedAttachment: undefined,
  listAttachments: [],
  selectedResponse: undefined,
  listResponses: {},
  selectedFinalDecision: undefined,
  listFinalDecisions: {},
};

const namespaced = true;
  
export const decisions: Module<DecisionsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
