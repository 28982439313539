
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { Getter, State } from 'vuex-class';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { DeceasedDonor, DeceasedDonorOrgan } from '@/store/deceasedDonors/types';
import { Organ, ExceptionalDistributionCodeValues } from '@/store/lookups/types';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';
import TimeInput from '../shared/TimeInput.vue';
import TextAreaInput from '../shared/TextAreaInput.vue';
import { SystemModules } from '@/store/features/types';

interface DonationInformationForm {
  donorType?: boolean;
  notApplicable?: boolean;
  paediatric?: boolean;
  exceptional_distribution?: boolean;
  exd_reason_codes?: number[];
  exd_reason_details_other?: string;
  exd_reason_details_travel?: string;
  exd_reason_details_transmission?: string;
  consentedForResearch?: boolean;
  consentedOrgans?: ConsentedOrgansForm;
  consentedDates?: ConsentedDatesForm;
  ecd?: boolean;
  doubleKidney?: boolean;
  ventilatorAtReferral?: string;
  ventilatorStatus?: string|null,
  ventilatorType?: string|null;
  ventilatorTypeOther?: string;
  postRelease?: boolean;
  postReleaseReason?: string;
  resultsReceived?: string; // Changing to string because the field is a SelectInput which takes strings/numbers
  resultsReceivedDate?: string;
  resultsReceivedTime?: string;
  resultsReported?: string; // Changing to string because the field is a SelectInput which takes strings/numbers;
  resultsReason?: string;
  resultsReportedDate?: string;
  resultsReportedTime?: string;
  kdpi?: number|null;
  kdpiDate?: string|null;
  kdpiTime?: string|null;
}

interface ConsentedOrgansForm {
  [key: string]: boolean;
}

interface ConsentedDatesForm {
  [key: string]: string|undefined;
}

@Component({
  components: {
    CardSection,
    SubSection,
    TextInput,
    DateInput,
    SelectInput,
    NumberInput,
    CheckboxInput,
    SelectOtherInput,
    BooleanRadioInput,
    TimeInput,
    TextAreaInput
  }
})
export default class DonationInformation extends mixins(DateUtilsMixin, TranslationUtilsMixin) implements SaveableSection {
  @State(state => state.lookups.ventilator_type) ventilatorTypeLookup!: Organ[];
  @State(state => state.lookups.deceased_donor_type) ReferralTypeLookup!: Organ[];
  @State(state => state.lookups.ventilator_status) ventilatorStatusLookup!: Organ[];
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.donationInformation) editState!: DonationInformationForm;

  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;  
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('getTagsFromLookup', { namespace: 'utilities' }) getTagsFromLookup!: (lookup: any[]) => { text: string, code?: number, expired_date?: string, tiClasses?: string[] }[];
  @Getter('exceptionalDistributionOptionsForDonor', { namespace: 'lookups' }) private exceptionalDistributionOptionsForDonor!: GenericCodeValue[];
  @Getter('nonExpiredOrganOptions', { namespace: 'lookups' }) nonExpiredOrganOptions!: (type?: string) => NumericCodeValue[];
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  @Prop({ default: false }) newDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Is the recipient death related to waittime field enabled?
  get isKdpiEnabled(): boolean {
    return this.moduleEnabled(SystemModules.KDPI);
  }

  get isKdpiBlank(): boolean {
    return this.editState.kdpi === null ||
           this.editState.kdpi === undefined ||
           this.editState.kdpi.toString() === '';
  }

  get hasWriteAccessAllocations(): boolean {
    return this.isGroupWriteable("allocation");
  }

  get hasWriteAccessGeneral(): boolean {
    return this.isGroupWriteable("donor_medical");
  }

  get isSaveDisabled(): boolean {
    let disabled = true;
    if (this.hasWriteAccessAllocations) { disabled = false; }
    if (this.hasWriteAccessGeneral) { disabled = false; }
    return disabled;
  }

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['deceased_donor_type', 'donor_exceptional_distribution', 'ventilator_status', 'ventilator_type'];

  // Initialize the form before the page mounts
  public mounted(): void {
    this.initializeForm();
  }

  // Initialize the form
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'donationInformation',
      value: this.buildDonationInformationForm(this.deceasedDonor)
    });
    if(this.editState.kdpi === null) {
      Vue.set(this.editState, "kdpi", "-");
    }
  }

  // Event handlers

  public clearVentTypeOther() {
    Vue.set(this.editState, 'ventilatorTypeOther', undefined);
  }

  public clearResultsReceivedDate() {
    if (this.editState.resultsReceived == null) {
      Vue.set(this.editState, 'resultsReceivedDate', null);
      Vue.set(this.editState, 'resultsReceivedTime', null);
    }
  }

  public clearResultsReportedInfo() {
    if (this.editState.resultsReported == null) {
      Vue.set(this.editState, 'resultsReason', null);
      Vue.set(this.editState, 'resultsReportedDate', null);
      Vue.set(this.editState, 'resultsReportedTime', null);
    }
  }

  /**
   * Check if we have Kidney as a consented organs
   *
   * @returns {boolean} true if Kidney is a consented organs
   */
  get checkForKidneyConsent(): boolean {
    const organs = this.editState.consentedOrgans || {};
    return organs[3] === true ? true : false;
  }

  /**
   * Convert the value of editState.postRelease to a boolean
   *
   * @returns {boolean} value of postRelease as a boolean
   */
  get postReleaseRequired(): boolean {
    return !!this.editState.postRelease;
  }

  /**
   * Clear exd reason codes on changing exceptional distribution
   *
   * @returns {void}
   */
  public changeEXDStatus(): void {
    Vue.set(this.editState, 'exd_reason_codes', []);
  }

  /**
   * Return organs of type 'single'
   *
   * NOTE: here we assume that the Deceased Donor page should only show Organ Consent checkboxes for non-expired entries in the organ lookup
   *
   * @returns {NumericCodeValue[]} filtered list of Organs
   */
  get buildSingleTypeOrgans(): NumericCodeValue[] {
    return this.nonExpiredOrganOptions('single');
  }

  // Triggered when all the lookups have been loaded
  public loaded(): void {
    this.$emit('loaded', 'donationInformation');
  }

  /**
   * Apply value to organ checkboxes based on donor.organ_consents
   */
  public buildConsentedOrgansForm(deceasedDonor?: DeceasedDonor): ConsentedOrgansForm {
    const result: ConsentedOrgansForm = {};
    const organs = deceasedDonor ? deceasedDonor.organ_consents : this.buildSingleTypeOrgans;
    // Return if we have no organs
    if (!organs || organs.length <= 0) { return result; }

    organs.forEach((organ: any, index: number) => {
      result[organ.organ_code || index] = organ.consented;
    });
    return result;
  }

  /**
   * Apply value to organ date fields based on donor.organ_consents
   */
  public buildConsentedDatesForm(deceasedDonor?: DeceasedDonor): ConsentedDatesForm {
    const result: ConsentedDatesForm = {};
    const organs = deceasedDonor ? deceasedDonor.organ_consents : this.buildSingleTypeOrgans;
    // Return if we have no organs
    if (!organs || organs.length <= 0) { return result; }

    organs.forEach((organ: any, index: any) => {
      result[organ.organ_code || index] = this.parseDateUi(organ.consented_date);
    });
    return result;
  }

  /**
   * Generates Donation Information form state based on a Deceased Donor document
   *
   * @param deceasedDonor Deceased Donor document provided by API
   * @returns {DonationInformationForm} Donation Information form state
   */
  public buildDonationInformationForm(deceasedDonor?: DeceasedDonor): DonationInformationForm {
    if (!deceasedDonor) {
      return {
        consentedOrgans: this.buildConsentedOrgansForm(),
        consentedDates: this.buildConsentedDatesForm()
      };
    }

    const death = deceasedDonor.death || {};
    const indicators = deceasedDonor.indicators || {};
    const exdReasonCodes = (indicators.exd_reason_codes && indicators.exd_reason_codes.length > 0) ? indicators.exd_reason_codes : [];
    
    // The YesNoOptions lookup uses '1' for "yes/true" and '0' for "no/false"
    // Have to use "1" and "0" strings as SelectInput sanitizes the value to a string
    let results_received = null;
    if (indicators.results_received === true) {
      results_received = '1';
    } else if (indicators.results_received === false) {
      results_received = '0';
    }

    // The YesNoOptions lookup uses '1' for "yes/true" and '0' for "no/false"
    // Have to use "1" and "0" strings as SelectInput sanitizes the value to a string
    let results_reported = null;
    if (indicators.results_reported === true) {
      results_reported = '1';
    } else if (indicators.results_reported === false) {
      results_reported = '0';
    }

    return {
      donorType: death.neurological_death,
      consentedOrgans: this.buildConsentedOrgansForm(deceasedDonor),
      consentedDates: this.buildConsentedDatesForm(deceasedDonor),
      exceptional_distribution: indicators.exceptional_distribution,
      exd_reason_codes: exdReasonCodes,
      exd_reason_details_other: indicators.exd_reason_details_other || undefined,
      exd_reason_details_travel: indicators.exd_reason_details_travel || undefined,
      exd_reason_details_transmission: indicators.exd_reason_details_transmission || undefined,
      consentedForResearch: deceasedDonor.consented_for_research,
      ecd: indicators.ecd_donor,
      doubleKidney: indicators.double_kidney,
      ventilatorAtReferral: indicators.ventilator_at_referral,
      ventilatorStatus: indicators.ventilator_status,
      ventilatorType: indicators.ventilator_type,
      ventilatorTypeOther: indicators.ventilator_type_other,
      postRelease: indicators.post_release,
      postReleaseReason: indicators.post_release_reason,
      resultsReceived: results_received || undefined,
      resultsReceivedDate: indicators.results_received_date ? this.parseDateUiFromDateTime(indicators.results_received_date) : undefined,
      resultsReceivedTime: indicators.results_received_date ? this.parseTimeUiFromDateTime(indicators.results_received_date) : undefined,
      resultsReported: results_reported || undefined,
      resultsReason: indicators.results_reason,
      resultsReportedDate: indicators.results_reported_date ? this.parseDateUiFromDateTime(indicators.results_reported_date) : undefined,
      resultsReportedTime: indicators.results_reported_date ? this.parseTimeUiFromDateTime(indicators.results_reported_date) : undefined,
      kdpi: indicators.kdpi,
      kdpiDate: indicators.kdpi_datetime ? this.parseDateUiFromDateTime(indicators.kdpi_datetime) : undefined,
      kdpiTime: indicators.kdpi_datetime ? this.parseTimeUiFromDateTime(indicators.kdpi_datetime) : undefined,
    };
  }

  private filterNullValues(values: any): any {
    return values.filter((el: any) => { return el != null; });
  }

  // Translate the form structure into the Donor data structure
  public extractPatch(): DeceasedDonor {
    const organs = this.editState.consentedOrgans || {};

    // Check whether or not kidney is consented, since there are kidney-only fields here
    const kidneyConsented = organs[OrganCodeValue.Kidney] || false;
    
    let exdReasonOther: string|undefined = undefined;
    let exdReasonOtherTravel: string|undefined = undefined;
    let exdReasonOtherIncreasedRisk: string|undefined = undefined;
    
    const filteredCodes = this.filterNullValues(this.editState.exd_reason_codes!);
    const exdReasonCodes = filteredCodes.length > 0 ? filteredCodes : null;

    if (exdReasonCodes && exdReasonCodes.includes(ExceptionalDistributionCodeValues.Other)) {
      exdReasonOther = this.editState.exd_reason_details_other;
    }
    if (exdReasonCodes && exdReasonCodes.includes(ExceptionalDistributionCodeValues.OtherTravel)) {
      exdReasonOtherTravel = this.editState.exd_reason_details_travel;
    }
    if (exdReasonCodes && exdReasonCodes.includes(ExceptionalDistributionCodeValues.OtherIncreasedRisk)) {
      exdReasonOtherIncreasedRisk = this.editState.exd_reason_details_transmission;
    }

    // Take the string value of resultsReceived from editState and convert it to boolean for sending to API
    let results_received = null;
    if (this.editState.resultsReceived === '1') {
      results_received = true;
    } else if (this.editState.resultsReceived === '0') {
      results_received = false;
    }

    // Take the string value of resultsReceived from editState and convert it to boolean for sending to API
    let results_reported = null;
    if (this.editState.resultsReported === '1') {
      results_reported = true;
    } else if (this.editState.resultsReported === '0') {
      results_reported = false;
    }

    return {
      death: {
        neurological_death: this.editState.donorType,
      },
      consented_for_research: this.editState.consentedForResearch,
      indicators: {
        exceptional_distribution: this.editState.exceptional_distribution,
        exd_reason_codes: exdReasonCodes,
        exd_reason_details_other: exdReasonOther || null,
        exd_reason_details_travel: exdReasonOtherTravel || null,
        exd_reason_details_transmission: exdReasonOtherIncreasedRisk || null,
        ecd_donor: this.editState.ecd,
        double_kidney: this.editState.doubleKidney,
        ventilator_status: this.editState.ventilatorStatus || null,
        ventilator_type: this.editState.ventilatorType || null,
        ventilator_type_other: this.editState.ventilatorTypeOther,
        post_release: this.editState.postRelease,
        post_release_reason: this.editState.postReleaseReason,
        results_received: results_received,
        results_received_date: this.editState.resultsReceivedDate ? this.sanitizeDateTimeApi(this.editState.resultsReceivedDate, this.editState.resultsReceivedTime) : null,
        results_reported: results_reported,
        results_reason: this.editState.resultsReason,
        results_reported_date: this.editState.resultsReportedDate ? this.sanitizeDateTimeApi(this.editState.resultsReportedDate, this.editState.resultsReportedTime) : null,
        kdpi: this.editState.kdpi && this.editState.kdpi.toString() === "-" ? null : this.editState.kdpi,
        kdpi_datetime: this.editState.kdpiDate || this.editState.kdpiTime ? this.sanitizeDateTimeApi(this.editState.kdpiDate, this.editState.kdpiTime) : null
      },
      organ_consents: this.extractOrganConsents()
    };
  }

  /**
   * Return a hash for API with consented organ values
   *
   * @returns {DeceasedDonorOrgan[]} the donors consented organ values
   */
  public extractOrganConsents(): DeceasedDonorOrgan[] {
    const singularOrgans = this.buildSingleTypeOrgans;
    return singularOrgans.map(organ => {
      return {
        organ_code: organ.code,
        consented: this.editState.consentedOrgans![organ.code] || false,
        consented_date: this.sanitizeDateApi(this.editState.consentedDates![organ.code])
      };
    });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveDonationInformation as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveDonationInformation as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'donationInformation');
    // Generate payload based on current edit state
    const donorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store.dispatch('deceasedDonors/saveDonor', { clientId: this.clientId, donor: donorPatch }).then((success: SaveResult) => {
      // If successful, update the current donor and show success notification
      this.$store.commit('deceasedDonors/set', success.responseData.donor);
      saveProvider.registerSaveResult(success);
      this.initializeForm();
      // Request donor page reload data that might be affected by this form changing
      this.$emit('reload');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'death.neurological_death'                  : 'donor-type',
    'indicators.ventilator_status'              : 'donationInformation-ventilator_status',
    'indicators.ventilator_type'                : 'donationInformation-ventilator_type',
    'indicators.ventilator_type_other'          : 'donationInformation-ventilator_type_other',
    'indicators.exceptional_distribution'       : 'donationInformation-exceptional_distribution',
    'indicators.exd_reason_codes'               : 'donationInformation-exd_reason_codes',
    'indicators.exd_reason_codes.3'             : 'donationInformation-exd_reason_codes',
    'indicators.exd_reason_codes.4'             : 'donationInformation-exd_reason_codes',
    'indicators.exd_reason_details_other'       : 'donationInformation-exd_reason_details_other',
    'indicators.exd_reason_details_travel'      : 'donationInformation-exd_reason_details_travel',
    'indicators.exd_reason_details_transmission': 'donationInformation-exd_reason_details_transmission',
    'indicators.post_release'                   : 'donationInformation-post_release',
    'indicators.post_release_reason'            : 'donationInformation-post_release_reason',
    'indicators.results_reason'                 : 'donationInformation-results_reason',
    'indicators.results_reported'               : 'donationInformation-results_reported',
    'indicators.kdpi'                           : 'donationInformation-kdpi',
    'indicators.kdpi_datetime'                  : ['donationInformation-kdpi_date', 'donationInformation-kdpi_time'],
    'consented_date'                            : 'donationInformation-consented_date',
    'indicators.ecd'                            : 'donationInformation-ecd',
    'indicators.double_kidney'                  : 'donationInformation-double-kidney',
    'indicators.results_received_date'          : 'donationInformation-results_received_date',
    'indicators.results_received_time'          : 'donationInformation-results_received_time',
    'indicators.results_reported_date'          : 'donationInformation-results_reported_date',
    'indicators.results_reported_time'          : 'donationInformation-results_reported_time',
    'organ_consents[1.0].consented_date'        : 'donationInformation-1-consented_date',
    'organ_consents[2.0].consented_date'        : 'donationInformation-2-consented_date',
    'organ_consents[3.0].consented_date'        : 'donationInformation-3-consented_date',
    'organ_consents[4.0].consented_date'        : 'donationInformation-4-consented_date',
    'organ_consents[6.0].consented_date'        : 'donationInformation-6-consented_date',
    'organ_consents[6.5].consented_date'        : 'donationInformation-6.5-consented_date',
    'organ_consents[7.0].consented_date'        : 'donationInformation-7-consented_date',
    'organ_consents[7.5].consented_date'        : 'donationInformation-7.5-consented_date',
  }
}
