import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { FeatureState } from '@/store/features/types';

export const actions: ActionTree<FeatureState, RootState> = {
  load({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Prepare request
      const url = APIRoute(EP.features.index);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process feature portion
        const featuresData = response.data.features;
        commit('set', { type: 'features', data: featuresData });
        // Process configuration portion
        const configurationData = response.data.configuration;
        commit('set', { type: 'configuration', data: configurationData });
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  }
};
