
import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Allocation } from '@/store/allocations/types';


@Component({
  components: {
    SubSection,
    ModalSection,
  }
})
export default class AllocationAuditMessages extends Vue {
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;

  // Show modal
  public showModal(): void {
    const targetModal = this.$refs.allocationAuditMessagesModal as ModalSection;
    if (!targetModal) return;

    targetModal.toggleModal();
  }

  // Which audit warnings to show
  get filteredWarningMessages(): string[] {
    if (!this.allocation) return [];

    // NOTE: here is where to do any translation, pattern-matching, or filtering
    const warnings = this.allocation.messages.warnings || [];
    return warnings;
  }

  // Which audit errors to show
  get filteredErrorMessages(): string[] {
    if (!this.allocation) return [];

    // NOTE: here is where to do any translation, pattern-matching, or filtering
    const errors = this.allocation.messages.errors || [];
    return errors;
  }

}
