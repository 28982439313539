
import {Component} from "vue-property-decorator";

import SearchBar from "@/components/search/SearchBar.vue";
import PageTop from "@/components/shared/PageTop.vue";
import {mixins} from "vue-class-component";
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import WarningBanner from "@/components/shared/WarningBanner.vue";
import RecipientsSearchResults from "@/components/search/RecipientsSearchResults.vue";
import DeceasedDonorsSearchResults from "@/components/search/DeceasedDonorsSearchResults.vue";

@Component({
  components: {
    PageTop,
    SearchBar,
    RecipientsSearchResults,
    DeceasedDonorsSearchResults,
    FullPageListLayout,
    WarningBanner
  }
})
export default class SearchIndex extends mixins(UrlUtilsMixin) {
  loading = false;

  get searchedTerm() {
    return this.getUrlQueryValue('term') || '';
  }

  get searchedType() {
    return this.getUrlQueryValue('type') || 'recipients';
  }
}
