
import { State, Getter } from 'vuex-class';
import { Component, Vue, } from 'vue-property-decorator';
import UserList from '@/components/login/UserList.vue';
import { mixins } from 'vue-class-component';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import LoginContainer from '@/components/shared/LoginContainer.vue';

@Component({
  components: {
    LoginContainer,
    UserList
  },
})
export default class FusionRequest extends mixins(UrlUtilsMixin) {
  @State(state => state.pageState.currentPage.login) private editState!: any;

  userToken: string|undefined = undefined;
  loading = true;

  storeToken(): void {
    const fromUrl = this.getUrlQueryValue('access_token');
    const fromStorage = localStorage.getItem('user_token');
    if (fromUrl) {
      localStorage.setItem('user_token', fromUrl);
      this.userToken = fromUrl;
    } else if (fromStorage) {
      this.userToken = fromStorage;
    }
  }

  private mounted(): void {
    this.$store.commit('setPageTitle', 'Log in');
    // get access token
    Promise.all([
      this.storeToken()
    ]).finally(() => {
      this.loading = false;
    });
  }
}

