import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { LabsState } from '@/store/labs/types';
import { actions } from '@/store/labs/actions';
import { mutations } from '@/store/labs/mutations';
import { getters } from '@/store/labs/getters';

const state: LabsState = {
  virologies: [],
  hlaAntibodies: []
};

const namespaced = true;

export const labs: Module<LabsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
