
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { Recipient, RecipientMeasurement } from '@/store/recipients/types';
import { RecipientJourney, RecipientDonorAcceptability, AssessmentState, JourneyStage, ReferralDecisionState, JourneyStatus, HSP_PROGRAM_INDICATOR_ORGANS, HSH_PROGRAM_INDICATOR_ORGANS, IposProgram } from '@/store/recipientJourney/types';
import { calculateAge, titleCase } from '@/utils';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NewOrganLink from '@/components/shared/NewOrganLink.vue';
import { DonorAcceptabilityDefaults } from '@/store/hospitals/types';
import { PancreasWholeDetails } from '@/store/organSpecificDetails/types';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";

@Component({
  components: {
    OrganIcon,
    SubSection,
    NewOrganLink,
  }
})
export default class RecipientSummaryOop extends mixins(DateUtilsMixin, TranslationUtilsMixin) {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney|undefined;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string;
  @Getter('recipientDisplayName', { namespace: 'recipients' }) recipientDisplayName!: string;
  @Getter('getJourneysByStatus', { namespace: 'recipients' }) filterJourneys!: (status: string) => RecipientJourney[];
  @Getter('getJourneyStatusDisplayValue', { namespace: 'recipients' }) journeyStatusDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('getJourneyStageDisplayValue', { namespace: 'recipients' }) journeyStageDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('organName', { namespace: 'lookups'}) organName!: (organCode?: number) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyName!: (organCode?: number, journeyId?: string) => string;
  @Getter('ctrIposHeart', { namespace: 'features' }) private ctrIposHeart!: boolean;

  get oop_recipient(): boolean {
    return this.recipient.oop_recipient ? true : false;
  }

  private showMultiOrgan(journey: RecipientJourney): any {
    // oop_multi_organ will only have a value if it's combination
    const combination = journey && journey.oop_info ? journey.oop_info.oop_multi_organ : null;
    // get related journeys for single / clustered journeys
    const related_journeys = journey.related_journeys;
    if (combination) {
      return this.$t(combination);
    } else {
      const journeys = related_journeys || [];
      return journeys.length > 0 ? this.$t('clustered') : this.$t('single');
    }
  }

  /**
   * Return the CTR ID of the current recipient.
   *
   * @returns {string}
   */
  get ctrIdDisplayValue(){
    return this.recipient?.patient_profile?.ctr?.national_recipient_id || '-';
  }

  /**
   * Return all active journeys
   *
   * @returns {RecipientJourney[]} all active journeys
   */
  get activeJourneys(): any {
    return this.filterJourneys('active');
  }

  /**
   * Return all postTransplant journeys
   *
   * @returns {RecipientJourney[]} all postTransplant journeys
   */
  get postTransplantJourneys(): any {
    return this.filterJourneys('post-transplant');
  }

  /**
   * Return all complete journeys
   *
   * @returns {RecipientJourney[]} all complete journeys
   */
  get completedJourneys(): any {
    return this.filterJourneys('complete');
  }

  /**
   * Return all oop journeys
   *
   * @returns {RecipientJourney[]} all oop journeys
   */
  get oopJourneys(): any {
    return this.filterJourneys('oop');
  }

  /**
   * Get a number for the age of the Recipient
   *
   * Calculates the age of the Recipient using the value of Date of Birth and Date of Death (if exists)
   *
   * @returns {number|null} Recipient's age or null
   */

  get calculatedAge() {
    const recipientDoB = this.recipient.patient_profile?.birth?.date || null;
    const recipientDoD =
      this.recipient.patient_profile && this.recipient.death
        ? this.recipient.death.death_date
        : null;
    // I have a birth and death date
    if (recipientDoB && recipientDoD) {
      return calculateAge(recipientDoB, this.parseDateUi(recipientDoD));
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    // I have age only
    if (this.recipient.patient_profile && this.recipient.patient_profile.age) {
      return this.recipient.patient_profile.age;
    }
    return null;
  }

  get getCumulativeCPRA() {
    const cumulative_cpra = this.recipient.diagnostics?.hla?.cumulative_cpra;
    return cumulative_cpra != null ? cumulative_cpra : '-' ;
  }

  /**
   * Return journey stage display value
   */
  get stageDisplayValue() {
    return (journey: RecipientJourney): string|undefined => {
      // NOTE: delegate all logic for deriving Phase to vue-x getter to ensure consistency
      return this.journeyStageDisplayValue(journey);
    };
  }

  /**
   * Return journey's phase date
   *
   */
  get phaseDate() {
    return (journey: RecipientJourney): string|undefined => {
      if(!journey) return '';
      let phaseDate;
      const journeyStage = journey.stage;
      const journeyState =journey.state;
      switch(journeyStage) {
        case JourneyStage.Waitlist:
          phaseDate = journey.stage_attributes?.waitlist?.factors?.listing_date;
        break;
        default:
        break;
      }
      return phaseDate ? this.parseDate(phaseDate) : '';
    };
  }

  /**
   * Return journey's status date
   *
   */
  get statusDate() {
    return (journey: RecipientJourney, status: string): string|undefined => {
      if(!status) return '';
      let statusDate;
      switch(status) {
        case JourneyStatus.TransplantInProgress:
          statusDate = journey.stage_attributes?.waitlist?.factors?.transplant_in_progress_date;
          break;
        case JourneyStatus.MedicalHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_medical_date;
          break;
        case JourneyStatus.SerumHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_serum_hla_antibody_date;
          break;
        case JourneyStatus.MedicalSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_medical_date;
          break;
        case JourneyStatus.NaMELDSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_liver_sodium_meld_date;
          break;
        case JourneyStatus.HCCSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_liver_hcc_date;
          break;
        case JourneyStatus.HeartSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_heart_date;
          break;
        case JourneyStatus.ClusterHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_cluster_date;
          break;
        case JourneyStatus.ClusterSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_cluster_date;
          break;
        case JourneyStatus.IncompleteClusterHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_incomplete_cluster_date;
          break;
        case JourneyStatus.PartialClusterTransplantHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_partial_cluster_transplant_date;
          break;
        default:
          statusDate = '';
          break;
      }
      return statusDate ? this.parseDate(statusDate) : '';
    };
  }

  get pancreas_type() {
    return (journeyId: string):any =>{
      const journey = this.recipient.journeys?.filter((journey: RecipientJourney) => {
        return journey._id?.$oid == journeyId;
      });
      if (!journey) return false;
      let pancreas_type;
      journey.forEach((journey) => {
        if (journey.organ_code == OrganCodeValue.PancreasWhole){
          const organSpecificDetails: PancreasWholeDetails|undefined = journey?.organ_specific_details;
          pancreas_type = organSpecificDetails?.pancreas_type;
        }
      });
      return pancreas_type;
    };
  }

  // Styling for cells
  public getJourneyStyle(journey: RecipientJourney): string {
    let style = [];
    const organId = this.$route.params.organ_id;
    if(organId == journey._id?.$oid) style.push("active");
    if(journey.stage == JourneyStage.Waitlist && journey.state == "active") style.push("allocation-eligible");
    if(journey.stage == JourneyStage.Waitlist && journey.state == "inactive") style.push("allocation-not-eligible");
    return style.join(" ");
  }

  public isHsxEligible(journey: RecipientJourney): boolean {
    return journey?.recipient_programs?.hsx_eligible || false;
  }

  // Translation key for superscript inter-provincial organ sharing indicator
  public isHsxEligibleValue(journey: RecipientJourney): string|undefined {
    let result: string|undefined = undefined;
    const journeyOrgan = journey.organ_code;
    const hsxValue = journey?.recipient_programs?.hsx_eligible || false;
    if (!hsxValue || !journeyOrgan) return result;

    // The "HSP" program indicator applies to Kidneys (see ATU-8498)
    if (HSP_PROGRAM_INDICATOR_ORGANS.includes(journeyOrgan)) {
      // Changed Kidney HSP notation to IPOS notation through locale (see TPGLI-7066)
      result = IposProgram.HSP;
    }

    // The "HSH" program indicator applies to Hearts (see ATU-8498)
    // NOTE: this indicator is only applicable if "CTR IPOS Heart" feature is enabled
    if (HSH_PROGRAM_INDICATOR_ORGANS.includes(journeyOrgan) && this.ctrIposHeart) {
      result = IposProgram.HSH;
    }

    return result;
  }

  public medicalStatusCode(journey: RecipientJourney) :string {
    return journey?.stage_attributes?.waitlist?.factors?.medical_status_code || '-';
  }

  /**
   * Return the hospital MRN for the given journey, if no journey exists then returns -
   *
   * @returns {string}
   */
  public hospitalMrn(journey: RecipientJourney)  :string {
    return journey?.transplant_program?.transplant_hospital_mrn || '-';
  }

  /**
   * Return the transplant program abbreviation for the given journey, if no journey exists then returns -
   *
   * @returns {string}
   */
  public transplantProgramAbbreviation(journey: RecipientJourney)  :string {
    return journey?.transplant_program?.transplant_hospital_abbreviation || '-';
  }

  /**
   * Return journey status display value
   */
  get statusDisplayValue() {
    return (journey: RecipientJourney): string|string[]|undefined =>{
      const status = this.journeyStatusDisplayValue(journey);
      return status?  status.split(',') : '--';
    };
  }

  public parseDate(datetime: string) {
    return this.parseDisplayDateUi(datetime);
  }

  get isOrganPage(): boolean {
    return this.$route.params.organ_id ? true : false;
  }

  get getRecipient(): any|undefined {
    const age = this.recipient.patient_profile ? this.recipient.patient_profile.age : '-';
    const diagnostics = this.recipient.diagnostics || {};
    return {
      age: age
    };
  }

  get getMeasurements(): any {
    const emptyDefaultMeasurement = {
      height: '-',
      weight: '-',
      bmi: '-',
    };
    const measurements = this.recipient.measurements || [];
    if (measurements.length == 0) { return emptyDefaultMeasurement; }
    const measurement = measurements[0];
    return {
      height: this.recipient.oop_recipient ? '-' : measurement.height || '-',
      weight: this.recipient.oop_recipient ? '-' : measurement.weight || '-',
      bmi: this.recipient.oop_recipient ? '-' : measurement.bmi || '-',
    };
  }

  get getDonorAcceptability(): any {
    const donor_acceptability = this.journey && this.journey.donor_acceptability || undefined;
    if (!donor_acceptability) { return undefined; }
    return {
      min_weight: donor_acceptability.min_weight || '-',
      max_weight: donor_acceptability.max_weight || '-',
      min_height: donor_acceptability.min_height || '-',
      max_height: donor_acceptability.max_height || '-',
    };
  }
}
