
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { CauseOfDeathCategoryCodes } from '@/store/lookups/types';
import { Recipient, DeathRecipient } from '@/store/recipients/types';
import { GenericCodeValue, NumericCodeValue } from '@/store/types';
import { EP } from '@/api-endpoints';
import { SystemModules } from '@/store/features/types';

interface RecipientDeathPageState {
  causeOfDeathCategory?: string|null;
  causeOfDeathType?: number|null;
  causeOfDeathTypeOther?: string|null;
  deathDate?: string|null;
  estimated?: boolean|null;
  related_to_waittime?: boolean|null;
  comment?: string;
}

@Component({
  components: {
    CardSection,
    SubSection,
    TextInput,
    DateInput,
    TimeInput,
    SelectInput,
    CheckboxInput,
    TextAreaInput,
    BooleanRadioInput,
  }
})
export default class RecipientDeath extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.recipients.selectedDeath) private death!: DeathRecipient;
  @State(state => state.pageState.currentPage.recipientDeath) editState!: RecipientDeathPageState;
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryLookup!: any[];

  @Getter('clientId', { namespace: 'recipients' }) clientId!: string|undefined;
  @Getter('isDeceased', { namespace: 'recipients' }) isDeceased!: boolean;
  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathType!: (deathCode: string | undefined) => NumericCodeValue[] | undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['cause_of_death_category'];

  /**
   * Returns an array of options for cause of death type
   *
   *
   * @returns {NumericCodeValue[]|[]} cause of death type lookups
   */
  get causeOfDeathTypeLookup(): NumericCodeValue[] {
    const deathCode = this.editState.causeOfDeathCategory;
    if (!deathCode) {
      return [];
    }
    // Fetch appropriate options
    return this.causeOfDeathType(deathCode) || [];
  }

  // Is the recipient death extended details system module enabled?
  get isRecipientDeathExtendedDetailsEnabled(): boolean {
    return this.moduleEnabled(SystemModules.RECIPIENT_DEATH_EXTENDED_DETAILS);
  }

  // Is the recipient death related to waittime field enabled?
  get isRecipientRelatedToWaittimeEnabled(): boolean {
    return this.moduleEnabled(SystemModules.RECIPIENT_DEATH_RELATED_TO_WAITTIME);
  }

  // Checks when to disable the Cause of Death Type field
  get causeOfDeathTypeDisabled(): boolean {
    if (!this.editState.causeOfDeathCategory || this.editState.causeOfDeathCategory == '' || this.showCauseOfDeathTypeOther) {
      Vue.set(this.editState, 'causeOfDeathType', null);
      return true;
    } else {
      return false;
    }
  }

  // Checks when to show the Cause of Death Type Other field
  get showCauseOfDeathTypeOther(): boolean {
    const deathCode = this.editState.causeOfDeathCategory;

    if (!deathCode) {
      return false;
    }

    if (!this.causeOfDeathCategoryLookup) {
      return false;
    }

    const deathCategory = this.causeOfDeathCategoryLookup.find((deathCat: any) => {
      return deathCode == deathCat.code;
    });

    if (!deathCategory) {
      return false;
    }

    return deathCategory!.other_selected || false;
  }

  // Changes the undefined text to Unknown when cause of death category is unknown
  get changeDeathTypeUndefinedText(): string {
   if (this.editState.causeOfDeathCategory == CauseOfDeathCategoryCodes.Unknown) {
     return this.$t('unknown').toString();
   } else {
     return this.$t('select').toString();
   }
  }

  // clears the cause of death type and cause of death type other fields when a change is made to cause of death category
  private clearCauseOfDeathType(): void {
    Vue.set(this.editState, 'causeOfDeathType', null);
    Vue.set(this.editState, 'causeOfDeathTypeOther', null);
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.initializeForm();
    // check if user has access to death validations (hla will not)
    if (this.checkAllowed(EP.validations.death_edit_validations, "GET")) {
      this.$store.dispatch('validations/loadEditDeath', { clientId: this.clientId });
    }
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recipientDeath#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'recipientDeath');
  }

  /**
   * Populates the Recipient Death form state with data from the selected Recipient.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'recipientDeath',
      value: this.buildRecipientDeathPageState(this.recipient)
    });
  }

  /**
   * Generates Recipient Death form state based on a Recipient Death document
   *
   * @param recipient recipeint document provided by API
   * @returns {RecipientDeathPageState} page state for Recipient Death
   */
  public buildRecipientDeathPageState(recipient?: Recipient): RecipientDeathPageState {
    if (!recipient) { return {}; }

    const death = this.death || recipient.death || {};
    // Return parameters extracted from data document based on structure of form state interface
    return {
      causeOfDeathCategory: death.cause_category_code || null,
      causeOfDeathType: death.cause_type_code || null,
      causeOfDeathTypeOther: death.cause_type_other || null,
      deathDate: this.parseDateUi(death.death_date) || null,
      estimated: death.estimated_death_date || null,
      comment: death.comments,
      related_to_waittime: death.related_to_waittime
    };
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveRecipientDeath as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Recipient Death, dispatches a save action, handle errors and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveRecipientDeath as unknown as SaveProvider;
    // Clear validation errors
    this.$emit('clear');
    // Report to parent that saving has began
    this.$emit('save', 'recipientDeath');
    // Generate payload based on current edit state
    const deathPayload = {
      recipientId: this.clientId,
      death: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('recipients/saveRecipientDeath', deathPayload).then((success: SaveResult) => {
      // If successful, update death information and reload full recipient
      // Note: this is necessary, because the recipient-level and journey-level states are affected
      this.$store.commit('recipients/setRecipientDeath', success.responseData.death);
      this.$store.dispatch('recipients/get', this.clientId).then(() => {
        // Clear form for entry of a new measurement
        this.initializeForm();
        // Show success notification
        saveProvider.registerSaveResult(success);
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState) {
      return {};
    }
    return this.extractRecipientDeathPatch(this.editState);
  }

  // Private methods

  /**
   * Gets form edit state changes as a Recipient Death patch
   *
   * Changes to Recipient Death
   *
   * @param recipientDeath form edit state containing changes
   * @returns {DeathRecipient} patch object containing field changes
   */
  private extractRecipientDeathPatch(recipientDeath: RecipientDeathPageState): DeathRecipient {
    const result = {
      cause_category_code: recipientDeath.causeOfDeathCategory,
      cause_type_code: recipientDeath.causeOfDeathType,
      cause_type_other: recipientDeath.causeOfDeathTypeOther,
      death_date: this.sanitizeDateApi(recipientDeath.deathDate),
      estimated_death_date: recipientDeath.estimated,
      comments: recipientDeath.comment,
      related_to_waittime: recipientDeath.related_to_waittime
    };
    return result;
  }

  /**
   * Gets a string used to populate the confirmation alert dialog when adding saving recipient death
   *
   * @returns {string} text for confirmation property of card section
  */
  get confirmationText(): string|undefined {
    if (this.isDeceased) {
      // no dialog box if recipient is already dead
      return undefined;
    } else {
      if (this.editState && this.editState.causeOfDeathCategory && this.editState.deathDate) {
        // only show dialog box when certain fields have been filled out
        const death_date = this.correctTimeOffset(this.editState.deathDate);
        return this.$t('confirmation', { death_date: this.parseDisplayDateUi(death_date) }).toString();
      }
    }
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'cause_category_code'  : 'recipientDeath-death_category',
    'cause_type_code'      : 'recipientDeath-death_type',
    'cause_type_other'     : 'recipientDeath-death_type_other',
    'death_date'           : 'recipientDeath-death_date',
    'estimated_death_date' : 'recipientDeath-estimated',
    'related_to_waittime'  : 'recipientDeath-related_to_waittime',
    'comments'             : 'recipientDeath-comments',
  }
}
