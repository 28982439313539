
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { SaveResult } from '@/types';
import { TableConfig } from '@/types';
import { State, Getter } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import TableCheckboxInput from '@/components/shared/TableCheckboxInput.vue';
import ConfirmRecipientConsentModal from '@/components/deceasedDonors/ConfirmRecipientConsentModal.vue';

interface ExdConfirmationRow {
  id: string;
  organ: string;
  program: string;
  physician: string;
  dateAccepted: string;
  accepted: string;
  justificationReason: string;
  recipientLinkText: string;
  recipientClientId: string;
  confirmationProvided: string;
  confirmationProvidedTime?: string;
  selectable: boolean;
  expired: boolean;
}

interface ExdConfirmationState {
  selection: { [key: string]: boolean };
}

@Component({
  components: {
    SubSection,
    CardSection,
    SaveToolbar,
    TableCheckboxInput,
    ConfirmRecipientConsentModal,
  }
})
export default class ExceptionalDistributionDisposition extends mixins(DateUtilsMixin) {
  @State(state => state.deceasedDonors.exdConfirmations) private exdConfirmations!: any;
  @State(state => state.pageState.currentPage.exdConfirmations) editState!: ExdConfirmationState|null;
  @State(state => state.lookups.exceptional_distribution_acceptance_reasons) private exdAcceptanceReasons!: GenericCodeValue[];

  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: number;
  @Getter('isSurgicalUser', { namespace: 'users' }) private isSurgicalUser!: boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;

  private lookupsToLoad = [
    'exceptional_distribution_acceptance_reasons',
  ];

  private unableToLoad = false;

  //Table configuration
  get exdConfirmationsTableConfig(): TableConfig {
    return {
      data: this.exdConfirmationRows,
      columns: [
        { label: this.$t('select').toString(), field: 'selected', width: '5%' },
        { label: this.$t('organs').toString(), field: 'organ' },
        { label: this.$t('program').toString(), field: 'program' },
        { label: this.$t('physician').toString(), field: 'physician' },
        { label: this.$t('date_time_acceptance').toString(), field: 'dateAccepted' },
        { label: this.$t('acceptance').toString(), field: 'accepted' },
        { label: this.$t('acceptance_reason').toString(), field: 'justificationReason' },
        { label: this.$t('recipient').toString(), field: 'recipientLinkText' },
        { label: this.$t('confirmation').toString(), field: 'confirmationProvided' },
        { label: this.$t('save').toString(), field: 'save' },
      ],
      empty: this.emptyMessage,
    };
  }

  get emptyMessage(): string {
    if (this.unableToLoad) {
      return this.$t('no_permission_to_view').toString();
    } else if (!this.exdConfirmations) {
      return this.$t('loading').toString();
    } else {
      return this.$t('no_exceptional_distribution_acceptances').toString();
    }
  }

  // Map data loaded from API to array of UI rows
  get exdConfirmationRows(): ExdConfirmationRow[] {
    const collection = this.exdConfirmations || [];
    const rows = collection.map((item: any): ExdConfirmationRow => {
      const organName = this.getOrganSpecificationName(item.organ_code, item.organ_specification_code);
      const physician = (item?.organ_offer?.exceptional_distribution?.accepted_by_physician_first_name || item?.organ_offer?.exceptional_distribution?.accepted_by_physician_first_name) ? (`${item?.organ_offer?.exceptional_distribution?.accepted_by_physician_first_name} ${item?.organ_offer?.exceptional_distribution?.accepted_by_physician_last_name}`).trim() : '-';
      const accepted = item?.organ_offer?.exceptional_distribution?.accepted_date ? this.$t('yes').toString() : this.$t('no').toString();
      const reasonCode = item?.organ_offer?.exceptional_distribution?.accepted_reason_code;
      const reasonLookup = (this.exdAcceptanceReasons || []).find((lookup: GenericCodeValue) => {
        return lookup.code === reasonCode;
      });
      const reasonValue = reasonLookup?.other_selected ? item?.organ_offer?.exceptional_distribution?.accepted_reason_other : reasonLookup?.value;
      const justificationReason = reasonValue || this.$t('unknown').toString();
      const recipientLinkText = item?.organ_offer?.recipient_client_id ? `${item?.organ_offer?.recipient_client_id} - ${item?.organ_offer?.recipient_first_name} ${item?.organ_offer?.recipient_last_name}` : '-';
      const confirmedBy = (item?.organ_offer?.exceptional_distribution?.confirmed_by_physician_first_name || item?.organ_offer?.exceptional_distribution?.confirmed_by_physician_first_name) ? (`${item?.organ_offer?.exceptional_distribution?.confirmed_by_physician_first_name} ${item?.organ_offer?.exceptional_distribution?.confirmed_by_physician_last_name}`).trim() : this.$t('unknown').toString();
      const confirmationProvided = item?.organ_offer?.exceptional_distribution?.confirmed_date ? `${confirmedBy} ${this.parseDisplayDateUiFromDateTime(item?.organ_offer?.exceptional_distribution?.confirmed_date)}` : undefined;
      const confirmationProvidedTime = this.parseTimeUiFromDateTime(item?.organ_offer?.exceptional_distribution.confirmed_date);
      return {
        id: item?._id?.$oid,
        organ: this.$t(organName).toString(),
        program: item.organ_recovery[0].destination_program_identifier,
        physician,
        dateAccepted: this.parseFormattedDateTimeUiFromDateTime(item?.organ_offer?.exceptional_distribution?.accepted_date) || '-',
        accepted,
        justificationReason,
        recipientLinkText,
        recipientClientId: item?.organ_offer?.recipient_client_id,
        confirmationProvided: confirmationProvided || '-',
        confirmationProvidedTime,
        selectable: !confirmationProvided,
        expired: item?.organ_offer?.exceptional_distribution?.expired || false,
      };
    });
    return rows;
  }

  // Load donor Exceptional Distribution confirmations
  private loaded(): void {
    this.$emit('loaded', 'exdConfirmationForm');
    this.$store.dispatch('deceasedDonors/loadExdConfirmations', this.donorId).then(() => {
      this.initializeForm();
    }).catch(() => {
      this.unableToLoad = true;
    });
  }

  // Reload when confirmations have been updated
  public reload(): void {
    this.loaded();
  }

  // Commit UI form interface data to edit state based on loaded API data
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'exdConfirmations',
      value: this.buildExdConfirmationState()
    });
  }

  // Map API data to UI form interface
  private buildExdConfirmationState(): ExdConfirmationState|null {
    const collection = this.exdConfirmations || [];
    if (!collection) return null;

    const form: ExdConfirmationState = {
      selection: {},
    };
    collection.forEach((item: any) => {
      const rowId = item?.organ_offer.allocation_id;
      const selected = !!item?.organ_offer?.exceptional_distribution?.accepted;
      form.selection[rowId] = selected;
    });
    return form;
  }

  // Grey out rows that have been expired due to post-release
  get rowStyleClass(): (row: any) => string {
    return (row: any): string => {
      const expired = row.expired;
      return expired ? 'expired-row tr-link' : 'tr-link';
    };
  }

  // Array containing all selected IDs
  get selectedIds(): string[] {
    if (!this.editState) return [];

    const selectionCache = this.editState.selection || {};
    const selectedIds = Object.keys(selectionCache).filter((rowId: string) => {
      return !!selectionCache[rowId];
    });
    return selectedIds;
  }

  // Whether or not the user can open the ExD confirmation modal
  get canConfirmExdAcceptance(): boolean {
    if (!this.isSurgicalUser || this.unableToLoad) return false;

    const numSelected = this.selectedIds.length;
    return numSelected === 1;
  }

  // Open the ExD confirmation modal
  private confirmExdAcceptance(): void {
    const selectedIds = this.selectedIds || [];
    const firstSelectedId = selectedIds.length > 0 ? selectedIds[0] : null;
    if (firstSelectedId === null) return;

    const modal = this.$refs.confirmRecipientConsentModal as ConfirmRecipientConsentModal;
    modal.initialize(firstSelectedId);
  }

  // Whether or not the user can download the printable ExD PDF forms
  get canPrintSelectedForms(): boolean {
    if (this.unableToLoad) return false;

    const numSelected = this.selectedIds.length;
    return numSelected >= 1;
  }

  // Generate API request payload for one form
  private extractOneFormPayload(selectedId: string): any {
    // Check underlying source data loaded from the API
    const collection = this.exdConfirmations || [];

    // Find the underlying confirmation source item
    const exdConfirmation = collection.find((item: any) => {
      return selectedId === item?._id.$oid;
    });

    // Include the confirmation item ID in the request
    const exdForms: any[] = [{
      id: exdConfirmation?._id?.$oid,
    }];

    // Return request payload
    return { organ_donations: exdForms };
  }

  // Generate API request payload for all selected forms
  private extractSelectedFormsPayload(): any {
    // Check underlying source data loaded from the API
    const collection = this.exdConfirmations || [];

    // Build array of identifiers to request the forms
    let exdForms: any[] = [];

    // Iterate through rows selected in the edit state
    const selectedIds = this.selectedIds || [];
    selectedIds.forEach((selectedId: string) => {
      // Find the underlying confirmation source item
      const exdConfirmation = collection.find((item: any) => {
        return selectedId === item?._id.$oid;
      });
      // Include the confirmation item ID in the request
      exdForms.push({
        id: exdConfirmation?._id?.$oid,
      });
    });

    // Return request payload
    return { organ_donations: exdForms };
  }

  // Downloads the PDF or Zip file with the ExD Notice(s)
  private downloadForm(response: SaveResult) {
    const fileName = response.responseData.download_url;
    // Is there actually a filename
    if (!!fileName) {
      // Create a link
      const link = document.createElement('a');
      link.href = fileName;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      // Then click it forcing a save/open dialogue
      link.click();
    }
  }

  // Print one row's form by clicking the button in the row
  private printOneForm(selectedId: string): void {
    // Use save toolbar notification pattern
    const saveToolbar = this.$refs.printExdForms as SaveToolbar;
    saveToolbar.startSaving();

    // Generate payload based on all selected rows
    const params = {
      donorId: this.donorId,
      payload: this.extractOneFormPayload(selectedId),
    };

    // Dispatch vue-x action to begin the API activity
    this.$store.dispatch('deceasedDonors/printExdForms', params).then((result: SaveResult) => {
      this.downloadForm(result);
      saveToolbar.stopSaving(result);
    }).catch((result: SaveResult) => {
      // Handle error
      saveToolbar.stopSaving(result);
    });
  }

  // Print all selected forms by clicking the Save ExD Forms button
  private printSelectedForms(): void {
    // Use save toolbar notification pattern
    const saveToolbar = this.$refs.printExdForms as SaveToolbar;
    saveToolbar.startSaving();

    // Generate payload based on all selected rows
    const params = {
      donorId: this.donorId,
      payload: this.extractSelectedFormsPayload(),
    };

    // Dispatch vue-x action to begin the API activity
    this.$store.dispatch('deceasedDonors/printExdForms', params).then((result: SaveResult) => {
      this.downloadForm(result);
      saveToolbar.stopSaving(result);
    }).catch((result: SaveResult) => {
      // Handle error
      saveToolbar.stopSaving(result);
    });
  }
}
