
import { urlParams } from '@/utils';
import { SaveResult } from '@/types';
import { State, Getter }  from 'vuex-class';
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { StateLivingDonorsList, ListLivingDonor } from '@/store/livingDonors/types';
import { ListDonor, StateDonorList } from '@/store/deceasedDonors/types';

interface PerformSpecifiedVxmState {
  recipientId: string|null;
  donorId: string|null;
  donorType: string;
}

// Constants related to recipient/donor ID searching
const INDEX_PAGE_SIZE = 25;
const INDEX_PAGE = 1;
const LIVING_DONOR_KEY = 'living_donor_id';
const DECEASED_DONOR_KEY = 'deceased_donor_id';

@Component({
  components: {
    TextInput,
    SelectInput,
    SubSection,
    SaveToolbar,
    ModalSection,
  }
})
export default class HlaPerformSpecifiedVirtualCrossmatch extends Vue {
  @Prop({ required: false }) recipientId?: string; // Locks Recipient ID selection
  @Prop({ required: false }) donorId?: string; // Locks Donor ID selection
  @Prop({ required: false }) donorType?: string; // Locks Donor Type selection

  @State(state => state.deceasedDonors.donorsList) deceasedDonors!: StateDonorList;
  @State(state => state.livingDonors.livingDonorsList) livingDonors!: StateLivingDonorsList;
  @State(state => state.pageState.currentPage.performSpecifiedVxm) editState!: PerformSpecifiedVxmState;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  private saving = false;

  // Open or close the modal
  private toggleModal(): void {
    const targetModal = this.$refs.performSpecifiedVxmModal as ModalSection;
    targetModal.toggleModal();
  }

  get donorTypes() {
    return [
      { code: DECEASED_DONOR_KEY, value: this.$t('deceased_donor') },
      { code: LIVING_DONOR_KEY, value: this.$t('living_donor') },
    ];
  }
  // Initialize the form when the modal is opened
  private openModal(): void {
    (this.$refs.performSpecifiedVxmValdiations as any).reset();
    this.initializeForm();
    this.toggleModal();
  }

  // Commit UI form interface data to edit state based on selected API data
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'performSpecifiedVxm',
      value: this.buildPerformSpecifiedVxmState(),
    });
  }

  // Setup empty form interface
  private buildPerformSpecifiedVxmState(): PerformSpecifiedVxmState {
    const form: PerformSpecifiedVxmState = {
      recipientId: this.recipientId || null,
      donorId: this.donorId || null,
      donorType: this.donorType || DECEASED_DONOR_KEY,
    };
    return form;
  }

  // Initiate specified VXM request
  private confirmSpecifiedVxm(): void {
    this.toggleModal();
    this.saving = true;
    // Refer to the save provider that handles this form area
    const saveToolbar = this.$refs.performSpecificVxmSaveButton as SaveToolbar;
    saveToolbar.startSaving();
    // Setup saving payload
    const payload = {
      recipientId: this.editState?.recipientId,
      donorId: this.editState?.donorId,
      donorType: this.editState?.donorType
    };
    // Dispatch save action and register the response
    this.$store.dispatch('history/createRecipientVxm', payload).then((success: SaveResult) => {
      // Reload recipient, HLA labs, and HLA stem cell therapy procedure
      this.$store.dispatch('history/loadRecipientVxm', this.recipientId).then(() => {
        this.$store.dispatch('history/selectLatestRecipientVxm').then(() => {
          this.saving = false;
          saveToolbar.stopSaving(success);
          // Scroll to the new VXM results
          this.$store.dispatch('utilities/scrollTo', '#hla-selected-vxm');
        });
      }).catch((error:SaveResult) => {
        this.$store.commit('history/clearSelectedVxm');
        this.saving = false;
        saveToolbar.stopSaving(error);
      });
    }).catch((error:SaveResult) => {
        this.$store.commit('history/clearSelectedVxm');
      this.saving = false;
      saveToolbar.stopSaving(error);
    });
  }
}
