
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { GenericCodeValue } from '@/store/types';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from '@/store/utilities/types';

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    SubSection,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetPrimaryOffers extends Vue {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashPrimary) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getPrimaryOffers', { namespace: 'dashboard' }) getPrimaryOffers!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDateFormat', { namespace: 'features' }) private getDateFormat!: string;
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];

  public errorMessage = null;

  public lookupsToLoad = ['offer_responses'];

  private isWidgetLoaded = false;

  getDonorOrganUrl(params: any) {
    if (params.allocation_type) {
      const location = this.$router.resolve({ name: 'deceased-donor-organ-option', params: { id: params.id, organ_code: params.organ_code, option: params.allocation_type } });
      return location.href;
    } else {
      const location = this.$router.resolve({ name: 'deceased-donor-organ', params: { id: params.id, organ_code: params.organ_code } });
      return location.href;
    }
  }

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    setTimeout(() => {
      _vm.loadIfStillViewingDashboard();
    }, 5000);
  }

  /**
   * NOTE: we are relying on checking route 'name' to handle a special case for widget loading,
   * so we need to ensure this route name check matches dashboard route name in router.ts
   */
  loadIfStillViewingDashboard(): void {
    const viewingDashboard = this.$route.name === 'index';
    if (!viewingDashboard) return;

    Promise.all([
      this.$store.dispatch('dashboard/loadPrimaryOffers')
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashPrimary',
      value: this.buildPrimaryEditState(preferences),
    });
    this.isWidgetLoaded = true;
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idPrimaryOffers").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildPrimaryEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.primary);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ primary: {} }, currentSettings);
    newSettings.primary = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  // String representation of the Offer Response using the Offer Response Lookup
  private parseOfferResponse(offerResponseCode: any): string {
    const responseOffer = this.offerResponses.find((offerResponse: GenericCodeValue) => offerResponse.code == offerResponseCode);
    return responseOffer?.value || '-';
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.getPrimaryOffers,
      columns: [
        { label: this.$t('donor_id').toString(), field: 'deceased_donor_id', sortable: true, type: 'number', thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('transplant_program').toString(), field: 'program_identifier', sortable: false },
        { label: this.$t('allocation_id').toString(), field: 'client_id', type: 'number', sortable: false, thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('organ').toString(), field: 'offered_organ' },
        { label: this.$t('recipient_id').toString(), field: 'recipient_client_id', type: 'number', sortable: false, thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('offer_time').toString(), field: 'datetime_offered', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT},
        { label: this.$t('offer_expiry_time').toString(), field: 'datetime_expires', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT },
        { label: this.$t('offer_response').toString(), field: 'offer_response_code', sortable: false }
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'datetime_expires', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
