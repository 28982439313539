
import { State, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import { organCodeLookup } from '@/types';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import { aggregateSaveResults } from '@/utils';
import { SaveProvider, SaveResult, SaveableSection } from '@/types';
import { PostTransplantFollowUp, RecipientJourney, RecipientReferralAttributes } from '@/store/recipientJourney/types';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response } from '@/store/decisions/types';
import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SideNavJourney from '@/components/organs/shared/side-nav/SideNavJourney.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';
import PolicyExemptions from '@/components/organs/shared/PolicyExemptions.vue';
import PolicyAttachments from '@/components/organs/shared/PolicyAttachments.vue';
import PolicyResponses from '@/components/organs/shared/PolicyResponses.vue';
import PolicyFinalDecisions from '@/components/organs/shared/PolicyFinalDecisions.vue';
import CardSection from '@/components/shared/CardSection.vue';
import {ACTIVE_REGION_TRANSPLANT_PROGRAM} from '@/store/hospitals/types';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    SideNavJourney,
    RecipientSummary,
    RecipientStickySummary,
    LoadingSideNav,
    LoadingDonorPage,
    PolicyExemptions,
    PolicyAttachments,
    PolicyResponses,
    PolicyFinalDecisions,
    CardSection
  }
})
export default class EditPolicyExemptions extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter('selectedDecisionId', { namespace: 'decisions' }) selectedDecisionId!: string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;

  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;
  private selectedExemptionId = null;

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = ['decision_categories', 'decision_response_types', 'decision_final_decision_types', 'decision_meld_der_frequency'];

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  get canSave(): boolean {
    return this.isGroupWriteable('journey_policy_exemption');
  }

  get newJourney(): boolean {
    return false;
  }

  // Reload the Policy Exemptions index if a child has updated
  private reloadExemptions() {
    (this.$refs.policyExemptions as PolicyExemptions).loadDecisions();
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }
  
  // Vue lifecycle hooks
  private mounted(): void {
    const organId = this.$route.params.organId;
    const recipientId = this.recipientId;
    const journeyId = this.journey?._id?.$oid;
    Promise.all([
      this.$store.dispatch('journeyState/getJourney', organId),
      this.$store.dispatch('hospitals/load', ACTIVE_REGION_TRANSPLANT_PROGRAM),
      this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.$t('policy_exemptions')}`),
      this.$store.dispatch('validations/loadEdit', { view: `recipients/${recipientId}/journeys`, action: 'edit', clientId: journeyId })
    ]);
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup: IdLookup = {
      ...(this.$refs.policyExemptions as PolicyExemptions).idLookup(),
      ...(this.$refs.policyAttachments as PolicyAttachments).idLookup(),
      ...(this.$refs.policyResponses as PolicyResponses).idLookup(),
      ...(this.$refs.policyFinalDecisions as PolicyFinalDecisions).idLookup(),
    };
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }

  private selectedExemption(record: any) {
    this.$store.commit('decisions/setDecision', record);
    if (!record) {
      // if no record selected, clear child indexes
      this.$store.commit('decisions/flushChildren');
    } else {
      // reset children
      (this.$refs.policyAttachments as PolicyAttachments).loadAttachments();
      (this.$refs.policyResponses as PolicyResponses).loadResponses();
      (this.$refs.policyFinalDecisions as PolicyFinalDecisions).loadFinalDecisions();
    }
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    this.resetValidationErrors();
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }
}
