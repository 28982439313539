
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney, RecipientStageAttributes } from '@/store/recipientJourney/types';
import CardSection from '@/components/shared/CardSection.vue';
import ReferralDetailsSection, { ReferralDetailsPageState } from '@/components/organs/shared/ReferralDetailsSection.vue';
import ReferralDecisionSection, { ReferralDecisionPageState } from '@/components/organs/shared/ReferralDecisionSection.vue';
import { SystemModules } from '@/store/features/types';

export interface ReferralSectionPageState {
  referralDetails?: ReferralDetailsPageState;
  referral?: ReferralDecisionPageState;
}

@Component({
  components: {
    CardSection,
    ReferralDetailsSection,
    ReferralDecisionSection,
  }
})
export default class ReferralSection extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('isReferredWith', { namespace: 'journeyState' }) isReferredWith!: boolean;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('referredWithJourneys', { namespace: 'journeyState' }) referredWithJourneys!: RecipientJourney[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter("moduleEnabled", { namespace: "features" }) private moduleEnabled!: (module: string) => boolean;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private lookupsToLoad = [
    'organ',
    'country',
    'lhin_program',
    'organization_type',
    'professional_type',
    'recipient_referral_decision',
    'waitlist_removal_reason_codes',
  ];

  // Is the Referral Decision module enabled?
  get isReferralDecisionEnabled(): boolean {
    return this.moduleEnabled(SystemModules.REFERRAL_DECISION);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Referral Details / Referring Physician 
    const referralDetails = this.$refs.referralDetails as ReferralDetailsSection;
    if (referralDetails) {
      Object.assign(result, { ...referralDetails.idLookup });
    }

    // Referral Decision 
    const referralDecision = this.$refs.referralDecision as ReferralDecisionSection;
    if (referralDecision) {
      Object.assign(result, { ...referralDecision.idLookup });
    }

    return result;
  }

  /**
   * Populates form state with default values for the Referral Section
   */
  public initializeForm(): void {
    // Build the form state for the Referral Details and Referring Physician Details sections
    let referralDetailsPageState: ReferralDetailsPageState = {};
    let referralDecisionPageState: ReferralDecisionPageState = {};

    let referralSectionPageState: ReferralSectionPageState = {
      referralDetails: referralDetailsPageState,
      referral: referralDecisionPageState,
    };

    // !!stageAttributes is false if empty
    if (!!this.journey) {
      // Delegate responsibility of building form page states to the subsection form components
      const referralDetailsForm = this.$refs.referralDetails as ReferralDetailsSection;
      const referralDecisionForm = this.$refs.referralDecision as ReferralDecisionSection;

      // Build page stage for Referral Details
      if (!!referralDetailsForm) {
        referralDetailsPageState = referralDetailsForm.buildReferralDetailsForm(this.journey);
      }
      // Build page stage for Referral Decisions
      if (!!referralDecisionForm) {
        referralDecisionPageState = referralDecisionForm.buildReferralDecisionPageState();
      }
    }

    // Build Referral Section state from nested states
    referralSectionPageState = this.buildReferralSectionPageState(referralDetailsPageState, referralDecisionPageState);
    
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'referralSection',
      value: referralSectionPageState,
    });
  }

  // PRIVATE

  // Loading processes unique to this component
  private mounted(): void {
    // Clear stored referral decision
    this.$store.commit('journeyState/clearReferralDecision');
  }

  // Called after all lookups are loaded
  private loaded(): void {
    // Initialize Form
    this.initializeForm();
    this.$emit('loaded', 'referralSection');
  }

  /**
   * Builds a Referral Details and Referral Decision page state
   * 
   * @param referralDetails page state from the Referral Details and Physician Details subsection
   * @param referralDecision page state from the Referral Decision subsection
   * @returns {ReferralSectionPageState} page state for the Referral Section
   */
  private buildReferralSectionPageState(referralDetails?: ReferralDetailsPageState, referralDecision?: ReferralDecisionPageState): ReferralSectionPageState {
    return {
      referralDetails: referralDetails,
      referral: referralDecision
    };
  }

  /**
   * Copy referring referral received date, mrn and referring physician details 
   * from a related journey 
   *  
   * @param journeyId selected journeyId 
   */
  private copyFromRelatedJourney(journeyId: string): void {
    const recipientJourneys: RecipientJourney[] = this.recipient.journeys || [];
    const relatedJourney = recipientJourneys.find((j: RecipientJourney) => j._id?.$oid === journeyId);
    const referralDetailsForm = this.$refs.referralDetails as ReferralDetailsSection;
    let referralDetailsPageState: ReferralDetailsPageState = {};

    if (!!referralDetailsForm) {
      // Current journey referral details
      const combinedJourney: RecipientJourney = this.journey || {};
      // Related journey referral and transplant program
      const relatedReferrer = relatedJourney?.stage_attributes?.referral || {};
      const relatedTransplantProgram = relatedJourney?.transplant_program || {};
      // Copy Received Date and Referrer data from the related journey's referral attributes
      if (combinedJourney && combinedJourney.stage_attributes?.referral) {
        combinedJourney.stage_attributes.referral.received_date = relatedReferrer?.received_date;
        combinedJourney.stage_attributes.referral.referrer = relatedReferrer?.referrer; 
      }
      // Copy MRN from the related journey's Transplant Program model
      if (combinedJourney && combinedJourney.transplant_program) {
        combinedJourney.transplant_program.transplant_hospital_mrn = relatedTransplantProgram.transplant_hospital_mrn;
      }
      // Build referral details from thisJourney + relatedJourney
      referralDetailsPageState = referralDetailsForm.buildReferralDetailsForm(combinedJourney);
    }

    // Commit pageState for referralDetails section 
    this.$store.commit('pageState/set', {
      pageKey: 'referralSection',
      componentKey: 'referralDetails',
      value: referralDetailsPageState,
    });
  }
  
  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }
}
