
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId, GenericCodeValue } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response, ListFinalDecisions, FinalDecision } from '@/store/decisions/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import { HOSPITAL_CODE_OTHER } from "@/store/hospitals/types";
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

export interface FinalDecisionForm {
  _id?: { $oid: string };
  decision_type?: string;
  decision_date?: string;
  decision_time?: string;  
  medical_director_consulted?: boolean;
  consulted_physician?: string;
  comments?: string;
}

interface FinalDecisionRow {
  _id?: { $oid: string };
  decision_type?: string;
  decision_datetime?: string;
  authorized_user_name?: string;
  medical_director_consulted?: string;
}

@Component({
  components: {
    BooleanRadioInput,
    TextInput,
    TextAreaInput,
    DateInput,
    TimeInput,
    SubSection,
    SelectInput,
    SelectOtherInput
  }
})
export default class PolicyFinalDecisions extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.finalDecisions) editState!: FinalDecisionForm;
  @State(state => state.decisions.listFinalDecisions) private finalDecisions!: FinalDecision[];

  // Getters
  @Getter('getFinalDecisions', { namespace: 'decisions' }) getFinalDecisions!: FinalDecision[];
  @Getter('selectedDecisionId', { namespace: 'decisions' }) selectedDecisionId!: string;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;
  @Getter('decisionFinalDecisionTypes', { namespace: 'lookups' }) private decisionFinalDecisionTypes!: any;
  @Getter('regionTransplantOptionsByOrgan', { namespace: 'hospitals' }) hospitalOptions!: (organCode?: number | string) => GenericCodeValue[];
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: CoordinatorOptions[]; 

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Get a string representation the organ_code
   * 
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  // load validation rules
  public mounted() {
    this.loadFinalDecisions();
  }

  /**
   * Reload list of Policy Exemptions.
   *
   */
  public loadFinalDecisions(): void {
    // Skip if no top-level decision is selected
    if (!this.selectedDecisionId) {
      this.initializeFinalDecisionForm();
      return;
    }

    Promise.all([
      this.$store.dispatch('decisions/indexFinalDecisions', { recipientId: this.recipientId, journeyId: this.journeyId, decisionId: this.selectedDecisionId }),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions/${this.selectedDecisionId}/final_decisions`, action: 'new' }),
    ]).finally(() => {
      this.initializeFinalDecisionForm();
    }).catch(() => {
      this.initializeFinalDecisionForm();
    });
  }

  // Gets table data for policy exemptions.
  get finalDecisionRows(): FinalDecisionRow[] {
    if (!this.selectedDecisionId) return [];
    if (!this.getFinalDecisions) return [];

    const result: FinalDecisionRow[] = [];
    this.getFinalDecisions.forEach((record: FinalDecision) => {
      const decision_type = this.getFinalDecisionType(record.decision_type);
      const decision_datetime = this.parseDisplayDateTimeUiFromDateTime(record.decision_datetime);
      const medical_director_consulted = record.medical_director_consulted ? this.$t('yes') : this.$t('no');

      const row: any = {
        _id: record._id,
        decision_type: decision_type,
        decision_datetime: decision_datetime,
        authorized_user_name: record.authorized_user_name || '-',
        medical_director_consulted: medical_director_consulted
      };

      result.push(row);
    });
    return result;
  }

  private getFinalDecisionType(type: string|undefined): string {
    const types = this.decisionFinalDecisionTypes;
    if (!type) return '-';
    const result = types.find((record: any) => {
      return record.code == type;
    });
    return result ? result.value : '-';
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeFinalDecisionForm(record?: Decision): void {
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'finalDecisions',
      value: this.buildFinalDecisionForm(record)
    });
    this.$emit('clear');
  }

  /**
   * Returns policy exemption form edit state based on policy exemption document
   *
   * @param record policy exemption document fetched from API
   * @returns {HccResultForm} editable form state
   */
  public buildFinalDecisionForm(record?: FinalDecision): any {
    if (!record) {
      return {};
    }

    return {
      _id: record._id,
      decision_type: record.decision_type,
      decision_date: this.parseDateUiFromDateTime(record.decision_datetime),
      decision_time: this.parseTimeUiFromDateTime(record.decision_datetime),
      medical_director_consulted: record.medical_director_consulted,
      comments: record.comments,
    };
  }

  get finalDecisionTableConfig(): TableConfig {
    return {
      data: this.finalDecisionRows,
      columns: [
        { label: this.$t('final_decision').toString(), field: 'decision_type'},
        { label: this.$t('datetime_of_final_decision').toString(), field: 'decision_datetime'},
        { label: this.$t('authorized_user').toString(), field: 'authorized_user_name'},
        { label: this.$t('medical_director_consulted').toString(), field: 'medical_director_consulted'},
      ],
      empty: this.$t('use_form_below_final_decision').toString(),
      createButton: this.canSave,
      createText: this.$t('create_final_decision').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        dropdownAllowAll: true,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return {
      'decision_type'               : 'final_decision_type',
      'decision_datetime'           : ['final_decision_date', 'final_decision_time'],
      'medical_director_consulted'  : 'final_medical_director_consulted',
      'consulted_physician'         : 'final_consulted_physician',
      'comments'                    : 'final_comments',
    };
  }

  //  Builds form edit state based on selected document
  private selectFinalDecision(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;

    if (!selectedId || !this.finalDecisionRows) {
      return;
    }
    // Find the selected source document
    const found = this.getFinalDecisions.find((each: Decision) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }

    this.$store.dispatch('validations/loadEdit', { view: `recipients/${this.recipientId}/journeys/${this.journeyId}/decisions/${this.selectedDecisionId}/final_decisions`, action: 'edit', clientId: found._id?.$oid });

    // Build form state based on selected document
    this.initializeFinalDecisionForm(found);
  }

  // Build an empty new form edit state
  private createFinalDecision(): void {
    // Build form state
    this.initializeFinalDecisionForm();
  }

  // Saves current form state for policy exemption
  private saveFinalDecision(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveFinalDecisionsSection as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'finalDecision');
    // Generate payload based on current edit state
    const payload: any = {
      recipientId: this.recipient.client_id,
      journeyId: this.journey._id?.$oid,
      decisionId: this.selectedDecisionId,
      final_decision: this.extractPatch(),
    };
    // check for finalDecisionId
    if (this.editState._id && this.editState._id?.$oid) { payload.finalDecisionId = this.editState._id?.$oid; }

    // Dispatch save action and register the response
    this.$store.dispatch('decisions/saveFinalDecision', payload).then((success: SaveResult) => {
      // Clear any errors
      this.$emit('clear');
      // Show success notification
      saveProvider.registerSaveResult(success);
      // Reload index
      this.loadFinalDecisions();
      // Reload parent Policy Exemptions
      this.$emit('reloadIndex');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Returns a patch object containing changes for policy exemption document
  private extractPatch(): any {
    if (!this.editState) {
      return {};
    }
    const form = this.editState;

    return {
      decision_type: form.decision_type || null,
      decision_datetime: this.sanitizeDateTimeApi(form.decision_date, form.decision_time),
      medical_director_consulted: form.medical_director_consulted,
      comments: form.comments || null,
    };
  }
}
