
import { mixins } from "vue-class-component";
import { OfferOutcomeContext, MAKE_OFFER } from '@/store/allocations/types';
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { State } from 'vuex-class';
import { ClassObject, CtrErrorContext } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";

interface OfferOutcomeModalState {
  actionId: string;
  warningMessages: string[],
  instructionsTemplates: string[];
  ctrErrors: CtrErrorContext[],
  concatenatedCtrErrors: string;
  checkboxes: boolean[];
  isHardStopError: boolean;
  isConnectionError: boolean;
}

@Component({
  components: {
    ModalSection,
    CheckboxInput,
  }
})
export default class OfferOutcomeModal extends mixins(AllocationErrorsMixin, TranslationUtilsMixin) {
  @State(state => state.pageState.currentPage.offerOutcomeModalState) editState!: OfferOutcomeModalState;

  // Reference modal component to provide access to its functions
  get modalSection(): ModalSection|null {
    const modalSection = this.$refs.offerOutcomeModal as ModalSection;
    return modalSection ? modalSection : null;
  }

  // Should we use the 'hard stop error' messaging and styles?
  get isHardStopError(): boolean {
    if (!this.editState) return false;

    return this.editState.isHardStopError;
  }

  // How many CTR errors are we showing?
  get numCtrErrors(): number {
    if (!this.editState || !this.editState.ctrErrors) return 0;

    return (this.editState.ctrErrors || []).length;
  }

  // How many warning interpretations are we showing?
  get numWarningMessages(): number {
    if (!this.editState || !this.editState.warningMessages) return 0;

    return (this.editState.warningMessages || []).length;
  }

  // How many checkboxes are we showing?
  get numInstructionCheckboxes(): number {
    if (!this.editState || !this.editState.checkboxes) return 0;

    return (this.editState.checkboxes || []).length;
  }

  // What should we show for the error context heading text?
  get ctrErrorsHeading(): string {
    return this.$tc('offer_outcome_ctr_errors_heading', this.numCtrErrors).toString();
  }

  // What should we show for the interpretation heading text?
  get ctrWarningMessagesHeading(): string {
    return this.$tc('offer_outcome_warning_messages_heading', this.numCtrErrors).toString();
  }

  // What should we show for the consequences heading text?
  get consequencesHeading(): string {
    return this.$tc('offer_outcome_consequences_heading', this.numCtrErrors).toString();
  }

  // What should we show for the consequences message text?
  get consequencesMessage(): string {
    let messageKey = 'ctr_unexpected_error_occured';

    if (this.editState?.actionId === MAKE_OFFER) {
      if (this.editState?.isHardStopError) {
        // No offer persisted
        messageKey = 'no_offer_persisted';
      } else if (this.editState?.isConnectionError) {
        // Offer persisted, but cannot detect if offer sent to CTR or not
        messageKey = 'offer_persisted_but_cannot_detect_offer_sent_to_ctr';
      } else {
        // Offer persisted, not sent to CTR
        messageKey = 'offer_persisted_but_not_sent_to_ctr';
      }
    }

    return this.$t(messageKey).toString();
  }

  // Do we need to show the 'consequences' section?
  // NOTE: only shown for Make Offer scenario
  get isConsequenceMessageApplicable(): boolean {
    if (!this.editState) return false;

    return this.editState.actionId === MAKE_OFFER;
  }

  // Have all the checkboxes been selected yet?
  get allCheckboxesSelected(): boolean {
    if (!this.editState || !this.editState.checkboxes) return false;

    const numTicked: number = (this.editState.checkboxes.filter((checkboxTicked: boolean) => { return checkboxTicked; })).length;
    return numTicked === this.numInstructionCheckboxes;
  }

  // Concatenate CTR error IDs and error messages
  // NOTE: whitespace here will be shown, because this is used for <pre> element
  private concatenateCtrErrors(ctrErrors: CtrErrorContext[]): string {
    if (!ctrErrors || ctrErrors.length === 0) return this.$t('ctr_unexpected_error_occured').toString();

    // Show translated prefix labels
    const translatedErrors = ctrErrors.map((ctrError: CtrErrorContext): string => {
      return `${this.$t('ctr_error_id').toString()}: ${ctrError.ctr_error_id}
${this.$t('ctr_error_message')}: ${ctrError.ctr_error_message}`;
    });

    // Combine it all into one string to show in as pre-formatted text 
    const concatenated = translatedErrors.join(`

`);
    return concatenated;
  }

  // Generate editable form state for the checkboxes
  private buildCheckboxesState(context: OfferOutcomeContext): boolean[] {
    const instructions: string[] = context.instructionsTemplates || [];
    const result = instructions.map((instruction: string) => { return false; });
    return result;
  }

  // Build and show the modal section
  public initialize(context: OfferOutcomeContext) {
    if (!this.modalSection) {
      return;
    }

    this.$store.commit('pageState/set', {
      pageKey: 'offerOutcomeModalState',
      value: {
        actionId: context.actionId,
        warningMessages: context.warningMessages,
        instructionsTemplates: context.instructionsTemplates,
        ctrErrors: context.ctrErrors,
        concatenatedCtrErrors: this.concatenateCtrErrors(context.ctrErrors),
        checkboxes: this.buildCheckboxesState(context),
        isHardStopError: context?.isHardStopError || false,
        isConnectionError :context?.isConnectionError || false,
      },
    });

    if (!this.modalSection) {
      console.warn(this.$t('unable').toString(), { context });
      return;
    }

    this.modalSection.toggleStaticModal();
  }

  // Hide the modal section
  public dismiss() {
    if (!this.modalSection) {
      return;
    }
    this.$store.commit('pageState/set', {
      pageKey: 'offerOutcomeModalState',
      value: undefined,
    });
    this.modalSection.hideModal();
  }
}
