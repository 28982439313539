import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { DeceasedDonorsState } from '@/store/deceasedDonors/types';
import { actions } from '@/store/deceasedDonors/actions';
import { mutations } from '@/store/deceasedDonors/mutations';
import { getters } from '@/store/deceasedDonors/getters';

const state: DeceasedDonorsState = {
  selected: undefined,
  exdConfirmations: null,
  donorsList: undefined
};

const namespaced = true;

export const deceasedDonors: Module<DeceasedDonorsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
