import { GetterTree } from 'vuex';
import { EP } from '@/api-endpoints';
import { RootState } from '@/store/types';
import { OrganDetails, HistoryLiverSmc } from '@/store/organSpecificDetails/types';
import { journeyStatusMockData } from '@/store/organSpecificDetails/mock-data';

export const getters: GetterTree<OrganDetails, RootState> = {
  getMockReferrals() {
    return journeyStatusMockData.referrals;
  },
  getMockConsultations() {
    return journeyStatusMockData.consultations;
  },
  getMockAssessments() {
    return journeyStatusMockData.assessments;
  },
  numLiverPelds(state) {
    return state.liverPelds?.length;
  },
  canOverrideSmcScore(state, getters, rootState, rootGetters) {
    const isUserAuthorized = rootGetters['users/checkAllowed'](EP.recipients.journeys.organ_specific_details.liver_smc_result.update, 'PATCH');
    return isUserAuthorized;
  },
  canOverridePaediatricAge(state, getters, rootState, rootGetters) {
    const isUserAuthorized = rootGetters['users/checkAllowed'](EP.recipients.journeys.organ_specific_details.overridePaediatricAge.update, 'PATCH');
    return isUserAuthorized;
  },
  // Which entry in history_liver_smcs matches selected journey's latest_smc
  latestHistorySmc(state): HistoryLiverSmc|null {
    const collection = state.historyLiverSmc || [];
    // The latest_smc record is injected into history index without an ID
    const latest: HistoryLiverSmc|undefined = collection.find((item: HistoryLiverSmc) => {
      return item._id === null;
    });
    // Return the matching record, or null if we couldn't find it
    return latest || null;
  },
  // Is the latest HCC lab result data already expired?
  isMostRecentHccLabResultExpired(state, getters, rootState, rootGetters): boolean {
    const hccLabResults = state.liverHccs || [];
    if (hccLabResults.length === 0) return false;

    // NOTE: lab results are pre-sorted by API, so here we simply assume index 0 is most recent
    const mostRecentLabResult = hccLabResults[0];
    const expiryDate = mostRecentLabResult?.expiry_date;
    if (!expiryDate) return true;

    const isExpired = rootGetters['utilities/isExpired'](expiryDate) || false;
    return isExpired;
  },
};