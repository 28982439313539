import axios from 'axios';
import { ActionTree } from 'vuex';
import { buildErrorResult } from '@/utils';
import { APIRoute, EP } from '@/api-endpoints';
import { DeceasedDonor, DeceasedDonorsState } from '@/store/deceasedDonors/types';
import { RootState } from '@/store/types';
import { SaveResult } from '@/types';

export const actions: ActionTree<DeceasedDonorsState, RootState> = {
  new({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('set', getters.new);
      resolve();
    });
  },
  getList({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.index, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedDonors: DeceasedDonor[] = response.data.donors;
        commit('setList', sanitizedDonors);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  getListActive({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.active, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedDonors: DeceasedDonor[] = response.data.donors;
        commit('setList', sanitizedDonors);
        resolve();
      }).catch((error: any) => {
        console.warn(error);

        // Handle generic error message with an alert popup e.g. Network error
        if (!!error.message) {
          alert(`Error ${error.response?.status}\n\n${error.message}`);
        }

        reject();
      });
    });
  },
  get({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.show, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        const sanitizedDonor: DeceasedDonor = response.data.donor;
        commit('set', sanitizedDonor);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  getCtrLogs({ commit, getters }, { clientId }): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = APIRoute(EP.deceasedDonors.ctr_integration_log.index, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        // Check if our request was successful 
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Data is nested here
          const responseData = response.data.ctr_integration_log || [];
          // Commit the logs to our state
          commit('setCtrLogs', responseData);
        } else {
          // Clear CTR Logs if we were successful  
          commit('clearCtrLogs');
        }
        resolve();
      });
    });
  },
  getNowList({ commit, getters }, { search_params }): Promise<void> {
    return new Promise<void>((resolve) => {
      
      const url = APIRoute(EP.nowList.index);
      axios.post(url,search_params.payload).then((response: any) => {
        // Check if our request was successful 
        const isSuccessful = response.data.now && !response.data.errors;
        if (isSuccessful) {
          // Data is nested here
          const responseData = response.data.now || [];
          // Commit the logs to our state
          commit('setNowLists', responseData);
        } else {
          // Clear CTR Logs if we were successful  
          commit('clearNowLists');
        }
        resolve();
      });
    });
  },
  // TODO: Recipients also has this, we can remove this and just use one
  loadBmiHeightWeight({ commit, getters }, { height, height_unit, weight, weight_unit }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const url = APIRoute(EP.tools.bmi, [[':height', height], [':height_unit', height_unit], [':weight', weight], [':weight_unit', weight_unit]]);
      axios.get(url).then((response: any) => {
        resolve(response.data);
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  saveDonor({ commit, getters }, { clientId, donor }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (donor) {
        let method: any;
        let ep: string;
        const payload = { donor: donor };
        if (clientId) {
          method = axios.patch;
          ep = APIRoute(EP.deceasedDonors.update, [[':id', clientId]]);
        } else {
          method = axios.post;
          ep = APIRoute(EP.deceasedDonors.create);
        }
        method(ep, payload).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // Generate error for referral time field when there's an error on referral date
            if (response.data.errors["referral.referral_date"]) {
              response.data.errors["referral.referral_time"] = response.data.errors["referral.referral_date"];
            }
            // Generate error for results_received_time field if date failed
            if ('indicators.results_received_date' in response.data.errors) {
              response.data.errors['indicators.results_received_time'] = response.data.errors['indicators.results_received_date'];
            }
            // Generate error for results_reported_time field if date failed
            if ('indicators.results_reported_date' in response.data.errors) {
              response.data.errors['indicators.results_reported_time'] = response.data.errors['indicators.results_reported_date'];
            }
            // Generate error for withdrawl from life support time field when there's an error on date
            if (response.data.errors["organ_retrieval_surgery.life_support_withdrawal_date"]) {
              response.data.errors["organ_retrieval_surgery.life_support_withdrawal_time"] = response.data.errors["organ_retrieval_surgery.life_support_withdrawal_date"];
            }
            // Generate error for virology_labs test time field if date failed
            if ('virology_labs.test_date' in response.data.errors) {
              response.data.errors['virology_labs.test_time'] = response.data.errors['virology_labs.test_date'];
            }
            // Generate error for virology_labs sample time field if date failed
            if ('virology_labs.sample_date' in response.data.errors) {
              response.data.errors['virology_labs.sample_time'] = response.data.errors['virology_labs.sample_date'];
            }
            // Generate error for first delcaration time of death field when there's an error on first declaration date of death
            if (response.data.errors["death.first_declare_date"]) {
              response.data.errors["death.first_declare_time"] = response.data.errors["death.first_declare_date"];
            }
            // Generate error for second delcaration time of death field when there's an error on second declaration date of death
            if (response.data.errors["death.second_declare_date"]) {
              response.data.errors["death.second_declare_time"] = response.data.errors["death.second_declare_date"];
            }
            // Generate error for cross clamp time of when there's an error on cross clamp date
            if (response.data.errors["organ_retrieval_surgery.cross_clamp_date"]) {
              response.data.errors["organ_retrieval_surgery.cross_clamp_time"] = response.data.errors["organ_retrieval_surgery.cross_clamp_date"];
            }
            // Generate errors for organ recovery time fields if the date fields failed
            getters.organRecoveryRowData.forEach((or: any) => {
              // variables to prevent code from being 5000px wide
              const organCode                      = or.organ_code.toFixed(1);
              const organCodeRecovery              = `organ_donations[[${organCode}]].organ_recovery[0]`;
              const organSpecificationCodeRecovery = `organ_donations[[${organCode}, ${or.organ_specification_code}]].organ_recovery[0]`;
              if (or.organ_specification_code) {
                if (`${organSpecificationCodeRecovery}.shipped_date` in response.data.errors) {
                  response.data.errors[`${organSpecificationCodeRecovery}.shipped_time`] = response.data.errors[`${organSpecificationCodeRecovery}.shipped_date`];
                }
                if (`${organSpecificationCodeRecovery}.delivered_date` in response.data.errors) {
                  response.data.errors[`${organSpecificationCodeRecovery}.delivered_time`] = response.data.errors[`${organSpecificationCodeRecovery}.delivered_date`];
                }
              } else {
                if (`${organCodeRecovery}.shipped_date` in response.data.errors) {
                  response.data.errors[`${organCodeRecovery}.shipped_time`] = response.data.errors[`${organCodeRecovery}.shipped_date`];
                }
                if (`${organCodeRecovery}.delivered_date` in response.data.errors) {
                  response.data.errors[`${organCodeRecovery}.delivered_time`] = response.data.errors[`${organCodeRecovery}.delivered_date`];
                }
              }
            });
            // Handle server-side validation errors
            reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  uploadFile({ commit }, {id, donorId, fileList, fileName, description, categoryCode }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;

      // Avoid appending in `undefined` values for keys that we shouldnt be sending because
      // the data is empty...
      const payload: FormData = new FormData();

      //...exception for description
      //because we need to be able to send a empty comment
      payload.append('attachment[description]', description || "");

      if (!!categoryCode) {
        payload.append('attachment[category_code]', categoryCode);
      }

      if (!!fileName) {
        payload.append('attachment[original_filename]', fileName);
      }

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.deceasedDonors.attachments.update, [[':donorId', donorId], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.deceasedDonors.attachments.create, [[':donorId', donorId]]);
        // We only allow the file to be uploaded on creates, not updates
        if(fileList && fileList[0]) {
          payload.append('attachment[file]', fileList[0]);
        }
      }

      // Send asynchronously
      method(ep, payload, headers).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadDonorAttachments({ commit }, donorId): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.attachments.index, [[':donorId', donorId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          commit('setDonorAttchments', response.data.attachments);
          // Handle successful response
          resolve({success: true});
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error) => {
        reject();
      });
    });
  },
  downloadFile({  }, { donorId, id }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const ep = APIRoute(EP.deceasedDonors.attachments.show, [[':donorId', donorId], [':id', id]]);
      axios.get(ep).then((response: any) => {
        resolve(response.data.attachment);
      });
    });
  },
  downloadDocumentsFromCtr({  }, { donorId }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const ep = APIRoute(EP.deceasedDonors.attachments.download, [[':id', donorId]]);
      axios.post(ep).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          resolve(response.data.attachments);
        } else {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  uploadDocumentsToCtr({  }, { donorId, attachmentIds }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const payload = { attachment_ids: attachmentIds };
      const ep = APIRoute(EP.deceasedDonors.attachments.upload, [[':id', donorId]]);
      axios.post(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          resolve(response.data.attachments);
        } else {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  deleteFile( { commit }, { donorId, id } ): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Deletion is handled by updating with soft-delete fields
      const payload = {
        deleted: true,
        deleted_date: new Date().toISOString()
      };

      const ep = APIRoute(EP.deceasedDonors.attachments.update, [[':donorId', donorId], [':id', id]]);
      axios.patch(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  createPackagingForms({}, { donorId, payload }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const ep = APIRoute(EP.deceasedDonors.organ_packaging_forms, [[':id', donorId]]);
      axios.post(ep, { organ_packaging_forms: payload }).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  printExdForms({}, { donorId, payload }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const ep = APIRoute(EP.deceasedDonors.exdForms.create, [[':donor_id', donorId]]);
      axios.post(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          const result: SaveResult = { success: true, responseData: response.data };
          resolve(result);
        } else {
          // Handle activity errors
          const result = buildErrorResult(response?.data?.errors);
          reject(result);
        }
      }).catch((error: any) => {
        // Handle server error
        const result: SaveResult = buildErrorResult(error?.message);
        reject(result);
      });
    });
  },
  loadExdConfirmations({ commit }, donorId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('clearExdConfirmations');
      const url = APIRoute(EP.deceasedDonors.exdConfirmations.index, [[':donor_id', donorId]]);
      axios.get(url).then((response: any) => {
        const exdConfirmations: any = response.data.exd_confirmations;
        commit('setExdConfirmations', exdConfirmations);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveExdConfirmations({}, { donorId, organDonationId, payload }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.organDonations.organOffer.confirmations.create, [[':donor_id', donorId], [':organ_donation_id', organDonationId]]);
      axios.post(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic error
        reject({ success: false , errorMessages: [error?.message || 'Server error'] });
      });
    });
  },
  loadOrganDonations({ commit }, donorId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('clearOrganDonations');
      const url = APIRoute(EP.deceasedDonors.organDonations.index, [[':donor_id', donorId]]);
      axios.get(url).then((response: any) => {
        const organDonations: any = response.data.organ_donations;
        commit('setOrganDonations', organDonations);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  loadSignificantEvents({ commit }, donorId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('clearSignificantEvents');
      const url = APIRoute(EP.deceasedDonors.significantEvents.index, [[':donor_id', donorId]]);
      axios.get(url).then((response: any) => {
        const significantEvents: any = response.data.significant_events;
        commit('setSignificantEvents', significantEvents);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  shipOrgan({}, { donorId, organDonationId, ship }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.organDonations.ship, [[':donor_id', donorId], [':organ_donation_id', organDonationId]]);
      axios.post(url, { ship: ship }).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }
};
