
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { IdLookup } from '@/store/validations/types';
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { TableConfig, SaveableSection, SaveProvider, SaveResult } from '@/types';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Hospital } from '@/store/hospitals/types';
import { UserDetails, UserList } from '@/store/userAccounts/types';
import { urlParams } from '@/utils';
import { ObjectId } from '@/store/types';
import { coordinators } from '@/store/coordinators';
import { UserCoordinator, HospitalAssignment } from '@/store/users/types';
import { Coordinator, CoordinatorHospitalAssignments } from '@/store/coordinators/types';
import { ResponsiblePhysician, ResponsiblePhysicianHospitalAssignment } from '@/store/responsiblePhysicians/types';
import { User, NotificationDeliveryMechanism, NotificationSubscription, NotifiableEventChannel, NotificationOption } from "@/store/users/types";
import LoadingProfilePage from '@/components/administration/LoadingProfilePage.vue';
import { NotificationSubGroups, TransplantProgramNotificationChannels } from '@/store/lookups/types';
import { EP } from '@/api-endpoints';

interface UserRow {
  _id?: ObjectId|null,
  oauth2_user_identifier?: string|null,
  first_name?: string|null,
  last_name?: string|null,
  role_names?: string|null,
  effective_date?: string|null,
  expiry_date?: string|null;
}

interface UserForm {
  _id?: string|null,
  oauth2_user_identifier?: string|null,
  first_name?: string|null,
  last_name?: string|null,
  role_names?: string[]|null,
  coordinator_id?: ObjectId|null;
  coordinator?: UserCoordinator|null;
  responsible_physician_id?: ObjectId|null;
  responsible_physician?: UserCoordinator|null;
  surgical_person_id?: string|null,
  effective_date?: string|null,
  expiry_date?: string|null,
  notification_delivery_mechanisms?: NotificationDeliveryMechanism[],
  notification_subscriptions?: NotificationSubscription[],
  notification_lookup?: NotifiableEventChannel[],
  notification_options?: NotificationOption[],
  notification_options_grouped?: any,
  email?: string|null;
  sms?: string|null;
  pager?: string|null;
  dashboard?: string|null;
  not_grouped?: boolean|undefined;
}

@Component({
  components: {
    SaveToolbar,
    CardSection,
    SubSection,
    TextInput,
    SelectInput,
    CheckboxInput,
    DateInput,
    LoadingProfilePage
  }
})
export default class ManageAccounts extends mixins(DateUtilsMixin, ValidationUtilsMixin) {
  @State(state => state.userAccounts.selected) private selected!: UserDetails;
  @State(state => state.userAccounts.userList) private userList!: UserList;
  @State(state => state.pageState.currentPage.userAccounts) private editState!: any;
  @State(state => state.lookups.notifiable_event_channels) notifiableEventChannels!: NotifiableEventChannel[];

  @Getter('getSelected', { namespace: 'userAccounts' }) private getSelected!: UserDetails;
  @Getter('getUserEntries', { namespace: 'userAccounts' }) private getUserEntries!: UserDetails[];
  @Getter('getUserCount', { namespace: 'userAccounts' }) private getUserCount!: number;
  @Getter('getAllRoles', { namespace: 'users' }) private getAllRoles!: [];
  @Getter('findNotificationChannelsFromLookupByRoles', { namespace: 'lookups' }) private findNotificationChannelsFromLookupByRoles!: (roles?: string[]) => NotifiableEventChannel[];
  @Getter('ontarioTransplantOptions', { namespace: 'hospitals' }) hospitalOptions!: any[];
  @Getter('getHospitalById', { namespace: 'hospitals' }) private getHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: { code: string; value: string, hospital_assignments: string[], expiry_date: string|undefined }[];  
  @Getter('surgicalPersonOptions', { namespace: 'surgicalPersons' }) surgicalPersonOptions!: { code: string; value: string, hospital_assignments: string[] }[];
  @Getter('oneid', { namespace: 'features' }) private oneid!: boolean;
  @Getter('isAdmin', { namespace: 'users' }) private isAdmin!: boolean;
  @Getter('getTelephoneMask', { namespace: 'utilities' }) getTelephoneMask!: string;

  private dispatchEventsComplete = false;

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = ['notifiable_event_channels', 'notification_channels'];

  private currentPage = 1;
  private perPage = 10;

  private dirtyRoles = false;

  private rolesChanged(): void {
    this.dirtyRoles = true; // roles have been changed, reset defaults
    this.updateNotificationOptions();
  }

  get isNewUser(): boolean {
    return this.editState.user._id;  
  }

  get getCoordinatorName(): string|undefined {
    const coordinator = this.editState.user.coordinator;
    if (!coordinator) return undefined;
    const full_name: string[] = [];
    if (coordinator.first_name) full_name.push(coordinator.first_name);
    if (coordinator.middle_name) full_name.push(coordinator.middle_name);
    if (coordinator.last_name) full_name.push(coordinator.last_name);
    return full_name ? full_name.join(' ') : undefined;
  }

  get getPhysicianName(): string|undefined {
    const physician = this.editState.user.responsible_physician;
    if (!physician) return undefined;
    const full_name: string[] = [];
    if (physician.first_name) full_name.push(physician.first_name);
    if (physician.last_name) full_name.push(physician.last_name);
    return full_name ? full_name.join(' ') : undefined;
  }

  // Which transplant program hospitals does this user have Recipient Coordinator profile at?
  get recipientCoordinatorAssignments(): CoordinatorHospitalAssignments[] {
    if (!this.editState || !this.editState.user) return [];

    // None if no Coordinator profile at all
    const coordinator: Coordinator = this.editState.user.coordinator;
    if (!coordinator) return [];

    // Filter by 'transplant_coordinator' boolean flag (in this case based on Transplant Program Edit role)
    const hospitalAssignments: CoordinatorHospitalAssignments[] = coordinator.hospital_assignments || [];
    const recipientCoordinatorAssignments = hospitalAssignments.filter((assignment: CoordinatorHospitalAssignments) => {
      return assignment.transplant_coordinator;
    });
    return recipientCoordinatorAssignments;
  }

  // Which transplant program hospitals does this user have Living Donor Coordinator profile at?
  get livingDonorCoordinatorAssignments(): CoordinatorHospitalAssignments[] {
    if (!this.editState || !this.editState.user) return [];

    // None if no Coordinator profile at all
    const coordinator: Coordinator = this.editState.user.coordinator;
    if (!coordinator) return [];

    // Filter by 'transplant_coordinator' boolean flag (in this case based on Transplant Program Edit role)
    const hospitalAssignments: CoordinatorHospitalAssignments[] = coordinator.hospital_assignments || [];
    const recipientCoordinatorAssignments = hospitalAssignments.filter((assignment: CoordinatorHospitalAssignments) => {
      return assignment.living_donor_coordinator;
    });
    return recipientCoordinatorAssignments;
  }

  // Which transplant program hospitals does this user have Physician profile at?
  get responsiblePhysicianAssignments(): ResponsiblePhysicianHospitalAssignment[] {
    if (!this.editState || !this.editState.user) return [];

    // None if no Physician profile at all
    const physician: ResponsiblePhysician = this.editState.user.responsible_physician;
    if (!physician) return [];

    return physician.hospital_assignments || [];
  }

  // Does this user have a 'Recipient Coordinator' profile?
  get hasRecipientCoordinatorAssignments(): boolean {
    return this.recipientCoordinatorAssignments.length > 0;
  }

  // Does this user have a 'Living Donor Coordinator' profile?
  get hasLivingDonorCoordinatorAssignments(): boolean {
    return this.livingDonorCoordinatorAssignments.length > 0;
  }

  // Map from coordinator hospital assignment to Hospital documents
  private coordinatorHospitals(hospital_assignments: CoordinatorHospitalAssignments[]): Hospital[] {
    if (!hospital_assignments) return [];

    // NOTE: here we assume any 'non-existing hospitals' should just be filtered out
    const hospitals: Hospital[] = [];
    hospital_assignments.forEach((assignment: CoordinatorHospitalAssignments) => {
      if (assignment.hospital_id) {
        const hospital = this.getHospitalById(assignment.hospital_id.$oid);
        if (hospital) hospitals.push(hospital);
      }
    });
    return hospitals;
  }

  // Map from physician hospital assignment to Hospital documents
  private physicianHospitals(hospital_assignments: ResponsiblePhysicianHospitalAssignment[]): Hospital[] {
    if (!hospital_assignments) return [];

    // NOTE: here we assume any 'non-existing hospitals' should just be filtered out
    const hospitals: Hospital[] = [];
    hospital_assignments.forEach((assignment: ResponsiblePhysicianHospitalAssignment) => {
      if (assignment.hospital_id) {
        const hospital = this.getHospitalById(assignment.hospital_id.$oid);
        if (hospital) hospitals.push(hospital);
      }
    });
    return hospitals;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens administrationUsers#loaded
   * @emits loaded
   */
  private loaded(): void {
    this.$emit('loaded', 'userAccounts');
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', this.$t('manage_users')),
      this.$store.dispatch('hospitals/load'),
      this.$store.dispatch('users/loadRoles'),
    ]).finally(() => {
      this.loadData();
      this.initializeForm();
      this.dispatchEventsComplete = true;
    });
  }

  get availableRoles(): any[] {
    return (this.getAllRoles || []).map((role: any) => { return { text: role.role_name }; });
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  private filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  private loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'userAccounts/getUsers',
      {
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
      }
    );
  }

  private updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Populates the Users form state with data from the users Users.
   */
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'userAccounts',
      value: { user: this.newUser() }
    });
  }

  private get getUsersForTable(): UserRow[] {
    if (!this.userList) {
      return [];
    } else {
      const users = this.userList.entries || [];
      const results: UserRow[] = [];
      users.map((user: UserDetails) => {
        const result = {
          _id: user._id || undefined,
          first_name: user.first_name || undefined,
          last_name: user.last_name || undefined,
          role_names: user.role_names ? user.role_names.join(', ') : undefined,
          effective_date: user.effective_date ? this.parseDisplayDateUi(user.effective_date) : undefined,
          expiry_date: user.expiry_date ? this.parseDisplayDateUi(user.expiry_date) : undefined,
          active: user.active
        };
        results.push(result);
      });
      return results;
    }
  }

  /**
   * Gets configuration for the Users table
   *
   * @returns {TableConfig} Configuration for the Users table
   */
  private get usersTableConfig(): TableConfig {
    return {
      data: this.getUsersForTable,
      columns: [
        { label: this.$t('first_name'), 
          field: 'first_name', 
          sortable: true, 
          filterOptions: { enabled: true, custom: true, type: 'text' }
        },
        { label: this.$t('last_name'), 
          field: 'last_name', 
          sortable: true, 
          filterOptions: { enabled: true, custom: true, type: 'text' }
        },
        { label: this.$t('role_names'), 
          field: 'role_names', 
          sortable: false
        },
        { label: this.$t('effective_date'), 
          field: 'effective_date', 
          sortable: false
        },
        { label: this.$t('expiry_date'), 
          field: 'expiry_date', 
          sortable: false
        },
        { label: this.$t('active'), 
          field: 'active', 
          formatFn: this.formatTableBoolean,
          sortable: false
        },
      ],
      empty: this.$t('add_user_text').toString(),
      createButton: this.isAdmin ? true : false,
      createText: this.$t('new_user').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: 'first_name', type: 'asc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('nextLabel'),
        prevLabel: this.$t('prevLabel'),
        rowsPerPageLabel: this.$t('rowsPerPageLabel'),
        position: 'bottom'
      }
    };
  }

  formatTableBoolean(value: boolean) {
    return value ? "Yes" : "No";
  }

  // Load selected user into pageState
  private selectUser(event: any): void {
    // Get User ID from the table row referenced in the select event
    const selectedUserId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;

    if (selectedUserId) {
      Promise.all([
        this.$store.dispatch('userAccounts/get', { clientId: selectedUserId } ),
      ]).finally(() => {
        const selected = this.getSelected;
        if (selected) {
          // Get user & build form state
          const user = this.buildAccountForm(selected);
          // Save it to the editState

          this.$store.commit('pageState/set', {
            pageKey: 'userAccounts',
            componentKey: 'user',
            value: user
          });
          this.dirtyRoles = false;
          this.updateNotificationOptions();
        }
      });
    }
  }

  // Modify the measurements to fit our format
  private buildAccountForm(userDetails: UserDetails): UserForm {
    this.resetValidationErrors();

    const saveToolbar = this.$refs.userAccount as unknown as SaveToolbar;
    saveToolbar.reset();

    const id = userDetails._id ? userDetails._id.$oid : undefined;

    // get user account current settings along with lookup
    const notification_delivery_mechanisms = userDetails.notification_delivery_mechanisms || [];
    const notification_subscriptions = userDetails.notification_subscriptions;
    
    // get channels user has access to
    const notification_lookup = this.findNotificationChannelsFromLookupByRoles(userDetails.role_names || []);

    const user: UserForm = {
      _id: id,
      oauth2_user_identifier: userDetails.oauth2_user_identifier,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      role_names: userDetails.role_names,
      effective_date: this.parseDateUi(userDetails.effective_date),
      expiry_date: this.parseDateUi(userDetails.expiry_date),
      coordinator_id: userDetails.coordinator_id,
      coordinator: userDetails.coordinator,
      responsible_physician_id: userDetails.responsible_physician_id,
      responsible_physician: userDetails.responsible_physician,
      notification_delivery_mechanisms: notification_delivery_mechanisms,
      notification_subscriptions: notification_subscriptions,
      notification_lookup: notification_lookup,
      email: this.getDeliveryMechanism('email', notification_delivery_mechanisms),
      sms: this.getDeliveryMechanism('sms', notification_delivery_mechanisms),
      pager: this.getDeliveryMechanism('pager', notification_delivery_mechanisms),
      dashboard: this.getDeliveryMechanism('dashboard', notification_delivery_mechanisms)
    };

    // build notification options structure for ui
    const notification_options = this.buildNotificationOptions(user);
    user.notification_options = notification_options;

    // generate grouping for notifications

    // group array by sub_group name
    const notification_options_grouped = (notification_options as any).reduce(function (r: any, a: any) {
      r[a.sub_group] = r[a.sub_group] || [];
      r[a.sub_group].push(a);
    return r;
    }, Object.create(null));

   const notification_options_grouped_and_sorted = Object.keys(notification_options_grouped)
    .sort()
    .reduce((acc, key) => ({
        ...acc, [key]: notification_options_grouped[key]
    }), {});

    // store result into notification_options_grouped 
    user.notification_options_grouped = notification_options_grouped_and_sorted;

    // check for groups
    if (Object.entries(notification_options_grouped_and_sorted).length > 0) {
      user.not_grouped = Object.entries(notification_options_grouped_and_sorted)[0][0] == 'undefined';
    } else {
      user.not_grouped = true;
    }

    return user;
  }

  private getDeliveryMechanism(key: string, notification_delivery_mechanisms: any): any {
    const details = notification_delivery_mechanisms.find((item: any) => { return item.mechanism == key; });
    return details ? details.contact_info : '';
  }

  private buildNotificationOptions(user: UserForm) :NotificationOption[] {
    const notification_subscriptions = user.notification_subscriptions || [];
    const notification_options: any[] = [];
 
    if (!(Array.isArray(user.notification_lookup))) return [];
    user.notification_lookup.map((channel: NotifiableEventChannel) => {
      const option: any = {
        code: channel.code,
        required_delivery_mechanisms: {}, // for required user inputs
        optional_delivery_mechanisms: {}, // for optional user inputs
      };
      // get default delivery mechanisms for channel
      const default_delivery_mechanisms = this.getDefaultDeliveryMechanism(channel.code);

      if (channel.required_delivery_mechanisms && channel.required_delivery_mechanisms.length > 0) {
        channel.required_delivery_mechanisms.map((mechanism) => {
          if (this.dirtyRoles) {
            // use default
            const found = default_delivery_mechanisms ? default_delivery_mechanisms.includes(mechanism) : false;
            option.required_delivery_mechanisms[mechanism] = found || this.shouldSubscribeByDefault(channel.code);
          } else {
            // get existing value
            const found = notification_subscriptions.find((item: any) => { return item.channel_code == channel.code; }) || null;
            option.required_delivery_mechanisms[mechanism] = found && found.required_delivery_mechanisms && found.required_delivery_mechanisms.includes(mechanism) ? true : false;
          }
        });
      }
      if (channel.optional_delivery_mechanisms && channel.optional_delivery_mechanisms.length > 0) {
        channel.optional_delivery_mechanisms.map((mechanism) => {
          if (this.dirtyRoles) {
            // use default
            const found = default_delivery_mechanisms ? default_delivery_mechanisms.includes(mechanism) : false;
            option.optional_delivery_mechanisms[mechanism] = found;
          } else {
            // get existing value
            const found = notification_subscriptions.find((item: any) => { return item.channel_code == channel.code; }) || null;
            option.optional_delivery_mechanisms[mechanism] = found && found.optional_delivery_mechanisms && found.optional_delivery_mechanisms.includes(mechanism) ? true : false;
          }
        });
      }

      option.sub_group = this.getNotificationSubGroupByCode(channel.code) == 'undefined' ? 'Z_UNGROUPED': this.getNotificationSubGroupByCode(channel.code);

      notification_options.push(option);
    });

    return notification_options;
  }

  private getDefaultDeliveryMechanism(code: number|undefined): string[] {
    const channels = this.notifiableEventChannels || [];
    if (!code || !channels) return [];
    const channel = channels.find((item: any) => { return item.code == code; });
    return channel && channel.default_delivery_mechanisms ? channel.default_delivery_mechanisms : [];
  }

  shouldSubscribeByDefault(channel: number|undefined): boolean {
    if (!channel) return false;
    return TransplantProgramNotificationChannels.includes(channel) ? true : false;
  }

  /**
   * Getter method to determine whether to show the Required Methods column
   *
   * Defaults to false
   * Checks to see if any of the notifications have any keys in the required_delivery_mechanisms
   * If any notification has a key in required_delivery_mechanisms then sets it to true
   */
  private get showRequiredMethodsColumn() : boolean {
    let showRequired = false;

    this.editState.user.notification_options.filter((notification: NotificationOption) => {
      const requiredDeliveryMechanisms = notification.required_delivery_mechanisms || {};
      if (Object.keys(requiredDeliveryMechanisms).length > 0) {
        showRequired = true;
      }
    });

    return showRequired;
  } 

  /**
   * Getter method to determine whether to show the Optional Methods column
   *
   * Defaults to false
   * Checks to see if any of the notifications have any keys in the optional_delivery_mechanisms
   * If any notification has a key in optional_delivery_mechanisms then sets it to true
   */
  private get showOptionalMethodsColumn() : boolean {
    let showOptional = false;

    this.editState.user.notification_options.filter((notification: NotificationOption) => {
      const optionalDeliveryMechanisms = notification.optional_delivery_mechanisms || {};
      if (Object.keys(optionalDeliveryMechanisms).length > 0) {
        showOptional = true;
      }
    });

    return showOptional;
  } 

  private updateNotificationOptions(): void {
    const notification_lookup = this.findNotificationChannelsFromLookupByRoles(this.editState.user.role_names || []);
    // Sort the notification lookup by the translated text value
    const sorted_notification_lookup = notification_lookup.sort((a :any, b: any) => {
      // if don't have an order use value
      if (a.order === null) {
        return this.$t(a.value).toString().localeCompare(this.$t(b.value).toString());
      // otherwise use order attribute
      } else {
        return this.$t(a.order).toString().localeCompare(this.$t(b.order).toString());
      }
    });

    /**
     * START: TEMPORARILY REMOVE PAGER AND ROBOCALL OPTIONS 
     * 
     * Implementation of pager and robocall notifications for surgical users and transplant coordinators will be after go live. 
     * Until these are implemented, these options should be hidden from the subscriptions page for these types of notifications.
     */
    const filtered_notification_lookup = sorted_notification_lookup.map((option: NotifiableEventChannel) => {
      const optional_delivery_mechanisms: string[] = [];
      option.optional_delivery_mechanisms?.forEach((mechanism: string) => {
        if (mechanism != 'robocall' && mechanism != 'pager') {
          optional_delivery_mechanisms.push(mechanism);
        }
      });

      const required_delivery_mechanisms: string[] = [];
      option.required_delivery_mechanisms?.forEach((mechanism: string) => {
        if (mechanism != 'robocall' && mechanism != 'pager') {
          required_delivery_mechanisms.push(mechanism);
        }
      });

      return {
        value: option.value,
        code: option.code,
        required_delivery_mechanisms: required_delivery_mechanisms,
        optional_delivery_mechanisms: optional_delivery_mechanisms,
        sub_group: this.getNotificationSubGroupByCode(option.code)
      };
    });
    Vue.set(this.editState.user, 'notification_lookup', filtered_notification_lookup);
    // END: TEMPORARILY REMOVE PAGER AND ROBOCALL OPTIONS 

    const notification_options = this.buildNotificationOptions(this.editState.user);
    Vue.set(this.editState.user, 'notification_options', notification_options);

    // generate grouping for notifications

    // group array by sub_group name
    const notification_options_grouped = (notification_options as any).reduce(function (r: any, a: any) {
      r[a.sub_group] = r[a.sub_group] || [];
      r[a.sub_group].push(a);
    return r;
    }, Object.create(null));

   const notification_options_grouped_and_sorted = Object.keys(notification_options_grouped)
    .sort()
    .reduce((acc, key) => ({
        ...acc, [key]: notification_options_grouped[key]
    }), {});

    // store result into notification_options_grouped 
    Vue.set(this.editState.user, 'notification_options_grouped', notification_options_grouped_and_sorted);

    // check for groups
    if (Object.entries(notification_options_grouped_and_sorted).length > 0) {
      const not_grouped = Object.entries(notification_options_grouped_and_sorted)[0][0] == 'undefined';
      Vue.set(this.editState.user, 'not_grouped', not_grouped);
    } else {
      const not_grouped = true;
      Vue.set(this.editState.user, 'not_grouped', not_grouped);
    }

  }

  private get extractSubscriptionChoices() : NotificationSubscription[] {
    if (!this.editState.user.notification_options) return [];

    const newSubscriptions: any[] = [];

    this.editState.user.notification_options.map((item: any) => {
      if (item) {
        const sub: any = {
          channel_code: item.code,
          paused: null, 
          effective_date: null, 
          expiry_date: null
        };

        const required_delivery_mechanisms: string[] = [];
        for (const [key, value] of Object.entries(item.required_delivery_mechanisms)) {
          if (value) { required_delivery_mechanisms.push(key); }
        }

        const optional_delivery_mechanisms: string[] = [];
        for (const [key, value] of Object.entries(item.optional_delivery_mechanisms)) {
          if (value) { optional_delivery_mechanisms.push(key); }
        }

        // we pull from required_delivery_mechanisms & optional_delivery_mechanisms but we push to delivery_mechanisms
        sub.delivery_mechanisms = [...required_delivery_mechanisms, ...optional_delivery_mechanisms];

        newSubscriptions.push(sub);
      }
    });

    return newSubscriptions;
  }

  private get extractDetailsMechanisms(): any[] {
    const mechanisms = [];
    mechanisms.push({mechanism: 'email', contact_info: this.editState.user.email});
    mechanisms.push({mechanism: 'sms', contact_info: this.editState.user.sms});
    mechanisms.push({mechanism: 'pager', contact_info: this.editState.user.pager});
    mechanisms.push({mechanism: 'dashboard', contact_info: this.editState.user.dashboard});    
    return mechanisms;
  }

  // Returns a RecipientMeasurement patch request payload or null if the measurement edit state is empty
  public extractPatch(): UserDetails|null {
    const oauthId = this.editState.user.oauth2_user_identifier ? this.editState.user.oauth2_user_identifier.replace(/\s/g, '') : null;

    const result = {
      _id: this.editState.user._id,
      oauth2_user_identifier: oauthId,
      first_name: this.editState.user.first_name,
      last_name: this.editState.user.last_name,
      role_names: this.editState.user.role_names,
      coordinator_id: this.editState.user.coordinator_id,
      surgical_person_id: this.editState.user.surgical_person_id,
      effective_date: this.sanitizeDateApi(this.editState.user.effective_date),
      expiry_date: this.sanitizeDateApi(this.editState.user.expiry_date),
      notification_delivery_mechanisms: this.extractDetailsMechanisms,
      notification_subscriptions: this.extractSubscriptionChoices
    };

    return result;
  }

  private newUser(): UserForm {
    this.resetValidationErrors();
    this.resetToolbar();

    this.dirtyRoles = true;
    return {
      _id: null,
      oauth2_user_identifier: undefined,
      first_name: null,
      last_name: null,
      role_names: null,
      coordinator_id: null,
      surgical_person_id: null,
      effective_date: null,
      expiry_date: null,
      notification_delivery_mechanisms: [],
      notification_subscriptions: [],
      notification_lookup: [],
      notification_options: []
    };
  }

  // Set the measurement to an empty object
  private createUser() {
    this.$store.commit('pageState/set', {
      pageKey: 'userAccounts',
      componentKey: 'user',
      value: this.newUser()
    });
  }

  /**
   * Saves the User section (selected or new item)
   *
   */
  private performSave() {
    // Refer to the save provider that handles this form area
    const saveToolbar = this.$refs.userAccount as unknown as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Generate payload based on current edit state
    const userPayload = {
      clientId: this.editState.user._id,
      userDetails: this.extractPatch()
    };
    // Clear previous errors
    this.resetValidationErrors();
    // Dispatch save action and register the response
    this.$store.dispatch('userAccounts/save', userPayload).then((success: SaveResult) => {
      this.loadData();
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {      
      this.registerSaveResult(error);
    });
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    const validations = this.$refs.validations as any;
    if (validations) { validations.reset(); }
  }

  private resetToolbar() {
    const saveToolbar = this.$refs.userAccount as unknown as SaveToolbar;
    if (saveToolbar) { saveToolbar.reset(); }
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditRecipient updates the currently selected recipient), zero records, or multiple records.
     */
    if (result.success) {
      this.loadData();
      this.initializeForm();
    } else {
      // Handle errors
      this.handleErrors(result);
    }
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.userAccount as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup = this.idLookup;
    
    // Derive errors for UI input fields based on API error results
    const formErrors = this.parseFormErrors(errors, idLookup);

    (this.$refs.validations as any).setErrors(formErrors);
  }

  public get idLookup(): IdLookup {
    const mapping: { [key: string]: string } = {
      'oauth2_user_identifier'    : 'oauth2_user_identifier',
      'first_name'                : 'first_name',
      'last_name'                 : 'last_name',
      'role_names'                : 'role_names',
      'effective_date'            : 'effective_date',
      'expiry_date'               : 'expiry_date',
      'email'                     : 'email',
      'cell'                      : 'cell',
      'pager'                     : 'pager',
    };

    this.notifiableEventChannels.forEach((item: any) => {
        mapping[`notification_subscriptions[${item.code}].delivery_mechanisms`] = `user-notifications-required-${item.code}`;
    });

    return mapping;
  }

  getNotificationSubGroupByCode(code: number|undefined): string|undefined {
    const subGroups = NotificationSubGroups as any;
    let subGroup = 'Z_UNGROUPED'; // default for items with no group

    for (const [key, value] of Object.entries(subGroups)) { 
      const a = value as any;
      if ( a.includes(code) ) {
        subGroup = key;
      }
    }

    return subGroup;
  }
}
