import { render, staticRenderFns } from "./MeldSection.vue?vue&type=template&id=ce006b82&"
import script from "./MeldSection.vue?vue&type=script&lang=ts&"
export * from "./MeldSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/liver/_locales/MeldSection.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fliver%2FMeldSection.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports