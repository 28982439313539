var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal-section',{ref:"offerHistoryModal",attrs:{"modalId":"offer-history-modal","centered":true},scopedSlots:_vm._u([(_vm.records)?{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTitle)+" "+_vm._s(_vm.$t('title'))+" ")]},proxy:true}:null,(_vm.records.length > 0)?{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.getTitle)+" "+_vm._s(_vm.$t('title_table'))+" "+_vm._s(_vm.allocation.client_id))]),_c('div',{staticClass:"hr-break"}),_c('vue-good-table',{attrs:{"style-class":"vgt-table table table-bordered table-striped","columns":_vm.offerHistoryTableConfig.columns,"rows":_vm.offerHistoryTableConfig.data || [],"pagination-options":{
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: _vm.$t('table.older'),
        prevLabel: _vm.$t('table.newer'),
        rowsPerPageLabel:  _vm.$t('table.results'),
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'client_id')?[_c('strong',[_vm._v(_vm._s(props.formattedRow[props.column.field] || '-'))])]:[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] || '-')+" ")]]}}],null,false,74263007)})]},proxy:true}:{key:"body",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"modal-footer-body"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal","aria-label":_vm.$t('close')},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])])]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }