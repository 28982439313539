import { GetterTree } from 'vuex';
import { RootState, ObjectId } from '@/store/types';
import { sortOptionsByValue } from '@/utils';
import { ResponsiblePhysiciansState, ResponsiblePhysician, ResponsiblePhysicianHospitalAssignment, ResponsiblePhysicianOrganSpecialization, ResponsiblePhysicianOption } from '@/store/responsiblePhysicians/types';

// Map from a Responsible Physician document to a standardized dropdown option
function responsiblePhysicianOption(physician: ResponsiblePhysician): ResponsiblePhysicianOption {
  return {
    code: physician._id.$oid,
    value: `${physician.first_name} ${physician.last_name}`,
    hospital_assignments: physician.hospital_assignments,
  };
}

export const getters: GetterTree<ResponsiblePhysiciansState, RootState> = {
  /**
   * Get a select-input "friendly" list of responsible physicians
   * 
   * @returns {[{code: string, value: string, hospital_id: string, organ_code: string}]} array of responisble physicians
   */
  responsiblePhysicianOptions(state, getters): ResponsiblePhysicianOption[] {
    const physicians: ResponsiblePhysician[] = state.responsiblePhysicians;
    
    if (physicians) {
      return physicians.map((physician: ResponsiblePhysician): ResponsiblePhysicianOption => {
        return {
          code: physician._id.$oid,
          value: `${physician.first_name} ${physician.last_name}`,
          hospital_assignments: physician.hospital_assignments,
        };
      });
    } else {
      return [];
    }
  },

  /**
   * Get a select-input "friendly" list of responsible physicians filtered by the passed hospital id and passed organ code
   * 
   * @param byHospitalId hospital id
   * @param byOrganCode organ code
   * @returns {ResponsiblePhysicianOption[]} array of options for responsible physicians belonging to the hospital and specified organ code
   */
   responsiblePhysiciansByHospitalAndOrgan(state, getters, rootState, rootGetters) {
    return (byHospitalId: string, byOrganCode: string): ResponsiblePhysicianOption[] => {
      if (!byHospitalId || !byOrganCode) return [];
      const physicians: ResponsiblePhysician[] = state.responsiblePhysicians || [];
      const filtered: ResponsiblePhysician[] = physicians.filter((physician: ResponsiblePhysician) => {
        const hospitalAssignments = physician?.hospital_assignments || [];
        const applicableAssignments = hospitalAssignments.filter((assignment: ResponsiblePhysicianHospitalAssignment) => {
          const hospitalId = assignment?.hospital_id?.$oid || '';
          const organSpecializations = assignment?.organ_specializations || [];
          const organCodes = organSpecializations.map((specialization: ResponsiblePhysicianOrganSpecialization): string => {
            return (specialization?.organ_code || 0).toString();
          });
          // check for expired (no expired in physician's hospital & organ assignments but exists in physician's root)
          const isExpired = assignment.expiry_date ? rootGetters["utilities/isExpired"](assignment.expiry_date) : false;
          if (!isExpired) {
            // if not expired, match hospitalId and organCode
            return hospitalId === byHospitalId.toString() && organCodes.includes(byOrganCode.toString());
          }
        });
        return applicableAssignments.length > 0;
      });
      const options = filtered.map((physician: ResponsiblePhysician): ResponsiblePhysicianOption => {
        return {
          code: physician._id.$oid,
          value: `${physician.first_name} ${physician.last_name}`,
          hospital_assignments: physician.hospital_assignments,
        };
      });
      return options;
    };
  },

  // Options for the per-hospital 'filtered' list of Responsible Physicians used throughout a page
  activeSortedResponsiblePhysicianOptions(state, getters, rootState, rootGetters): (hospitalId: string, responsiblePhysicianId: string, organCode: number) => ResponsiblePhysicianOption[] {
    return (hospitalId: string, responsiblePhysicianId: string, organCode: number): ResponsiblePhysicianOption[] =>{
      const physicians: ResponsiblePhysician[] = state.responsiblePhysicians;
      let activePhysicians = [];
      
      activePhysicians = physicians.filter((physician: ResponsiblePhysician) => {
        const hospitalAssignments: ResponsiblePhysicianHospitalAssignment[] = physician.hospital_assignments || [];
        let organSpecialization = null;
        if(responsiblePhysicianId === physician._id.$oid) {
          return true;
        }

        const hospitalAssignment = hospitalAssignments.find((assignment: ResponsiblePhysicianHospitalAssignment) => {
          return assignment.hospital_id?.$oid === hospitalId && (!assignment.expiry_date || !rootGetters["utilities/isExpired"](assignment.expiry_date));
        });
        
        if(hospitalAssignment && hospitalAssignment.organ_specializations) {
          organSpecialization = hospitalAssignment.organ_specializations.find((organSpecialization: ResponsiblePhysicianOrganSpecialization) => {
            return organSpecialization.organ_code === organCode && (!organSpecialization.expiry_date || !rootGetters["utilities/isExpired"](organSpecialization.expiry_date));
          });
          return organSpecialization !== null && organSpecialization !== undefined;
        }
        return false;
      });

      if (activePhysicians && activePhysicians.length > 0) {
        let physicianList = [];
        let sortedPhysicians = [];
        physicianList = activePhysicians.map((physician: ResponsiblePhysician) => {
          return responsiblePhysicianOption(physician);
        });

        sortedPhysicians = sortOptionsByValue(physicianList);
        return sortedPhysicians;
      }
      return [];
    };
  }
};
