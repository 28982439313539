
import { Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserMenu from '@/components/shared/site-header/UserMenu.vue';

@Component({
  components: {
    UserMenu,
  }
})
export default class StickySummary extends Vue {
  // Properties for optional configuration
  @Prop({ default: 150 }) scrollThreshold!: number; // number of pixels below which the summary is visible

  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;

  get getSimpleDonorsUrl(): string {
    return this.isTransplantCoordinator ? '/donors/active/' : '/donors/';
  }

  // Component state
  private isVisible = false; // toggles whether or not the sticky summary can be seen

  // Lifecycle events
  private mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Event listeners
  private handleScroll(): void {
    const lastPosition = window.pageYOffset;
    if (lastPosition > this.scrollThreshold) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
}
