import { render, staticRenderFns } from "./SelectedRecipientProfileLinksOop.vue?vue&type=template&id=c3471426&"
import script from "./SelectedRecipientProfileLinksOop.vue?vue&type=script&lang=ts&"
export * from "./SelectedRecipientProfileLinksOop.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/recipientsOop/_locales/side-nav.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FrecipientsOop%2Fside-nav%2FSelectedRecipientProfileLinksOop.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports