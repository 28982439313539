import Vue from 'vue';
import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ToolsState, BmiResult, CpraResult, TlcResult } from '@/store/tools/types';

/**
 * Builds a single string value representing an array of unacceptable antigens
 * 
 * Formats the string to provide the API cPRA calculator endpoint its expected syntax
 * 
 * @param unacceptableAntigens array of antigen strings
 * @returns {string} antigen query value with prefixed key and ampersand separators
 */
function buildUnacceptableAntigenString(unacceptableAntigens: string[]): string {
  const prefixed = unacceptableAntigens.map((antigen: string) => {
    return `unacceptable_antigens[]=${antigen}`;
  });
  const result = prefixed.join('&');
  return result;
}

export const actions: ActionTree<ToolsState, RootState> = {
  loadBmiHeightWeight({ state }, { height, height_unit, weight, weight_unit }): Promise<BmiResult> {
    return new Promise<BmiResult>((resolve, reject) => {
      // Clear results of previous calculation
      Vue.set(state, 'bmiResult', undefined);
      // Perform calculation
      const url = APIRoute(EP.tools.bmi, [[':height', height], [':height_unit', height_unit], [':weight', weight], [':weight_unit', weight_unit]]);
      axios.get(url).then((response: any) => {
        // Store response
        const bmiResult: BmiResult = response.data;
        Vue.set(state, 'bmiResult', bmiResult);
        resolve(bmiResult);
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  calculateCpra({ state }, { bloodType, unacceptableAntigens }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // Clear results of previous calculation
      Vue.set(state, 'cpraResult', undefined);
      // Prepare request
      const unacceptableAntigenString = buildUnacceptableAntigenString(unacceptableAntigens);
      const url = APIRoute(EP.tools.cpra, [[':bloodType', bloodType], [':unacceptableAntigens', unacceptableAntigenString]]);
      axios.get(url).then((response: any) => {
        // Fetch response
        const cpraResult: CpraResult = response.data;
        Vue.set(state, 'cpraResult', cpraResult);
        resolve(cpraResult);
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  calculateTlc({ state }, { age, gender, height }): Promise<TlcResult> {
    return new Promise<TlcResult>((resolve, reject) => {
      // Clear results of previous calculation
      Vue.delete(state, 'tlcResult');
      // Perform calculation
      const url = APIRoute(EP.tools.tlc, [[':age', age], [':gender', gender], [':height', height]]);
      axios.get(url).then((response: any) => {
        // Store response
        const tlcResult: TlcResult = response.data;
        resolve(tlcResult);
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
};
