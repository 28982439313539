
import { Getter, State } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import TestComponent1 from '@/views/test/TestComponent1.vue';
import TestComponent2 from '@/views/test/TestComponent2.vue';

@Component({
  components: {
    PageTop,
    TestComponent1,
    TestComponent2
  }
})
export default class Test extends Vue {
  // purpose of this page is to test lookup functionality
}
