
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardState, DashboardSettingState, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import { urlParams } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Format } from "@/store/utilities/types";
import { HospitalOption } from "@/store/hospitals/types";

@Component({
  components: {
    BaseWidget,
    VueGoodTable,
    TextInput,
    CheckboxInput,
    SelectInput,
    SubSection
  }
})
export default class WidgetRecentRecipients extends mixins(DateUtilsMixin) {
  @Prop({ default: false }) requiredWidget!: boolean;

  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashRecentRecipients) private editState!: any;

  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getRecentRecipients', { namespace: 'dashboard' }) getRecentRecipients!: any[];
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('hospitalOptionsOntarioTransplant', { namespace: 'hospitals' }) hospitalOptionsOntarioTransplant!: HospitalOption[];

  public currentPage = 1;
  public perPage = 10;
  public errorMessage = null;

  mounted(): void {
    Promise.all([
      this.loadData()
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'dashboard/loadRecentRecipients', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;
    this.$store.commit('pageState/set', {
      pageKey: 'dashRecentRecipients',
      value: this.buildRecentRecipientsEditState(preferences),
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
      this.initializeWidget();
      // Reinitialize if overall dashboard settings change
      this.$emit('reloadDashboard');
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#idRecentRecipients").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildRecentRecipientsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.recentRecipients);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ recentRecipients: {} }, currentSettings);
    newSettings.recentRecipients = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  get tableData() {
    const recentRecipients: any = this.getRecentRecipients.entries || [];
    const results: any[] = [];
    recentRecipients.map((record: any) => {
      let metaData: any = {}; // data to inject

      if (!record.journeys || record.journeys.length === 0 ) {
        metaData = {
          transplant_program: this.$t('none').toString()
        };
      } else {
        const journeyPrograms = record.journeys.map((journey: any) => {
          if(journey.program_abbreviation)  return journey.program_abbreviation;

          if(journey.program_id && journey.program_id.$oid) {
            const program_abbreviation = this.hospitalOptionsOntarioTransplant.find(h => h.code === journey.program_id.$oid);
            return program_abbreviation ? program_abbreviation.abbr  : 'N/A';
          } else {
            return 'N/A';
          }
        });
        metaData = {
          transplant_program: journeyPrograms.join('<br /> ')
        };
      }

      const result = {
        client_id: record.client_id,
        first_name: record.first_name,
        last_name: record.last_name,
        transplant_program: metaData.transplant_program,
        registration_date: this.parseDisplayDateTimeUiFromDateTime(record.registration_date)
      };
      results.push(result);
    });
    return results;
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableData,
      columns: [
        { label: this.$t('recipient_id').toString(), field: 'client_id', sortable: true, type: 'number', thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: this.$t('first_name').toString(), field: 'first_name', sortable: true },
        { label: this.$t('last_name').toString(), field: 'last_name', sortable: true },
        { label: this.$t('transplant_program').toString(), field: 'transplant_program', sortable: false, html: true },
        { label: this.$t('registration_date').toString(), field: 'registration_date', sortable: true, type: 'date', dateInputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, dateOutputFormat: Format(this.getDateFormat).DATE_TIME_FORMAT, html: true },
      ],
      empty: this.$t('no_recipients').toString(),
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'registration_date', type: 'desc'}
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 10,
        mode: 'records',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
