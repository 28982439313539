
import ToggleButton from '@/components/shared/ToggleButton.vue';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';

@Component({
  components: {
    ToggleButton,
  }
})
export default class CollapsibleHeader extends Vue {
  // V-model
  @Model('change') value!: boolean; // collapsed or not

  // Props
  @Prop({ required: true }) heading?: string; // heading text
  @Prop({ default: false }) collapsible?: string; // set to true to enable collapsible behaviour

  // Getters
  get toggleLabel(): string {
    return `Collapse ${this.heading}`;
  }

  get untoggleLabel(): string {
    return `Reveal ${this.heading}`;
  }

  // Event handlers
  private changed(event: any): void {
    this.$emit('change', event);
  }
}
