
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LivingDonor } from '@/store/livingDonors/types';

@Component
export default class SelectedLivingDonorHlaLinks extends Vue {
  @State(state => state.livingDonors.selected) private donor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) clientId!: string|undefined;

  @Prop({default: true}) open!: boolean;
  @Prop({default: false}) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new living donors we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-living-donor', params: { id: (this.clientId || '') }, hash: hash });
      return location.href;
    }
  }
}
