import { GetterTree } from 'vuex';
import { GenericCodeValue, RootState } from '@/store/types';
import { ReportState } from '@/store/reports/types';
import i18n from '@/i18n';

export const getters: GetterTree<ReportState, RootState> = {
  waitlistDonorTypeOptions(): GenericCodeValue[] {
    return [
      { code: "deceased", value: "Deceased Donor" },
      { code: "living", value: "Living Donor" },
    ];
  },
  /**
   * Used for returning 'all' placeholder text in a multi select input dropdown
   * 
   * @param state The editState of the select input
   * @param {boolean} [disabled] optional parameter, if true then returns nothing
   * 
   * @returns {string|undefined} returns nothing if disabled, returns 'all' if state is empty otherwise returns nothing
   */
  allOptionsPlaceholderValue() {
    // disabled is an optional field, if field is disabled then send nothing
    return (state: any, disabled?: boolean): string|undefined => {
      if (disabled) {
        return;
      } 
      return ((state || []).length == 0) ? i18n.tc('all').toString() : undefined;
    };
  }
};
