
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import { DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedDeceasedDonorHlaLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorHlaLinks.vue";
import SelectedDeceasedDonorOrganLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorOrganLinks.vue";
import SelectedDeceasedDonorProfileLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorProfileLinks.vue";

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedDeceasedDonorHlaLinks,
    SelectedDeceasedDonorOrganLinks,
    SelectedDeceasedDonorProfileLinks,
  }
})
export default class SideNavDonorOrgan extends Vue {
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
