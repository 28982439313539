
import { mixins } from "vue-class-component";
import { Getter, State } from 'vuex-class';
import { titleCase, mergeClasses, isMasked } from '@/utils';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ClassObject } from '@/types';
import { EP } from '@/api-endpoints';

@Component({
  components: {
    CardSection,
    SubSection,
    SelectInput,
  }
})
export default class TestComponent2 extends Vue {
  @State(state => state.pageState.currentPage.testComponent1) editState!: any;
  @State(state => state.lookups.ethnicity) ethnicityLookup!: any[];

  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: boolean;
  @Getter("insurance", { namespace: "lookups" }) private insuranceLookup: any;
  @Getter('country', { namespace: 'lookups' }) private countryLookup: any;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    "ethnicity",
    "gender",
    "insurance_plan_codes",
    "country",
  ];

  private isFinishedLoadingEvents = false;

  private mounted(): void {
    this.initializeForm();
  }

  public loaded(): void {
    this.$emit('loaded', 'testComponent1');
  }

  // Private methods
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'testComponent1',
      value: {
        sex: undefined,
        ethnicity: undefined,
        country: undefined,
        referral_hospital: undefined
      }
    });
  }
}

