import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/tools/actions';
import { getters } from '@/store/tools/getters';
import { ToolsState } from '@/store/tools/types';

const state: ToolsState = {};

const namespaced = true;

export const tools: Module<ToolsState, RootState> = {
  namespaced,
  actions,
  getters,
  state,
};
