import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/reports/actions';
import { getters } from '@/store/reports/getters';
import { mutations } from '@/store/reports/mutations';
import { ReportState } from '@/store/reports/types';

const state: ReportState = {
  currentReport: undefined,
  TransplantInProgressReportState: undefined,
  WaitingListReportState: undefined
};

const namespaced = true;

export const reports: Module<ReportState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state,
};
