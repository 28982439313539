import { AllocationRecipient, RegistrationType } from '@/store/allocations/types';
import { LivingAllocationRecipient } from '@/store/livingAllocations/types';

/**
 * As part of CR 2023-019
 * Allocation Service return a sorted `listed_for` from `waitlisted_for_organs`
 * This function will create an array of organ_codes from `waitlisted_for_organ_codes`
 * that is following the sorted arrangement of `listed_for` values regardless of
 * clustered organ sorting
 * 
 * eg: Liver/Kidney == Kidney/Liver
 * 
 * Sanitize 'Listed For Codes' information
 *
 * @param record Allocation Recipient record
 * @returns Array of organ_codes sorted based on `listed_for`
 */
export function listedForCodes(record: AllocationRecipient|LivingAllocationRecipient): string[] {
  if (!record) {
      return [];
  }

  // Restructure listedForCodes sort based on `listed_for`
  let result: string[] = record.listed_for.map((listed_organ: string) => {
      let idx: number
      if (listed_organ.includes('/')) {
      const sorted_listed_organ = listed_organ.split('/').sort().join('/');
      idx = record.waitlisted_for_organs.findIndex((waitlisted_organ) => {
          return waitlisted_organ.split('/').sort().join('/') === sorted_listed_organ;
      });
      } else {
      idx = record.waitlisted_for_organs.indexOf(listed_organ)
      }
      return record.waitlisted_for_organ_codes[idx]
  })

  // Combine the organ listing information for Out-of-Province cluster entry
  if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
  }

  return result;
}