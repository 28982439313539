
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { HistoryLiverSmc } from '@/store/organSpecificDetails/types';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface ExceptionPointsForm {
  smcScoreSource?: string;
  smcScore?: number|null;
}

@Component({
  components: {
    TextInput,
    SubSection,
  }
})
export default class LatestSodiumMeldCalculation extends Vue {
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private loaded(): void {
    const journey: RecipientJourney = this.journey || {};
    const liverSpecificDetails: LiverDetails = journey.organ_specific_details || {};
    const latestSmc: HistoryLiverSmc = liverSpecificDetails.latest_smc || {};
    this.initializeForm(latestSmc);
    this.$emit('loaded', 'latestSmc');
  }

  // Reload this forms ection if the liver journey's Latest SMC changes
  @Watch('journey.organ_specific_details.latest_smc')
  onLatestSmcChanged(): void {
    this.loaded();
  }

  // Initialize form edit state
  private initializeForm(latestSmc?: HistoryLiverSmc): void {
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'latestSmc',
      value: this.buildExceptionPointsFormState(latestSmc)
    });
  }

  // Generate values for this form's UI edit state, based on a source document loaded from API
  private buildExceptionPointsFormState(latestSmc?: HistoryLiverSmc): ExceptionPointsForm {
    let result: ExceptionPointsForm = {};
    if (!latestSmc) return result;

    const smcScoreSource = this.$t(this.lookupValue(latestSmc.score_source_code, 'liver_score_source_codes'));
    Object.assign(result, {
      smcScoreSource,
      smcScore: latestSmc.score,
    });
    return result;
  }

  // Re-initialize the form edit state
  public reinitialize(): void {
    const journey: RecipientJourney = this.journey || {};
    const liverSpecificDetails: LiverDetails = journey.organ_specific_details || {};
    const latestSmc: HistoryLiverSmc = liverSpecificDetails.latest_smc || {};
    this.initializeForm(latestSmc);
  }
}
