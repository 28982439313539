
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TransplantMixin } from "@/mixins/transplant-mixin";
import { Getter, State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import SubSection from "@/components/shared/SubSection.vue";
import { KidneyTransplantAttributes, RecipientJourney, TransplantFactors } from "@/store/recipientJourney/types";
import DateInput from "@/components/shared/DateInput.vue";
import { RecipientTransplantAttributes } from "@/store/recipientJourney/types";
import TimeInput from "@/components/shared/TimeInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { Organ, OrganSpecification, OrganOfferSpecificationCodeValue } from '@/store/lookups/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { TransplantSectionPageState } from '@/mixins/transplant-mixin';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from "@/components/shared/TextInput.vue";

export interface KidneyTransplantDetailsPageState {
  transplantType?:number;
  transplantStartDate?: string;
  transplantStartTime?: string;
  removedFromColdDateLeft?: string;
  removedFromColdTimeLeft?: string;
  removedFromColdDateRight?: string;
  removedFromColdTimeRight?: string;
  clampOffDateLeft?: string;
  clampOffTimeLeft?: string;
  clampOffDateRight?: string;
  clampOffTimeRight?: string;
  vesselsUsed?: boolean;
  listOfVessels?: string;
  rewarmTimeLeft?: string;
  rewarmTimeRight?: string;
  coldIschTimeLeft?: string;
  coldIschTimeRight?: string;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    BooleanRadioInput,
    SelectInput,
    TextAreaInput,
    TextInput,
  }
})
export default class KidneyTransplantDetails extends mixins(DateUtilsMixin, TransplantMixin) implements SaveableSection {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter("transplantTypeLookup", { namespace: "lookups" }) organSpecification!: (organCode: number | string | undefined) => OrganSpecification[] | undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('convertTimeInSeconds', { namespace: 'utilities' }) convertTimeInSeconds!: (time?: number) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter("transplantOfferOrganSpecificationCode", { namespace: "journeyState" }) transplantOfferOrganSpecificationCode!: number | null;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  public loaded(): void {
    this.$emit('loaded', 'kidneyDetails');
  }

  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

   /**
   * Returns an array of options for Transplant Type
   * 
   * 
   * @returns {OrganSpecification[]|[]} organ-specific options 
   */
  get transplantTypeLookup(): OrganSpecification[] {
    const organCode = this.journey?.organ_code;
    if (!organCode) {
      return [];
    }
    // Fetch appropriate options
    let options = this.organSpecification(organCode) || [];

    if (this.transplantOfferOrganSpecificationCode == OrganOfferSpecificationCodeValue.BothKidneys ||
      this.transplantOfferOrganSpecificationCode == OrganOfferSpecificationCodeValue.BothKidneysPancreas) {
      // only shows "Kidney - Both" on options
      options = options.filter((option: OrganSpecification) => {
        return option.code == OrganOfferSpecificationCodeValue.BothKidneys;
      });
    } else {
      // by default if offer is not Kidney Both specs then it's Kidney - Single
      // then remove Both Kidney and show other options
      options = options.filter((option: OrganSpecification) => {
        return option.code != OrganOfferSpecificationCodeValue.BothKidneys;
      });
    }

    const translated = options.map((option: OrganSpecification): OrganSpecification => {
      return {
        ...option,
        value: this.$t(option.value).toString(),
      };
    });
    return translated;
  }

  /**
   * Get organ code from the params
   *
   * @returns {number} organ_code
   */
  get organCode(): number {
    return !isNaN(Number(this.$route.params.organ_code)) ? Number(this.$route.params.organ_code) : 0;
  }

  /**
   * Build state for the KidneyTransplantDetails form area
   *
   * @param KidneyTransplantAttributes from RecipientTransplantAttributes
   * @returns {KidneyTransplantDetailsPageState} form state for KidneyTransplant
   */
  public buildTransplantDetailsState(transplantDetails?: RecipientTransplantAttributes): KidneyTransplantDetailsPageState {
    const kidneyTransplantAttributes = transplantDetails as KidneyTransplantAttributes || {};
    const result: KidneyTransplantDetailsPageState = {
      transplantType: kidneyTransplantAttributes.organ_specification_code || undefined,
      transplantStartDate: this.parseDateUiFromDateTime(kidneyTransplantAttributes.transplant_start_date || undefined),
      transplantStartTime: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.transplant_start_date || undefined),
      removedFromColdDateLeft: this.parseDateUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_left_kid || undefined),
      removedFromColdTimeLeft: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_left_kid || undefined),     
      removedFromColdDateRight: this.parseDateUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_right_kid || undefined),
      removedFromColdTimeRight: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.end_cold_storage_date_right_kid || undefined),
      clampOffDateLeft: this.parseDateUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_left_kid || undefined),
      clampOffTimeLeft: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_left_kid || undefined),
      clampOffDateRight: this.parseDateUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_right_kid || undefined),
      clampOffTimeRight: this.parseTimeUiFromDateTime(kidneyTransplantAttributes.clamp_removal_date_right_kid || undefined),
      vesselsUsed: kidneyTransplantAttributes.vessels_used,
      listOfVessels: kidneyTransplantAttributes.list_of_vessels_used,
      rewarmTimeLeft: this.convertTimeInSeconds(kidneyTransplantAttributes.rewarm_time_left_kid || 0), 
      rewarmTimeRight: this.convertTimeInSeconds(kidneyTransplantAttributes.rewarm_time_right_kid || 0), 
      coldIschTimeRight: this.convertTimeInSeconds(kidneyTransplantAttributes.cold_isch_time_right_kid || 0),
      coldIschTimeLeft: this.convertTimeInSeconds(kidneyTransplantAttributes.cold_isch_time_left_kid || 0)
    };
    return result;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.transplantDetails || !this.editState.transplantDetails.organTransplantDetails) {
      return {};
    } else {
      return this.extractKidneyTransplantDetailsPatch(this.editState.transplantDetails.organTransplantDetails);
    }
  }

  // String for confirmation dialog for partial cluster transplant hold
  get confirmationText(): string {
    if (!this.isConfirmationRequired()) return '';

    return this.$t('confirm_partial_cluster_transplant_hold').toString();
  }


  /**
   * Saves the form edit state.
   *
   * Prepares a payload for Transplant Atributes, dispatches a save action, and registers the save result.
   * @emits clear clear validation errors because saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const transplantAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: transplantAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveTransplantDetails', payload).then((success: SaveResult) => {
      // Report to parent that saving has completed
      this.$emit('saved', 'kidneyTransplantDetails');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }


  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    return{
      'organ_specification_code' : 'transplantType',
      'transplant_start_date' : 'transplantStartDate',
      'end_cold_storage_date_left_kid' : 'removedFromColdDateLeft',
      'end_cold_storage_date_right_kid' : 'removedFromColdDateRight',
      'transplant_start_time' : 'transplantStartTime',
      'end_cold_storage_time_left_kid' : 'removedFromColdTimeLeft',
      'end_cold_storage_time_right_kid' : 'removedFromColdTimeRight',
      'clamp_removal_date_left_kid':  'clampOffDateLeft',
      'clamp_removal_time_left_kid':  'clampOffTimeLeft',
      'clamp_removal_date_right_kid':  'clampOffDateRight',
      'clamp_removal_time_right_kid':  'clampOffTimeRight',
      'vessels_used':  'vesselsUsed',
      'list_of_vessels_used':  'listOfVessels'
    };
  }

  /**
   * Returns a journey patch object containing changes from a Transplant Details form
   *
   * @returns {RecipientTransplantAttributes}
   */
  private extractKidneyTransplantDetailsPatch(transplantDetails: KidneyTransplantDetailsPageState): KidneyTransplantAttributes {
    const transplant = this.editState.transplantDetails || {};
    const kidneyTransplantDetails = transplant.organTransplantDetails as KidneyTransplantDetailsPageState || {};

    // Fetch Transplant Date from the top-level of the form
    const transplantDate = this.editState.transplantDetails?.transplantDate;

    // Build nested 'factors' object to include in the Transplant Attributes patch
    const factors: TransplantFactors = {
      transplant_date: transplantDate ? this.sanitizeDateApi(transplantDate) : null,
    };

    // Build patch for Transplant Attributes object
    const result: KidneyTransplantAttributes = {
      organ_specification_code: kidneyTransplantDetails.transplantType,
      transplant_start_date: this.sanitizeDateTimeApi(kidneyTransplantDetails.transplantStartDate, kidneyTransplantDetails.transplantStartTime),
      end_cold_storage_date_left_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.removedFromColdDateLeft, kidneyTransplantDetails.removedFromColdTimeLeft),
      end_cold_storage_date_right_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.removedFromColdDateRight, kidneyTransplantDetails.removedFromColdTimeRight),
      clamp_removal_date_left_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.clampOffDateLeft, kidneyTransplantDetails.clampOffTimeLeft),
      clamp_removal_date_right_kid: this.sanitizeDateTimeApi(kidneyTransplantDetails.clampOffDateRight, kidneyTransplantDetails.clampOffTimeRight),
      vessels_used: kidneyTransplantDetails.vesselsUsed,
      list_of_vessels_used: kidneyTransplantDetails.listOfVessels,
      factors,
      donor_id: transplant.donorId ? { $oid: transplant.donorId } : null,
    };

    // Copy Details to Clustered Organs for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
    if (this.isClustered) {
      result.copy_to_cluster = transplant.copyToCluster;
    }

    return result;
  }
}
