
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { EP } from '@/api-endpoints';
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import { GenericCodeValue, ObjectId } from '@/store/types';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { SaveResult, SaveableSection, SaveProvider } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { RecipientJourney, RecipientJourneyTransferStatus, TransferDirection, JourneyOption, JourneyStage } from '@/store/recipientJourney/types';

export interface TransferListingPageState {
  transferredFrom?: string;
  transferFromDate?: string;
  otherProgramJourneyId?: ObjectId; // Used when journey is able to make a transfer
  transferredTo?: string; // Otherwise we show a read only transferredTo text
  transferToDate?: string;
  comments?: string;
}

const TRANSFER_LISTING_MODAL_ERRORS = [
  'validation.messages.activity_mixin_patch.expects_request_parameters:other_program_journey_id',
  'validation.messages.journey.must_be_on_waitlist',
  'validation.messages.transfer_to_journey.must_be_for_the_same_organ_code',
  'validation.messages.transfer_to_journey.must_have_different_hospital',
  'validation.messages.transfer_to_journey.cannot_be_destination_for_multiple_active_transfers',
  'validation.messages.transfer_to_journey.consultation_decision_must_be_completed',
  'validation.messages.transfer_to_journey.assessment_decision_must_be_to_be_listed',
  'validation.messages.related_journeys.must_all_be_on_waitlist',
  'validation.messages.transfer_to_cluster.must_include_same_organ_codes',
  'validation.messages.transfer_to_cluster.must_all_have_different_hospital',
  'validation.messages.transfer_to_cluster.cannot_be_destination_for_multiple_active_transfers',
  'validation.messages.transfer_to_cluster.all_consultation_decisions_must_be_completed',
  'validation.messages.transfer_to_cluster.all_assessment_decisions_must_be_to_be_listed',
];

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    SelectInput,
    ModalSection,
  },
})
export default class TransferListing extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.transferListing) editState!: TransferListingPageState;
  
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter('isWaitlisted', { namespace: 'journeyState' }) isWaitlisted!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('hasActiveTransfer', { namespace: 'journeyState' }) hasActiveTransfer!: boolean;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('mostRecentActiveTransfer', { namespace: 'journeyState' }) mostRecentActiveTransfer!: RecipientJourneyTransferStatus|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('describeTransferStatus', { namespace: 'journeyState' }) describeTransferStatus!: (transferStatus: RecipientJourneyTransferStatus, journey_id: string|null) => string;
  @Getter('buildJourneyOptions', { namespace: 'journeyState' }) buildJourneyOptions!: (journeys: RecipientJourney[], showHospital: boolean) => JourneyOption[];
  @Getter('transferDestinationJourneys', { namespace: 'journeyState' }) transferDestinationJourneys!: (recipient: Recipient, originatingJourney: RecipientJourney) => RecipientJourney[];

  @Prop({ default: false }) newJourney!: boolean;

  // Internal state variables that don't need to be shared across components
  private modalErrorMessages: string[] = [];

  // True only if user is permitted to save
  get isTransferListingCreateAuthorized(): boolean {
    return this.checkAllowed(EP.recipients.journeys.transferStatuses.activeTransfer.singleToSingle, 'PUT');
  }

  // True journey isn't new or completed and create action is allowed 
  get canSave(): boolean {
    return !this.newJourney
        && !this.hasActiveTransfer
        && this.isTransferListingCreateAuthorized
        && !this.journey.completed;
  }
  
  // Build custom select list for the Transfer To drop down
  get transferToOptions(): JourneyOption[] {
    if (!this.recipient || !this.journey) return [];

    // Identify which journeys are valid options
    const destinationJourneys = this.transferDestinationJourneys(this.recipient, this.journey);

    // Build options based on array of entire journeys.
    // Note: 'true' in optional second argument means hospital abbreviations are included.
    const options = this.buildJourneyOptions(destinationJourneys, true);
    return options;
  }

  // Process changes to Transferred To dropdown in edit state, which can affect Transfer Date
  private onTransferredToChanged(newValue: string|null): void {
    const transferToDate = this.editState?.transferToDate;
    if (newValue && !transferToDate) {
      // User changed Transferred To from blank to a journey, so populate Transfer Date
      Vue.set(this.editState, 'transferToDate', this.currentDateUi());
    } else if (!newValue && transferToDate) {
      // User cleared Transferred To selection, so clear Transfer Date
      Vue.set(this.editState, 'transferToDate', null);
    }
  }

  // Setup form state based on journey data
  private buildTransferListingState(transferStatuses?: RecipientJourneyTransferStatus[]): TransferListingPageState {
    // Here we initialize Transfer Date as blank, so it does not imply a Transfer that did not happen yet.
    // Note: requirement says it is populated IF journey was identified as 'Transfer To' value, i.e. on change
    const result: TransferListingPageState = {};

    // Check most recent Active/Cluster transfer
    const transferStatus = this.mostRecentActiveTransfer;
    if (!transferStatus) return result;

    // Popualte result object based on transfer status
    Object.assign(result, {
      otherProgramJourneyId: '',
    });
    switch (transferStatus.direction as TransferDirection) {
      case TransferDirection.To:
        Object.assign(result, {
          transferToDate: this.parseDateUi(transferStatus.status_changed_date),
          transferredTo: this.describeTransferStatus(transferStatus,this.journeyId || null),
        });
        break;
      case TransferDirection.From:
        Object.assign(result, {
          transferFromDate: this.parseDateUi(transferStatus.status_changed_date),
          transferredFrom: this.describeTransferStatus(transferStatus,null),
        });
        break;
    }
    return result;
  }

  // Setup request payload based on form state
  private extractTransferListingPatch(): any {
    const form = this.editState || {};
    const result: any = {
      id: form.otherProgramJourneyId || null,
    };
    return result;
  }

  // Loading
  private mounted(): void {
    this.initializeForm();
  }
  
  // Initialize Form
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'transferListing',
      value: this.buildTransferListingState(this.newJourney ? [] : (this.journey.transfer_statuses || [])),
    });
  }

  // Saving
  private savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransferListing as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'transferListing');
    // Generate payload based on current edit state
    const transferListingPatch = this.extractTransferListingPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      otherProgramJourneyId: transferListingPatch.id
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/createTransferListing', payload).then((success: SaveResult) => {
      // Reload recipient, journey, and journey durations
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
            // Re-initialize form state based on response from API
            this.initializeForm();
            // Report to parent that saving has completed so it can clear validations and reload waitlist decisions
            this.$emit('saved', 'transferListing');
          });
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle field-level validation errors
      this.$emit('handleErrors', error);
      // Check if the error includes any errors to show in popup modal
      if (this.checkModalErrors(error)) {
        // Show generic error in save toolbar
        saveProvider.registerSaveResult({
          success: false,
          errorMessages: [this.$t('transfer_listing_generic_error').toString()] 
        });
      } else {
        // Show error messages directly in the save toolbar
        saveProvider.registerSaveResult(error);
      }

    });
  }

  // String for secondary confirmation pop-up
  get confirmationText(): string {
    let key: string;
    if (this.isClustered) {
      // secondary confirmation prompt for 'cluster-to-cluster'
      key = 'transfer_listing_secondary_confirmation_cluster';
    } else {
      // secondary confirmation prompt for 'single-to-single' and 'single-to-cluster'
      key = 'transfer_listing_secondary_confirmation';
    }
    return this.$t(key).toString();
  }

  /**
   * Check if the error result needs an error popup modal
   *
   * Note: for /active_transfer endpoint, the activity-level errors are nested under 'journey' key
   * as single string property. That is why instead of checking errorMessages in the SaveResult
   * here as usual we instead need to check the SaveResult's validationErrors for the error code.
   *
   * @param error unsuccessful SaveResult from 'createTransferListing' action
   * @returns {boolean} true if we displayed the error modal, false if no modal errors detected
   */
  private checkModalErrors(error: SaveResult): boolean {
    const errorMessages = error?.errorMessages || [];
    const modalErrors = errorMessages.filter((errorMessage: string) => {
      return TRANSFER_LISTING_MODAL_ERRORS.includes(errorMessage);
    });
    if (modalErrors.length > 0) {
      this.displayErrorModal(modalErrors);
      return true;
    } else {
      return false;
    }
  }

  // Display error modal for the Transfer Listing section
  private displayErrorModal(modalErrorKeys: string[]): void {
    // Translate error keys to display messages
    const errorMessages: string[] = modalErrorKeys.map((errorKey: string): string => {
      return this.$t(errorKey).toString();
    });

    // Store error messages locally in this component
    Vue.set(this, 'modalErrorMessages', errorMessages);

    // Display the error modal
    const errorModal = this.$refs.transferListingErrorModal as ModalSection;
    if (errorModal) errorModal.showModal();
  }
}
