import { render, staticRenderFns } from "./SelectedRecipientHlaLinks.vue?vue&type=template&id=4829c11e&"
import script from "./SelectedRecipientHlaLinks.vue?vue&type=script&lang=ts&"
export * from "./SelectedRecipientHlaLinks.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/recipients/_locales/SelectedRecipientHlaLinks.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Frecipients%2Fside-nav%2FSelectedRecipientHlaLinks.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports