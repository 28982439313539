import { GetterTree } from 'vuex';
import { ObjectId, RootState } from '@/store/types';
import { CoordinatorsState, Coordinator, CoordinatorHospitalAssignments, CoordinatorOptions, CoordinatorOrganSpecialization } from '@/store/coordinators/types';
import { sortOptionsByValue } from '@/utils';
import { HospitalAssignment } from '../users/types';

// Map from a Coordinator document to a standardized dropdown option
function coordinatorOption(coordinator: Coordinator): CoordinatorOptions {
  const hospital_assignments = coordinator.hospital_assignments ? coordinator.hospital_assignments.map((item: CoordinatorHospitalAssignments) => { return item.hospital_id.$oid; }) : [];
  return {
    code: coordinator._id.$oid,
    value: `${coordinator.first_name} ${coordinator.last_name}`,
    hospital_assignments: hospital_assignments,
    expiry_date: coordinator.expiry_date || undefined,
  };
}

/**
* Maps a given coordinator's list to remove the invalid/expired coordinators from the list
* it preserves the selected coordinator by the current hospital.
*/
function activeCoordinators(coordinators: Coordinator[], hospitalId: string, coordinatorId: string, organCode: number, rootGetters: any): CoordinatorOptions[]{
  let activeCoordinators = [];
  const currentDateObject = new Date();
  activeCoordinators = coordinators.filter((coordinator: Coordinator) => {
    const hospitalAssignments: CoordinatorHospitalAssignments[] = coordinator.hospital_assignments || [];
    let organSpecialization = null;
    if(coordinatorId === coordinator._id.$oid) {
      return true;
    }

    const hospitalAssignment = hospitalAssignments.find((assignment: HospitalAssignment) => {
      return assignment.hospital_id?.$oid === hospitalId && (!assignment.expiry_date || !rootGetters["utilities/isExpired"](assignment.expiry_date));
    });
    
    if(hospitalAssignment && hospitalAssignment.organ_specializations) {
      organSpecialization = hospitalAssignment.organ_specializations.find((organSpecialization: CoordinatorOrganSpecialization) => {
        return organSpecialization.organ_code === organCode && (!organSpecialization.expiry_date || !rootGetters["utilities/isExpired"](organSpecialization.expiry_date));
      });
      return organSpecialization !== null && organSpecialization !== undefined;
    }
    return false;
  });

  if (activeCoordinators && activeCoordinators.length > 0) {
    let coordinatorsList = [];
    let sortedCoordinators = [];
    coordinatorsList = activeCoordinators.map((coordinator: Coordinator) => {
      return coordinatorOption(coordinator);
    });

    sortedCoordinators = sortOptionsByValue(coordinatorsList);
    return sortedCoordinators;
  }
  return [];
}

export const getters: GetterTree<CoordinatorsState, RootState> = {
  // Options for the per-hospital 'filtered' list of Coordinators used throughout a page
  coordinatorOptions(state, getters, rootState, rootGetters): CoordinatorOptions[] {
    const coordinators: Coordinator[] = state.filtered;
    if (coordinators && coordinators.length > 0) {
      let coordinatorsList = [];
      coordinatorsList = coordinators.map((coordinator: Coordinator) => {
        return coordinatorOption(coordinator);
      });
      return coordinatorsList;
    }
    return [];
  },

  // Options for the per-hospital 'filtered' list of Coordinators used throughout a page
  activeSortedCoordinatorOptions(state, getters, rootState, rootGetters): (hospitalId: string, coordinatorId: string, organCode: number) => CoordinatorOptions[] {
    return (hospitalId: string, coordinatorId: string, organCode: number): CoordinatorOptions[] =>{
      const coordinators: Coordinator[] = state.filtered;
      return activeCoordinators(coordinators, hospitalId, coordinatorId, organCode, rootGetters);
    };
  },

  // Sorted coordinator options by name.
  sortedCoordinatorOptions(state, getters, rootState, rootGetters): CoordinatorOptions[] {
    const coordinators: Coordinator[] = state.filtered;
    if (coordinators && coordinators.length > 0) {
      let coordinatorsList = [];
      let sortedCoordinators = [];
      coordinatorsList = coordinators.map((coordinator: Coordinator) => {
        return coordinatorOption(coordinator);
      });

      sortedCoordinators = sortOptionsByValue(coordinatorsList);
      return sortedCoordinators;
    }
    return [];
  },

  // Options for Post-Transplant Follow-Up Transfer Coordinators (may be different than 'filtered')
  followUpCoordinatorOptions(state, getters, rootState, rootGetters): (hospitalId: string, coordinatorId: string, organCode: number) => CoordinatorOptions[] {
    return (hospitalId: string, coordinatorId: string, organCode: number): CoordinatorOptions[] =>{
      if (!state.followUpCoordinators || state.followUpCoordinators.length === 0) return [];

      return activeCoordinators(state.followUpCoordinators, hospitalId, coordinatorId, organCode, rootGetters);
    };
  },
};
