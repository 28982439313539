
import { Getter, State } from "vuex-class";
import {Component, Vue} from "vue-property-decorator";
import ModalSection from '@/components/shared/ModalSection.vue';
import {SaveResult} from "@/types";

@Component({
  components: {
    ModalSection,
  }
})
export default class PotentialDuplicateModal extends Vue {
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  public modalDuplicateWarningMessage = '';
  public backEmitAction = '';

  public saveDuplicatePatch(): void {
    const targetModal = this.$refs.duplicateLivingDonorModal as ModalSection;
    targetModal.toggleModal();
    this.$emit(this.backEmitAction);
  }

  public checkingDuplicateWarning(error: SaveResult, backEmitAction: any): void {
    this.backEmitAction = backEmitAction;
    if(error.warning === true) {
      const targetModal = this.$refs.duplicateLivingDonorModal as ModalSection;
      this.modalDuplicateWarningMessage = this.translateError(error.warningMessage, null);
      targetModal.toggleModal();
    }
  }
}

