import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { prefixErrors, buildErrorResult } from '@/utils';
import { DecisionsState, ListDecisions, Decision, ListAttachments, Attachment, ListResponses, Response, ListFinalDecisions, FinalDecision } from '@/store/decisions/types';

export const actions: ActionTree<DecisionsState, RootState> = {
  // decisions ----- start
  newDecision({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setDecision', getters.new);
      resolve();
    });
  },
  getDecision({ commit, getters }, {recipientId, journeyId, decisionId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.show, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId]]);
      axios.get(url).then((response: any) => {
        const sanitizedData: Decision = response.data.decision;
        commit('setDecision', sanitizedData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  indexDecisions({ commit, getters }, { recipientId, journeyId, pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.index, [
        [':recipientId', recipientId],
        [':journeyId', journeyId],
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedData: Decision[] = response.data.decisions;
        commit('setListDecisions', sanitizedData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveDecision({ commit, getters }, { recipientId, journeyId, decisionId, decision }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any;
      let ep: string;
      const payload = { decision: decision };
      if (decisionId) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.decisions.update, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.decisions.create, [[':recipientId', recipientId], [':journeyId', journeyId]]);
      }
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  // decisions ----- end

  // attachments ----- start
  newAttachment({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setAttachment', getters.new);
      resolve();
    });
  },  
  getAttachment({ commit, getters }, {recipientId, journeyId, decisionId, attachmentId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.attachments.show, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':attachmentId', attachmentId]]);
      axios.get(url).then((response: any) => {
        const sanitizedData: any = response.data.attachment;
        commit('setAttachment', sanitizedData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  indexAttachments({ commit, getters }, { recipientId, journeyId, decisionId, pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.attachments.index, [
        [':recipientId', recipientId],
        [':journeyId', journeyId],
        [':decisionId', decisionId],
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedData: any[] = response.data.attachments;
        commit('setListAttachments', sanitizedData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveAttachment({ commit, getters }, { recipientId, journeyId, decisionId, attachmentId, attachment }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any;
      let ep: string;

      // Avoid appending in `undefined` values for keys that we shouldnt be sending because
      // the data is empty...
      const payload: FormData = new FormData();

      //...exception for description
      //because we need to be able to send a empty comment
      payload.append('attachment[description]', attachment.description || "");

      if (attachment.fileName) {
        payload.append('attachment[original_filename]', attachment.fileName);
      }

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      // We only allow the file to be uploaded on creates, not updates
      if(attachment.fileList && attachment.fileList[0]) {
        payload.append('attachment[file]', attachment.fileList[0]);
      }

      if (attachmentId) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.decisions.attachments.update, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':attachmentId', attachmentId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.decisions.attachments.create, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId]]);
      }
      method(ep, payload, headers).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  downloadAttachment({ commit, getters }, {recipientId, journeyId, decisionId, attachmentId}): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const ep = APIRoute(EP.recipients.journeys.decisions.attachments.show, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':attachmentId', attachmentId]]);
      axios.get(ep).then((response: any) => {
        resolve(response.data.attachment);
      });
    });
  },
  deleteAttachment({ commit, getters }, {recipientId, journeyId, decisionId, attachmentId}): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Deletion is handled by updating with soft-delete fields
      const payload = {
        deleted: true,
        deleted_date: new Date().toISOString()
      };

      const ep = APIRoute(EP.recipients.journeys.decisions.attachments.update, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':attachmentId', attachmentId]]);
      axios.patch(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  // attachments ----- end

  // responses ----- start
  newResponse({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setResponse', getters.new);
      resolve();
    });
  },  
  getResponse({ commit, getters }, {recipientId, journeyId, decisionId, responseId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.responses.show, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':responseId', responseId]]);
      axios.get(url).then((response: any) => {
        const sanitizedData: Decision = response.data.response;
        commit('setResponse', sanitizedData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  indexResponses({ commit, getters }, { recipientId, journeyId, decisionId, pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.responses.index, [
        [':recipientId', recipientId],
        [':journeyId', journeyId],
        [':decisionId', decisionId],
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedData: Decision[] = response.data.decision_responses;
        commit('setListResponses', sanitizedData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveResponse({ commit, getters }, { recipientId, journeyId, decisionId, responseId, response }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any;
      let ep: string;
      const payload = { decision_response: response };
      if (responseId) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.decisions.responses.update, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':responseId', responseId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.decisions.responses.create, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId]]);
      }
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  // responses ----- end

  // final decisions ----- start
  newFinalDecision({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setFinalDecision', getters.new);
      resolve();
    });
  },
  getFinalDecision({ commit, getters }, {recipientId, journeyId, decisionId, finalDecisionId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.finalDecisions.show, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':finalDecisionId', finalDecisionId]]);
      axios.get(url).then((response: any) => {
        const sanitizedData: Decision = response.data.final_decision;
        commit('setFinalDecision', sanitizedData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  indexFinalDecisions({ commit, getters }, { recipientId, journeyId, decisionId, pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.journeys.decisions.finalDecisions.index, [
        [':recipientId', recipientId],
        [':journeyId', journeyId],
        [':decisionId', decisionId],
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedData: Decision[] = response.data.final_decisions;
        commit('setListFinalDecisions', sanitizedData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveFinalDecision({ commit, getters }, { recipientId, journeyId, decisionId, finalDecisionId, final_decision }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      let method: any;
      let ep: string;
      const payload = { final_decision: final_decision };
      if (finalDecisionId) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.journeys.decisions.finalDecisions.update, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId], [':finalDecisionId', finalDecisionId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.journeys.decisions.finalDecisions.create, [[':recipientId', recipientId], [':journeyId', journeyId], [':decisionId', decisionId]]);
      }
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  // final decisions ----- end
};
