
import { urlParams } from '@/utils';
import { Getter, State } from 'vuex-class';
import { ObjectId } from '@/store/types';
import { GenericCodeValue } from '@/store/types';
import { LivingAllocation } from '@/store/livingAllocations/types';
import { Recipient } from '@/store/recipients/types';
import { Hospital } from '@/store/hospitals/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';

interface AddRecipientPageState {
  recipientId?: string;
  transplantProgramId?: string;
  reason?: string;
  // Variables for tag inputs
  recipientIdInput?: string;
  recipientIdTags?: { text: string, tiClasses: string[] }[];
}

// Constants related to recipient ID searching
const INDEX_PAGE_SIZE = 25;
const INDEX_PAGE = 1;

@Component({
  components: {
    TextInput,
    SelectInput,
    ModalSection,
    VueTagsInput,
    TextAreaInput,
  }
})
export default class AddRecipientModal extends Vue {
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.pageState.currentPage.addRecipientToLivingAllocation) editState!: AddRecipientPageState;
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isAddingRecipient) private isAddingRecipient!: boolean;

  // TODO: TECH_DEBT: recipient listing items should be defined in recipients/types
  @Getter('showList', { namespace: 'recipients' }) public recipients!: { entries: any[] };
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('recipientDisplayName', { namespace: 'recipients' }) private recipientDisplayName!: string;
  @Getter('oopHospitalsByOrgan', { namespace: 'hospitals' }) private oopHospitalsByOrgan!: (organCode: string) => Hospital[];
  
  public addRecipientToLivingAllocationError = '';
  
  /**
   * Get the organ_code param from the url.
   *
   * @returns {string} the organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code.toString() || '';
  }

  get getHospitals(): Hospital[] {
    return this.oopHospitalsByOrgan(this.organCode);
  } 

  /**
   * Return select list of options for Hospitals
   * 
   * @returns {GenericCodeValue[]} options for a select list
   */
  get hospitalOptions(): GenericCodeValue[] {
    const hospitalOptions: Hospital[] = this.getHospitals;
    if (hospitalOptions.length === 0) return [];
    return hospitalOptions.map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : ''
      };
    });
  }

  /**
   * Get the selectedRecipient OHIP number
   * 
   * @returns {string} OHIP number for the selectedRecipient
   */
  get ohipNumber(): string {
    if (!this.recipient) return '';
    return this.recipient.patient_profile?.insurance?.number || '';
  }
  
  /**
   * Get the selectedRecipient waitlisted transplant program 
   * 
   * @returns {string} the transplant program name
   */
  get transplantProgram(): string {
    if (!this.recipient) return '';
    
    const recipientJourneys: RecipientJourney[] = this.recipient.journeys || [];
    // Find the journey that matches our organ and has stage of waitlist
    const waitlistedJourney = recipientJourneys.find((journey: RecipientJourney) => {
      return `${journey.organ_code}` === this.organCode && journey.stage === 'waitlist';
    });
    
    // No journey then no transplant program
    if (!waitlistedJourney) return '';
    
    const hospitalId = waitlistedJourney?.transplant_program?.transplant_hospital_id?.$oid;
    const recipientHospital = this.getHospitals.find((hospital: Hospital) => {
      return hospital._id.$oid === hospitalId;
    });
    
    return recipientHospital ? recipientHospital.hospital_name_info?.name : '';
  }
  
  /**
   * Can we save the form
   * 
   * @returns {boolean} true if we can save
   */
  get canSave(): boolean {
    if (!this.editState || !this.editState.reason) return false;
    if (this.recipient) return true;
    if (!this.recipient && this.editState.transplantProgramId) return true;
    
    return false;
  }

  // Clear state and open modal 
  public initialize() {
    // Reset any error message
    this.addRecipientToLivingAllocationError = '';
    // Clear any previously selectedRecipient
    this.$store.commit('recipients/clearRecipient');
    // Clear any validation errors
    (this.$refs.addRecipientValidations as any).reset();
    // Initialize a blank state and open the modal
    this.initializePageState();
    this.openModal();
  }

  // PRIVATE

  private mounted(): void {
    // Load oop hospitals
    this.$store.dispatch('hospitals/load', 'oop');
    // Load recipients
    this.loadRecipients();
  }

  // Identify a Recipient ID to use in the search query
  get recipientIdForQuery(): string {
    // If there is a tag, then use that (only option if value was pasted)
    const recipientIdTags = this?.editState?.recipientIdTags || [];
    if (recipientIdTags.length > 0) {
      // We only allow one entry, so getting the first item should be sufficient
      const recipientIdTagText = recipientIdTags[0]?.text;
      return recipientIdTagText || '';
    }

    // If there is no tag, then we should be using the tag text input entry value
    const recipientIdInput = this?.editState?.recipientIdInput;
    return recipientIdInput || '';
  }

  // Load recipients based on Recipient ID search input
  private loadRecipients(): Promise<void> {
    const recipientId = this.recipientIdForQuery;
    return new Promise<void>((resolve, reject) => {
      const query = urlParams({
        client_id: recipientId,
      });
      const search_params = query ? `&${query}` : '';
      const opts = {
        pageNumber: INDEX_PAGE,
        pageSize: INDEX_PAGE_SIZE,
        search_params,
      };
      this.$store.dispatch('recipients/getList', opts).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  // Reload recipient options before adding tags
  private beforeRecipientIdChanged(newTag: any): void {
    // Search recipients for Recipient ID
    this.loadRecipients().then(() => {
      // Add the tag after the search has completed, so that validations
      // will be based on a recipient index containing the tagged ID
      newTag.addTag();
    });
  }

  // Open a static modal
  private openModal(): void {
    const targetModal = this.$refs.addRecipient as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Build empty pageState
  private initializePageState(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'addRecipientToLivingAllocation',
      value: { 
        recipientId: undefined, 
        transplantProgramId: undefined, 
        reason: undefined,
        recipientIdInput: '',
        recipientIdTags: [],
      }
    });
    this.initializeTagInputs();
  }

  // Set initial tag objects based on underlying model variables
  private initializeTagInputs(): void {
    // Recipient ID
    const recipientIdTags = this.editState.recipientId ? createTags(this.editState.recipientId) : [];
    Vue.set(this.editState, 'recipientIdTags', recipientIdTags);
  }

  // Array of recipients included in the filter options
  get recipientsIncluded(): any[] {
    if (!this.recipients) return [];

    const entries = this.recipients.entries || [];
    return entries;
  }

  // Recipient ID autocomplete options for vue-tags-input
  get recipientIdAutocompleteOptions(): { text: string, hint: string }[] {
    const options: any[] = this.recipientsIncluded;
    const autocompleteOptions: { text: string, hint: string }[] = options.map((recipient: any): { text: string, hint: string } => {
      return {
        text: `${recipient.client_id}`,
        hint: [recipient?.first_name, recipient?.last_name].join(' '),
      };
    });
    return autocompleteOptions;
  }

  // Limit dropdown options to values that match input
  get filteredRecipientIdAutocompleteOptions(): { text: string }[] {
    const options = this.recipientIdAutocompleteOptions || [];
    const input = this?.editState?.recipientIdInput;
    if (!this.editState || !input) {
      return options;
    }
    const filtered = options.filter((option: { text: string }) => {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
    return filtered;
  }

  // Validate manual entry of recipient ID to match list of included values
  private validateRecipientIdTag(): { classes: string, rule: any }[] {
    const _recipientIDsIncluded = [...this.recipientsIncluded].map((recipient: any): string => {
      return `${recipient?.client_id}`;
    });
    return [
      {
        classes: 'invalid-recipient-id',
        rule: ((tag: { text: string }) => {
          return !_recipientIDsIncluded.includes(tag.text.toUpperCase());
        }),
      },
      {
        classes: 'only-numbers',
        rule: /^([0-9]*)$/,
      }
    ];
  }

  // Update recipient ID model based on tags
  private onRecipientIdChanged(tags: { text: string }[]): void {
    const recipientIds = tags.map((tag: { text: string}) => {
      return tag.text;
    });
    const firstRecipientId = recipientIds.length > 0 ? recipientIds[0] : null;
    const recipientIdTags = createTags(recipientIds, this.validateRecipientIdTag());
    Vue.set(this.editState, 'recipientId', firstRecipientId);
    Vue.set(this.editState, 'recipientIdTags', recipientIdTags);

    // Load additional details about the selected recipient
    this.getRecipientInfo(firstRecipientId);
  }

  /*
   * In order to ensure that the autocomplete options correspond to the input,
   * we must reload the recipients after the 'embedded text input' v-model changes.
   *
   * Note: this also impacts which tag entries are considered valid
   */
  @Watch('editState.recipientIdInput')
  private onRecipientIdInputChanged(): void {
    this.loadRecipients();
  }

  // Get the Recipient details or show an error on the recpient field
  private getRecipientInfo(recipientClientId?: string|null): void {    
    // Clear any previous selectedRecipient
    this.$store.commit('recipients/clearRecipient');
    if (!recipientClientId) return;

    this.$store.dispatch('recipients/get', recipientClientId).then(() => {
      return;
    }).catch((error: any) => {
      // Show error that recipient isn't valid
      (this.$refs.addRecipientValidations as any).setErrors({'recipient-client-id': [this.$t('recipient_id_invalid').toString()]});
    });
  }

  // Extract a patch from the editState
  private extractPatch(): AddRecipientPageState {
    if (!this.editState) return {};
    
    const recipientId = this.recipient ? `${this.recipient.client_id}` : undefined;
    const transplantProgramId = this.editState.transplantProgramId;
    
    // Only send recipient or transplant program
    return {
      recipientId: transplantProgramId ? undefined : recipientId,
      transplantProgramId: recipientId ? undefined : transplantProgramId,
      reason: this.editState.reason,
    };
  }

  // Attempt to add this Recipient or Program to the Allocation
  private saveRecipient(): void {
    // Payload for our patch
    const payload = {
      clientId: this.clientId,
      organCode: this.organCode,
      allocationId: this.allocation._id, 
      ...this.extractPatch()
    };
    this.$store.commit('livingAllocations/startAddingRecipient');
    this.$store.commit('livingAllocations/startLoading');
    this.$store.dispatch('livingAllocations/addRecipient', payload).then((success: any) => {
      // Close the modal
      (this.$refs.addRecipient as ModalSection).hideModal();
      // Reload the Allocation
      this.$store.commit('livingAllocations/stopAddingRecipient');
      this.$emit('reloadAllocation');
    }).catch((error: any) => {
      // Show the error if any
      this.addRecipientToLivingAllocationError = error.validationErrors?.allocation_service;
      this.$store.commit('livingAllocations/stopLoading');
      this.$store.commit('livingAllocations/stopAddingRecipient');
    });
  }
}
