import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class UrlUtilsMixin extends Vue {

  getUrlQueryValue(q: string): string|null {
    let term: string|null = null;
    if(this.$route.query[q]) {
      // Since TS cannot currently understand that the Array.isArray check against an assignment
      // we are forced to ignore this for a bit
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      term = Array.isArray(this.$route.query[q]) ? this.$route.query[q][0] : this.$route.query[q];
    }

    return term;
  }

  /**
   * set access_type (either fusionauth or oneid)
   * used to determine what the next stage of authentication will be
   *
   * @param type string
   * @param replace parameters to replace in path
   * @returns {string}
   */
  prepareRequest(type: string) {
    localStorage.removeItem('access_type');
    localStorage.setItem('access_type', type);
  }
}
