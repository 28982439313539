
import { State, Getter } from 'vuex-class';
import { DeceasedDonor, DeceasedDonorAllocationSummary } from '@/store/deceasedDonors/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedDeceasedDonorHlaLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorHlaLinks.vue";
import SelectedDeceasedDonorOrganLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorOrganLinks.vue";
import SelectedDeceasedDonorProfileLinks from "@/components/deceasedDonors/side-nav/SelectedDeceasedDonorProfileLinks.vue";


@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedDeceasedDonorHlaLinks,
    SelectedDeceasedDonorOrganLinks,
    SelectedDeceasedDonorProfileLinks,
  }
})
export default class SideNavDeceasedDonor extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  @Getter('selectedDonorConsentedOrganList', { namespace: 'deceasedDonors'}) private selectedDonorConsentedOrganList!: DeceasedDonorAllocationSummary[];
  @Getter('getDonorsUrl', { namespace: 'users' }) private getDonorsUrl!: string;

  @Prop({default: false }) newDonor!: boolean;

  public sideNavClosed = false;
  
  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
