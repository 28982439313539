
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { HistoryLiverSmc } from '@/store/organSpecificDetails/types';

interface HistoryLiverSmcRow {
  _id?: { $oid: string }|null;
  scoreDate?: string;
  scoreTime?: string;
  score_source?: string;
  score?: string;
}

@Component({
  components: {
    SubSection,
  }
})
export default class ExceptionPoints extends mixins(DateUtilsMixin) {
  @State(state => state.organSpecificDetails.historyLiverSmc) private historyLiverSmc!: HistoryLiverSmc[];

  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Gets table row data for the Exception Points table
   *
   * @returns {HistoryLiverSmcRow[]} Exception Points table rows
   */
  get historyLiverSmcRows(): HistoryLiverSmcRow[] {
    const historyLiverSmc = this.historyLiverSmc || [];
    const result: HistoryLiverSmcRow[] = historyLiverSmc.map((smc: HistoryLiverSmc) => {
      const scores = smc?.exception_points_scores || {};
      return {
        _id: smc._id,
        scoreDate: this.parseDisplayDateUiFromDateTime(smc.date),
        scoreTime: this.parseTimeUiFromDateTime(smc.date),
        score_source: this.$t(this.lookupValue(smc?.score_source_code, 'liver_score_source_codes')).toString(),
        score: smc.score != null ? smc.score.toString() : '-',        
      };
    });
    return result;
  }
  
  /**
   * Gets configuration for the Exception Points table
   *
   * @returns {TableConfig} Exception Points Table configuration
   */
  get historyLiverSmcTableConfig(): TableConfig {
    return {
      data: this.historyLiverSmcRows || [],
      columns: [
        { label: this.$t('date_column').toString(), field: 'scoreDate', width: '33%' },
        { label: this.$t('smc_score_column').toString(), field: 'score', width: '33%' },
        { label: this.$t('smc_score_source_column').toString(), field: 'score_source', width: '33%' },
      ],
      empty: this.historyLiverSmc ? this.$t('exception_points_empty_table').toString() : this.$t('loading').toString(),
      createButton: false,
      pagination: true
    };
  }
}
