
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseWidget extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) preferences!: any;

  get getStyle(): string {
    const style = this.preferences ? this.preferences.style : null;
    let className = '';

    if(this.preferences && !this.preferences.visible) {
      className = 'd-none';
    } else {
      switch(style) {
        case 'full':
          className =  'col-12';
          break;
        case 'half':
          className =  'col-6';
          break;
        case 'one-third':
          className =  'col-4';
          break;
        case 'two-thirds':
          className =  'col-8';
          break;
        default:
          className =  'col-12';
          break;
      }
    }

    return className;
  }
}
