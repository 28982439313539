import { MutationTree } from 'vuex';

import { DashboardSettingState, DashboardState } from '@/store/dashboard/types';
import Vue from 'vue';

export const mutations: MutationTree<DashboardState> = {
  setNotifications(state, notifications) {
    // Store notifications
    Vue.set(state, 'notifications', notifications);
  },
  setAllocations(state, allocations) {
    // Store active allocations
    Vue.set(state, 'allocations', allocations);
  },
  setPrimaryOffers(state, offers) {
    // Store primary offers
    Vue.set(state, 'primaryOffers', offers);
  },
  setBackupOffers(state, offers) {
    // Store backup offers
    Vue.set(state, 'backupOffers', offers);
  },
  setBackupOffersAbortController(state, abortController: AbortController) {
    // Store backup offers
    Vue.set(state, 'backupOffersAbortController', abortController);
  },
  setPrimaryOffersAbortController(state, abortController: AbortController) {
    // Store backup offers
    Vue.set(state, 'primaryOffersAbortController', abortController);
  },
  setLabDonorResultsAbortController(state, abortController: AbortController) {
    // Store backup offers
    Vue.set(state, 'labDonorResultsAbortController', abortController);
  },
  setExdDonorsAbortController(state, abortController: AbortController) {
    // Store backup offers
    Vue.set(state, 'exdDonorsAbortController', abortController);
  },
  setTransplantInProgressAbortController(state, abortController: AbortController) {
    // Store backup offers
    Vue.set(state, 'transplantInProgressAbortController', abortController);
  },
  setBookmarksEntries(state, entries) {
    // Store bookmarks
    Vue.set(state.bookmarks, 'entries', entries);
  },
  setActiveDonors(state, donors) {
    // Store active donors
    Vue.set(state, 'activeDonors', donors);
  },
  setRecentRecipients(state, recipients) {
    // Store active donors
    Vue.set(state, 'recentRecipients', recipients);
  },
  setLabResults(state, lab_results) {
    // Store active donors
    Vue.set(state, 'labResults', lab_results);
  },
  setRecentLivingDonors(state, donors) {
    // Store active donors
    Vue.set(state, 'recentLivingDonors', donors);
  },
  setUrgentListings(state, listings) {
    // Store active donors
    Vue.set(state, 'urgentListings', listings);
  },
  setExdDonors(state, listings) {
    // Store active donors
    Vue.set(state, 'exdDonors', listings);
  },
  setTransplantInProgress(state, transplants) {
    // Store transplants in progress
    Vue.set(state, 'transplantInProgress', transplants);
  },
  setWidgetRolePermissions(state, rolePermissions) {
    // Store dashboard widget permissions by role
    Vue.set(state, 'widgetRolePermissions', rolePermissions);
  }
};
